
import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from 'axios'
import { USER_CLIENT,USER_TASK_TEMPLATES } from "./CONSTANTS";
const auth = localStorage.getItem('itemOxygenAuth')

const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': auth != undefined ? "Basic " + auth : ''
    // 'Authorization': url.Auth_Token
}


export const addRequest = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_CLIENT() + "addRequestForOperator", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addRequestForOperator axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const getRequestList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_CLIENT() + "requestListForClient", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getRequestList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getUserRequestList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_TASK_TEMPLATES() + "taskWorkOrderListForUserByDate", data,{
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in requestListForClientByClientId axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};