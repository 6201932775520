import React, { useEffect, useState } from "react";
import { CommanComponents } from "../components/index";
import DataTable, {
  createTheme,
  FilterComponent,
} from "react-data-table-component";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { Modal, Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Select from "react-select";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import $ from "jquery";
import loader from "../assets/img/content/loader.svg";
import { Multiselect } from "multiselect-react-dropdown";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  getOperatorList,
  addOperator,
  activeOperator,
  inActiveOperator,
  deleteOperator,
  updateOperator,
  getOperatorDetails,
  getClientKeyValueList,
} from "../services/index";

export const Operators = (props) => {
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date1 = curr.toISOString()?.substring(0, 10);

  const [rows, setRows] = useState([]);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [hospitalsKeyValue, setHospitalsKeyValue] = useState([]);
  const [hospitalValue, setHospitalValue] = useState([]);
  const [selectedHospitalValue, setSelectedHospitalValue] = useState([]);
  //const [plainArray,setPlainArray]= useState(["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"])
  const objectArray = [
    { key: "Option 1", cat: "Group 1" },
    { key: "Option 2", cat: "Group 1" },
    { key: "Option 3", cat: "Group 1" },
    { key: "Option 4", cat: "Group 2" },
    { key: "Option 5", cat: "Group 2" },
    { key: "Option 6", cat: "Group 2" },
    { key: "Option 7", cat: "Group 2" },
  ];

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleHospital = (selectedItem) => {
    console.log(selectedItem);
    setSelectedHospitalValue(selectedItem);
    //setHospital(e)
  };

  const handleRemove = (selectedItem) => {
    console.log(selectedItem);
    setSelectedHospitalValue(selectedItem);
    //setHospital(e)
  };

  /* function onSelect(selectedList, selectedItem) {
        console.log(selectedItem);
        setHospitalValue({
            ...hospitalValue,
            clientId: selectedItem.label
          })
      } */

  /* const selectedHospital =[
        { label: 'Mukeshkumawat', value: 'd9292218-25ce-4b3f-ad96-89155c34e7ca'},
        { label: 'ytrytrytrtrytr', value: '1ab6e556-f01d-4393-87c1-dcc5a06b071b'}
     ] */

  const [genderKeyValue, setGenderKeyValue] = useState([
    {
      value: "MALE",
      label: "Maschio",
    },
    {
      value: "FEMALE",
      label: "Femmina",
    },
  ]);

  const columns = [
    {
      name: "Nome e cognome",
      selector: (row) => row.fullName,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Stato",
      cell: (row) => (
        <>
          {row.active ? (
            <span className="badge badge-success">Attivo</span>
          ) : (
            <span className="badge badge-danger">Inattivo</span>
          )}
        </>
      ),
    },
    {
      name: "Azione",
      cell: (row) => (
        <>
          <ModeEditOutlineOutlinedIcon
            style={{ color: "blue" }}
            onClick={() => handleEditOperator(row.recordId)}
          />

          <DeleteOutlineOutlinedIcon
            style={{ color: "red" }}
            onClick={() => handleDeleteOperator(row.recordId)}
          />
        </>
      ),
    },
  ];

  const handleEditOperator = (id) => {
    $(".se-pre-con").css("display", "block");
    getOperatorDetails(id)
      .then((res) => {
        if (res.errorCode == 0) {
          $(".se-pre-con").css("display", "none");
          let d = res.responsePacket;
          setShow(true);
          setEdit(true);
          setAddRequestData(d);
          let dict = {
            value: d.gender,
            label: d.gender,
          };
          let arr = [];
          let vk = d.associatedClientId.split(",");
          vk?.map((data_get) => {
            console.log(data_get);

            hospitalsKeyValue?.map((data) => {
              if (data_get == data.value) {
                let temp = {
                  label: data.label,
                  value: data.value,
                };
                console.log(temp);
                arr.push(temp);
              }
            });
          });
          console.log(arr);
          setSelectedHospitalValue(arr);
          //debugger;

          /*  hospitalsKeyValue?.map((data) => {
                    if (data.value === d.associatedClientId) {
                        let temp = {
                            value: data.value,
                            label: data.label,
                        }
                        setHospitalValue(temp)
                    }
                }) */

          setAddRequestData({
            ...addRequestData,
            firstName: d.fullName.split(" ")[0],
            lastName: d.fullName.split(" ")[1],
            birthDate: d.birthDate,
            mobile: d.mobile,
            email: d.email,
            recordId: d.recordId,
            password: d.password,
            gender: dict,
          });
        } else {
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((err) => {
        $(".se-pre-con").css("display", "none");
        toast(err, {
          icon: "🙁",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });
  };

  const handleDeleteOperator = (id) => {
    Swal.fire({
      title: "Sei sicuro?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, conferma !!",
      cancelButtonText: "No !!",
    }).then((result) => {
      if (result.isConfirmed) {
        //Active Request
        $(".se-pre-con").css("display", "block");
        deleteOperator(id)
          .then((res) => {
            if (res.errorCode == 0) {
              $(".se-pre-con").css("display", "none");
              toast(res.message, {
                icon: "👏",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
              getOperator(0);
            } else {
              $(".se-pre-con").css("display", "none");
              toast(res.message, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            }
          })
          .catch((err) => {
            $(".se-pre-con").css("display", "none");
            toast(err, {
              icon: "🙁",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          });
      }
    });
  };

  const handleShowAdd = () => {
    setShow(true);
    setEdit(false);
  };

  const [addRequestData, setAddRequestData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    mobile: "",
    password: "",
    birthDate: date1,
    city: "",
    province: "",
    zipCode: "",
    country: "",
    photoImageUrl: "",
    notes: "",
    recordId: "",
  });

  const handleSaveHospital = () => {
    let selectedAssociatedClientId = "";
    console.log(selectedHospitalValue);
    for (var i = 0; i < selectedHospitalValue.length; i++) {
      console.log(selectedHospitalValue[i].value);
      selectedAssociatedClientId =
        selectedAssociatedClientId + selectedHospitalValue[i].value + ",";
    }

    let dict = {
      firstName: addRequestData.firstName,
      lastName: addRequestData.lastName,
      gender: addRequestData.gender.value,
      birthDate: new Date(addRequestData.birthDate),
      mobile: addRequestData.mobile,
      email: addRequestData.email,
      password: addRequestData.password,
      associatedClientId: selectedAssociatedClientId,
      //"associatedClientName": hospitalValue.selectedValues,
      recordId: addRequestData.recordId,
    };

    if (
      dict.associatedClientId === undefined ||
      dict.associatedClientId === ""
    ) {
      toast("Prego entra Ospedale !", {
        icon: "🙁",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    } else if (dict.firstName === undefined || dict.associatedClientId === "") {
      toast("Prego entra primo nome !", {
        icon: "🙁",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    } else if (dict.lastName === undefined || dict.lastName === "") {
      toast("Prego entra Ultimo nome !", {
        icon: "🙁",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    } else if (dict.gender === undefined || dict.gender === "") {
      toast("Si prega di selezionare Genere !", {
        icon: "🙁",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    } else if (dict.email === undefined || dict.email === "") {
      toast("Prego entra e-mail !", {
        icon: "🙁",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    } else if (dict.mobile === undefined || dict.mobile === "") {
      toast("Prego entra il numero di cellulare !", {
        icon: "🙁",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    } else if (dict.password === undefined || dict.password === "") {
      toast("Prego entra password !", {
        icon: "🙁",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }
    console.log(dict, "dict");
    $(".se-pre-con").css("display", "block");
    {
      edit
        ? updateOperator(addRequestData.recordId, dict)
            .then((res) => {
              if (res.errorCode == 0) {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "👏",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });
                setAddRequestData({
                  email: "",
                  fullName: "",
                  mobile: "",
                  password: "",
                });
                setShow(false);

                getOperator(0);
              } else {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "🙁",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });
              }
            })
            .catch((err) => {
              $(".se-pre-con").css("display", "none");
              toast(err, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            })
        : addOperator(dict).then((res) => {
            if (res.errorCode == 0) {
              $(".se-pre-con").css("display", "none");
              toast(res.message, {
                icon: "👏",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
              setAddRequestData({
                email: "",
                fullName: "",
                mobile: "",
                password: "",
              });
              setShow(false);

              getOperator(0);
              setEdit(false);
            } else {
              $(".se-pre-con").css("display", "none");
              toast(res.message, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            }
          });
    }
  };

  const handleActiveInActive = (status, uuid) => {
    Swal.fire({
      title: "Sei sicuro?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, conferma !!",
      cancelButtonText: "No !!",
    }).then((result) => {
      if (result.isConfirmed) {
        //Active Request
        if (status) {
          $(".se-pre-con").css("display", "block");
          activeOperator(uuid)
            .then((res) => {
              if (res.errorCode == 0) {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "👏",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });
                setRows([]);
                getOperator(0);
              } else {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "🙁",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });
              }
            })
            .catch((err) => {
              $(".se-pre-con").css("display", "none");
              toast(err, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            });
        }
        //InActive Request
        else {
          $(".se-pre-con").css("display", "block");
          inActiveOperator(uuid)
            .then((res) => {
              if (res.errorCode == 0) {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "👏",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });
                setRows([]);
                getOperator(0);
              } else {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "🙁",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });
              }
            })
            .catch((err) => {
              $(".se-pre-con").css("display", "none");
              toast(err, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            });
        }
      } else {
        $(".se-pre-con").css("display", "none");
      }
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // fetchData(page, newPerPage);
    getOperator(0, newPerPage);
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    getOperator(page - 1);
    setCurrentPage(page);
  };

  const getOperator = async (page, size = perPage) => {
    var rId = localStorage.getItem("rId");
    if (rId != undefined) {
      var recordIdForList = rId;
    } else {
      var recordIdForList = "";
    }
    var dict = {
      requestedPage: page,
      requestedPageSize: size,
      searchKey: "",
      clientId: recordIdForList,
    };
    $(".se-pre-con").css("display", "block");
    getOperatorList(dict)
      .then((res) => {
        $(".se-pre-con").css("display", "none");
        setRows(res.data);
        setTotalRows(res.recordsTotal);
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    getOperator(0);
    $(".se-pre-con").css("display", "block");
    getClientKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        $(".se-pre-con").css("display", "none");
        const options = res.responsePacket.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setHospitalsKeyValue(options);
      } else {
        alert(res.message);
        $(".se-pre-con").css("display", "none");
      }
    });
  }, []);

  useEffect(() => {
    console.log(addRequestData, "addRequestData");
  }, [addRequestData]);
  return (
    <>
      <Modal
        show={show}
        className={show ? "is-active is-animate" : ""}
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title>
            {edit ? "Aggiorna il Operatori" : "Operatori"}
          </Modal.Title>
        
            <CancelIcon className="pointer" onClick={() => setShow(false)} />
        
        </Modal.Header>
        <Modal.Body>
          <table>
            <tr>
              <td style={{ width: "200px" }}>Ospedale</td>
              <td style={{ minWidth: "250px" }}>
                <Multiselect
                  options={hospitalsKeyValue}
                  selectedValues={selectedHospitalValue}
                  displayValue="label"
                  onSelect={handleHospital}
                  onRemove={handleRemove}
                />
              </td>
            </tr>
            <tr>
              <td>Nome di battesimo</td>
              <td>
                <input
                  
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Entra primo"
                  value={addRequestData.firstName}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      firstName: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Ultimo Nome</td>
              <td>
                <input
                  
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Ultimo"
                  value={addRequestData.lastName}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      lastName: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Sesso</td>
              <td>
                <Select
                  options={genderKeyValue}
                  name="roundOffTo"
                  value={addRequestData.gender}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      gender: e,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Data di nascita</td>
              <td>
                <input
                style={{width:"250px"}}
                  class="form-control"
                  type="date"
                  value={addRequestData.birthDate}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      birthDate: e.target.value,
                    })
                  }
                />

               
              </td>
            </tr>

            <tr>
              <td>Email</td>
              <td>
                {" "}
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Email"
                  value={addRequestData.email}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      email: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Mobile"
                  value={addRequestData.mobile}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      mobile: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>Password</td>
              <td>
                <input
                  type="password"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  value={addRequestData.password}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      password: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
          </table>
        </Modal.Body>
        <Modal.Footer><Button variant="primary" onClick={handleSaveHospital}>
              {edit ? "Aggiorna operatore" : "Salva"}
            </Button></Modal.Footer>
      </Modal>
      <div>
        <div className="sidebar-backdrop" />
        <div className="page-wrapper">
          <CommanComponents />
          <main className="page-content">
            <div className="container">
              <div className="page-header justify-content-between align-items-center d-flex gap-1">
                <div>
                  <h1 className="page-header__title">Operatori</h1>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm d-flex justify-content-center gap-1"
                    onClick={handleShowAdd}
                  >
                    <i className="fa fa-plus"></i>Aggiungi operatore
                  </button>
                </div>
              </div>
              <div className="row">
                <div style={{ height: "100%", width: "100%" }}>
                  <DataTable
                    columns={columns}
                    data={rows}
                    theme="solarized"
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}

                    // expandableRows
                    // expandableRowsComponent={ExpandedComponent}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <ToastContainer />
      <div className="se-pre-con">
        <img src={loader} />
      </div>
    </>
  );
};
