import React, { useState, useEffect } from 'react'
import { Route, Link, BrowserRouter as Router, Switch } from "react-router-dom";
import { CommanComponents } from "../components/index"
import { openClientNotification, getMessageList, sendMessage } from "../services/index"
import $ from "jquery"
import moment from 'moment';
import userOne from "../assets/img/content/humans/userone.png";
import userSecond from "../assets/img/content/humans/usersecond.png";
import loader from "../assets/img/content/loader.svg"
import TextField from '@mui/material/TextField';

import Select from 'react-select';

const ViewClientNotification = () => {

    const [notification, setNotification] = useState([]);
    const [chatUser, setChatUser] = useState([]);
    const [chatMessage, setChatMessage] = useState([]);
    const [message, setMessage] = useState("")
    const [ticketValue, setTicketValue] = useState()
    
    const ticketOptions = [
        { label: "Ticket Open", value: "REQUESTED" },
        { label: "Ticket Closed", value: "CLOSED" }
    ];

    const [addRequestData, setAddRequestData] = useState({
        requestDate:new Date(),
    })

    const filterTicketStatus = (e) =>{
        console.log(e);
        setTicketValue({
            ...ticketValue,
            ticketOptions: e.value
        })

        let robj={
            requestStatus:e.value
        }
        /* getTicketList(robj).then((res) => {
            if (res.errorCode === 0) {
                $('.se-pre-con').css('display','none');
                const data=res.responsePacket;
                setNotification(data)
                       
            }
        }) */
    }

    const searchRecordByDate = () =>{

        var rId = localStorage.getItem("rId")
        if (rId != undefined) {
            var recordIdForList = rId
        }
        else {
            var recordIdForList = ""
        }

        let obj={
            clientId:recordIdForList,
            requestDate: moment(addRequestData.requestDate).format("YYYY-MM-DD")
        }   
        /* getSearchByDate(obj).then((res) => {
            //console.log(res);
            if (res.errorCode === 0) {
                $('.se-pre-con').css('display','none');
                const data=res.responsePacket;
                setNotification(data)
            }

        }) */
    }

    const handleOpenNotification = () => {
        $('.se-pre-con').css('display','block');

        var rId = localStorage.getItem("rId")
        if (rId != undefined) {
              var recordIdForList = rId
        }
        else {
              var recordIdForList = ""
        }
        var dict = {
          "requestedPage": 0,
          "requestedPageSize": 1000000,
          "clientId":recordIdForList
        } 

        openClientNotification(dict).then((res) => {
            if (res.errorCode === 0) {
                $('.se-pre-con').css('display','none');
                const data=res.responsePacket;
                setNotification(data)
            }
        })
    }

    const handleViewChat = (adminId) => {
        console.log(adminId);
        $(".sidebox").addClass('is-active');
        localStorage.setItem('clientRecordId', localStorage.getItem("client_recordId"));
        localStorage.setItem('clientRequestId', adminId.recordId);
        //localStorage.setItem('adminDataId', adminId.event._def.title);
        /* setClienRecordtId(localStorage.getItem("client_recordId"))
        setAdminRecordId(adminId)
        var messageRequest = {
            "receiverId": adminId.event._def.title,
            "senderId": localStorage.getItem("client_recordId"),
        }
        console.log(messageRequest);
        //$('.se-pre-con').css('display','block');
        getMessageList(messageRequest).then((res) => {
            if (res.errorCode === 0) {
                $('.se-pre-con').css('display', 'none');
                const messageData = res.responsePacket;
                setChatMessage(messageData)
            }
        }) */
    }

    const handleClose = () => {
        $(".sidebox").removeClass('is-active');
    }

    const handleSendMessage = () => {
        var dict = {
            "message": message,
            "senderId": localStorage.getItem("clientRecordId"),
            "senderReceiverEnum": "CLIENT",
            "requestId":localStorage.getItem("clientRequestId")
        }
        console.log(dict)
        //debugger;
       /*  var dict = {
            "message": message,
            "receiverId": adminRecord.events._def.title,
            "senderId": clientRecordId,
            "senderReceiverEnum": "CLIENT"
        }
        console.log(dict);
        $('.se-pre-con').css('display', 'block');
        sendMessage(dict).then((res) => {
            console.log(res);
            if (res.errorCode === 0) {
                $('.se-pre-con').css('display', 'none');
                var data = {
                    "clientId": clientRecordId
                }
                handleViewChat(clientRecordId, adminRecord)
                setMessage('')
            }
        }) */
    }


    useEffect(() => {
        handleOpenNotification()
        
    }, []);
    return (
        <>
            <div className="sidebar-backdrop" />
            <div className="page-wrapper">
                <CommanComponents />
                <main className="page-content">
                <div className='col-auto align-items-center d-flex'>
                           {/*  <label htmlFor="exampleInputEmail1">Data di fine</label> */}
                            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    label="."
                                    inputFormat="dd/MM/yyyy"
                                    value={addRequestData.requestDate}
                                    onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        requestDate: e
                                    })}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <a type="button" className="btn btn-primary btn" style={{marginLeft: "19px"}} onClick={searchRecordByDate}><i className='fa fa-find mr-2'></i>Search</a>
                            
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Select options={ticketOptions} name='roundOffTo' value={ticketOptions.value} onChange={filterTicketStatus}/>
                                </div>
                            </div> */}
                </div>
                    <div className="container">
                        <div class="row">
                            {notification.map((d) => (
                                <div class="col-md-4" onClick={() => handleViewChat(d)}>
                                    <div class="card bg-white p-3 text-black my-3">
                                    <div class="d-flex">
                                <div class="mr-2">
                                    <h4 className='text-black'>{d.clientName}</h4>
                                </div>
                                <div class="">
                                {
                                    d.requestStatusEnum === "CLOSED" ? 
                                    <span class="badge badge-success">Closed</span>
                                    :
                                    <span class="badge badge-danger">Open</span>
                                }
                                </div>
                                </div>
                                        <p className='text-black mb-0'>Richiesto in questa data - {moment(d.requestedDate).format("DD/MM/YYYY")}</p>
                                        <p className='text-black mb-0'>Ticket Open Date - {moment(d.openingDate).format("DD/MM/YYYY")}</p>
                                        <p className='text-black mb-0'>Ticket Close Date - {d.closeDate === "" ? "On Process": moment(d.closeDate).format("DD/MM/YYYY")}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </main>
            </div>
             <div className="modal custommodal demo-panel modal--panel modal--right is-animate sidebox" id="demo">
                <div className="modal__overlay" data-dismiss="modal" />
                <div className="modal__wrap">
                    <div className="modal__window scrollbar-thin" data-simplebar="data-simplebar">
                        <div className="modal__content">
                            <div class="modal-header">
                                <h5 class="modal-title">Messaggi</h5>
                                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}><i class="fa fa-times" aria-hidden="true"></i></button>
                            </div>
                            <div className="modal__body p-2">
                                <div className='box box-primary direct-chat direct-chat-primary'>
                                    <div class="direct-chat-messages">
                                        {chatMessage.map((item) => (
                                            item.senderName != "Admin" ?
                                                <div class="direct-chat-msg right">
                                                    <div class="direct-chat-info clearfix">
                                                        <span class="direct-chat-name pull-left">{item.senderName}</span>
                                                        <span class="direct-chat-timestamp pull-right">{moment(item.createdAt).format("lll")}</span>
                                                    </div>
                                                    <img class="direct-chat-img" src={userOne} alt="Message User Image" /> <div class="direct-chat-text">
                                                        {item.message}
                                                    </div>
                                                </div>
                                                :
                                                <div class="direct-chat-msg">
                                                    <div class="direct-chat-info clearfix">
                                                        <span class="direct-chat-name pull-right">{item.senderName}</span>
                                                        <span class="direct-chat-timestamp pull-left">{moment(item.createdAt).format("lll")}</span>
                                                    </div>
                                                    <img class="direct-chat-img" src={userSecond} alt="Message User Image" /> <div class="direct-chat-text">
                                                        {item.message}
                                                    </div>
                                                </div>
                                        ))}
                                        {/* <div class="direct-chat-msg right">
                                    <div class="direct-chat-info clearfix">
                                        <span class="direct-chat-name pull-right">Sarah Bullock</span>
                                        <span class="direct-chat-timestamp pull-left">23 Jan 2:05 pm</span>
                                    </div>
                                   
                                    <img class="direct-chat-img" src="https://bootdey.com/img/Content/user_2.jpg" alt="Message User Image"/>
                                    <div class="direct-chat-text">
                                        You better believe it!
                                    </div>
                                    
                                    </div> */}
                                    </div>
                                </div>

                            </div>
                            <div className="modal__footer cursor py-2 px-2">
                                {/* <div className="modal__container">
                                    <button className="button button--primary button--block" data-dismiss="modal"><span className="button__text" >Close</span></button>
                                </div> */}

                                <div class="input-group">
                                    <textarea name="message" placeholder="Type Message ..." class="form-control" onChange={(e) => setMessage(e.target.value)} value={message}></textarea>
                                    <button type="submit" class="btn btn-primary btn-flat" onClick={handleSendMessage}>Inviare</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewClientNotification