import React, { useState, useEffect, useCallback } from "react";
import { CommanComponents } from "../components/index";
import {
  openUserNotification,
  getSearchByDateUser,
  getUserMessageList,
  sendMessage,
  uploadFile,
  getRoomDataByDepartment,
  addRequestByUser,
  getuserAssociatedClientsList,
  getTicketListByUser,
  getDepartmentDataByHospital,
} from "../services/index";
import $ from "jquery";
import moment from "moment";
import userOne from "../assets/img/content/humans/userone.png";
import usersecond from "../assets/img/content/humans/usersecond.png";
import loader from "../assets/img/content/loader.svg";
import jspdf from "jspdf";
import { ToastContainer, toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { DropBox } from "./Common/index";

const ViewUserNotification = () => {
  const [notification, setNotification] = useState([]);
  const [chatUser, setChatUser] = useState([]);
  const [chatMessage, setChatMessage] = useState([]);
  const [message, setMessage] = useState("");
  const [ticketValue, setTicketValue] = useState();
  const [showModalByChat, setShowModalByChat] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesAsFile, setImagesAsFile] = useState([]);
  const [fileData, setFileData] = useState();
  const [fileSendType, setFileSendType] = useState(false);
  const [hospitalKeyValuePair, setHospitalKeyValuePair] = useState([]);
  const [departmentKeyValuePair, setDepartmentKeyValuePair] = useState([]);
  const [roomKeyValuePair, setRoomKeyValuePair] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [hospital, setHospital] = useState();
  const [department, setDepartment] = useState();
  const [room, setRoom] = useState();
  const [question, setQuestion] = useState();
  const [btnStatus, setBtnStatus] = useState(false);

  const handleDeleteFile = (index) => {
    var tempArray = [...images];
    tempArray.splice(index, 1);
    setImages(tempArray);
    onDrop(tempArray);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0 && acceptedFiles[0].id != null) {
      return acceptedFiles;
    } else {
      setImagesAsFile(acceptedFiles);
      acceptedFiles.map((file, index) => {
        const reader = new FileReader();

        reader.onload = function (e) {
          setImages((prevState) => [
            ...prevState,
            { id: index, src: e.target.result },
          ]);
        };

        reader.readAsDataURL(file);
        return file;
      });
    }
  }, []);

  const [record, setRecord] = useState({
    requestDate: new Date(),
  });

  const [addRequestData, setAddRequestData] = useState({
    clientId: "",
    departmentId: "",
    roomId: "",
    requestDate: new Date(),
  });

  const handleClose = () => {
    $(".sidebox").removeClass("is-active");
  };

  const handleOpenNotification = () => {
    var rId = localStorage.getItem("rId");
    if (rId != undefined) {
      var recordIdForList = rId;
    } else {
      var recordIdForList = "";
    }
    var dict = {
      requestedPage: 0,
      requestedPageSize: 1000000,
      clientId: recordIdForList,
    };
    openUserNotification(dict).then((res) => {
      if (res.errorCode === 0) {
        $(".se-pre-con").css("display", "none");
        const data = res.responsePacket;
        console.log(data);
        setNotification(data);
      }
    });
  };

  const handleViewChat = (data) => {
    console.log(data);
    if (data.requestStatusEnum === "REQUESTED") {
      $(".chatBtn").css("display", "block");
      $(".sidebox").addClass("is-active");
    } else {
      $(".sidebox").addClass("is-active");
      $(".chatBtn").css("display", "none");
    }
    let recordId = data.recordId;
    console.log(recordId);
    var userRecordId = localStorage.getItem("client_recordId");
    localStorage.setItem("clientRecordId", recordId);
    var messageRequest = {
      requestId: recordId,
    };
    $(".se-pre-con").css("display", "block");
    getUserMessageList(messageRequest).then((res) => {
      if (res.errorCode === 0) {
        $(".se-pre-con").css("display", "none");
        const messageData = res.responsePacket;
        setChatMessage(messageData);
      }
    });
  };

  const handlePDF = () => {
    let doc = new jspdf("p", "pt", "a4");
    doc.html(document.getElementById("pdf"), {
      callback: function (pdf) {
        pdf.save("chat.pdf");
        toast("PDF Downloaded Successfully", {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
    });
  };

  const openTicketByAdmin = () => {
    setShowModal(true);
    setHospital();
    setDepartment();
    setRoom();
    setAddRequestData({});
    setQuestion();
  };

  const handleHospital = (e) => {
    setAddRequestData({
      ...addRequestData,
      clientId: e.value,
    });
    setHospital(e);

    getDepartmentDataByHospital(e.value)
      .then((res) => {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setDepartmentKeyValuePair(options);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleDepartment = (e) => {
    setAddRequestData({
      ...addRequestData,
      departmentId: e.value,
    });
    setDepartment(e);

    let dep_id = {
      departmentId: e.value,
    };

    getRoomDataByDepartment(dep_id)
      .then((res) => {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setRoomKeyValuePair(options);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleRoom = (e) => {
    setAddRequestData({
      ...addRequestData,
      roomId: e.value,
    });
    setRoom(e);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowModalByChat(false);
  };

  const ticketOptions = [
    { label: "All", value: "All" },
    { label: "Ticket Open", value: "REQUESTED" },
    { label: "Ticket Closed", value: "CLOSED" },
  ];

  const filterTicketStatus = (e) => {
    console.log(e);
    if (e.label === "All") {
      handleOpenNotification();
    } else {
      setTicketValue({
        ...ticketValue,
        ticketOptions: e.value,
      });

      let robj = {
        requestStatus: e.value,
        clientId: localStorage.getItem("client_recordId"),
      };

      getTicketListByUser(robj).then((res) => {
        if (res.errorCode === 0) {
          $(".se-pre-con").css("display", "none");
          const data = res.responsePacket;
          setNotification(data);
        }
      });
    }
  };

  const handleAddFiles = () => {
    const formData = new FormData();
    console.log(imagesAsFile);
    if (imagesAsFile.length == 0) {
      handleCreateFileFolder(null);
    } else {
      // for (let i = 0; i < file.length; i++) {
      imagesAsFile.map((img) => {
        formData.append("file", img);
      });
      $(".se-pre-con").css("display", "block");
      uploadFile(formData).then((res) => {
        if (res.errorCode == 0) {
          $(".se-pre-con").css("display", "none");
          if (fileSendType) {
            handleSendMessageByFile(res.responsePacket);
          } else {
            handleCreateFileFolder(res.responsePacket);
          }
        } else {
          handleCreateFileFolder(null);
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      });
    }
  };

  const handleWarnToast = (data) => {
    toast(data, {
      icon: "🙁",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  };

  const handleCreateFileFolder = (file) => {
    if (
      addRequestData.clientId === undefined ||
      addRequestData.clientId === ""
    ) {
      handleWarnToast("Please select hospital");
      return;
    } else if (question === undefined || question === "") {
      handleWarnToast("Please enter question");
      return;
    } else {
      var fileLite;
      if (file === null) {
        fileLite = "";
      } else {
        fileLite = file[0].primaryImageUrl + "/" + file[0].fileName;
      }
      const dict = {
        clientId: addRequestData?.clientId,
        departmentId: addRequestData?.departmentId,
        roomId: addRequestData?.roomId,
        note: question,
        requestedDate: moment(addRequestData?.requestDate).format("YYYY-MM-DD"),
        attachedFileUrl: fileLite,
        userId: localStorage.getItem("client_recordId"),
      };
      $(".se-pre-con").css("display", "block");
      addRequestByUser(dict).then((res) => {
        if (res.errorCode === 0) {
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setShowModal(false);
          handleOpenNotification();
          setHospital();
          setDepartment();
          setRoom();
          setAddRequestData({});
          setQuestion();
        }
      });
    }
  };

  const handleSendMessageByFile = (file) => {
    var fileSendURL = file[0].primaryImageUrl + file[0].fileName;
    var sendFile =
      "<a href=" + fileSendURL + " target=_blank>" + file[0].fileName + "</a>";
    setMessage(sendFile);
    setShowModalByChat(false);
  };

  const handleSendMessage = () => {
    var userRecordId = localStorage.getItem("client_recordId");
    var clientRecordId = localStorage.getItem("clientRecordId");
    var dict = {
      message: message,
      requestId: clientRecordId,
      senderId: userRecordId,
      senderReceiverEnum: "USER",
    };
    $(".se-pre-con").css("display", "block");
    sendMessage(dict).then((res) => {
      if (res.errorCode === 0) {
        $(".se-pre-con").css("display", "none");
        var data = {
          recordId: clientRecordId,
        };
        handleViewChat(data);
        setMessage("");
        $(".chatBtn").css("display", "block");
      }
    });
  };

  const handleSendFileByChat = () => {
    setShowModalByChat(true);
    setFileSendType(true);
  };

  const searchRecordByDateUser = () => {
    console.log(record.requestDate);

    var rId = localStorage.getItem("rId");
    if (rId != undefined && rId != "") {
      var recordIdForList = rId;
    } else {
      var recordIdForList = localStorage.getItem("client_recordId");
    }

    let obj = {
      clientId: recordIdForList,
      requestDate: moment(record.requestDate).format("YYYY-MM-DD"),
    };
    getSearchByDateUser(obj).then((res) => {
      //console.log(res);
      if (res.errorCode === 0) {
        $(".se-pre-con").css("display", "none");
        const data = res.responsePacket;
        setNotification(data);
      }
    });
  };
  const searchRecordByDateReset = () => {
    handleOpenNotification();
    setRecord({
      ...record,
      requestDate: new Date(),
    });
  };

  useEffect(() => {
    handleOpenNotification();
    if (
      localStorage.getItem("openUserNotificationId") !== undefined ||
      localStorage.getItem("openUserNotificationId") !== "" ||
      localStorage.getItem("openNotificationId") !== null
    ) {
      if (localStorage.getItem("openNotificationId") == null) {
        $(".sidebox").removeClass("is-active");
      } else {
        $(".sidebox").addClass("is-active");
        var clientRecordId = localStorage.getItem("clientRecordId");
        var messageRequest = {
          requestId: clientRecordId,
        };
        $(".se-pre-con").css("display", "block");
        getUserMessageList(messageRequest).then((res) => {
          if (res.errorCode === 0) {
            $(".se-pre-con").css("display", "none");
            const messageData = res.responsePacket;
            setChatMessage(messageData);
          }
        });
      }
    }

    getuserAssociatedClientsList()
      .then((res) => {
        if (res.errorCode == 0) {
          $(".se-pre-con").css("display", "none");
          const data = res.responsePacket;
          const options = data.map((d) => ({
            value: d.option,
            label: d.value,
          }));

          setHospitalKeyValuePair(options);
        } else {
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((err) => {
        $(".se-pre-con").css("display", "none");
        toast(err, {
          icon: "🙁",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });
  }, []);
  return (
    <>
      <div className="sidebar-backdrop" />
      <div className="page-wrapper">
        <CommanComponents />
        <main className="page-content">
          <div className="container">
            <div
              className="row d-flex justify-content-center align-items-center gap-1"
             
            >
              <div className="d-flex gap-1 "  style={{ alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="."
                    inputFormat="dd/MM/yyyy"
                    value={record.requestDate}
                    onChange={(e) =>
                      setRecord({
                        ...record,
                        requestDate: e,
                      })
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <button
                  type="button"
                  className="btn btn-primary btn d-flex justify-content-center align-items-center"
                  onClick={searchRecordByDateUser}
                >
                  <i className="fa fa-search mr-2"></i>Search
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn d-flex justify-content-center align-items-center"
                  onClick={searchRecordByDateReset}
                >
                  <i className="fa fa-serach mr-2"></i>Reset
                </button>
              </div>
              <div className="col-md-3 justify-content-center gap-1 ">
                <Select
                  options={ticketOptions}
                  name="roundOffTo"
                  value={ticketOptions.value}
                  onChange={filterTicketStatus}
                />
              </div>
              <div className="col-md-3 justify-content-center gap-1">
                <button
                  type="button"
                  className="btn btn-primary btn mr-2"
                  onClick={openTicketByAdmin}
                >
                  <i className="fa fa-find mr-2"></i>Apri nuovo Ticket
                </button>
              </div>
            </div>
            <div class="row">
              {notification.map((d) => (
                <div class="col-md-4" onClick={() => handleViewChat(d)}>
                  <div class="card bg-white p-3 text-black my-3">
                    <div class="d-flex">
                      <div class="mr-2 d-flex">
                        <h4 className="text-black">
                          {d.clientName}-- {d.ticketNumber}
                        </h4>
                        <div class="ml-2">
                          {d.requestStatusEnum === "CLOSED" ? (
                            <span class="badge badge-success">Closed</span>
                          ) : (
                            <span class="badge badge-danger">Open</span>
                          )}
                        </div>
                        {d.userNotViewdCount !== 0 ? (
                          <div className="ml-2">
                            <span class="badge badge-danger">
                              {d.userNotViewdCount}
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <p className="text-black mb-0">
                      Richiesto da- {d.userName === null ? "Admin" : d.userName}
                    </p>
                    <p className="text-black mb-0">Richiest - {d.note}</p>
                    <p className="text-black mb-0">
                      Richiesto in questa data -{" "}
                      {moment(d.requestedDate).format("DD/MM/YYYY")}
                    </p>
                    <p className="text-black mb-0">
                      Data apertura Ticket-{" "}
                      {moment(d.openingDate).format("DD/MM/YYYY")}
                    </p>
                    <p className="text-black mb-0">
                      Data chiusura Ticket-{" "}
                      {d.closeDate === ""
                        ? "In corso"
                        : moment(d.closeDate).format("DD/MM/YYYY")}
                    </p>
                    <p className="text-black mb-0">
                      Dipartimento Cardiologia - {d.departmentName}
                    </p>
                    <p className="text-black mb-0">Stanza - {d.roomName}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
      <div
        className="modal custommodal demo-panel modal--panel modal--right is-animate sidebox"
        id="demo"
      >
        <div className="modal__overlay" data-dismiss="modal" />
        <div className="modal__wrap">
          <div
            className="modal__window scrollbar-thin"
            data-simplebar="data-simplebar"
          >
            <div className="modal__content">
              <div class="modal-header">
                <h5 class="modal-title">Messaggi</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <div className="modal__body p-2">
                <div className="box box-primary direct-chat direct-chat-primary">
                  <div class="direct-chat-messages" id="pdf">
                    {chatMessage.map((item) =>
                      item.senderName == "Admin" ? (
                        <div class="direct-chat-msg right">
                          <div class="direct-chat-info clearfix">
                            <span class="direct-chat-name pull-left">
                              {item.senderName}
                            </span>
                            <span class="direct-chat-timestamp pull-right">
                              {moment(item.createdAt).format("lll")}
                            </span>
                          </div>
                          <img
                            class="direct-chat-img"
                            src={usersecond}
                            alt="Message User Image"
                          />{" "}
                          <div class="direct-chat-text">
                            <p
                              dangerouslySetInnerHTML={{ __html: item.message }}
                            ></p>
                          </div>
                        </div>
                      ) : (
                        <div class="direct-chat-msg left">
                          <div class="direct-chat-info clearfix">
                            <span class="direct-chat-name pull-right">
                              {item.senderName}
                            </span>
                            <span class="direct-chat-timestamp pull-left">
                              {moment(item.createdAt).format("lll")}
                            </span>
                          </div>
                          <img
                            class="direct-chat-img"
                            src={userOne}
                            alt="Message User Image"
                          />{" "}
                          <div class="direct-chat-text">
                            <p
                              dangerouslySetInnerHTML={{ __html: item.message }}
                            ></p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="modal__footer cursor px-2 py-2">
                <div className="chatBtn">
                  <div class="input-group">
                    <textarea
                      name="message"
                      placeholder="Digita messaggio ..."
                      class="form-control"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    ></textarea>
                    <button
                      type="submit"
                      class="btn btn-primary btn-flat"
                      onClick={handleSendFileByChat}
                    >
                      <i class="fa fa-paperclip" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div style={{ paddingTop: "8px", textAlign: "center" }}>
                    <button
                      type="submit"
                      class="btn btn-primary btn-flat ml-2"
                      onClick={handleSendMessage}
                    >
                      Inviare
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        className={showModal ? "is-active is-animate" : ""}
      >
        <Modal.Header>
          <Modal.Title>Open Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4">
              <div>Ospedale</div>
              <div>
                <Select
                  options={hospitalKeyValuePair}
                  name="roundOffTo"
                  value={hospital}
                  onChange={handleHospital}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div>Dipartimento</div>
              <div>
                <Select
                  options={departmentKeyValuePair}
                  name="roundOffTo"
                  value={department}
                  onChange={handleDepartment}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div>Stanza</div>
              <div>
                <Select
                  options={roomKeyValuePair}
                  name="roundOffTo"
                  value={room}
                  onChange={handleRoom}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div>Scrivi una domanda?</div>
              <div>
                <textarea
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder=""
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col-md-4">
              <div>Upload di file</div>
              <div>
                <DropBox onDrop={onDrop} parentCallBack={handleDeleteFile} />
              </div>
            </div>
            <div className="col-md-4">
              <div>Data richiesta</div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="."
                    inputFormat="dd/MM/yyyy"
                    value={addRequestData.requestDate}
                    onChange={(e) =>
                      setAddRequestData({
                        ...addRequestData,
                        requestDate: e,
                      })
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleAddFiles} disabled={btnStatus}>
            Submit
          </Button>
          <Button variant="secondary" onClick={() => handleModalClose()}>
            Chiudere
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModalByChat}
        className={showModalByChat ? "is-active is-animate" : ""}
      >
        <Modal.Header>
          <Modal.Title>Send File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div>File Uploade</div>
              <div>
                <DropBox onDrop={onDrop} parentCallBack={handleDeleteFile} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleAddFiles}>Submit</Button>
          <Button variant="secondary" onClick={() => handleModalClose()}>
            Chiudere
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ViewUserNotification;
