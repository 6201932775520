import React, { useState, useEffect } from "react";
import { CommanComponents } from "../components/index";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {
  getRoomListData,
  getClientKeyValueList,
  getClientDepartmentListInKeyValue,
  addRoom,
  updateRoom,
  activeRoom,
  inActiveRoom,
  deleteRoom,
  getRoomByID,
  getDepartmentDataByHospital,
} from "../services/index";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import $ from "jquery";
import loader from "../assets/img/content/loader.svg";
import CancelIcon from "@mui/icons-material/Cancel";

export const Room = () => {
  const [show, setShow] = useState(false);
  const handleShowAdd = () => setShow(true);
  const [hospitalKeyValuePair, setHospitalKeyValuePair] = useState([]);
  const [departmentKeyValuePair, setDepartmentKeyValuePair] = useState([]);
  const [rows, setRows] = useState([]);
  const [addRequestData, setAddRequestData] = useState({
    clientId: "",
    departmentName: "",
    roomName: "",
    recordId: "",
  });
  const [hospital, setHospital] = useState();
  const [department, setDepartment] = useState();
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [clientRoleEnum, setClientRoleEnum] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [isChecked, setIsChecked] = useState(false);

  const localTempArray = [
    {
      gasesName: "O2",
      value: "",
      isChecked: false,
    },
    {
      gasesName: "N2O",
      value: "",
      isChecked: false,
    },
    {
      gasesName: "A",
      value: "",
      isChecked: false,
    },
    {
      gasesName: "AC",
      value: "",
      isChecked: false,
    },
    {
      gasesName: "A8",
      value: "",
      isChecked: false,
    },
    {
      gasesName: "AT",
      value: "",
      isChecked: false,
    },
    {
      gasesName: "CO2",
      value: "",
      isChecked: false,
    },
    {
      gasesName: "VAC",
      value: "",
      isChecked: false,
    },
    {
      gasesName: "EGA",
      value: "",
      isChecked: false,
    },
    {
      gasesName: "GAS TECNICI",
      value: "",
      isChecked: false,
    },
    {
      gasesName: "ALTRI GAS",
      value: "",
      isChecked: false,
    },
  ];
  const [tempArray, setTempArray] = useState(localTempArray);

  const handleClose = () => {
    setShow(false);
  };

  const handleAddRoomData = () => {
    console.log(tempArray);
    //debugger;

    let gasObj = [];
    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].isChecked) {
        gasObj.push(tempArray[i]);
      }
    }

    let dict = {
      clientId: addRequestData?.clientId,
      departmentId: addRequestData?.departmentId,
      roomName: addRequestData.roomName,
      roomId: addRequestData.recordId,
      gases: gasObj,
    };

    if (dict.clientId === undefined || dict.clientId === "") {
      toast("Please select hospital first !", {
        icon: "🙁",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    } else if (dict.roomName === undefined || dict.roomName === "") {
      toast("Department name shouldn`t be blank", {
        icon: "🙁",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }
    if (dict.roomId != undefined) {
      $(".se-pre-con").css("display", "block");
      updateRoom(addRequestData.recordId, dict)
        .then((res) => {
          if (res.errorCode == 0) {
            $(".se-pre-con").css("display", "none");
            toast(res.message, {
              icon: "👏",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            setShow(false);
          } else {
            $(".se-pre-con").css("display", "none");
            toast(res.data.message, {
              icon: "🙁",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          }
        })
        .catch((err) => {
          $(".se-pre-con").css("display", "none");
          toast(err, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        });
    } else {
      $(".se-pre-con").css("display", "block");
      addRoom(dict)
        .then((res) => {
          if (res.errorCode == 0) {
            $(".se-pre-con").css("display", "none");
            toast(res.message, {
              icon: "👏",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            setShow(false);
            setRows([]);
            getRoomList(0);
          } else {
            $(".se-pre-con").css("display", "none");
            toast(res.message, {
              icon: "🙁",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          }
        })
        .catch((err) => {
          $(".se-pre-con").css("display", "none");
          toast(err, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        });
    }
  };

  /*  const handleAddRoom = () => {
     setShow(true)
     setAddRequestData(true)
     setHospital(true)
     setDepartment(true)
     setTempArray([
       {
         gasesName: "O2",
         value: "",
         isChecked:false,
       },
       {
         gasesName: "N2O",
         value: "",
         isChecked:false,
       },
       {
         gasesName: "A",
         value: "",
         isChecked:false
       },
       {
         gasesName: "AC",
         value: "",
         isChecked:false
       },
       {
         gasesName: "A8",
         value: "",
         isChecked:false
       },
       {
         gasesName: "AT",
         value: "",
         isChecked:false
       },
       {
         gasesName: "CO2",
         value: "",
         isChecked:false
       },
       {
         gasesName: "VAC",
         value: "",
         isChecked:false
       },
       {
         gasesName: "EGA",
         value: "",
         isChecked:false
       },
       {
         gasesName: "GAS TECNICI",
         value: "",
         isChecked:false
       },
       {
         gasesName: "ALTRI GAS",
         value: "",
         isChecked:false
       },
     ])
     var dict = {
       "clientRoleEnum": "CLIENTS"
     }
     $('.se-pre-con').css('display','block');
     getClientKeyValueList(dict).then((res) => {
       if (res.errorCode == 0) {
         $('.se-pre-con').css('display','none');
         const data = res.responsePacket;
         const options = data.map((d) => ({
           value: d.option,
           label: d.value,
         }));
 
         setHospitalKeyValuePair(options)
       } else {
         $('.se-pre-con').css('display','none');
         toast(res.message,
           {
             icon: '🙁',
             style: {
               borderRadius: '10px',
               background: '#333',
               color: '#fff',
             },
           }
         );
       }
     }).catch((err) => {
       $('.se-pre-con').css('display','none');
       toast(err,
         {
           icon: '🙁',
           style: {
             borderRadius: '10px',
             background: '#333',
             color: '#fff',
           },
         }
       );
     })
 
     getClientDepartmentListInKeyValue().then((res) => {
       if (res.errorCode == 0) {
         $('.se-pre-con').css('display','none');
         const data = res.responsePacket;
         const options = data.map((d) => ({
           value: d.option,
           label: d.value,
         }));
 
         setDepartmentKeyValuePair(options)
       } else {
         $('.se-pre-con').css('display','none');
         toast(res.message,
           {
             icon: '🙁',
             style: {
               borderRadius: '10px',
               background: '#333',
               color: '#fff',
             },
           }
         );
       }
     }).catch((err) => {
       $('.se-pre-con').css('display','none');
       toast(err,
         {
           icon: '🙁',
           style: {
             borderRadius: '10px',
             background: '#333',
             color: '#fff',
           },
         }
       );
     })
   } */

  const handleAddRoom = () => {
    setShow(true);
    setAddRequestData(true);
    setHospital(true);
    setDepartment(true);
    setTempArray(localTempArray);
    var dict = {
      clientRoleEnum: "CLIENTS",
    };
    $(".se-pre-con").css("display", "block");
    getClientKeyValueList(dict)
      .then((res) => {
        if (res.errorCode == 0) {
          $(".se-pre-con").css("display", "none");
          const data = res.responsePacket;
          const options = data.map((d) => ({
            value: d.option,
            label: d.value,
          }));

          setHospitalKeyValuePair(options);
        } else {
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((err) => {
        $(".se-pre-con").css("display", "none");
        toast(err, {
          icon: "🙁",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });

    getClientDepartmentListInKeyValue()
      .then((res) => {
        if (res.errorCode == 0) {
          $(".se-pre-con").css("display", "none");
          const data = res.responsePacket;
          const options = data.map((d) => ({
            value: d.option,
            label: d.value,
          }));

          setDepartmentKeyValuePair(options);
        } else {
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((err) => {
        $(".se-pre-con").css("display", "none");
        toast(err, {
          icon: "🙁",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });
  };

  const handlePageChange = (page) => {
    getRoomList(page - 1);
    setCurrentPage(page);
  };

  const getRoomList = async (page, size = perPage) => {
    console.log("page", page);
    var rId = localStorage.getItem("rId");
    if (rId != undefined) {
      var recordIdForList = rId;
    } else {
      var recordIdForList = "";
    }
    var dict = {
      requestedPage: page,
      requestedPageSize: size,
      departmentId: "",
      clientId: recordIdForList,
    };
    $(".se-pre-con").css("display", "block");
    getRoomListData(dict)
      .then((res) => {
        $(".se-pre-con").css("display", "none");
        setRows(res.data);
        setTotalRows(res.recordsTotal);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleHospital = (e) => {
    setAddRequestData({
      ...addRequestData,
      clientId: e.value,
    });
    setHospital(e);

    getDepartmentDataByHospital(e.value)
      .then((res) => {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setDepartmentKeyValuePair(options);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleDepartment = (e) => {
    setAddRequestData({
      ...addRequestData,
      departmentId: e.value,
    });
    setDepartment(e);
  };

  const handleEditRecord = (id) => {
    //setEdit(true)
    setShow(true);
    var dict = {
      clientRoleEnum: "CLIENTS",
    };
    $(".se-pre-con").css("display", "block");
    getClientKeyValueList(dict)
      .then((res) => {
        if (res.errorCode == 0) {
          $(".se-pre-con").css("display", "none");
          const data = res.responsePacket;
          const options = data.map((d) => ({
            value: d.option,
            label: d.value,
          }));

          setHospitalKeyValuePair(options);
        } else {
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((err) => {
        $(".se-pre-con").css("display", "none");
        toast(err, {
          icon: "🙁",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });

    getClientDepartmentListInKeyValue()
      .then((res) => {
        if (res.errorCode == 0) {
          $(".se-pre-con").css("display", "none");
          const data = res.responsePacket;
          const options = data.map((d) => ({
            value: d.option,
            label: d.value,
          }));

          setDepartmentKeyValuePair(options);
        } else {
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((err) => {
        $(".se-pre-con").css("display", "none");
        toast(err, {
          icon: "🙁",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });
    setId(id);
    getRoomByID(id).then((res) => {
      if (res.errorCode === 0) {
        $(".se-pre-con").css("display", "none");
        console.log(res);
        setAddRequestData(res.responsePacket);
        let d = res.responsePacket;
        let clientData = {
          value: d.clientId,
          label: d.clientName,
        };
        setHospital(clientData);
        let departmentData = {
          value: d.departmentId,
          label: d.departmentName,
        };
        setDepartment(departmentData);

        const _tempArray = [...localTempArray];
        if (d.gases) {
          for (let i = 0; i < d.gases.length; i++) {
            for (let j = 0; j < _tempArray.length; j++) {
              if (d.gases[i].gasesName === _tempArray[j].gasesName) {
                _tempArray[j] = { ...d.gases[i], isChecked: true };
              }
            }
          }
        }
        console.log(_tempArray);
        setTempArray(_tempArray);
      }
    });
  };

  const handleActiveInActive = (status, uuid) => {
    Swal.fire({
      title: "Sei sicuro?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, conferma !!",
      cancelButtonText: "No !!",
    }).then((result) => {
      if (result.isConfirmed) {
        //Active Request
        console.log(status);
        console.log(uuid);

        if (status) {
          $(".se-pre-con").css("display", "block");
          activeRoom(uuid)
            .then((res) => {
              if (res.errorCode == 0) {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "👏",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });

                getRoomList(0);
              } else {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "🙁",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });
                getRoomList(0);
              }
            })
            .catch((err) => {
              $(".se-pre-con").css("display", "none");
              toast(err, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            });
        }
        //InActive Request
        else {
          $(".se-pre-con").css("display", "block");
          inActiveRoom(uuid)
            .then((res) => {
              if (res.errorCode == 0) {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "👏",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });

                getRoomList(0);
              } else {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "🙁",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });
                getRoomList(0);
              }
            })
            .catch((err) => {
              $(".se-pre-con").css("display", "none");
              toast(err, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            });
        }
      } else {
        $(".se-pre-con").css("display", "none");
      }
    });
  };

  const handleDeleteRoom = (id) => {
    Swal.fire({
      title: "Sei sicuro?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, conferma !!",
      cancelButtonText: "No !!",
    }).then((result) => {
      $(".se-pre-con").css("display", "block");
      if (result.isConfirmed) {
        deleteRoom(id).then((res) => {
          if (res.errorCode === 0) {
            $(".se-pre-con").css("display", "none");
            toast(res.message, {
              icon: "👏",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            getRoomList(0);
          } else {
            $(".se-pre-con").css("display", "none");
            toast(res.message, {
              icon: "🙁",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          }
        });
      } else {
        $(".se-pre-con").css("display", "none");
      }
    });
  };

  const columns = [
    {
      name: "Nome dell ospedale",
      selector: (row) => row.clientName,
    },
    {
      name: "Nome Dipartimento",
      selector: (row) => row.departmentName,
    },
    {
      name: "Data di Creazione",
      selector: (row) => row.roomName,
    },
    {
      name: "Stato",
      cell: (row) => (
        <>
          {row.status ? (
            <span className="badge badge-success">Attivo</span>
          ) : (
            <span className="badge badge-danger">Inattivo</span>
          )}
        </>
      ),
    },
    {
      name: "Azione",
      cell: (row) => (
        <>
          <ModeEditOutlineOutlinedIcon
            style={{ color: "blue" }}
            onClick={() => handleEditRecord(row.recordId)}
          />
          {row.status ? (
            <CancelOutlinedIcon
              style={{ color: "green" }}
              onClick={() => handleActiveInActive(false, row.recordId)}
            />
          ) : (
            <CheckOutlinedIcon
              style={{ color: "red" }}
              onClick={() => handleActiveInActive(true, row.recordId)}
            />
          )}
          <DeleteOutlineOutlinedIcon
            style={{ color: "red" }}
            onClick={() => handleDeleteRoom(row.recordId)}
          />
        </>
      ),
    },
  ];

  const handlePerRowsChange = async (newPerPage, page) => {
    // fetchData(page, newPerPage);
    getRoomList(0, newPerPage);
    setPerPage(newPerPage);
  };

  const handleOnChange = (e, i) => {
    console.log(e);
    console.log(i);
    let robj = tempArray[i];
    console.log("1", robj);
    robj = {
      ...robj,
      isChecked: !robj.isChecked,
    };
    console.log("2", robj);
    tempArray[i] = robj;
    setTempArray([...tempArray]);
  };

  const handleInputOnChange = (e, i) => {
    console.log(e);
    console.log(i);
    let robj = tempArray[i];
    console.log("1", robj);
    robj = {
      ...robj,
      value: e.target.value,
    };
    console.log("2", robj);
    tempArray[i] = robj;
    setTempArray([...tempArray]);
  };

  useEffect(() => {
    //getRoomList(0)
    getRoomList(0);
  }, []);

  return (
    <>
      <div className="sidebar-backdrop" />
      <div className="page-wrapper">
        <CommanComponents />
        <main className="page-content">
          <div className="container">
            <div className="page-header justify-content-between align-items-center d-flex">
              <div>
                <h1 className="page-header__title">Stanza</h1>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-sm d-flex align-items-center justify-content-center gap-1"
                  onClick={handleAddRoom}
                >
                  <i className="fa fa-plus"></i>Aggiungi Stanza
                </button>
              </div>
            </div>

            <div className="row">
              <div style={{ height: "100%", width: "100%" }}>
                <DataTable
                  columns={columns}
                  data={rows}
                  theme="solarized"
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
      <Modal
        show={show}
        className={show ? "is-active is-animate" : ""}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Aggiungi Stanza</Modal.Title>
          <CancelIcon className="pointer" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Ospedale Nome</Form.Label>
                <Select
                  options={hospitalKeyValuePair}
                  name="roundOffTo"
                  value={hospital}
                  onChange={handleHospital}
                />
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-6">
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Reparto Nome</Form.Label>
                <Select
                  options={departmentKeyValuePair}
                  name="roundOffTo"
                  value={department}
                  onChange={handleDepartment}
                />
              </Form.Group>
            </div>
            <div className="col-lg-4 col-md-6">
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Stanza Nome</Form.Label>
                <Form.Control
                  placeholder="Dipartimento"
                  value={addRequestData.roomName}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      roomName: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </div>

            {tempArray.map((obj, i) => (
              <div className="col-lg-4 col-md-6 mb-3">
                <table className="table-border">
                  <tr key={i}>
                    <td style={{ width: "140px" }}>
                      <div className="d-flex align-items-center gap-1">
                        <input
                          type="checkbox"
                          placeholder="Dipartimento"
                          id={obj.gasesName}
                          onChange={(e) => handleOnChange(e, i)}
                          checked={obj.isChecked}
                        />
                        &nbsp;{obj.gasesName}
                      </div>
                    </td>
                    <td>
                      <Form.Group className=" " controlId="formBasicText">
                        <Form.Control
                          id={obj.gasesName}
                          value={obj.value}
                          onChange={(e) => handleInputOnChange(e, i)}
                          placeholder="Quantità"
                        />
                      </Form.Group>
                    </td>
                  </tr>
                </table>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddRoomData}>
            Salva
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      <div className="se-pre-con">
        <img src={loader} />
      </div>
    </>
  );
};
