
import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from 'axios'
import { CLIENT_REQUEST, } from "./CONSTANTS";

const auth = localStorage.getItem('itemOxygenAuth')

const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': auth != undefined ? "Basic " + auth : ''
    // 'Authorization': url.Auth_Token
}
console.log(auth, "===================")




export const requestListForAdmin = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(CLIENT_REQUEST() + "requestListForAdmin", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in requestListForAdmin axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const acceptRequest = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(CLIENT_REQUEST() + "acceptRequest/" + id, {}, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in requestListForAdmin axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const rejectRequest = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(CLIENT_REQUEST() + "rejectRequest/" + id, {}, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in requestListForAdmin axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};