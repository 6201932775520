import { useEffect, useState } from "react";

import Slider from "react-rangeslider";
import StarRatingComponent from "react-star-rating-component";
import moment from "moment";

const defaultElementData = {
  backgroundColor: "#fff",
  alignment: "left",
  col_count: 1,
  field_name: "",
  label: "",
  pageBreakBefore: false,
  pageBreakAfter: false,
};

const pdfHeaderDefaultValue = {
  taskTitle: "",
  operatorTitle: "",
  clientTitle: "",
  departmentName: "",
  roomName: "",
  taskUpdatedDate: "",
  taskCode: "",
};

// Pdf Header
export const PdfHeader = (props) => {
  const headerData = { ...pdfHeaderDefaultValue, ...props.headerData };
  const {
    taskTitle,
    operatorTitle,
    clientTitle,
    departmentName,
    roomName,
    taskUpdatedDate,
    taskCode,
  } = headerData;

  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <div>
          <div className="col-md-12">
            <h4 className="py-4">
              <img
                src="https://www.itemcare.it:8443/ItemOxygen/api/attachment/download/1686831089240_itemoxygenlogo.png"
                style={{ height: "120px" }}
              />
            </h4>
          </div>
          <div className="col-md-12 my-4">
            <div className=" titleForm">
              <div className="d-flex">
                <div></div>
                <div>
                  <ul style={{ textAlign: "left" }}>
                    <li key={taskTitle}>Scheda : {taskTitle}</li>
                    <li key={operatorTitle}>Operatore : {operatorTitle}</li>
                    <li key={clientTitle}>Ospedale : {clientTitle}</li>
                    <li key={departmentName}>
                      Dipartimento : {departmentName}
                    </li>
                    <li key={roomName}>Stanza : {roomName}</li>
                  </ul>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="mr-3">
            <img
              src="https://www.itemcare.it:8443/ItemOxygen/api/attachment/download/1686832066718_headerrightimg.png"
              style={{ width: "300px" }}
            />
          </div>
          <div>
            <div className="mt-5 text-right" style={{ marginRight: "46px" }}>
              <b>Date : {moment(taskUpdatedDate).format("DD-MM-YYYY")}</b>
            </div>
            <div className=" titleForm" style={{ marginRight: "46px" }}>
              <div className="d-flex justify-content-end">
                <div></div>
                <div>
                  <ul>
                    <li>
                      n<sup>0</sup> : {taskCode}
                    </li>
                  </ul>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PdfHeader.defaultProps = {
  headerData: { ...pdfHeaderDefaultValue },
  taskType: "Completed",
};

const ElementWrapper = (props) => {
  const { backgroundColor, pageBreakAfter, pageBreakBefore } =
    props.elementData;

  let pageBreakClass = "";
  if (pageBreakBefore) {
    pageBreakClass += "page-break-before-item";
  }
  if (pageBreakAfter) {
    pageBreakClass += "page-break-after-item";
  }

  return (
    <>
      <div
        className={`px-1 inline-block-element ${pageBreakClass}`}
        style={{
          width: `${100 / props.col_count}%`,
          backgroundColor: backgroundColor,
        }}
      >
        {props.children}
      </div>
    </>
  );
};

ElementWrapper.defaultProps = {
  elementData: { ...defaultElementData },
  col_count: 1,
};

// Signature,Image,Camera,Download
export const Signature = ({ elementData, ...props }) => {
  const _elementData = { ...defaultElementData, ...elementData, ...props };
  const { label, field_name, alignment, col_count, taskType } = _elementData;

  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
        <div className={`text-${alignment}`}>
          {taskType === "Completed" ? (
            <img className="pdf-signature-image" src={field_name} />
          ) : (
            <div className="pdf-signature-image"></div>
          )}
        </div>
      </div>
    </ElementWrapper>
  );
};

Signature.defaultProps = {
  elementData: { ...defaultElementData },
  col_count: 1,
  taskType: "Completed",
};

// FileUpload
export const FileUpload = ({ elementData, ...props }) => {
  const [isLink, setIsLink] = useState(false);

  const _elementData = { ...defaultElementData, ...elementData, ...props };
  const { label, field_name, alignment, col_count, taskType } = _elementData;

  useEffect(() => {
    var fileEx = field_name.split(".").pop();
    if (
      fileEx === "jpg" ||
      fileEx === "png" ||
      fileEx === "jpeg" ||
      fileEx === "tiff" ||
      fileEx === "raw" ||
      fileEx === "gif"
    ) {
      setIsLink(false);
    } else {
      setIsLink(true);
    }
  }, []);

  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
        {isLink ? (
          <>
            <a href={field_name} target="_blank">
              <div className={`text-${alignment}`}>
                <img src="/fileIcon.png" style={{ height: "75px" }} />
              </div>
            </a>
          </>
        ) : (
          <>
            <div className={`text-${alignment}`}>
              {taskType === "Completed" ? (
                <img src={field_name} style={{ height: "75px" }} />
              ) : (
                <div style={{ height: "75px" }}></div>
              )}
            </div>
          </>
        )}
      </div>
    </ElementWrapper>
  );
};

FileUpload.defaultProps = {
  elementData: { ...defaultElementData },
  col_count: 1,
  taskType: "Completed",
};

//Header
export const Header = ({ elementData, ...props }) => {
  const _elementData = {
    ...defaultElementData,
    content: "",
    ...elementData,
    ...props,
  };
  const { content, taskType, col_count } = _elementData;
  const valueToShow = taskType === "Completed" ? content : "";

  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div className="col-md-12" style={{ wordSpacing: "6px" }}>
        <p dangerouslySetInnerHTML={{ __html: valueToShow }}></p>
      </div>
    </ElementWrapper>
  );
};

//LineBreak
export const LineBreak = () => {
  return (
    <div className="spaceType col-md-12">
      <hr />
    </div>
  );
};

LineBreak.defaultProps = {
  elementData: { ...defaultElementData, content: "" },
};

// Paragraph
export const Paragraph = ({ elementData, ...props }) => {
  const _elementData = { ...defaultElementData, ...elementData, ...props };
  const { label, col_count } = _elementData;

  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div className="">
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
      </div>
    </ElementWrapper>
  );
};

Paragraph.defaultProps = {
  elementData: { ...defaultElementData },
  col_count: 1,
  taskType: "Completed",
};

// TextInput,NumberInput,PhoneNumber,EmailInput,DatePicker
export const TextInput = ({ elementData, ...props }) => {
  const _elementData = { ...defaultElementData, ...elementData, ...props };
  const { label, field_name, alignment, col_count, taskType } = _elementData;
  const valueToShow = taskType === "Completed" ? field_name : "";

  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
        <input
          className={`form-control text-${alignment}`}
          value={valueToShow}
          style={{ marginTop: "14px" }}
          disabled
        />
      </div>
    </ElementWrapper>
  );
};

TextInput.defaultProps = {
  elementData: { ...defaultElementData },
  col_count: 1,
  taskType: "Completed",
};

// TextArea
export const TextArea = ({ elementData, ...props }) => {
  const _elementData = { ...defaultElementData, ...elementData, ...props };
  const { label, field_name, alignment, col_count, taskType } = _elementData;
  const valueToShow = taskType === "Completed" ? field_name : "";

  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div className="bg-white m-5">
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
        <div className={`text-${alignment}`}>
          <div className="pdf-text-area">{valueToShow}</div>
        </div>
      </div>
    </ElementWrapper>
  );
};

TextArea.defaultProps = {
  elementData: { ...defaultElementData },
  col_count: 1,
  taskType: "Completed",
};

// Label
export const Label = ({ elementData, ...props }) => {
  const _elementData = {
    ...defaultElementData,
    content: "",
    ...elementData,
    ...props,
  };
  const { content, col_count } = _elementData;
  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div
        className="custom-label-h"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </ElementWrapper>
  );
};

Label.defaultProps = {
  elementData: { ...defaultElementData, content: "" },
  taskType: "Completed",
};

// Range
export const Range = ({ elementData, ...props }) => {
  const _elementData = { ...defaultElementData, ...elementData, ...props };
  const { label, field_name, alignment, col_count, taskType } = _elementData;
  const valueToShow = taskType === "Completed" ? field_name : "";

  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
        <div className={`text-${alignment}`}>
          <Slider
            min={0}
            max={parseInt(_elementData.max_value)}
            value={parseInt(valueToShow)}
          />
          <div className="value">{valueToShow}</div>
        </div>
      </div>
    </ElementWrapper>
  );
};

Range.defaultProps = {
  elementData: { ...defaultElementData, max_value: 0 },
  col_count: 1,
  taskType: "Completed",
};

// Rating
export const Rating = ({ elementData, ...props }) => {
  const _elementData = { ...defaultElementData, ...elementData, ...props };
  const { label, field_name, alignment, col_count, taskType } = _elementData;
  const valueToShow = taskType === "Completed" ? field_name : "";

  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
        <div className={`text-${alignment}`}>
          <StarRatingComponent
            name="rate2"
            editing={false}
            renderStarIcon={() => (
              <span>
                <i className="fa fa-star-o" aria-hidden="true"></i>
              </span>
            )}
            starCount={5}
            value={parseInt(valueToShow)}
          />
        </div>
      </div>
    </ElementWrapper>
  );
};

Rating.defaultProps = {
  elementData: { ...defaultElementData, field_name: 0 },
  col_count: 1,
  taskType: "Completed",
};

// HyperLink
export const HyperLink = ({ elementData, ...props }) => {
  const _elementData = { ...defaultElementData, ...elementData, ...props };
  const { label, field_name, alignment, col_count } = _elementData;

  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
        <div className={`text-${alignment}`}>
          <h4>{_elementData.content}</h4>
        </div>
      </div>
    </ElementWrapper>
  );
};

HyperLink.defaultProps = {
  elementData: { ...defaultElementData },
  col_count: 1,
  taskType: "Completed",
};

// Dropdown,Tags
export const Dropdown = ({ elementData, ...props }) => {
  const _elementData = { ...defaultElementData, ...elementData, ...props };
  const { label, field_name, alignment, col_count } = _elementData;

  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
        <select className={`spaceType form-control text-${alignment}`} disabled>
          {_elementData.options.map((currentVal) => (
            <option
              key={currentVal.key}
              value={currentVal.key}
              selected={field_name === currentVal.key ? true : false}
              // defaultValue={field_name === currentVal.key ? true : false}
            >
              {currentVal.text}
            </option>
          ))}
        </select>
      </div>
    </ElementWrapper>
  );
};

Dropdown.defaultProps = {
  elementData: { ...defaultElementData },
  col_count: 1,
  taskType: "Completed",
};

// RadioButtons
export const RadioButtons = ({ elementData, ...props }) => {
  const _elementData = { ...defaultElementData, ...elementData, ...props };
  const { label, field_name, alignment, col_count } = _elementData;
  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
        <div className={`text-${alignment}`}>
          <div>
            {_elementData.options.map((currentVal, i) => (
              <div key={i}>
                <div>
                  <label className="radio">
                    <input
                      type="radio"
                      checked={!!(field_name === currentVal.key)}
                      disabled
                    />
                    <span>{currentVal.text}</span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};

RadioButtons.defaultProps = {
  elementData: { ...defaultElementData },
  col_count: 1,
  taskType: "Completed",
};

// Checkboxes
export const Checkboxes = ({ elementData, ...props }) => {
  const _elementData = { ...defaultElementData, ...elementData, ...props };
  const { label, field_name, alignment, col_count } = _elementData;
  const splitData = field_name.split(",");

  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
        <div className={`text-${alignment}`}>
          <div>
            {_elementData.options.map((currentVal, j) => (
              <div key={j}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="flexRadioDefault"
                    // id="flexRadioDefault2"
                    // checked={!!(splitDataId === currentVal.key)}
                    checked={splitData.includes(currentVal.key)}
                    disabled
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    {currentVal.text}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};

Checkboxes.defaultProps = {
  elementData: { ...defaultElementData },
  col_count: 1,
  taskType: "Completed",
};

// DefaultElement
export const DefaultElement = ({ elementData, ...props }) => {
  const _elementData = { ...defaultElementData, ...elementData, ...props };
  const { label, field_name, alignment, col_count, taskType } = _elementData;
  const valueToShow = taskType === "Completed" ? field_name : "";

  return (
    <ElementWrapper elementData={_elementData} col_count={col_count}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
        <div className={`text-${alignment}`}>{valueToShow}</div>
      </div>
    </ElementWrapper>
  );
};

DefaultElement.defaultProps = {
  elementData: { ...defaultElementData },
  col_count: 1,
  taskType: "Completed",
};
