import React from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import Select from "react-select";
import {
  getClientKeyValueList,
  getTaskSlotKeyValue,
  getTemplatesKeyValueList,
  getOperatorKeyValueList,
  getDepartmentKeyvalueList,
  assignTask,
  getTaskWorkOrderList,
  getTaskTemplate,
  generateTaskSheet,
  updateTaskSheet,
  deleteAssignOperator,
  deleteMultipleTask,
  getAssignOperatorDetails,
  updateAssignOperator,
  getDepartmentDataByHospital,
  getRoomListInKeyValue,
  getRoomDataByDepartment,
  getClientListListInKeyValueByOperatorId,
  updateTask,
} from "../services/index";
import { CommanComponents } from "../components";
import jspdf from "jspdf";
import $ from "jquery";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import loader from "../assets/img/content/loader.svg";
import { LoaderIcon } from "react-hot-toast";

require("formBuilder");

var taskTemplateUuid;
var taskTemplateTitle;
var formData = [];

const typeOfSlots = [
  {
    value: "Date",
    label: "Data",
    dayCount: 0,
  },
  {
    value: "Daily",
    label: "Giornaliero",
    dayCount: 0,
  },
  {
    value: "Weekly",
    label: "Settimanale",
    dayCount: 7,
  },
  {
    value: "15 Days",
    label: "15 Giorni",
    dayCount: 15,
  },
  {
    value: "Monthly",
    label: "mensile",
    dayCount: 30,
  },
  {
    value: "Bimonthly",
    label: "bimestrale",
    dayCount: 60,
  },
  {
    value: "Quarterly",
    label: "trimestrale",
    dayCount: 90,
  },
  {
    value: "Half-yearly",
    label: "Semestrale",
    dayCount: 120,
  },
  {
    value: "Annually",
    label: "Annualmente",
    dayCount: 365,
  },
];
const weekendOptions = [
  {
    label: "Lunedì",
    value: "Monday",
  },
  {
    label: "Martedí",
    value: "Tuesday",
  },
  {
    label: "Mercoledí",
    value: "Wednesday",
  },
  {
    label: "Giovedì",
    value: "Thursday",
  },
  {
    label: "Venerdì",
    value: "Friday",
  },
  {
    label: "Sabato",
    value: "Saturday",
  },
  {
    label: "Domenica",
    value: "Sunday",
  },
];

var curr = new Date();
curr.setDate(curr.getDate());
var date1 = curr.toISOString()?.substring(0, 10);

const initialAddData = {
  startDate: date1,
  endDate: date1,
  clientId: "",
  departmentId: "",
  roomId: "",
};
const skipDayDefaultValue={
  Saturday: false,
  AllHolidays: false,
}
export const AssignOperator = () => {
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDetails, setDetails] = useState(false);
  const [recordData, setRecordData] = useState("");
  const [taskTemplateKeyValue, setTaskTemplateKeyValue] = useState([]);
  const [taskTemplateValue, setTaskTemplateValue] = useState(null);
  const [operatorKeyValue, setOperatorKeyValue] = useState([]);
  const [operatorValue, setOperatorValue] = useState(null);
  const [hospitalsKeyValue, setHospitalsKeyValue] = useState([]);
  const [hospitalValue, setHospitalValue] = useState(null);
  const [departmentsKeyValue, setDepartmentsKeyValue] = useState([]);
  const [slotTypeKeyValue, setSlotTypeKeyValue] = useState([]);
  const [slotType, setSlotType] = useState(null);
  const [weekDay, setWeekDay] = useState(null);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [roomKeyValuePair, setRoomKeyValuePair] = useState([]);

  const [hospital, setHospital] = useState();
  const [department, setDepartment] = useState();
  const [room, setRoom] = useState();
  const [skipDay, setSkipDay] = useState({...skipDayDefaultValue});
  const [weekendOptionsList, setWeekendOptionsList] = useState([
    ...weekendOptions,
  ]);

  const [timeKeyValue, setTimeKeyValue] = useState([...typeOfSlots]);
  const [addRequestData, setAddRequestData] = useState({ ...initialAddData });
  const [isCreatingTasks, setIsCreatingTasks] = useState(false);

  const handleClose = () => {
    setTaskTemplateValue();
    setOperatorValue();
    setHospitalValue();
    setSlotType();
    setWeekDay();
    setHospital([]);
    setDepartment([]);
    setRoom([]);
    setAddRequestData({ ...initialAddData });
    setOpen(false);
    setEdit(false);
  };
  const handleDetailsClose = () => setDetails(false);

  const handleSlotType = (e) => {
    setSlotType(e);
    if (e.value === "Weekly") {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const handleOperator = (e) => {
    setOperatorValue(e);
    getClientListListInKeyValueByOperatorId(e.value)
      .then((res) => {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setHospitalsKeyValue(options);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleHospital = (e) => {
    setAddRequestData({
      ...addRequestData,
      clientId: e.value,
    });
    setHospital(e);

    getDepartmentDataByHospital(e.value)
      .then((res) => {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setDepartmentsKeyValue(options);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleDepartment = (e) => {
    setAddRequestData({
      ...addRequestData,
      departmentId: e.value,
    });
    setDepartment(e);

    let dep_id = {
      departmentId: e.value,
    };

    getRoomDataByDepartment(dep_id)
      .then((res) => {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setRoomKeyValuePair(options);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleRoom = (e) => {
    setAddRequestData({
      ...addRequestData,
      roomId: e.value,
    });
    setRoom(e);
  };

  const handleSaveEditAssignOperator = () => {
    var dict = {
      skipSaturday: skipDay.Saturday,
      taskSlotType: slotType.value,
      assignedToOperatorId: operatorValue.value,
      startDate: moment(addRequestData.startDate).format("YYYY-MM-DD"),
      endDate: moment(addRequestData.endDate).format("YYYY-MM-DD"),
      associatedClientId: addRequestData?.clientId,
      associatedClientDepartmentId: addRequestData?.departmentId,
      associatedClientRoomId: addRequestData?.roomId,
      templateId: addRequestData?.recordId,
    };
    if (weekDay && weekDay?.value) {
      dict.weekDay = weekDay.value;
    }

    $(".se-pre-con").css("display", "block");
    updateTask(addRequestData.recordId, dict).then((res) => {
      if (res.errorCode === 0) {
        $(".se-pre-con").css("display", "none");
        toast(res.message, {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        handleClose();
        getAssignedtaskList(0);
      } else {
        $(".se-pre-con").css("display", "none");
        toast(res.message, {
          icon: "🙁",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    });
  };

  const handleAssignOperator = () => {
    if (show === true) {
      var dict = {
        skipSaturday: skipDay.Saturday,
        skipHoliday: skipDay.AllHolidays,
        taskSlotType: slotType.value,
        assignedToOperatorId: operatorValue.value,
        startDate: moment(addRequestData.startDate).format("YYYY-MM-DD"),
        endDate: moment(addRequestData.endDate).format("YYYY-MM-DD"),
        weekDay: weekDay.value,
        associatedClientId: addRequestData?.clientId,
        associatedClientDepartmentId: addRequestData?.departmentId,
        associatedClientRoomId: addRequestData?.roomId,
      };
    } else {
      var dict = {
        skipSaturday: skipDay.Saturday,
        skipHoliday: skipDay.AllHolidays,
        taskSlotType: slotType.value,
        assignedToOperatorId: operatorValue.value,
        startDate: moment(addRequestData.startDate).format("YYYY-MM-DD"),
        endDate: moment(addRequestData.endDate).format("YYYY-MM-DD"),
        associatedClientId: addRequestData?.clientId,
        associatedClientDepartmentId: addRequestData?.departmentId,
        associatedClientRoomId: addRequestData?.roomId,
      };
    }

    $(".se-pre-con").css("display", "block");
    setIsCreatingTasks(true);
    assignTask(taskTemplateValue.option, dict)
      .then((res) => {
        if (res.errorCode === 0) {
          setIsCreatingTasks(false);
          setSkipDay({...skipDayDefaultValue})
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          handleClose();
          getAssignedtaskList(0);
        } else {
          setIsCreatingTasks(false);
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((error) => setIsCreatingTasks(false));
  };

  const handleOpen = () => {
    setAddRequestData({ ...initialAddData });
    setOpen(true);
    setEdit(false);
    callBasicAPI();
  };

  const callBasicAPIsForEdit = (responseData, recordId) => {
    let dict_first = {
      value: responseData.templateId,
      label: responseData.templateTitle,
    };
    setTaskTemplateValue(dict_first);

    const operatorObj = {
      value: responseData.operatorId,
      label: responseData.operatorTitle,
    };

    handleOperator(operatorObj);
    setOperatorValue(operatorObj);

    let dict_third = {
      value: responseData.clientId,
      label: responseData.clientTitle,
    };
    handleHospital(dict_third);
    setHospitalValue(dict_third);

    let dict_fourth = {
      value: responseData.departmentId || "",
      label: responseData.departmentName,
    };

    handleDepartment(dict_fourth);

    let dict_fifth = {
      value: responseData.roomId,
      label: responseData.roomName,
    };
    handleRoom(dict_fifth);

    // let dict_Sixth = {
    //   value: responseData.slotType,
    //   label: responseData.slotType,
    // };

    const dict_Sixth = timeKeyValue.find(
      (currentTimeObj) => currentTimeObj.value === responseData.slotType
    );
    handleSlotType(dict_Sixth);
    setSlotType(dict_Sixth);
    setAddRequestData({ ...responseData, recordId: recordId });
  };

  const handleEditAssignOperator = (id) => {
    // id Record Id
    getAssignOperatorDetails(id).then((res) => {
      if (res.errorCode == 0) {
        $(".se-pre-con").css("display", "none");
        let d = res.responsePacket;
        setOpen(true);
        setEdit(true);
        callBasicAPIsForEdit(res.responsePacket, id);
      }
    });
  };

  const handleDeleteAssignOperator = (id) => {
    Swal.fire({
      title: "Sei sicuro?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, conferma !!",
      cancelButtonText: "No !!",
    }).then((result) => {
      $(".se-pre-con").css("display", "block");
      if (result.isConfirmed) {
        //Active Request

        deleteAssignOperator(id)
          .then((res) => {
            if (res.errorCode == 0) {
              $(".se-pre-con").css("display", "none");
              toast(res.message, {
                icon: "👏",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
              getAssignedtaskList(0);
            } else {
              $(".se-pre-con").css("display", "none");
              toast(res.message, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            }
          })
          .catch((err) => {
            $(".se-pre-con").css("display", "none");
            toast(err, {
              icon: "🙁",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          });
      } else {
        $(".se-pre-con").css("display", "none");
      }
    });
  };

  const columns = [
    {
      name: "Nome attività",
      cell: (row) => row.taskTitle,
      width: "130px",
    },
    {
      name: "Nome operatore",
      cell: (row) => row.operatorTitle,
    },
    {
      name: "Ospedale/ Dipartimento/ Stanza",
      selector: (row) => (
        <>
          <div style={{ height: "30px", margin: "10px" }}>
            {row?.clientTitle}
          </div>
          <div style={{ height: "30px", margin: "10px" }}>
            {row?.departmentName}
          </div>
          <div style={{ height: "30px", margin: "10px" }}>{row?.roomName}</div>
        </>
      ),
    },
    {
      name: "Data dell attività",
      selector: (row) => moment(row.dateOfWork).format("DD/MM/YYYY"),
    },
    {
      name: "Stato",
      selector: (row) => row.taskStatus,
    },
    {
      name: "Azione",
      cell: (row) => (
        <>
          {/* <EditIcon
            style={{ color: "red" }}
            onClick={() => handleEditAssignOperator(row.recordId)}
          /> */}
          <DeleteOutlineOutlinedIcon
            style={{ color: "red" }}
            onClick={() => handleDeleteAssignOperator(row.recordId)}
          />
        </>
      ),
    },
  ];

  const handlePDF = () => {
    let doc = new jspdf("p", "pt", "a3");
    doc.html(document.getElementById("pdf"), {
      callback: function (pdf) {
        pdf.save("task.pdf");
        toast("PDF Downloaded Successfully", {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
    });
  };

  const callBasicAPI = () => {
    var rId = localStorage.getItem("rId");
    if (rId != undefined) {
      var recordIdForList = rId;
    } else {
      var recordIdForList = "";
    }
    var dict = {
      clientId: recordIdForList,
      requestedPage: 0,
      requestedPageSize: 1000000,
    };
    $(".se-pre-con").css("display", "block");
    getTemplatesKeyValueList(dict).then((res) => {
      if (res.errorCode == 0) {
        $(".se-pre-con").css("display", "none");
        let opt = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
          option: d.option,
        }));
        setTaskTemplateKeyValue(opt);
      } else {
        alert(res.message);
        $(".se-pre-con").css("display", "none");
      }
    });

    var rIdFirst = localStorage.getItem("rId");
    if (rIdFirst != undefined && rIdFirst != "") {
      var rIdFirst = rIdFirst;
    } else {
      var rIdFirst = "All";
    }

    getOperatorKeyValueList(rIdFirst).then((res) => {
      if (res.errorCode == 0) {
        $(".se-pre-con").css("display", "none");
        let opt = res.responsePacket.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setOperatorKeyValue(opt);
      } else {
        alert(res.message);
        $(".se-pre-con").css("display", "none");
      }
    });

    getClientKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        $(".se-pre-con").css("display", "none");
        let opt = res.responsePacket.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setHospitalsKeyValue(opt);
      } else {
        alert(res.message);
        $(".se-pre-con").css("display", "none");
      }
    });

    setDepartmentsKeyValue();
    setRoomKeyValuePair();

    getTaskSlotKeyValue().then((res) => {
      if (res.errorCode == 0) {
        $(".se-pre-con").css("display", "none");
        let opt = res.responsePacket.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setSlotTypeKeyValue(opt);
      } else {
        alert(res.message);
        $(".se-pre-con").css("display", "none");
      }
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getAssignedtaskList(0, newPerPage);
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    getAssignedtaskList(page - 1);
    setCurrentPage(page);
  };
  const getAssignedtaskList = async (page, size = perPage, searchKey = "") => {
    var rId = localStorage.getItem("rId");
    if (rId != undefined) {
      var recordIdForList = rId;
    } else {
      var recordIdForList = "";
    }
    let taskStatus = {
      searchKey: searchKey,
      clientName: recordData,
      taskStatusEnum: "Todo",
      clientId: recordIdForList,
      startDate: "",
      endDate: "",
      requestedPage: page,
      requestedPageSize: size,
    };
    $(".se-pre-con").css("display", "block");
    getTaskWorkOrderList(taskStatus).then((res) => {
      if (res.errorCode === 0 && res.responsePacket.length > 0) {
        $(".se-pre-con").css("display", "none");
        setRows(res.responsePacket);
        setRows2(res.responsePacket);
        if (res.responsePacket.length > 0) {
          setTotalRows(res.responsePacket[0].recordsTotal);
        } else {
          setTotalRows(0);
        }
        setRecordData("");
      } else {
        setRows([]);
        setRows2([]);
        setTotalRows();
      }
    });
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.taskStatus == "Completed",
      style: {
        cursor: "pointer",
      },
    },
  ];

  const handleData = (e) => {
    if (e.taskStatus == "Completed") {
      setDetails(true);
      taskTemplateUuid = e.recordId;
      if (e.recordId != 0) {
        formData = JSON.parse(e.subTaskJson);
        taskTemplateTitle = e.taskTitle;
        console.log("dict", formData);
        console.log("dict", taskTemplateTitle);
      } else {
        toast("❌" + e.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const handleMouseLeave = () => {
    $(".tooltip-inner").css("display", "none");
  };

  const [filterRows, setFilterRows] = useState({});

  const deletedRecords = (data) => {
    // if (data.selectedRows.length > 0 && data.allSelected == true) {
    //   $(".deleteBtn").css("display", "flex");
    // } else {
    //   $(".deleteBtn").css("display", "none");
    // }
    let selectedDeletedRecords = [];
    for (let i = 0; i < data.selectedRows.length; i++) {
      selectedDeletedRecords.push(data.selectedRows[i].recordId);
    }

    var dict = {
      taskIds: selectedDeletedRecords,
    };
    setFilterRows(dict);
    //data.selectedRows
  };

  const handleRecordsDeleted = () => {
    if (filterRows?.taskIds?.length > 0) {
      Swal.fire({
        title: "Sei sicuro?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sì, conferma !!",
        cancelButtonText: "No !!",
      }).then((result) => {
        $(".se-pre-con").css("display", "block");
        if (result.isConfirmed) {
          //Active Request
          deleteMultipleTask(filterRows)
            .then((res) => {
              if (res.errorCode == 0) {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "👏",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });
                getAssignedtaskList(0);
                setFilterRows({});
              } else {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "🙁",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });
              }
            })
            .catch((err) => {
              $(".se-pre-con").css("display", "none");
              toast(err, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            });
        } else {
          $(".se-pre-con").css("display", "none");
        }
      });
    }
  };

  useEffect(() => {
    getAssignedtaskList(0);
    handleMouseLeave();
  }, []);

  // const handleSearch = (search) => {
  //   const result = rows2.filter((a) => {
  //     return (
  //       a?.taskTitle?.toLowerCase().match(search.toLowerCase()) ||
  //       a?.dateOfWork?.toLowerCase().match(search.toLowerCase()) ||
  //       a?.taskStatus?.toLowerCase().match(search.toLowerCase()) ||
  //       a?.operatorTitle?.toLowerCase().match(search.toLowerCase()) ||
  //       a?.clientTitle?.toLowerCase().match(search.toLowerCase()) ||
  //       a?.departmentName?.toLowerCase().match(search.toLowerCase())
  //     );
  //   });
  //   setRows(result);
  // };

  const handleSearchTasks = (searchKey) => {
    getAssignedtaskList(0, perPage, searchKey);
  };

  const handleSelectEndDate = (e) => {
    setAddRequestData({
      ...addRequestData,
      endDate: e.target.value,
    });

    // let initialDate = new Date(addRequestData.startDate);
    // let endDate = new Date(e.target.value);

    // const days = (endDate, initialDate) => {
    //   let difference = endDate.getTime() - initialDate.getTime();
    //   let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    //   return TotalDays;
    // };
    // const totalDays = days(endDate, initialDate);
    // const updatedList = typeOfSlots.filter(
    //   (currentSlot) => totalDays >= currentSlot.dayCount
    //   );
    // setTimeKeyValue(updatedList);
  };

  const handleSkipDay = (e) => {
    setWeekendOptionsList(weekendOptions);
    const { id, checked } = e.target;
    if (id === "Saturday" && checked) {
      const updatedList = weekendOptions.filter(
        (currentObj) => currentObj.value !== id
      );
      setWeekendOptionsList(updatedList);
    }
    setSkipDay((prev) => ({ ...prev, [id]: checked }));
  };

  return (
    <>
      <Modal
        show={open}
        className={open ? "is-active is-animate" : ""}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Assegnare gli operatorir</Modal.Title>
          <CancelIcon className="pointer" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <table>
            <tr>
              <td style={{ width: "200px" }}>Modello di attività</td>
              <td style={{ minWidth: "250px" }}>
                <Select
                  options={taskTemplateKeyValue}
                  name="roundOffTo"
                  value={taskTemplateValue}
                  onChange={(e) => setTaskTemplateValue(e)}
                />
              </td>
            </tr>
            <tr>
              <td>Operatori</td>
              <td>
                <Select
                  options={operatorKeyValue}
                  name="roundOffTo"
                  value={operatorValue}
                  onChange={handleOperator}
                />
              </td>
            </tr>

            <tr>
              <td>Ospedale</td>
              <td>
                <Select
                  options={hospitalsKeyValue}
                  name="roundOffTo"
                  value={hospital}
                  onChange={handleHospital}
                />
              </td>
            </tr>

            <tr>
              <td>Dipartimento</td>
              <td>
                {" "}
                <Select
                  options={departmentsKeyValue}
                  name="roundOffTo"
                  value={department}
                  onChange={handleDepartment}
                />
              </td>
            </tr>

            <tr>
              <td>Room</td>
              <td>
                <Select
                  options={roomKeyValuePair}
                  name="roundOffTo"
                  value={room}
                  onChange={handleRoom}
                />
              </td>
            </tr>

            <tr>
              <td>Data d'inizio</td>
              <td>
                <input
                  style={{ width: "250px" }}
                  className="form-control"
                  type="date"
                  value={addRequestData.startDate}
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      startDate: e.target.value,
                    })
                  }
                  min={new Date().toISOString().slice(0, 10)}
                />
              </td>
            </tr>
            <tr>
              <td>Data di fine</td>
              <td>
                <input
                  style={{ width: "250px" }}
                  className="form-control"
                  type="date"
                  onKeyDown={(e) => e.preventDefault()}
                  value={addRequestData.endDate}
                  onChange={(e) => handleSelectEndDate(e)}
                  min={addRequestData.startDate}
                />
              </td>
            </tr>

            <tr>
              <td>Tipo di slot</td>
              <td>
                <Select
                  options={timeKeyValue}
                  name="roundOffTo"
                  value={slotType}
                  onChange={(e) => handleSlotType(e)}
                />
              </td>
            </tr>
            <tr>
              <td>Skip Day</td>
              <td>
                <div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Saturday"
                      value={skipDay.Saturday}
                      checked={skipDay.Saturday}
                      onChange={handleSkipDay}
                    />
                    <label className="form-check-label" htmlFor="Saturday">
                      Saturday
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Skip Holidays</td>
              <td>
                <div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="AllHolidays"
                      value={skipDay.AllHolidays}
                      checked={skipDay.AllHolidays}
                      onChange={handleSkipDay}
                    />
                    <label className="form-check-label" htmlFor="AllHolidays">
                      All Holidays
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            {show ? (
              <tr>
                <td> Giorno della settimana</td>
                <td>
                  <Select
                    options={weekendOptionsList}
                    name="roundOffTo"
                    value={weekDay}
                    onChange={(e) => setWeekDay(e)}
                  />
                </td>
              </tr>
            ) : (
              <></>
            )}
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            // onClick={edit ? handleSaveEditAssignOperator : handleAssignOperator}
            onClick={handleAssignOperator}
            disabled={isCreatingTasks}
          >
            {isCreatingTasks ? <LoaderIcon /> : "Salva"}
            {/* {edit ? "Update" : "Salva"} */}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="sidebar-backdrop" />
      <div className="page-wrapper">
        <CommanComponents />
        <main className="page-content">
          <div className="container">
            <div className="row0">
              <div>Assegnare gli operatori</div>
              <div className="row1">
                <SearchIcon />
                <input
                  className="form-control"
                  placeholder="Search Nome Attivita"
                  onChange={(e) => handleSearchTasks(e.target.value)}
                  // onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              <div>
                {/* {filterRows?.taskIds?.length > 0 && (
                )} */}
                <button
                  className="btn btn-primary mr-3"
                  onClick={() => handleRecordsDeleted()}
                >
                  Elimina attività selezionata
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => handleOpen()}
                >
                  <i className="fa fa-plus"></i> Assegnare gli operatori
                </button>
              </div>
            </div>
            <br />
            <div className="row">
              <div style={{ height: "100%", width: "100%" }} id="pdf">
                <DataTable
                  columns={columns}
                  data={rows}
                  theme="solarized"
                  pagination
                  onRowClicked={(e) => handleData(e)}
                  conditionalRowStyles={conditionalRowStyles}
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  selectableRows
                  onSelectedRowsChange={deletedRecords}
                />
              </div>
            </div>
          </div>
        </main>
      </div>

      <ToastContainer />
      {/* <div className="se-pre-con">
        <img src={loader} />
      </div> */}
    </>
  );
};
