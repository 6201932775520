// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from 'axios'
import { USER_DEPARTMENT } from "./CONSTANTS";

const auth = localStorage.getItem('itemOxygenAuth')

const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': auth != undefined ? "Basic " + auth : ''
    // 'Authorization': url.Auth_Token
}


/**
 * Function to Get User Operators List.
 */
export const getDepartmentList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_DEPARTMENT() + "list", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

/**
 * Function to Add Department Client List.
 */
export const addDepartment = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_DEPARTMENT() + "add", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getDepartmentKeyvalueList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_DEPARTMENT() + "getClientDepartmentListInKeyValue", {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const deleteDepartment = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(USER_DEPARTMENT() + "delete/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deleteDepartment axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getDepartmentDetails = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_DEPARTMENT() + "get/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getOperatorDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateDepartment = (uuid, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(USER_DEPARTMENT() + "update/" + uuid, data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateOperator axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
