// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from 'axios'


/**
 * Function to register the users.
 */
export const signup = (data) => {
    console.log("userServices > signup called...");
    return new Promise((resolve, reject) => {
        try {

        } catch (error) {
            console.error("in userServices > signup, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};
/**
 * Function to login the users.
 */
export const signIn = (data) => {
    console.log("userServices > signIn called...");
    return new Promise((resolve, reject) => {
        try {

        } catch (error) {
            console.error("in userServices > signIn, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};



/**
 * Function to fetch all the users.
 */
export const getAllUsers = () => {
    console.log("userServices > getAllUsers called...");
    return new Promise((resolve, reject) => {
        try {

        } catch (error) {
            console.error("in userServices > getAllUsers, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

/**
 * Function to fetch the details of the user based on userId.
 * @param {string} userid of the user.
 * early dev example passing Skeleton(static object) as API response.
 */
export const getUserDetails = (id) => {
    console.log("userServices > getUserDetails called...");
    return new Promise((resolve, reject) => {
        try {
            // do an SDK, DB call or API endpoint axios call here and return the promise.

        } catch (error) {
            console.error("in userServices > getUserDetails, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

// TODO: Dummy service - delete this.
export const getUserDetails1 = (id) => {
    console.log("userServices > getUserDetails called...");
    return new Promise((resolve, reject) => {
        try {

        } catch (error) {
            console.error("in userServices > getUserDetails1, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

