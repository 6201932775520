import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Login } from "./index"
import { ClientCommonComponent } from './../components/ClientCommonComponent';
import { CommanComponents } from '../components';

export const Dashboard = props => {
    const history = useHistory();
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [userType, setUserType] = useState("ROLE_ADMIN")

    // const goTo = (path) => {
    //     history.push(path || ROOT);
    // }
    useEffect(() => {
        var loginAuth = localStorage.getItem('itemOxygenAuth');
        if (loginAuth == undefined) {
            setIsLoggedIn(false)
        } else {
            setIsLoggedIn(true)
        }
        const role = localStorage.getItem("itemOxygenRole")
        console.log(role);
        setUserType(role)
    }, []);

    return (
        <>
            {isLoggedIn ?
                <>
                    <div>
                        <div className="sidebar-backdrop" />
                        <div className="page-wrapper">
                            {userType === "ROLE_ADMIN" ?
                                <CommanComponents />
                                :
                                <ClientCommonComponent />
                            }
                            <main className="page-content">
                                <div className="container">
                                    <div className="widgets">
                                        <div className="widgets__row row gutter-bottom-xl">
                                            <div className="col-12 col-sm-6 col-xl-4 d-flex">
                                                <div className="widget">
                                                    <div className="widget__wrapper">
                                                        <div className="widget__row">
                                                            <div className="widget__left">
                                                                <h3 className="widget__title">Visits</h3>
                                                                <div className="widget__status-title text-grey">Total visits today</div>
                                                                <div className="widget__spacer" />
                                                                <div className="widget__trade">
                                                                    <span className="widget__trade-count">4000</span>
                                                                    <span className="trade-icon trade-icon--up">
                                                                        <svg className="icon-icon-trade-up">
                                                                            <use xlinkHref="#icon-trade-up" />
                                                                        </svg>
                                                                    </span>
                                                                    <span className="badge badge--sm badge--green">7%</span>
                                                                </div>
                                                                <div className="widget__details"><a className="link-under text-grey" href="#">Detail</a></div>
                                                            </div>
                                                            <div className="widget__chart">
                                                                <div className="widget__chart-inner">
                                                                    <div className="widget__chart-percentage">50<small>%</small></div>
                                                                    <div className="widget__chart-caption">New Visits</div>
                                                                </div>
                                                                <div className="widget__chart-canvas js-progress-circle" data-value="0.5" data-color="#22CCE2" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-xl-4 d-flex">
                                                <div className="widget">
                                                    <div className="widget__wrapper">
                                                        <div className="widget__row">
                                                            <div className="widget__left">
                                                                <h3 className="widget__title">Orders</h3>
                                                                <div className="widget__status-title text-grey">Total visits today</div>
                                                                <div className="widget__spacer" />
                                                                <div className="widget__trade">
                                                                    <span className="widget__trade-count">1000</span>
                                                                    <span className="trade-icon trade-icon--down">
                                                                        <svg className="icon-icon-trade-down">
                                                                            <use xlinkHref="#icon-trade-down" />
                                                                        </svg>
                                                                    </span>
                                                                    <span className="badge badge--sm badge--red">3%</span>
                                                                </div>
                                                                <div className="widget__details"><a className="link-under text-grey" href="#">Detail</a></div>
                                                            </div>
                                                            <div className="widget__chart">
                                                                <div className="widget__chart-inner">
                                                                    <div className="widget__chart-percentage">75<small>%</small></div>
                                                                    <div className="widget__chart-caption">New Orders</div>
                                                                </div>
                                                                <div className="widget__chart-canvas js-progress-circle" data-value="0.75" data-color="#FDBF5E" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-xl-4 d-flex">
                                                <div className="widget">
                                                    <div className="widget__wrapper">
                                                        <div className="widget__row">
                                                            <div className="widget__left">
                                                                <h3 className="widget__title">Sales</h3>
                                                                <div className="widget__status-title text-grey">Total visits today</div>
                                                                <div className="widget__spacer" />
                                                                <div className="widget__trade">
                                                                    <span className="widget__trade-count">$500</span>
                                                                    <span className="trade-icon trade-icon--up">
                                                                        <svg className="icon-icon-trade-up">
                                                                            <use xlinkHref="#icon-trade-up" />
                                                                        </svg>
                                                                    </span>
                                                                    <span className="badge badge--sm badge--green">9%</span>
                                                                </div>
                                                                <div className="widget__details"><a className="link-under text-grey" href="#">Detail</a></div>
                                                            </div>
                                                            <div className="widget__chart">
                                                                <div className="widget__chart-inner">
                                                                    <div className="widget__chart-percentage">80<small>%</small></div>
                                                                    <div className="widget__chart-caption">New Sales</div>
                                                                </div>
                                                                <div className="widget__chart-canvas js-progress-circle" data-value="0.8" data-color="#FF3D57" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <section className="section">
                                        <div className="section__title d-none">
                                            <h2>Section</h2>
                                        </div>
                                        <div className="row justify-content-center gutter-bottom-xl">
                                            <div className="col-12 col-lg-7 col-xl-8 d-flex">
                                                <div className="card">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Revenue</h3>
                                                                </div>
                                                                <div className="card__tools">
                                                                    <div className="card__tools-row row row--xs gutter-bottom-xs">
                                                                        <div className="card__tools-calendar col-auto">
                                                                            <div className="input-group input-group--prepend input-group--append">
                                                                                <div className="input-group__prepend">
                                                                                    <svg className="icon-icon-calendar">
                                                                                        <use xlinkHref="#icon-calendar" />
                                                                                    </svg>
                                                                                </div>
                                                                                <input className="input input--select js-datepicker"  defaultValue="01.12.18 / 07.12.18" />
                                                                                <span className="input-group__arrow">
                                                                                    <svg className="icon-icon-keyboard-down">
                                                                                        <use xlinkHref="#icon-keyboard-down" />
                                                                                    </svg>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card__tools-week col">
                                                                            <div className="input-group input-group--append">
                                                                                <input className="input input--select"  defaultValue="Week" data-toggle="dropdown" readOnly="readonly" />
                                                                                <span className="input-group__arrow">
                                                                                    <svg className="icon-icon-keyboard-down">
                                                                                        <use xlinkHref="#icon-keyboard-down" />
                                                                                    </svg>
                                                                                </span>
                                                                                <div className="dropdown-menu dropdown-menu--right dropdown-menu--fluid js-dropdown-select">
                                                                                    <a className="dropdown-menu__item active" href="#" tabIndex={0} data-value="Week">Week</a><a className="dropdown-menu__item" href="#" tabIndex={0} data-value="Month">Month</a>
                                                                                    <a className="dropdown-menu__item" href="#" tabIndex={0} data-value="Quarter">Quarter</a><a className="dropdown-menu__item" href="#" tabIndex={0} data-value="Year">Year</a>
                                                                                    <a className="dropdown-menu__item" href="#" tabIndex={0} data-value="All Time">All Time</a>
                                                                                    <a className="dropdown-menu__item" href="#" tabIndex={0} data-value="Custom">Custom</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card__widgets">
                                                                    <div className="card__widgets-row gutter-bottom-sm">
                                                                        <div className="card-widget">
                                                                            <h4 className="card-widget__title">Current week</h4>
                                                                            <div className="card-widget__trade">
                                                                                <span className="card-widget__count text-red">$180</span>
                                                                                <span className="trade-icon trade-icon--up">
                                                                                    <svg className="icon-icon-trade-up">
                                                                                        <use xlinkHref="#icon-trade-up" />
                                                                                    </svg>
                                                                                </span>
                                                                                <span className="badge badge--green badge--sm">5%</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-widget">
                                                                            <h4 className="card-widget__title">Previous week</h4>
                                                                            <div className="card-widget__trade">
                                                                                <span className="card-widget__count text-grey">$52.30</span>
                                                                                <span className="trade-icon trade-icon--down">
                                                                                    <svg className="icon-icon-trade-down">
                                                                                        <use xlinkHref="#icon-trade-down" />
                                                                                    </svg>
                                                                                </span>
                                                                                <span className="badge badge--red badge--sm">2%</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card__chart">
                                                                    <div className="card__container card__container--gutter-sm">
                                                                        <div className="card__chart-item chart-revenue" id="revenueChart" data-series="[[0, 10, 48, 9, 9, 53, 12], [0, 45, 9, 49, 53, 180, 70]]" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-5 col-xl-4 d-flex">
                                                <div className="card">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header mb-0">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Profit</h3>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card__chart">
                                                                    <div className="card__chart-item chart-profit" id="profitPieChart" data-series="[200, 120, 180]" />
                                                                </div>
                                                            </div>
                                                            <div className="card__footer">
                                                                <div className="card__container">
                                                                    <table className="card__stat">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><span className="signal" style={{ color: '#ff3d57' }} /><span>Current</span></td>
                                                                                <td>
                                                                                    <span>$500</span>
                                                                                    <span className="quotation quotation--up">
                                                                                        <svg className="icon-icon-arrow-down">
                                                                                            <use xlinkHref="#icon-arrow-down" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </td>
                                                                                <td><span className="text-grey">37%</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><span className="signal" style={{ color: '#fdbf5e' }} /><span>Lost</span></td>
                                                                                <td>
                                                                                    <span>$450</span>
                                                                                    <span className="quotation quotation--down">
                                                                                        <svg className="icon-icon-arrow-down">
                                                                                            <use xlinkHref="#icon-arrow-down" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </td>
                                                                                <td><span className="text-grey">35%</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><span className="signal" style={{ color: '#22cce2' }} /><span>Target</span></td>
                                                                                <td>
                                                                                    <span>$300</span>
                                                                                    <span className="quotation quotation--down">
                                                                                        <svg className="icon-icon-arrow-down">
                                                                                            <use xlinkHref="#icon-arrow-down" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </td>
                                                                                <td><span className="text-grey">28%</span></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="section">
                                        <div className="section__title d-none">
                                            <h2>Section</h2>
                                        </div>
                                        <div className="row justify-content-center gutter-bottom-xl">
                                            <div className="col-12 col-lg-7 col-xl-8 d-flex">
                                                <div className="card">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Activity</h3>
                                                                </div>
                                                                <div className="card__tools">
                                                                    <div className="card__tools-row row row--xs gutter-bottom-xs">
                                                                        <div className="card__tools-calendar col-auto">
                                                                            <div className="input-group input-group--prepend input-group--append">
                                                                                <div className="input-group__prepend">
                                                                                    <svg className="icon-icon-calendar">
                                                                                        <use xlinkHref="#icon-calendar" />
                                                                                    </svg>
                                                                                </div>
                                                                                <input className="input input--select js-datepicker"  defaultValue="01.12.18 / 07.12.18" />
                                                                                <span className="input-group__arrow">
                                                                                    <svg className="icon-icon-keyboard-down">
                                                                                        <use xlinkHref="#icon-keyboard-down" />
                                                                                    </svg>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card__tools-week col">
                                                                            <div className="input-group input-group--append">
                                                                                <input className="input input--select"  defaultValue="Week" data-toggle="dropdown" readOnly="readonly" />
                                                                                <span className="input-group__arrow">
                                                                                    <svg className="icon-icon-keyboard-down">
                                                                                        <use xlinkHref="#icon-keyboard-down" />
                                                                                    </svg>
                                                                                </span>
                                                                                <div className="dropdown-menu dropdown-menu--right dropdown-menu--fluid js-dropdown-select">
                                                                                    <a className="dropdown-menu__item active" href="#" tabIndex={0} data-value="Week">Week</a><a className="dropdown-menu__item" href="#" tabIndex={0} data-value="Month">Month</a>
                                                                                    <a className="dropdown-menu__item" href="#" tabIndex={0} data-value="Quarter">Quarter</a><a className="dropdown-menu__item" href="#" tabIndex={0} data-value="Year">Year</a>
                                                                                    <a className="dropdown-menu__item" href="#" tabIndex={0} data-value="All Time">All Time</a>
                                                                                    <a className="dropdown-menu__item" href="#" tabIndex={0} data-value="Custom">Custom</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card__chart">
                                                                    <div className="card__container">
                                                                        <div className="card__chart-item chart-activity" id="activityChart" data-series="[[&quot;Mon&quot;, &quot;Tue&quot;, &quot;Wed&quot;, &quot;Thu&quot;, &quot;Fri&quot;, &quot;Sat&quot;, &quot;Sun&quot;], [170, 120, 200, 270, 220, 290, 320], [120, 90, 100, 240, 120, 200, 250]]" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-5 col-xl-4 d-flex">
                                                <div className="card">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header mb-0">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Tasks</h3>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card__chart">
                                                                    <div className="card__chart-item chart-task" id="taskPieChart" data-series="[75, 25, 80]" />
                                                                </div>
                                                                <div className="card__chart-progress">
                                                                    <div className="card__chart-progress-item">
                                                                        <div className="card__progressbar">
                                                                            <div className="progressbar">
                                                                                <div className="progressbar__legend"><span className="progressbar__legend-marker" style={{ color: '#ff3d57' }} /><span>Complete</span></div>
                                                                                <div className="progressbar__items">
                                                                                    <div className="progressbar__bar" style={{ width: '75%', backgroundColor: '#ff3d57' }} />
                                                                                </div>
                                                                                <div className="progressbar__append"><span className="progressbar__percentage">75%</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card__chart-progress-item">
                                                                        <div className="card__progressbar">
                                                                            <div className="progressbar">
                                                                                <div className="progressbar__legend"><span className="progressbar__legend-marker" style={{ color: '#fdbf5e' }} /><span>In Progress</span></div>
                                                                                <div className="progressbar__items">
                                                                                    <div className="progressbar__bar" style={{ width: '25%', backgroundColor: '#fdbf5e' }} />
                                                                                </div>
                                                                                <div className="progressbar__append"><span className="progressbar__percentage">25%</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card__chart-progress-item">
                                                                        <div className="card__progressbar">
                                                                            <div className="progressbar">
                                                                                <div className="progressbar__legend"><span className="progressbar__legend-marker" style={{ color: '#22cce2' }} /><span>Started</span></div>
                                                                                <div className="progressbar__items">
                                                                                    <div className="progressbar__bar" style={{ width: '80%', backgroundColor: '#22cce2' }} />
                                                                                </div>
                                                                                <div className="progressbar__append"><span className="progressbar__percentage">80%</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="section">
                                        <div className="section__title d-none">
                                            <h2>Section</h2>
                                        </div>
                                        <div className="row justify-content-center gutter-bottom-xl">
                                            <div className="col-12 col-lg-7 col-xl-8 d-flex">
                                                <div className="card card--overview">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Overview</h3>
                                                                </div>
                                                                <div className="card__tools">
                                                                    <div className="card__tools-row row row--xs gutter-bottom-xs">
                                                                        <div className="card__tools-switcher col">
                                                                            <div className="switcher-button">
                                                                                <div className="switcher-button__items">
                                                                                    <div className="switcher-button__float" />
                                                                                    <div className="switcher-button__item active">
                                                                                        <button className="switcher-button__btn">Weekly</button>
                                                                                    </div>
                                                                                    <div className="switcher-button__item">
                                                                                        <button className="switcher-button__btn">Monthly</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card__widgets">
                                                                    <div className="card__widgets-row gutter-bottom-sm">
                                                                        <div className="card-widget card-widget--sm">
                                                                            <h4 className="card-widget__title">Views</h4>
                                                                            <div className="card-widget__trade">
                                                                                <span className="card-widget__count">9,000</span>
                                                                                <span className="trade-icon trade-icon--up">
                                                                                    <svg className="icon-icon-trade-up">
                                                                                        <use xlinkHref="#icon-trade-up" />
                                                                                    </svg>
                                                                                </span>
                                                                                <span className="badge badge--green badge--sm">5%</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-widget card-widget--sm">
                                                                            <h4 className="card-widget__title">Visits</h4>
                                                                            <div className="card-widget__trade">
                                                                                <span className="card-widget__count">15,000</span>
                                                                                <span className="trade-icon trade-icon--down">
                                                                                    <svg className="icon-icon-trade-down">
                                                                                        <use xlinkHref="#icon-trade-down" />
                                                                                    </svg>
                                                                                </span>
                                                                                <span className="badge badge--red badge--sm">5%</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-widget card-widget--sm">
                                                                            <h4 className="card-widget__title">Orders</h4>
                                                                            <div className="card-widget__trade">
                                                                                <span className="card-widget__count">18,000</span>
                                                                                <span className="trade-icon trade-icon--up">
                                                                                    <svg className="icon-icon-trade-up">
                                                                                        <use xlinkHref="#icon-trade-up" />
                                                                                    </svg>
                                                                                </span>
                                                                                <span className="badge badge--green badge--sm">5%</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card__chart">
                                                                    <div className="card__container card__container--gutter-sm">
                                                                        <div className="card__chart-item chart-overview" id="overviewLineChart" data-series="[20, 60, 30, 50, 120, 90, 200]" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-5 col-xl-4 d-flex">
                                                <div className="card">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Statistics</h3>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card__chart">
                                                                    <div className="card__container">
                                                                        <div className="card__chart-item chart-statistics" id="statisticsBarChart" data-series="[[&quot;Mon&quot;, &quot;Tue&quot;, &quot;Wed&quot;, &quot;Thu&quot;, &quot;Fri&quot;, &quot;Sat&quot;, &quot;Sun&quot;],[23000, 62000, 79000, 86000, 90500, 39500, 64000],[42000, 49000, 39000, 100000, 67000, 59000, 45000]]" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="section">
                                        <div className="section__title d-none">
                                            <h2>Section</h2>
                                        </div>
                                        <div className="row gutter-bottom-xl">
                                            <div className="col-12 col-md-6 col-lg-7 col-xl-4 d-flex">
                                                <div className="card">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Credit Card</h3>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card__credit-card">
                                                                    <div className="credit-card">
                                                                        <img className="credit-card__image" src="img/content/credit-card.svg" alt="#" />
                                                                        <div className="credit-card__number-wrapper">
                                                                            <input className="credit-card__number-input"  defaultValue={1} readOnly="readonly" />
                                                                            <div className="credit-card__number">
                                                                                <div className="credit-card__number-item">0123</div>
                                                                                <div className="credit-card__number-item">4567</div>
                                                                                <div className="credit-card__number-item">8910</div>
                                                                                <div className="credit-card__number-item">1112</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="credit-card__name">
                                                                            <div className="credit-card__caption">Card Holder</div>
                                                                            Mark Anderson
                                                                        </div>
                                                                        <div className="credit-card__date">
                                                                            <div className="credit-card__caption">Expire</div>
                                                                            <span>12/31</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__footer">
                                                                <div className="card__container">
                                                                    <div className="card__credit-list">
                                                                        <ul className="card-list">
                                                                            <li className="row row--xs">
                                                                                <div className="card-list__title col-auto">Card Type</div>
                                                                                <div className="card-list__value col">VISA</div>
                                                                            </li>
                                                                            <li className="row row--xs">
                                                                                <div className="card-list__title col-auto">Card Holder</div>
                                                                                <div className="card-list__value col">Mark Anderson</div>
                                                                            </li>
                                                                            <li className="row row--xs">
                                                                                <div className="card-list__title col-auto">Expire</div>
                                                                                <div className="card-list__value col">12/31</div>
                                                                            </li>
                                                                            <li className="row row--xs">
                                                                                <div className="card-list__title col-auto">Card Number</div>
                                                                                <div className="card-list__value col">0123 4567 8910 1112</div>
                                                                            </li>
                                                                            <li className="row row--xs">
                                                                                <div className="card-list__title col-auto">Balance</div>
                                                                                <div className="card-list__value col">$1,000,000</div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-5 col-xl-4 d-flex">
                                                <div className="card">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Add New Card</h3>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <form className="add-card js-add-card-form" method="POST">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Name on Card</label>
                                                                        <div className="input-group">
                                                                            <input className="input"  placeholder="Mark Anderson" required="required" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="form-label">Card Number</label>
                                                                        <div className="input-group input-group--append">
                                                                            <input className="input js-card-number"  placeholder="****   ****   ****   1234" required="required" />
                                                                            <span className="input-group__append"><img className="add-card__input-number-logo" src="img/content/visa-logo.png" alt="#" /></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row row--sm">
                                                                        <div className="form-group col">
                                                                            <label className="form-label">Expire Date</label>
                                                                            <div className="input-group">
                                                                                <input className="input js-card-date"  placeholder="12/2020" required="required" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group col">
                                                                            <label className="form-label">CVV Code</label>
                                                                            <div className="input-group">
                                                                                <input className="input js-card-cvv"  placeholder="***" required="required" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="add-card__submit">
                                                                        <button className="button button--primary button--block" type="submit">
                                                                            <span className="button__icon button__icon--left">
                                                                                <svg className="icon-icon-plus">
                                                                                    <use xlinkHref="#icon-plus" />
                                                                                </svg>
                                                                            </span>
                                                                            <span className="button__text">Add Card</span>
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 col-xl-4 d-flex">
                                                <div className="card">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Saved Cards</h3>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card__new-credits card__scrollbar scrollbar-thin scrollbar-visible" data-simplebar="data-simplebar">
                                                                    <div className="card__container">
                                                                        <div className="card__credits-items row">
                                                                            <div className="card__credits-item col-auto">
                                                                                <div className="credit-card credit-card--light">
                                                                                    <img className="credit-card__image credit-card__image--light" src="img/content/credit-visa.svg" alt="#" />
                                                                                    <img className="credit-card__image credit-card__image--dark" src="img/content/credit-visa-dark.svg" alt="#" />
                                                                                    <div className="credit-card__number-wrapper">
                                                                                        <input className="credit-card__number-input"  defaultValue={1} readOnly="readonly" />
                                                                                        <div className="credit-card__number">
                                                                                            <div className="credit-card__number-item">0123</div>
                                                                                            <div className="credit-card__number-item">4567</div>
                                                                                            <div className="credit-card__number-item">8910</div>
                                                                                            <div className="credit-card__number-item">1112</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="credit-card__name">
                                                                                        <div className="credit-card__caption">Card Holder</div>
                                                                                        Mark Anderson
                                                                                    </div>
                                                                                    <div className="credit-card__date">
                                                                                        <div className="credit-card__caption">Expire</div>
                                                                                        <span>12/31</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card__credits-item col-auto">
                                                                                <div className="credit-card credit-card--light">
                                                                                    <img className="credit-card__image credit-card__image--light" src="img/content/credit-mastercard.svg" alt="#" />
                                                                                    <img className="credit-card__image credit-card__image--dark" src="img/content/credit-mastercard-dark.svg" alt="#" />
                                                                                    <div className="credit-card__number-wrapper">
                                                                                        <input className="credit-card__number-input"  defaultValue={1} readOnly="readonly" />
                                                                                        <div className="credit-card__number">
                                                                                            <div className="credit-card__number-item">0123</div>
                                                                                            <div className="credit-card__number-item">4567</div>
                                                                                            <div className="credit-card__number-item">8910</div>
                                                                                            <div className="credit-card__number-item">1112</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="credit-card__name">
                                                                                        <div className="credit-card__caption">Card Holder</div>
                                                                                        Mark Anderson
                                                                                    </div>
                                                                                    <div className="credit-card__date">
                                                                                        <div className="credit-card__caption">Expire</div>
                                                                                        <span>12/31</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__footer card__footer--md">
                                                                <div className="card__container">
                                                                    <div className="card__credit-button">
                                                                        <button className="button button--primary button--block" type="button">
                                                                            <span className="button__icon button__icon--left">
                                                                                <svg className="icon-icon-plus">
                                                                                    <use xlinkHref="#icon-plus" />
                                                                                </svg>
                                                                            </span>
                                                                            <span className="button__text">Add New Card</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="section">
                                        <div className="section__title d-none">
                                            <h2>Section</h2>
                                        </div>
                                        <div className="row gutter-bottom-xl">
                                            <div className="col-12 col-lg-7 col-xl-8 d-flex">
                                                <div className="card card--lg">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Revenue By Country</h3>
                                                                </div>
                                                                <div className="card__tools">
                                                                    <div className="card__tools-row row row--xs gutter-bottom-xs">
                                                                        <div className="card__tools-switcher col">
                                                                            <div className="switcher-button">
                                                                                <div className="switcher-button__items">
                                                                                    <div className="switcher-button__float" />
                                                                                    <div className="switcher-button__item">
                                                                                        <button className="switcher-button__btn">Daily</button>
                                                                                    </div>
                                                                                    <div className="switcher-button__item active">
                                                                                        <button className="switcher-button__btn">Weekly</button>
                                                                                    </div>
                                                                                    <div className="switcher-button__item">
                                                                                        <button className="switcher-button__btn">Monthly</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card__widgets">
                                                                    <div className="card__widgets-row gutter-bottom-sm">
                                                                        <div className="card-widget card-widget--xl">
                                                                            <h4 className="card-widget__title">Total Revenue</h4>
                                                                            <div className="card-widget__trade"><span className="card-widget__count">$210,000</span></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card__countries-progressbar">
                                                                    <div className="progressbar progressbar--stacked progressbar--axis-top progressbar--lg">
                                                                        <div className="progressbar__items">
                                                                            <div className="progressbar__bar progressbar__bar--green" style={{ width: '40%' }}><span className="progressbar__bar-count">$70,000</span></div>
                                                                            <div className="progressbar__bar progressbar__bar--orange" style={{ width: '20%' }}><span className="progressbar__bar-count">$55,000</span></div>
                                                                            <div className="progressbar__bar progressbar__bar--red" style={{ width: '20%' }}><span className="progressbar__bar-count">$40,000</span></div>
                                                                            <div className="progressbar__bar progressbar__bar--orange-dark" style={{ width: '10%' }}><span className="progressbar__bar-count">$25,000</span></div>
                                                                            <div className="progressbar__bar progressbar__bar--teal" style={{ width: '10%' }}><span className="progressbar__bar-count">$20,000</span></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__footer card__footer--lg">
                                                                <div className="card__container">
                                                                    <div className="country-legend">
                                                                        <div className="country-legend__row">
                                                                            <div className="country-legend__item">
                                                                                <div className="country-legend__left"><span className="country-legend__marker color-green" /><span className="country-legend__name">Russia</span></div>
                                                                                <div className="country-legend__trade">
                                                                                    <span className="country-legend__quot quotation quotation--up">
                                                                                        <svg className="icon-icon-arrow-down">
                                                                                            <use xlinkHref="#icon-arrow-down" />
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span className="country-legend__percentage">40%</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="country-legend__item">
                                                                                <div className="country-legend__left"><span className="country-legend__marker color-orange" /><span className="country-legend__name">Australia</span></div>
                                                                                <div className="country-legend__trade">
                                                                                    <span className="country-legend__quot quotation quotation--down">
                                                                                        <svg className="icon-icon-arrow-down">
                                                                                            <use xlinkHref="#icon-arrow-down" />
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span className="country-legend__percentage">20%</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="country-legend__item">
                                                                                <div className="country-legend__left"><span className="country-legend__marker color-red" /><span className="country-legend__name">China</span></div>
                                                                                <div className="country-legend__trade">
                                                                                    <span className="country-legend__quot quotation quotation--up">
                                                                                        <svg className="icon-icon-arrow-down">
                                                                                            <use xlinkHref="#icon-arrow-down" />
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span className="country-legend__percentage">20%</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="country-legend__item">
                                                                                <div className="country-legend__left"><span className="country-legend__marker color-orange-dark" /><span className="country-legend__name">France</span></div>
                                                                                <div className="country-legend__trade">
                                                                                    <span className="country-legend__quot quotation quotation--down">
                                                                                        <svg className="icon-icon-arrow-down">
                                                                                            <use xlinkHref="#icon-arrow-down" />
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span className="country-legend__percentage">10%</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="country-legend__item">
                                                                                <div className="country-legend__left"><span className="country-legend__marker color-teal" /><span className="country-legend__name">USA</span></div>
                                                                                <div className="country-legend__trade">
                                                                                    <span className="country-legend__quot quotation quotation--up">
                                                                                        <svg className="icon-icon-arrow-down">
                                                                                            <use xlinkHref="#icon-arrow-down" />
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span className="country-legend__percentage">10%</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-5 col-xl-4 d-flex">
                                                <div className="card card--right pb-0">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Transactions History</h3>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card__transactions card__scrollbar scrollbar-thin scrollbar-visible" data-simplebar="data-simplebar">
                                                                    <div className="card-transactions">
                                                                        <div className="card-transactions__item">
                                                                            <div className="card-transactions__left">
                                                                                <div className="card-transactions__icon">
                                                                                    <svg className="icon-icon-bill">
                                                                                        <use xlinkHref="#icon-bill" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="card-transactions__right">
                                                                                    <h5 className="card-transactions__title">MacBook Pro</h5>
                                                                                    <span className="card-transactions__time">12:00 PM</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-transactions__details">
                                                                                <div className="card-transactions__cost">$2,500</div>
                                                                                <a className="text-sm text-grey link-under" href="#">Detail</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-transactions__item">
                                                                            <div className="card-transactions__left">
                                                                                <div className="card-transactions__icon">
                                                                                    <svg className="icon-icon-bill">
                                                                                        <use xlinkHref="#icon-bill" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="card-transactions__right">
                                                                                    <h5 className="card-transactions__title">Apple Watch</h5>
                                                                                    <span className="card-transactions__time">01:00 PM</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-transactions__details">
                                                                                <div className="card-transactions__cost">$1,500</div>
                                                                                <a className="text-sm text-grey link-under" href="#">Detail</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-transactions__item">
                                                                            <div className="card-transactions__left">
                                                                                <div className="card-transactions__icon">
                                                                                    <svg className="icon-icon-bill">
                                                                                        <use xlinkHref="#icon-bill" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="card-transactions__right">
                                                                                    <h5 className="card-transactions__title">AirPods</h5>
                                                                                    <span className="card-transactions__time">02:00 PM</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-transactions__details">
                                                                                <div className="card-transactions__cost">$500</div>
                                                                                <a className="text-sm text-grey link-under" href="#">Detail</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-transactions__item">
                                                                            <div className="card-transactions__left">
                                                                                <div className="card-transactions__icon">
                                                                                    <svg className="icon-icon-bill">
                                                                                        <use xlinkHref="#icon-bill" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="card-transactions__right">
                                                                                    <h5 className="card-transactions__title">iPhone Xs Max</h5>
                                                                                    <span className="card-transactions__time">03:00 PM</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-transactions__details">
                                                                                <div className="card-transactions__cost">$2,500</div>
                                                                                <a className="text-sm text-grey link-under" href="#">Detail</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-transactions__item">
                                                                            <div className="card-transactions__left">
                                                                                <div className="card-transactions__icon">
                                                                                    <svg className="icon-icon-bill">
                                                                                        <use xlinkHref="#icon-bill" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="card-transactions__right">
                                                                                    <h5 className="card-transactions__title">IPad Pro 10.5</h5>
                                                                                    <span className="card-transactions__time">04:00 PM</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-transactions__details">
                                                                                <div className="card-transactions__cost">$1,000</div>
                                                                                <a className="text-sm text-grey link-under" href="#">Detail</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-transactions__item">
                                                                            <div className="card-transactions__left">
                                                                                <div className="card-transactions__icon">
                                                                                    <svg className="icon-icon-bill">
                                                                                        <use xlinkHref="#icon-bill" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="card-transactions__right">
                                                                                    <h5 className="card-transactions__title">iMac Pro</h5>
                                                                                    <span className="card-transactions__time">05:00 PM</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-transactions__details">
                                                                                <div className="card-transactions__cost">$5,000</div>
                                                                                <a className="text-sm text-grey link-under" href="#">Detail</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-transactions__item">
                                                                            <div className="card-transactions__left">
                                                                                <div className="card-transactions__icon">
                                                                                    <svg className="icon-icon-bill">
                                                                                        <use xlinkHref="#icon-bill" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="card-transactions__right">
                                                                                    <h5 className="card-transactions__title">AirPods</h5>
                                                                                    <span className="card-transactions__time">02:00 PM</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-transactions__details">
                                                                                <div className="card-transactions__cost">$500</div>
                                                                                <a className="text-sm text-grey link-under" href="#">Detail</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-transactions__item">
                                                                            <div className="card-transactions__left">
                                                                                <div className="card-transactions__icon">
                                                                                    <svg className="icon-icon-bill">
                                                                                        <use xlinkHref="#icon-bill" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="card-transactions__right">
                                                                                    <h5 className="card-transactions__title">iPhone Xs Max</h5>
                                                                                    <span className="card-transactions__time">03:00 PM</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-transactions__details">
                                                                                <div className="card-transactions__cost">$2,500</div>
                                                                                <a className="text-sm text-grey link-under" href="#">Detail</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-transactions__item">
                                                                            <div className="card-transactions__left">
                                                                                <div className="card-transactions__icon">
                                                                                    <svg className="icon-icon-bill">
                                                                                        <use xlinkHref="#icon-bill" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="card-transactions__right">
                                                                                    <h5 className="card-transactions__title">IPad Pro 10.5</h5>
                                                                                    <span className="card-transactions__time">04:00 PM</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-transactions__details">
                                                                                <div className="card-transactions__cost">$1,000</div>
                                                                                <a className="text-sm text-grey link-under" href="#">Detail</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="section">
                                        <div className="section__title d-none">
                                            <h2>Section</h2>
                                        </div>
                                        <div className="row gutter-bottom-xl">
                                            <div className="col-12 d-flex">
                                                <div className="card pb-0">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Transactions</h3>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card__scrollbar card__table">
                                                                    <div className="card__table-transactions scrollbar-thin scrollbar-visible" data-simplebar="data-simplebar">
                                                                        <table className="table table--lines table--striped">
                                                                            <colgroup>
                                                                                <col className="colgroup-1" />
                                                                                <col className="colgroup-2" />
                                                                                <col className="colgroup-3" />
                                                                                <col className="colgroup-4" />
                                                                                <col className="colgroup-5" />
                                                                                <col className="colgroup-6" />
                                                                                <col />
                                                                            </colgroup>
                                                                            <thead className="table__header table__header--sticky">
                                                                                <tr>
                                                                                    <th><span className="text-nowrap">Order No.</span></th>
                                                                                    <th><span>Name</span></th>
                                                                                    <th><span>Date</span></th>
                                                                                    <th><span>Amount</span></th>
                                                                                    <th><span>Address</span></th>
                                                                                    <th><span>Status</span></th>
                                                                                    <th className="table__actions" />
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td"><span className="text-grey">#790841</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="media-item">
                                                                                            <div className="media-item__icon color-orange">
                                                                                                <div className="media-item__icon-text">WS</div>
                                                                                                <img className="media-item__thumb" src="img/content/humans/item-4.jpg" alt="#" />
                                                                                            </div>
                                                                                            <div className="media-item__right">
                                                                                                <h5 className="media-item__title">Walter Sanders</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">12.07.2018</span></td>
                                                                                    <td className="table__td"><span>$700</span></td>
                                                                                    <td className="table__td"><span className="text-grey">Winding Way West, RI 3261, US</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="table__status"><span className="table__status-icon color-green" /> Paid</div>
                                                                                    </td>
                                                                                    <td className="table__td table__actions">
                                                                                        <div className="items-more">
                                                                                            <button className="items-more__button">
                                                                                                <svg className="icon-icon-more">
                                                                                                    <use xlinkHref="#icon-more" />
                                                                                                </svg>
                                                                                            </button>
                                                                                            <div className="dropdown-items dropdown-items--right">
                                                                                                <div className="dropdown-items__container">
                                                                                                    <ul className="dropdown-items__list">
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-view">
                                                                                                                        <use xlinkHref="#icon-view" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Details
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-duplicate">
                                                                                                                        <use xlinkHref="#icon-duplicate" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Duplicate
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-archive">
                                                                                                                        <use xlinkHref="#icon-archive" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Archive
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-trash">
                                                                                                                        <use xlinkHref="#icon-trash" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Delete
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td"><span className="text-grey">#781208</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="media-item">
                                                                                            <div className="media-item__icon color-blue">
                                                                                                <div className="media-item__icon-text">MA</div>
                                                                                                <img className="media-item__thumb" src="img/content/humans/item-6.jpg" alt="#" />
                                                                                            </div>
                                                                                            <div className="media-item__right">
                                                                                                <h5 className="media-item__title">Mark Anderson</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">15.07.2018</span></td>
                                                                                    <td className="table__td"><span>$500</span></td>
                                                                                    <td className="table__td"><span className="text-grey">Jarvis Street Portville, NY 2596, US</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="table__status"><span className="table__status-icon color-green" /> Paid</div>
                                                                                    </td>
                                                                                    <td className="table__td table__actions">
                                                                                        <div className="items-more">
                                                                                            <button className="items-more__button">
                                                                                                <svg className="icon-icon-more">
                                                                                                    <use xlinkHref="#icon-more" />
                                                                                                </svg>
                                                                                            </button>
                                                                                            <div className="dropdown-items dropdown-items--right">
                                                                                                <div className="dropdown-items__container">
                                                                                                    <ul className="dropdown-items__list">
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-view">
                                                                                                                        <use xlinkHref="#icon-view" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Details
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-duplicate">
                                                                                                                        <use xlinkHref="#icon-duplicate" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Duplicate
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-archive">
                                                                                                                        <use xlinkHref="#icon-archive" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Archive
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-trash">
                                                                                                                        <use xlinkHref="#icon-trash" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Delete
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td"><span className="text-grey">#987120</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="media-item">
                                                                                            <div className="media-item__icon color-green">
                                                                                                <div className="media-item__icon-text">PG</div>
                                                                                                <img className="media-item__thumb" src="img/content/humans/item-3.jpg" alt="#" />
                                                                                            </div>
                                                                                            <div className="media-item__right">
                                                                                                <h5 className="media-item__title">Pamela Garza</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">17.07.2018</span></td>
                                                                                    <td className="table__td"><span>$800</span></td>
                                                                                    <td className="table__td"><span className="text-grey">Calico Drive Ellensburg, WA 5572, US</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="table__status"><span className="table__status-icon color-red" /> Canceled</div>
                                                                                    </td>
                                                                                    <td className="table__td table__actions">
                                                                                        <div className="items-more">
                                                                                            <button className="items-more__button">
                                                                                                <svg className="icon-icon-more">
                                                                                                    <use xlinkHref="#icon-more" />
                                                                                                </svg>
                                                                                            </button>
                                                                                            <div className="dropdown-items dropdown-items--right">
                                                                                                <div className="dropdown-items__container">
                                                                                                    <ul className="dropdown-items__list">
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-view">
                                                                                                                        <use xlinkHref="#icon-view" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Details
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-duplicate">
                                                                                                                        <use xlinkHref="#icon-duplicate" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Duplicate
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-archive">
                                                                                                                        <use xlinkHref="#icon-archive" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Archive
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-trash">
                                                                                                                        <use xlinkHref="#icon-trash" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Delete
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td"><span className="text-grey">#871023</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="media-item">
                                                                                            <div className="media-item__icon color-red">
                                                                                                <div className="media-item__icon-text">WS</div>
                                                                                                <img className="media-item__thumb" src="img/content/humans/item-9.jpg" alt="#" />
                                                                                            </div>
                                                                                            <div className="media-item__right">
                                                                                                <h5 className="media-item__title">Walter Sanders</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">18.07.2018</span></td>
                                                                                    <td className="table__td"><span>$1,000</span></td>
                                                                                    <td className="table__td"><span className="text-grey">Randall Drive Pahala, HI 7289, US</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="table__status"><span className="table__status-icon color-green" /> Paid</div>
                                                                                    </td>
                                                                                    <td className="table__td table__actions">
                                                                                        <div className="items-more">
                                                                                            <button className="items-more__button">
                                                                                                <svg className="icon-icon-more">
                                                                                                    <use xlinkHref="#icon-more" />
                                                                                                </svg>
                                                                                            </button>
                                                                                            <div className="dropdown-items dropdown-items--right">
                                                                                                <div className="dropdown-items__container">
                                                                                                    <ul className="dropdown-items__list">
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-view">
                                                                                                                        <use xlinkHref="#icon-view" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Details
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-duplicate">
                                                                                                                        <use xlinkHref="#icon-duplicate" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Duplicate
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-archive">
                                                                                                                        <use xlinkHref="#icon-archive" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Archive
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-trash">
                                                                                                                        <use xlinkHref="#icon-trash" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Delete
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td"><span className="text-grey">#902370</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="media-item">
                                                                                            <div className="media-item__icon color-orange">
                                                                                                <div className="media-item__icon-text">RH</div>
                                                                                                <img className="media-item__thumb" src="img/content/humans/item-5.jpg" alt="#" />
                                                                                            </div>
                                                                                            <div className="media-item__right">
                                                                                                <h5 className="media-item__title">Ryan Henderson</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">22.07.2018</span></td>
                                                                                    <td className="table__td"><span>$900</span></td>
                                                                                    <td className="table__td"><span className="text-grey">Archwood Newcastle, WY 1139, US</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="table__status"><span className="table__status-icon color-green" /> Paid</div>
                                                                                    </td>
                                                                                    <td className="table__td table__actions">
                                                                                        <div className="items-more">
                                                                                            <button className="items-more__button">
                                                                                                <svg className="icon-icon-more">
                                                                                                    <use xlinkHref="#icon-more" />
                                                                                                </svg>
                                                                                            </button>
                                                                                            <div className="dropdown-items dropdown-items--right">
                                                                                                <div className="dropdown-items__container">
                                                                                                    <ul className="dropdown-items__list">
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-view">
                                                                                                                        <use xlinkHref="#icon-view" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Details
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-duplicate">
                                                                                                                        <use xlinkHref="#icon-duplicate" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Duplicate
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-archive">
                                                                                                                        <use xlinkHref="#icon-archive" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Archive
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-trash">
                                                                                                                        <use xlinkHref="#icon-trash" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Delete
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td"><span className="text-grey">#922309</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="media-item">
                                                                                            <div className="media-item__icon color-blue">
                                                                                                <div className="media-item__icon-text">SA</div>
                                                                                            </div>
                                                                                            <div className="media-item__right">
                                                                                                <h5 className="media-item__title">Stephen Allen</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">25.07.2018</span></td>
                                                                                    <td className="table__td"><span>$3,000</span></td>
                                                                                    <td className="table__td"><span className="text-grey">Park Boulevard Oskaloosa, IA 4814, US</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="table__status"><span className="table__status-icon color-blue" /> Pending</div>
                                                                                    </td>
                                                                                    <td className="table__td table__actions">
                                                                                        <div className="items-more">
                                                                                            <button className="items-more__button">
                                                                                                <svg className="icon-icon-more">
                                                                                                    <use xlinkHref="#icon-more" />
                                                                                                </svg>
                                                                                            </button>
                                                                                            <div className="dropdown-items dropdown-items--right dropdown-items--up">
                                                                                                <div className="dropdown-items__container">
                                                                                                    <ul className="dropdown-items__list">
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-view">
                                                                                                                        <use xlinkHref="#icon-view" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Details
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-duplicate">
                                                                                                                        <use xlinkHref="#icon-duplicate" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Duplicate
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-archive">
                                                                                                                        <use xlinkHref="#icon-archive" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Archive
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-trash">
                                                                                                                        <use xlinkHref="#icon-trash" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Delete
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td"><span className="text-grey">#892107</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="media-item">
                                                                                            <div className="media-item__icon color-red">
                                                                                                <div className="media-item__icon-text">JT</div>
                                                                                                <img className="media-item__thumb" src="img/content/humans/item-1.jpg" alt="#" />
                                                                                            </div>
                                                                                            <div className="media-item__right">
                                                                                                <h5 className="media-item__title">Jennifer Tang</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">27.07.2018</span></td>
                                                                                    <td className="table__td"><span>$1,000</span></td>
                                                                                    <td className="table__td"><span className="text-grey">Harvest Lane Eagleville, MO 9818, US</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="table__status"><span className="table__status-icon color-green" /> Paid</div>
                                                                                    </td>
                                                                                    <td className="table__td table__actions">
                                                                                        <div className="items-more">
                                                                                            <button className="items-more__button">
                                                                                                <svg className="icon-icon-more">
                                                                                                    <use xlinkHref="#icon-more" />
                                                                                                </svg>
                                                                                            </button>
                                                                                            <div className="dropdown-items dropdown-items--right dropdown-items--up">
                                                                                                <div className="dropdown-items__container">
                                                                                                    <ul className="dropdown-items__list">
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-view">
                                                                                                                        <use xlinkHref="#icon-view" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Details
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-duplicate">
                                                                                                                        <use xlinkHref="#icon-duplicate" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Duplicate
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-archive">
                                                                                                                        <use xlinkHref="#icon-archive" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Archive
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-trash">
                                                                                                                        <use xlinkHref="#icon-trash" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Delete
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td"><span className="text-grey">#902370</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="media-item">
                                                                                            <div className="media-item__icon color-green">
                                                                                                <div className="media-item__icon-text">SH</div>
                                                                                                <img className="media-item__thumb" src="img/content/humans/item-2.jpg" alt="#" />
                                                                                            </div>
                                                                                            <div className="media-item__right">
                                                                                                <h5 className="media-item__title">Sophia Hale</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">22.07.2018</span></td>
                                                                                    <td className="table__td"><span>$900</span></td>
                                                                                    <td className="table__td"><span className="text-grey">Archwood Newcastle, WY 1139, US</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="table__status"><span className="table__status-icon color-blue" /> Pending</div>
                                                                                    </td>
                                                                                    <td className="table__td table__actions">
                                                                                        <div className="items-more">
                                                                                            <button className="items-more__button">
                                                                                                <svg className="icon-icon-more">
                                                                                                    <use xlinkHref="#icon-more" />
                                                                                                </svg>
                                                                                            </button>
                                                                                            <div className="dropdown-items dropdown-items--right dropdown-items--up">
                                                                                                <div className="dropdown-items__container">
                                                                                                    <ul className="dropdown-items__list">
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-view">
                                                                                                                        <use xlinkHref="#icon-view" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Details
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-duplicate">
                                                                                                                        <use xlinkHref="#icon-duplicate" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Duplicate
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-archive">
                                                                                                                        <use xlinkHref="#icon-archive" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Archive
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-trash">
                                                                                                                        <use xlinkHref="#icon-trash" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Delete
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td"><span className="text-grey">#781208</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="media-item">
                                                                                            <div className="media-item__icon color-blue">
                                                                                                <div className="media-item__icon-text">MA</div>
                                                                                                <img className="media-item__thumb" src="img/content/humans/item-6.jpg" alt="#" />
                                                                                            </div>
                                                                                            <div className="media-item__right">
                                                                                                <h5 className="media-item__title">Mark Anderson</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">24.07.2018</span></td>
                                                                                    <td className="table__td"><span>$800</span></td>
                                                                                    <td className="table__td"><span className="text-grey">Jarvis Street Portville, NY 2596, US</span></td>
                                                                                    <td className="table__td">
                                                                                        <div className="table__status"><span className="table__status-icon color-green" /> Paid</div>
                                                                                    </td>
                                                                                    <td className="table__td table__actions">
                                                                                        <div className="items-more">
                                                                                            <button className="items-more__button">
                                                                                                <svg className="icon-icon-more">
                                                                                                    <use xlinkHref="#icon-more" />
                                                                                                </svg>
                                                                                            </button>
                                                                                            <div className="dropdown-items dropdown-items--right dropdown-items--up">
                                                                                                <div className="dropdown-items__container">
                                                                                                    <ul className="dropdown-items__list">
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-view">
                                                                                                                        <use xlinkHref="#icon-view" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Details
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-duplicate">
                                                                                                                        <use xlinkHref="#icon-duplicate" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Duplicate
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-archive">
                                                                                                                        <use xlinkHref="#icon-archive" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Archive
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li className="dropdown-items__item">
                                                                                                            <a className="dropdown-items__link">
                                                                                                                <span className="dropdown-items__link-icon">
                                                                                                                    <svg className="icon-icon-trash">
                                                                                                                        <use xlinkHref="#icon-trash" />
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Delete
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="section">
                                        <div className="section__title d-none">
                                            <h2>Section</h2>
                                        </div>
                                        <div className="row gutter-bottom-xl">
                                            <div className="col-12 col-lg-6 col-xl-8 d-flex">
                                                <div className="card pb-0">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Sales by country</h3>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card-country card__table card__scrollbar">
                                                                    <div className="card-country__map" id="mapCountry" />
                                                                    <div className="card-country__items scrollbar-thin scrollbar-visible" data-simplebar="data-simplebar">
                                                                        <table className="table table--lines">
                                                                            <colgroup>
                                                                                <col className="colgroup-1" />
                                                                                <col className="colgroup-2" />
                                                                                <col />
                                                                            </colgroup>
                                                                            <tbody>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td text-nowrap"><img className="card-country__flag" src="img/content/flags/au.svg" alt="#" /><span className="card-country__name">Australia</span></td>
                                                                                    <td className="table__td text-nowrap">
                                                                                        <span className="card-country__sales">Sales: <span className="font-weight-medium">25,000</span></span>
                                                                                        <span className="quotation quotation--up">
                                                                                            <svg className="icon-icon-arrow-down">
                                                                                                <use xlinkHref="#icon-arrow-down" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">40%</span></td>
                                                                                </tr>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td text-nowrap"><img className="card-country__flag" src="img/content/flags/ru.svg" alt="#" /><span className="card-country__name">Russia</span></td>
                                                                                    <td className="table__td text-nowrap">
                                                                                        <span className="card-country__sales">Sales: <span className="font-weight-medium">10,000</span></span>
                                                                                        <span className="quotation quotation--up">
                                                                                            <svg className="icon-icon-arrow-down">
                                                                                                <use xlinkHref="#icon-arrow-down" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">20%</span></td>
                                                                                </tr>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td text-nowrap"><img className="card-country__flag" src="img/content/flags/us.svg" alt="#" /><span className="card-country__name">United States</span></td>
                                                                                    <td className="table__td text-nowrap">
                                                                                        <span className="card-country__sales">Sales: <span className="font-weight-medium">5,000</span></span>
                                                                                        <span className="quotation quotation--down">
                                                                                            <svg className="icon-icon-arrow-down">
                                                                                                <use xlinkHref="#icon-arrow-down" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">15%</span></td>
                                                                                </tr>
                                                                                <tr className="table__row">
                                                                                    <td className="table__td text-nowrap"><img className="card-country__flag" src="img/content/flags/cn.svg" alt="#" /><span className="card-country__name">China</span></td>
                                                                                    <td className="table__td text-nowrap">
                                                                                        <span className="card-country__sales">Sales: <span className="font-weight-medium">2,000</span></span>
                                                                                        <span className="quotation quotation--down">
                                                                                            <svg className="icon-icon-arrow-down">
                                                                                                <use xlinkHref="#icon-arrow-down" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td className="table__td"><span className="text-grey">10%</span></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 col-xl-4 d-flex">
                                                <div className="card card--right pb-0">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Reviews</h3>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="card__review-list card__scrollbar scrollbar-thin scrollbar-visible" data-simplebar="data-simplebar">
                                                                    <div className="card__container">
                                                                        <div className="review-list">
                                                                            <div className="review-list__item">
                                                                                <a className="review-list__avatar color-red" href="#">
                                                                                    <div className="review-list__avatar-text">KG</div>
                                                                                    <img src="img/content/humans/item-1.jpg" alt="#" />
                                                                                </a>
                                                                                <div className="review-list__right">
                                                                                    <h5 className="review-list__name"><a href="#">Kathy Graham</a></h5>
                                                                                    <span className="review-list__time">3 hours ago</span>
                                                                                    <div className="rating js-rating-stars" data-rating={5} />
                                                                                    <div className="review-list__message">
                                                                                        <p className="review-list__text">
                                                                                            Nemo enim ipsam voluptatem, qui in ea voluptate veting esse quam nihil molestiae consequatur, vels illum utasle etcos quam voluptates recusandae.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="review-list__item">
                                                                                <a className="review-list__avatar color-orange" href="#">
                                                                                    <div className="review-list__avatar-text">DF</div>
                                                                                    <img src="img/content/humans/item-4.jpg" alt="#" />
                                                                                </a>
                                                                                <div className="review-list__right">
                                                                                    <h5 className="review-list__name"><a href="#">Dale Fisher</a></h5>
                                                                                    <span className="review-list__time">5 hours ago</span>
                                                                                    <div className="rating js-rating-stars" data-rating={4} />
                                                                                    <div className="review-list__message">
                                                                                        <p className="review-list__text">
                                                                                            At vero eos accusamus as iusto odio dignissimos provis ducimus, consectetur adipiscing elit, piscings obcaecati cupiditate nonpiscing provident fuga.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="review-list__item">
                                                                                <a className="review-list__avatar color-teal" href="#">
                                                                                    <div className="review-list__avatar-text">BH</div>
                                                                                    <img src="img/content/humans/item-5.jpg" alt="#" />
                                                                                </a>
                                                                                <div className="review-list__right">
                                                                                    <h5 className="review-list__name"><a href="#">Bruce Henderson</a></h5>
                                                                                    <span className="review-list__time">6 hours ago</span>
                                                                                    <div className="rating js-rating-stars" data-rating={3} />
                                                                                    <div className="review-list__message">
                                                                                        <p className="review-list__text">
                                                                                            Et harum quidem rerum facilis esits expedita distinctios, quia voluptas sit, aspernatur aut odit aut fugit, seedrnat dolores consequuntur magni...
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="review-list__item">
                                                                                <a className="review-list__avatar color-green" href="#">
                                                                                    <div className="review-list__avatar-text">JH</div>
                                                                                    <img src="img/content/humans/item-6.jpg" alt="#" />
                                                                                </a>
                                                                                <div className="review-list__right">
                                                                                    <h5 className="review-list__name"><a href="#">John Hendrix</a></h5>
                                                                                    <span className="review-list__time">8 hours ago</span>
                                                                                    <div className="rating js-rating-stars" data-rating={5} />
                                                                                    <div className="review-list__message">
                                                                                        <p className="review-list__text">
                                                                                            Nemo enim ipsam voluptatem, qui in ea voluptate veting esse quam nihil molestiae consequatur, vels illum utasle etcos quam voluptates recusandae.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="review-list__item">
                                                                                <a className="review-list__avatar color-blue" href="#">
                                                                                    <div className="review-list__avatar-text">SA</div>
                                                                                </a>
                                                                                <div className="review-list__right">
                                                                                    <h5 className="review-list__name"><a href="#">Stephen Allen</a></h5>
                                                                                    <span className="review-list__time">9 hours ago</span>
                                                                                    <div className="rating js-rating-stars" data-rating={4} />
                                                                                    <div className="review-list__message">
                                                                                        <p className="review-list__text">
                                                                                            At vero eos accusamus as iusto odio dignissimos provis ducimus, consectetur adipiscing elit, piscings obcaecati cupiditate nonpiscing provident fuga.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="section">
                                        <div className="section__title d-none">
                                            <h2>Section</h2>
                                        </div>
                                        <div className="row gutter-bottom-xl">
                                            <div className="col-12 col-lg-6 col-xl-4 d-flex">
                                                <div className="card pb-0">
                                                    <div className="card__wrapper">
                                                        <div className="card__container">
                                                            <div className="card__header">
                                                                <div className="card__header-left">
                                                                    <h3 className="card__header-title">Calendar</h3>
                                                                </div>
                                                                <div className="card__tools-more">
                                                                    <button className="items-more__button">
                                                                        <svg className="icon-icon-more">
                                                                            <use xlinkHref="#icon-more" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="dropdown-items">
                                                                        <div className="dropdown-items__container">
                                                                            <ul className="dropdown-items__list">
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-refresh">
                                                                                                <use xlinkHref="#icon-refresh" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Refresh
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-settings">
                                                                                                <use xlinkHref="#icon-settings" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Settings
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-download">
                                                                                                <use xlinkHref="#icon-download" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Download
                                                                                    </a>
                                                                                </li>
                                                                                <li className="dropdown-items__item">
                                                                                    <a className="dropdown-items__link">
                                                                                        <span className="dropdown-items__link-icon">
                                                                                            <svg className="icon-icon-action">
                                                                                                <use xlinkHref="#icon-action" />
                                                                                            </svg>
                                                                                        </span>
                                                                                        Action
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card__body">
                                                                <div className="calendar-inline" id="calendarOne">
                                                                    <div className="js-calendar-inline" />
                                                                </div>
                                                            </div>
                                                            <div className="card__footer card__footer--md">
                                                                <div className="card__container">
                                                                    <div className="calendar-widget" data-calendar="#calendarOne">
                                                                        <div className="calendar-widget__row">
                                                                            <div className="calendar-widget__item calendar-widget__item--left">
                                                                                <div className="calendar-widget__day"><span className="calendar-widget__dateday">13</span><sup className="calendar-widget__weekday text-grey">TH</sup></div>
                                                                                <div className="calendar-widget__month text-grey">December</div>
                                                                            </div>
                                                                            <div className="calendar-widget__item">
                                                                                <div className="calendar-widget__status"><span className="circle color-green" /><span>Upcoming</span></div>
                                                                                <div className="calendar-widget__order text-grey">
                                                                                    Order Delivery 04:30 PM
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </main>
                        </div>
                    </div>
                </>
                :
                <Login />
            }
        </>
    )
}

// LoaderComponent.propTypes = {
//     title: PropTypes.string.isRequired
// }

// export default LoaderComponent
