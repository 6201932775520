import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import moment from "moment";
import { getTaskWorkOderWithoutAuth } from '../../services';
import { Checkboxes, DefaultElement, Dropdown, FileUpload, Header, HyperLink, Label, LineBreak, Paragraph, PdfHeader, RadioButtons, Range, Rating, Signature, TextArea, TextInput } from './pdfElements';

var sideFolderArrayTemp = [];

const SingleRecordPdf = () => {
    const [sideFolderListHtml, setSideFolderListHtml] = useState(null);
    const [viewPdf, setViewPdf] = useState(false);

    const { operatorId } = useParams();

    const checkVal = (elementData, dataList, col_count) => {
        if (elementData !== null) {
            const backgroundColor = elementData?.backgroundColor || "#fff"
            const pageBreakBefore = elementData.pageBreakBefore || false
            const pageBreakAfter = elementData.pageBreakAfter || false

            let pageBreakClass = ""
            if (pageBreakBefore) {
                pageBreakClass += "page-break-before-item"
            }
            if (pageBreakAfter) {
                pageBreakClass += "page-break-after-item"
            }

            let singleRowItems = []
            for (let i = 0; i < elementData.childItems.length; i++) {
                const matchedItem = check(elementData.childItems[i], dataList, col_count, backgroundColor);
                singleRowItems.push(matchedItem)
            }
            const itemsInRow = <div className={`w-100 ${pageBreakClass}`} style={{ backgroundColor }}>{singleRowItems}</div>
            sideFolderArrayTemp.push(itemsInRow)
        }

    };

    const check = (childItemId, newDataList, col_count, backgroundColor) => {
        for (let i = 0; i < newDataList.length; i++) {
            if (childItemId === newDataList[i].id) {
                const _elementData = { ...newDataList[i], backgroundColor }
                const { element } = _elementData
                if (element === "Image" || element === "Signature" || element === "Camera" || element === "Download") {
                    return (<Signature elementData={_elementData} col_count={col_count} />);
                } else if (element === "FileUpload") {
                    return (<FileUpload elementData={_elementData} col_count={col_count} />);
                } else if (element === "Header") {
                    return (<Header elementData={_elementData} col_count={col_count} />);
                } else if (element === "Paragraph") {
                    return (<Paragraph elementData={_elementData} col_count={col_count} />);
                } else if (element === "TextInput" || element === "NumberInput" || element === "EmailInput" || element === "PhoneNumber" || element === "DatePicker") {
                    return (<TextInput elementData={_elementData} col_count={col_count} />);
                } else if (element === "Rating") {
                    return (<Rating elementData={_elementData} col_count={col_count} />);
                } else if (element === "Range") {
                    return (<Range elementData={_elementData} col_count={col_count} />);
                } else if (element === "Label") {
                    return (<Label elementData={_elementData} col_count={col_count} />);
                } else if (element === "LineBreak") {
                    return (<LineBreak elementData={_elementData} col_count={col_count} />);
                } else if (element === "Dropdown" || element === "Tags") {
                    return (<Dropdown elementData={_elementData} col_count={col_count} />);
                } else if (element === "HyperLink") {
                    return (<HyperLink elementData={_elementData} col_count={col_count} />);
                } else if (element === "TextArea") {
                    return (<TextArea elementData={_elementData} col_count={col_count} />);
                } else if (element === "Checkboxes") {
                    return (<Checkboxes elementData={_elementData} col_count={col_count} />);
                } else if (element === "RadioButtons") {
                    return (<RadioButtons elementData={_elementData} col_count={col_count} />);
                } else {
                    return (<DefaultElement elementData={_elementData} col_count={col_count} />);
                }
            }
        }
        setSideFolderListHtml(sideFolderArrayTemp);
    };

    const handleData = (e) => {
        setViewPdf(false)
        sideFolderArrayTemp = [];
        if (e.taskStatus == "Completed") {
            if (e.recordId != 0) {
                var operatorTitle = e.operatorTitle;
                var clientTitle = e.clientTitle;
                var roomName = e.roomName;
                var departmentName = e.departmentName;
                var taskUpdatedDate = e.taskUpdatedDate;
                var taskTitle = e.templateTitle;
                var taskCode = e.taskCode;
                var formDataEditList = JSON.parse(e.subTaskJson);

                const headerData = {
                    taskTitle,
                    operatorTitle,
                    clientTitle,
                    departmentName,
                    roomName,
                    taskUpdatedDate,
                    taskCode,
                }
                sideFolderArrayTemp.push(<Fragment key={e.recordId}><PdfHeader headerData={headerData} /></Fragment>);

                if (formDataEditList.task_data.length == 0) {
                    setSideFolderListHtml(
                        <div className="page-break-after dr">
                            <div className="row">{sideFolderArrayTemp}</div>
                        </div>
                    );
                    setViewPdf(true);
                    setTimeout(() => {
                        window.print();
                    }, 2000);
                } else {
                    for (let i = 0; i < formDataEditList.task_data.length; i++) {
                        if (formDataEditList.task_data[i].isAdded !== true) {

                            let elementData = formDataEditList.task_data[i];
                            let pageBreakBefore = elementData.pageBreakBefore || false
                            let pageBreakAfter = elementData.pageBreakAfter || false
                            let { col_count, backgroundColor, element } = elementData
                            col_count = col_count || 1
                            backgroundColor = backgroundColor || "#fff"

                            if (pageBreakBefore) {
                                // sideFolderArrayTemp.push(<h1></h1>)
                                sideFolderArrayTemp.push(<h1 className="page-break-before-item"></h1>)
                            }

                            if (element === "TwoColumnRow") {
                                checkVal(
                                    elementData,
                                    formDataEditList.task_data,
                                    2
                                );
                            } else if (element === "ThreeColumnRow") {
                                checkVal(
                                    elementData,
                                    formDataEditList.task_data,
                                    3
                                );
                            } else if (
                                element === "FourColumnRow" ||
                                element === "MultiColumnRow"
                            ) {
                                checkVal(
                                    elementData,
                                    formDataEditList.task_data,
                                    4
                                );
                            } else if (
                                element === "SixColumnRow" ||
                                element === "MultiColumnRow"
                            ) {
                                checkVal(
                                    elementData,
                                    formDataEditList.task_data,
                                    6
                                );
                            } else if (
                                element === "CustomColumnRow"
                            ) {
                                checkVal(
                                    elementData,
                                    formDataEditList.task_data,
                                    col_count
                                );
                            } else if (element === "Image" || element === "Signature" || element === "Camera" || element === "Download") {
                                sideFolderArrayTemp.push(<Signature elementData={elementData} col_count={1} />);
                            } else if (element === "FileUpload") {
                                sideFolderArrayTemp.push(<FileUpload elementData={elementData} col_count={1} />);
                            } else if (element === "Header") {
                                sideFolderArrayTemp.push(<Header elementData={elementData} col_count={1} />);
                            } else if (element === "Paragraph") {
                                sideFolderArrayTemp.push(<Paragraph elementData={elementData} col_count={1} />);
                            } else if (element === "TextInput" || element === "NumberInput" || element === "EmailInput" || element === "PhoneNumber" || element === "DatePicker") {
                                sideFolderArrayTemp.push(<TextInput elementData={elementData} col_count={1} />);
                            } else if (element === "TextArea") {
                                sideFolderArrayTemp.push(<TextArea elementData={elementData} col_count={1} />);
                            } else if (element === "Label") {
                                sideFolderArrayTemp.push(<Label elementData={elementData} col_count={1} />);
                            } else if (element === "Range") {
                                sideFolderArrayTemp.push(<Range elementData={elementData} col_count={1} />);
                            } else if (element === "Rating") {
                                sideFolderArrayTemp.push(<Rating elementData={elementData} col_count={1} />);
                            } else if (element === "LineBreak") {
                                sideFolderArrayTemp.push(<LineBreak elementData={elementData} col_count={1} />);
                            } else if (element === "HyperLink") {
                                sideFolderArrayTemp.push(<HyperLink elementData={elementData} col_count={1} />);
                            } else if (element === "Dropdown" || element == "Tags") {
                                sideFolderArrayTemp.push(<Dropdown elementData={elementData} col_count={1} />);
                            } else if (element === "RadioButtons") {
                                sideFolderArrayTemp.push(<RadioButtons elementData={elementData} col_count={1} />);
                            } else if (element === "Checkboxes") {
                                sideFolderArrayTemp.push(<Checkboxes elementData={elementData} col_count={1} />);
                            } else {
                                sideFolderArrayTemp.push(<DefaultElement elementData={elementData} col_count={1} />);
                            }

                            if (pageBreakAfter) {
                                // sideFolderArrayTemp.push(<h1></h1>)
                                sideFolderArrayTemp.push(<h1 className="page-break-after-item"></h1>)
                            }
                        }

                        if (i === formDataEditList.task_data.length - 1) {
                            setSideFolderListHtml(
                                <>
                                    <div className="page-break-after dr" style={{ position: "relative" }}>
                                        <div className="m-0" style={{ width: "100%" }}>{sideFolderArrayTemp}</div>
                                    </div>
                                </>
                            );
                            setViewPdf(true);
                            setTimeout(() => {
                                let _taskUpdatedDate = moment(taskUpdatedDate).format("DD-MM-YYYY")
                                let title = `${_taskUpdatedDate}_${taskCode}_${clientTitle}_${taskTitle}`
                                document.title = title
                                // Just for firefox
                                window.history.replaceState({}, title, title);
                                window.print();
                            }, 4000);
                        }
                    }
                }
            } else {
                toast("❌" + e.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    useEffect(() => {
        const handleEditAssignOperator = async (id) => {
            try {
                // id Record Id
                const res = await getTaskWorkOderWithoutAuth(id)
                if (res.errorCode == 0) {
                    let d = res.responsePacket;
                    handleData(d)
                }

            } catch (error) {
                console.log(error)
            }
        };
        const operatorIdInSession = sessionStorage.getItem("operatorId")
        if (operatorIdInSession) {
            handleEditAssignOperator(operatorIdInSession)
            window.history.replaceState({}, operatorIdInSession, operatorIdInSession);
        }else{
            sessionStorage.setItem("operatorId", operatorId)
            handleEditAssignOperator(operatorId)
        }
    }, [operatorId])


    return (
        <>
            {
                viewPdf ? <>
                    <div id="pdf" className="">
                        <div className="print-items">
                            <div className="page">
                                {sideFolderListHtml && sideFolderListHtml}
                            </div>
                            <div className="footer">
                                <div className="text-center">
                                    <div>
                                        <span>Item Oxygen s.r.l. Via Le Fogge, 11 - 70022 Altamura (BA) Italy</span>
                                        <span>   p.iva/c.f. 06068560728 Tel +39 080 3143647 fax +39 080 3160611</span>
                                    </div>
                                    <div style={{ color: "#4687ff" }}>
                                        <span>info@itemoxygen.com – item.oxygen@legalmail.it</span>
                                        <span>  www.itemoxygen.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : <div className='single-pdf-loader-style'>
                    <h4>Please wait...</h4>
                </div>
            }
        </>
    )
}

export default SingleRecordPdf
