import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
// import {logoType} from "../assets/i"
import logoType from "../assets/img/content/logo.svg";
import authBg from "../assets/img/content/auth-bg.jpg";
import authBgDark from "../assets/img/content/auth-bg-dark.jpg";
import { adminLogin, clientLogin ,forgotPassword} from "../services/index"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Route, Link, BrowserRouter as Router, Switch } from "react-router-dom";
import $ from "jquery"; //Load jquery
import loader from "../assets/img/content/loader.svg"
import { Modal, Button, Form } from 'react-bootstrap';

export const ForgotPassword = () => {

    const [userEmail, setUserEmail] = useState(null)
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(null)


    const handleClose = () => {
        setOpen(false);
        history.push("/VerifyOTP");
    }

    const handleForgotPasswordClick = () =>
    {
        var dict = {
            "email": userEmail
        }
        if (dict.email === null || dict.email === "") {
            toast("Please enter mail id !",
              {
                icon: '🙁',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }
            );
            return
        }
        else
        {
            $('.se-pre-con').css('display','block');
                forgotPassword(dict).then((res) => {
                    if (res.errorCode == 0) {
                        $('.se-pre-con').css('display','none');
                        var message=res.message;
                        setMessage(message);
                        setOpen(true)
                        localStorage.setItem('forgotPasswordUserEmail', userEmail);
                        /* setTimeout(() => {
                            history.push("/VerifyOTP");
                        }, 3000) */
                        

                    } else {
                        $('.se-pre-con').css('display','none');
                        toast('❌' + res.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                });

        }
        



    }




  return (
    <>
        <div className="page-wrapper h-100">
            <main className="page-auth">
                <div className="page-auth__center">
                    <div className="page-auth__gradient">
                        <div className="page-auth__gradient-shape" />
                        <div className="page-auth__gradient-shape" />
                        <div className="auth-logo">
                            <img className="auth-logo__icon" src={logoType} width={44} alt="#"/>
                            {/* <div className="auth-logo__text">item care</div> */}
                        </div>
                        <div className="page-auth__gradient-column" />
                    </div>
                    <div className="auth-card card">
                        <div className="auth-card__shape" />
                        <div className="auth-card__shape" />
                        <div className="card__wrapper">
                            <div className="auth-card__left bg-white">
                                <div className="auth-card__logo">
                                    <div className="auth-logo">
                                        <img className="auth-logo__icon" src={logoType} width={44} alt="#" style={{width: "200%"}} />
                                        {/* <div className="auth-logo__text">item care</div> */}
                                    </div>
                                </div>
                                {/* <img className="auth-card__bg auth-bg-image-light" src={authBg} alt="#" /> */}
                                <img className="auth-card__bg auth-bg-image-dark" src={authBgDark} alt="#" />
                            </div>
                            <div className="auth-card__right bg-white">
                                <div className="auth-card__top">
                                    <h1 className="auth-card__title">Benvenuto in <span className="text-theme">item care</span></h1>
                                    <p className="auth-card__text">Passowrd dimenticata</p>
                                </div>
                                <div className="auth-card__body box1">
                                    <div className="form-group">
                                        <div className="input-group input-group--prepend"><span className="input-group__prepend">
                                            <svg className="icon-icon-user">
                                                <use xlinkHref="#icon-user" />
                                            </svg></span>
                                            <input className="input"  onChange={(e) => setUserEmail(e.target.value)} value={userEmail} placeholder='Inserisci Email' required />
                                        </div>
                                    </div>
                                </div>
                                <div className="auth-card__bottom">
                                    <div className="auth-card__buttons">
                                        {/* <div className="auth-card__button"><a className="button button--secondary button--block" href="auth-create-v2.html"><span className="button__text">Sign Up</span></a>
                                        </div> */}
                                        <div className="auth-card__button btn1">
                                            <button className="button button--primary button--block" type="button" onClick={handleForgotPasswordClick}><span className="button__text">Accedi</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ToastContainer />
        </div>
        <div className="se-pre-con" style={{display:'none'}}>
                <img src={loader}/>
            </div>
            <Modal show={open} className={open ? "is-active is-animate" : ""} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                            <p className="text-center">{message}</p>            
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Chiudere
                    </Button>
                </Modal.Footer>
            </Modal>

    </>
  )
}