import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { CommanComponents } from "../components/index"
import DataTable, { createTheme, FilterComponent } from 'react-data-table-component';
import { Modal, Button, Form } from 'react-bootstrap';
import { getClientList, addClient, activeClient, inActiveClient, getUserClientDetails, deleteUserClient, updateClient } from "../services/index"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Select from 'react-select';
import loader from "../assets/img/content/loader.svg"
import $ from "jquery";



export const Hospital = () => {
    const [rows, setRows] = useState([])
    const [show, setShow] = useState(false)
    const [edit, setEdit] = useState(false)
    const [id, setId] = useState(null)
    const [addRequestData, setAddRequestData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        password: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        province: '',
        zipCode: '',
        country: '',
        photoImageUrl: '',
        notes: '',
        tender: '',
        tenderStartDate: new Date(),
        tenderEndDate: new Date(),
        recordId: ''
    })
    //const [clientRoleEnum, setClientRoleEnum] = useState(null);

    /* const [roleKeyValue, setRoleKeyValue] = useState([{
        value: "ADMINISTRATION",
        label: "Administration",
    }, {
        value: "CLIENTS",
        label: "Clients",
    }]) */

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);


    const handleClearModal = () => {
        setAddRequestData(true)
        handleClearForm()
    }

    const handleClearForm = () => {
        setShow(false)
    }

    const columns = [
        {
            name: 'Nome e cognome',
            selector: row => row.fullName,
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Stato',
            cell: row => (
                <>
                    {row.active ? <span className="badge badge-success">Attivo</span> : <span className="badge badge-danger">Inattivo</span>}
                </>
            ),
        },
        {
            name: 'Azione',
            cell: row => (
                <>

                    <ModeEditOutlineOutlinedIcon style={{ color: "blue" }} onClick={() => handleEditRecord(row.recordId)} />
                    {row.active ?
                        <CancelOutlinedIcon style={{ color: "green" }} onClick={() => handleActiveInActive(false, row.recordId)} />
                        :
                        <CheckOutlinedIcon style={{ color: "red" }} onClick={() => handleActiveInActive(true, row.recordId)} />
                    }
                    <DeleteOutlineOutlinedIcon style={{ color: "red" }} onClick={() => handleDeleteUserClient(row.recordId)} />
                </>
            ),
        }
    ];

    const handleActiveInActive = (status, uuid) => {

        Swal.fire({
            title: 'Sei sicuro?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sì, conferma !!',
            cancelButtonText: 'No !!'
        }).then((result) => {
            if (result.isConfirmed) {
                //Active Request
                if (status) {
                    $('.se-pre-con').css('display', 'block');
                    activeClient(uuid).then((res) => {
                        if (res.errorCode == 0) {
                            $('.se-pre-con').css('display', 'none');
                            toast(res.message,
                                {
                                    icon: '👏',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                }
                            );

                            getHospitalList(0)
                        } else {
                            $('.se-pre-con').css('display', 'none');
                            toast(res.message,
                                {
                                    icon: '🙁',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                }
                            );
                        }

                    }).catch((err) => {
                        $('.se-pre-con').css('display', 'none');
                        toast(err,
                            {
                                icon: '🙁',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                            }
                        );
                    })
                }
                //InActive Request
                else {
                    $('.se-pre-con').css('display', 'block');
                    inActiveClient(uuid).then((res) => {
                        if (res.errorCode == 0) {
                            $('.se-pre-con').css('display', 'none');
                            toast(res.message,
                                {
                                    icon: '👏',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                }
                            );

                            getHospitalList(0)
                        } else {
                            $('.se-pre-con').css('display', 'none');
                            toast(res.message,
                                {
                                    icon: '🙁',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                }
                            );
                        }
                    }).catch((err) => {
                        $('.se-pre-con').css('display', 'none');
                        toast(err,
                            {
                                icon: '🙁',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                            }
                        );
                    })
                }
            }
        });

    }

    /*  const handleClientRoleEnum = (e) => {
         setClientRoleEnum(e);
       }; */

    const handleEditRecord = (id) => {
        setEdit(true)
        setShow(true)
        setId(id)
        getUserClientDetails(id).then((res) => {
            if (res.errorCode === 0) {
                $('.se-pre-con').css('display', 'none');
                console.log(res)
                setAddRequestData(res.responsePacket)
                /* let d = res.responsePacket;
                let dict = {
                    value: d.clientRoleEnum,
                    label: d.clientRoleEnum,
                }
                setClientRoleEnum(dict) */
            }
        })
    }

    const handleDeleteUserClient = (id) => {
        Swal.fire({
            title: 'Sei sicuro?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sì, conferma !!',
            cancelButtonText: 'No !!'
        }).then((result) => {
            $('.se-pre-con').css('display', 'block');
            if (result.isConfirmed) {
                deleteUserClient(id).then((res) => {
                    if (res.errorCode === 0) {
                        $('.se-pre-con').css('display', 'none');
                        toast(res.message,
                            {
                                icon: '👏',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                            }
                        );
                        getHospitalList(0)
                    } else {
                        $('.se-pre-con').css('display', 'none');
                        toast(res.message,
                            {
                                icon: '🙁',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                            }
                        );
                    }
                })

            }
            else {
                $('.se-pre-con').css('display', 'none');
            }
        })
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        // fetchData(page, newPerPage);
        getHospitalList(0, newPerPage);
        setPerPage(newPerPage);
    };

    const handlePageChange = page => {
        getHospitalList(page - 1);
        setCurrentPage(page);
    };

    const getHospitalList = async (page, size = perPage) => {
            var dict = {
                "requestedPage": page,
                "requestedPageSize": size,
                "searchKey": "",
                "clientRoleEnum": "CLIENTS"
            }
            getClientList(dict).then((res) => {
                $('.se-pre-con').css('display', 'none');
                setRows(res.data)
                setTotalRows(res.recordsTotal);
            }).catch((err) => {
                $('.se-pre-con').css('display', 'none');
                alert(err)
            })
    }

    const handleShowAdd = () => {
        setEdit(false)
        setShow(true)
    }

    const handleWarnToast = (data) => {
        toast(data,
            {
                icon: '🙁',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }
        );
    }

    const handleSaveHospital = () => {
        let dict = {
            "firstName": addRequestData.firstName,
            "lastName": addRequestData.lastName,
            "clientRoleEnum": "CLIENTS",
            "addressLine1": addRequestData.addressLine1,
            "addressLine2": "Test",
            "city": addRequestData.city,
            "province": addRequestData.province,
            "zipCode": addRequestData.zipCode,
            "country": addRequestData.country,
            "photoImageUrl": "",
            "notes": "Test Note",
            "mobile": addRequestData.mobile,
            "email": addRequestData.email,
            "password": addRequestData.password,
            "tender": addRequestData.tender,
            "tenderStartDate": addRequestData.tenderStartDate,
            "tenderEndDate": addRequestData.tenderEndDate,
        }
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (dict.firstName === undefined || dict.firstName === "") {
            handleWarnToast('Entra Ospedale Nome !')
            return
        } else if (dict.email === undefined || dict.email === "") {
            handleWarnToast('Inserisci Email !')
            return
        } else if (!dict.email.match(mailformat)) {
            handleWarnToast('Entra Valid Email !')
            return
        } else if (dict.password === undefined || dict.password === "") {
            handleWarnToast('Entra Password !')
            return
        }else if (dict.tender === undefined || dict.tender === "") {
            handleWarnToast('Entra Commessa !')
            return
        }else if (dict.tenderStartDate === undefined || dict.tenderStartDate === "") {
            handleWarnToast('Entra Data inizio  contratto !')
            return
        }else if (dict.tenderEndDate === undefined || dict.tenderEndDate === "") {
            handleWarnToast('Entra Data fine contratto !')
            return
        }
        
        if (edit) {
            $('.se-pre-con').css('display', 'block');
            updateClient(id, dict).then((res) => {
                if (res.errorCode == 0) {
                    $('.se-pre-con').css('display', 'none');
                    toast(res.message,
                        {
                            icon: '👏',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );
                    setAddRequestData({
                        email: '',
                        fullName: '',
                        mobile: '',
                        password: ''
                    })
                    setShow(false)
                    getHospitalList(0)
                } else {
                    $('.se-pre-con').css('display', 'none');
                    toast(res.message,
                        {
                            icon: '🙁',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );

                }
            }).catch((err) => {
                alert("byy");
                $('.se-pre-con').css('display', 'none');
                toast(err,
                    {
                        icon: '🙁',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            })
        } else {
            $('.se-pre-con').css('display', 'block');
            addClient(dict).then((res) => {
                if (res.errorCode == 0) {
                    $('.se-pre-con').css('display', 'none');
                    toast(res.message,
                        {
                            icon: '👏',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );
                    setAddRequestData({
                        email: '',
                        fullName: '',
                        mobile: '',
                        password: ''
                    })
                    setShow(false)

                    getHospitalList(0)
                } else {
                    $('.se-pre-con').css('display', 'none');
                    toast(res.message,
                        {
                            icon: '🙁',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );

                }
            }).catch((err) => {
                $('.se-pre-con').css('display', 'none');
                toast(err,
                    {
                        icon: '🙁',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            })
        }

    }

    useEffect(() => {
        getHospitalList(0)
    }, []);
    return (
        <>
            <div>



                <div className="sidebar-backdrop" />
                <div className="page-wrapper">

                    <CommanComponents />
                    <main className="page-content">

                        <div className="container">
                            <div className="page-header justify-content-between align-items-center d-flex gap-1">
                                <div>
                                    <h1 className="page-header__title">Ospedale</h1>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-primary btn-sm d-flex justify-content-center gap-1" onClick={() => handleShowAdd()}><i className='fa fa-plus'></i>Aggiungi Ospedale</button>
                                </div>
                            </div>
                            <div className="row">
                                <div style={{ height: '100%', width: '100%' }}>
                                    <DataTable
                                        columns={columns}
                                        data={rows}
                                        theme="solarized"
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </main>
                    <Modal show={show} className={show ? "is-active is-animate" : ""} onHide={() => handleClearModal()} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Utenti</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="required">tutti i campi sono obbligatori</p>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputEmail1">Ospedale Nome</label>
                                    <input  className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Entra Ospedale Nome" value={addRequestData.firstName} onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        firstName: e.target.value
                                    })} />
                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputEmail1">E-mail</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Entra email" value={addRequestData.email} onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        email: e.target.value
                                    })} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputPassword1">Parola d'ordine</label>
                                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Entra Password" value={addRequestData.password} onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        password: e.target.value
                                    })} />
                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputEmail1">Mobile</label>
                                    <input type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Entra Mobile" value={addRequestData.mobile} onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        mobile: e.target.value
                                    })} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputPassword1">Indirizzo</label>
                                    <input  className="form-control" id="exampleInputPassword1" placeholder="Entra Indirizzo" value={addRequestData.addressLine1} onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        addressLine1: e.target.value
                                    })} />
                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputPassword1">Nazione</label>
                                    <input  className="form-control" id="exampleInputPassword1" placeholder="Entra Nazione" value={addRequestData.country} onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        country: e.target.value
                                    })} />
                                </div>
                            </div>
                        
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputPassword1">Provincia</label>
                                    <input  className="form-control" id="exampleInputPassword1" placeholder="Entra Provincia" value={addRequestData.province} onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        province: e.target.value
                                    })} />
                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputPassword1">Città</label>
                                    <input  className="form-control" id="exampleInputPassword1" placeholder="Entra Città" value={addRequestData.city} onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        city: e.target.value
                                    })} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputPassword1">Codice postale</label>
                                    <input  className="form-control" id="exampleInputPassword1" placeholder="Entra Codice Postale " value={addRequestData.zipCode} onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        zipCode: e.target.value
                                    })} />
                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputPassword1">Commessa </label>
                                    <input  className="form-control" id="exampleInputPassword1" placeholder="Entra Commessa " value={addRequestData.tender} onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        tender: e.target.value
                                    })} />
                                </div>
                            </div>  
                            <div className='row' style={{paddingTop: '0px'}}>  
                                <div className='col-md-6 d-md-grid'>
                                    <label for="exampleInputEmail1">Data inizio  contratto</label>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            label="."
                                            inputFormat="dd/MM/yyyy"
                                            value={addRequestData.tenderStartDate}
                                            onChange={(e) => setAddRequestData({
                                                ...addRequestData,
                                                tenderStartDate: e
                                            })}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className='col-md-6 d-md-grid' >
                                    <label for="exampleInputEmail1">Data fine contratto</label>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            label="."
                                            inputFormat="dd/MM/yyyy"
                                            value={addRequestData.tenderEndDate}
                                            onChange={(e) => setAddRequestData({
                                                ...addRequestData,
                                                tenderEndDate: e
                                            })}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            {/* <div className="se-pre-con">
                                    <img src={loader}/>
                            </div> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <a variant="secondary" className="btn btn-secondary" onClick={() => handleClearModal()}>
                                Chiudere
                            </a>
                            <a variant="primary" className="btn btn-primary" onClick={() => handleSaveHospital()}>
                                Salva
                            </a>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
            <div className="se-pre-con">
                <img src={loader} />
            </div>
            <ToastContainer />
        </>
    )
}
