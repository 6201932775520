// You can use CONSTANTS.js file for below definitions of constants and import here.
export const ADD_LOGIN_DATA = "ADD_LOGIN_DATA";
export const ADD_LOGIN_AUTH = "ADD_LOGIN_AUTH";
// Without THUNK MIDDLEWARE only actions can be dispatched.

export const addLoginData = (payload) => ({
    type: ADD_LOGIN_DATA,
    payload
});


export const addAuth = (payload) => ({
    type: ADD_LOGIN_AUTH,
    payload
});

