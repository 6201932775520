const DOMAIN = "https://www.itemcare.it:8443/ItemOxygen/";
// const DOMAIN = "http://192.168.1.14:8078/";
const BASE_URL = DOMAIN + "ops/v1/";

export const BASE = () => BASE_URL;
export const ADMIN_LOGIN = () => BASE_URL + "login";
export const CLIENT_LOGIN = () => BASE_URL + "hospitalLogin";

export const FILE_FOLDER = () => BASE_URL + "fileFolder/";
export const FILE_UPLOAD = () => DOMAIN + "api/attachment/upload";
export const USER_CLIENT = () => BASE_URL + "userClient/";
export const USER_OPERATOR = () => BASE_URL + "userOperator/";
export const USER_DEPARTMENT = () => BASE_URL + "userClientDepartment/";
export const USER_TASK_TEMPLATES = () => BASE_URL + "taskTemplate/";
export const ASSIGN_TASK = () => BASE_URL + "taskTemplate/";
export const UPDATE_TASK = () => BASE_URL + "taskTemplate/";
export const CLIENT_REQUEST = () => BASE_URL + "clientRequest/";
export const ADMIN_URL = () => BASE_URL + "userAdmin/";
export const MESSAGE_URL = () => BASE_URL + "message/";
export const ROOM_URL = () => BASE_URL + "rooms/";
