import React, { useEffect, useState } from 'react'
import { CommanComponents } from "../components/index"
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import $ from "jquery";
import loader from "../assets/img/content/loader.svg"
import DataTable, { createTheme, FilterComponent } from 'react-data-table-component';
import { getDepartmentList, addDepartment, getClientKeyValueList, deleteDepartment, getDepartmentDetails, updateDepartment } from "../services/index"
import moment from 'moment';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const Departments = props => {
    const [hospitalKeyValuePair, setHospitalKeyValuePair] = useState([])
    const [rows, setRows] = useState([])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShowAdd = () => setShow(true);

    const [typeOfModal, setTypeOfModal] = useState(true)
    const [edit, setEdit] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const handleAddDepartment = () => {
        setShow(true)
        setTypeOfModal(true)
        setAddRequestData({
            hospitalId: '',
            departmentName: '',
            recordId: ''
        })
        setHospital([])
        $('.se-pre-con').css('display', 'block');
        getClientKeyValueList().then((res) => {
            if (res.errorCode == 0) {
                $('.se-pre-con').css('display', 'none');
                const data = res.responsePacket;
                const options = data.map((d) => ({
                    value: d.option,
                    label: d.value,
                }));

                setHospitalKeyValuePair(options)
            } else {
                $('.se-pre-con').css('display', 'none');
                toast(res.message,
                    {
                        icon: '🙁',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            }
        }).catch((err) => {
            $('.se-pre-con').css('display', 'none');
            toast(err,
                {
                    icon: '🙁',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        })
    }

    const [hospital, setHospital] = useState()

    const [addRequestData, setAddRequestData] = useState({
        hospitalId: '',
        departmentName: '',
        recordId: ''
    })

    const handleHospital = (e) => {
        setAddRequestData({
            ...addRequestData,
            hospitalId: e.value
        })
        setHospital(e)
    }

    const handleAddDepartmentData = () => {

        let dict = {
            "associatedClientId": addRequestData.hospitalId,
            "departmentName": addRequestData.departmentName
        }

        if (dict.associatedClientId === undefined || dict.associatedClientId === "") {
            toast("Si prega di selezionare prima il nome dell'ospedale !",
                {
                    icon: '🙁',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
            return
        } else if (dict.departmentName === undefined || dict.departmentName === "") {
            toast("DSi prega di selezionare prima il nome dell'ospedale",
                {
                    icon: '🙁',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
            return
        }

        {
            edit ?
                updateDepartment(addRequestData.recordId, dict).then((res) => {
                    if (res.errorCode == 0) {
                        $('.se-pre-con').css('display', 'none');
                        toast(res.message,
                            {
                                icon: '👏',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                            }
                        );
                        setAddRequestData({
                            hospitalId: '',
                            departmentName: '',
                            recordId: ''
                        })
                        setShow(false)
                        getDepartment(0)
                    } else {
                        $('.se-pre-con').css('display', 'none');
                        toast(res.message,
                            {
                                icon: '🙁',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                            }
                        );

                    }
                }).catch((err) => {
                    $('.se-pre-con').css('display', 'none');
                    toast(err,
                        {
                            icon: '🙁',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );

                })
                :
                $('.se-pre-con').css('display', 'block');
            addDepartment(dict).then((res) => {
                if (res.errorCode == 0) {
                    $('.se-pre-con').css('display', 'none');
                    toast(res.message,
                        {
                            icon: '👏',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );
                    setAddRequestData({
                        hospitalId: '',
                        departmentName: '',
                        recordId: ''
                    })
                    setHospital([])
                    setShow(false)
                    setRows([])
                    getDepartment(0)
                } else {
                    $('.se-pre-con').css('display', 'none');
                    toast(res.message,
                        {
                            icon: '🙁',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );

                }
            }).catch((err) => {
                $('.se-pre-con').css('display', 'none');
                toast(err,
                    {
                        icon: '🙁',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            })
        }

    }

    const columns = [
        {
            name: 'Nome dell ospedale',
            selector: row => row.associatedClientName,
        },
        {
            name: 'Nome Dipartimento',
            selector: row => row.departmentName,
        },
        {
            name: 'Data di Creazione',
            selector: row => moment(row.createdAt).format("DD/MM/YYYY"),
        },
        {
            name: 'Stato',
            cell: row => (
                <>
                    {row.active ? <span className="badge badge-success">Attivo</span> : <span className="badge badge-danger">Inattivo</span>}
                </>
            ),
        },
        {
            name: 'Azione',
            cell: row => (
                <>

                    <ModeEditOutlineOutlinedIcon style={{ color: "blue" }} onClick={() => handleEditDepartment(row.recordId)} />

                    <DeleteOutlineOutlinedIcon style={{ color: "red" }} onClick={() => handleDeleteDepartment(row.recordId)} />
                </>
            ),
        }
    ];

    const handleDeleteDepartment = (id) => {
        Swal.fire({
            title: 'Sei sicuro?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sì, conferma !!',
            cancelButtonText: 'No !!'
        }).then((result) => {
            $('.se-pre-con').css('display', 'block');
            if (result.isConfirmed) {
                //Active Request

                deleteDepartment(id).then((res) => {
                    if (res.errorCode == 0) {
                        $('.se-pre-con').css('display', 'none');
                        toast(res.message,
                            {
                                icon: '👏',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                            }
                        );
                        getDepartment(0)
                    } else {
                        $('.se-pre-con').css('display', 'none');
                        toast(res.message,
                            {
                                icon: '🙁',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                            }
                        );

                    }
                }).catch((err) => {
                    $('.se-pre-con').css('display', 'none');
                    toast(err,
                        {
                            icon: '🙁',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );
                })

            }
            else {
                $('.se-pre-con').css('display', 'none');
            }
        });

    }

    const handleEditDepartment = (id) => {
        $('.se-pre-con').css('display', 'block');
        getDepartmentDetails(id).then((res) => {
            if (res.errorCode == 0) {
                $('.se-pre-con').css('display', 'none');
                let d = res.responsePacket
                setShow(true)
                setEdit(true)
                setAddRequestData(d)
                let dict = {
                    value: d.associatedClientId,
                    label: d.associatedClientName,
                }
                setHospital(dict);
            }

        });
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        // fetchData(page, newPerPage);
        getDepartment(0, newPerPage);
        setPerPage(newPerPage);
    };

    const handlePageChange = page => {
        getDepartment(page - 1);
        setCurrentPage(page);
    };

    const getDepartment = async (page, size = perPage) => {
        var rId = localStorage.getItem("rId")
        if (rId != undefined) {
            var recordIdForList = rId
        }
        else {
            var recordIdForList = ""
        }
        var dict = {
            "requestedPage": page,
            "requestedPageSize": size,
            "searchKey": "",
            "clientId": recordIdForList
        }
        $('.se-pre-con').css('display', 'block');
        getDepartmentList(dict).then((res) => {
            $('.se-pre-con').css('display', 'none');
            setRows(res.data);
            setTotalRows(res.recordsTotal);
        }).catch((err) => {
            alert(err)
        })
    }



    useEffect(() => {
        getDepartment(0)
    }, []);

    return (
        <>

            <div className="sidebar-backdrop" />
            <div className="page-wrapper">
                <CommanComponents />
                <main className="page-content">
                    <div className="container">
                        <div className="page-header justify-content-between align-items-center d-flex gap-1">
                            <div>
                                <h1 className="page-header__title">Elenco Dipartimento</h1>
                            </div>
                            <div>
                                <button type="button" className="btn btn-primary btn-sm justify-content-between align-items-center d-flex gap-1" onClick={handleAddDepartment}><i className='fa fa-plus'></i>Aggiungere Dipartimento</button>
                            </div>
                        </div>
                        {/* <div className="row">
                                <div style={{ height: 500, width: '100%' }}>
                                    <DataTable
                                        columns={columns}
                                        data={rows}
                                        theme="solarized"
                                        pagination
                                        // expandableRows
                                        // expandableRowsComponent={ExpandedComponent}
                                    />
                                </div>
                            </div> */}
                        <div className="row">
                            <div style={{ height: '100%', width:'100%' }}>
                                <DataTable
                                    columns={columns}
                                    data={rows}
                                    theme="solarized"
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    paginationDefaultPage={currentPage}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <Modal show={show} className={show ? "is-active is-animate" : ""} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Dipartimento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Nome dell'ospedale</Form.Label>
                        <Select options={hospitalKeyValuePair} name='roundOffTo' value={hospital} onChange={handleHospital} />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Nome struttura</Form.Label>
                        <Form.Control  placeholder="Entra struttura" value={addRequestData.structureName} onChange={(e) => setAddRequestData({
                            ...addRequestData,
                            structureName: e.target.value
                        })} />
                    </Form.Group> */}
                    <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Dipartimento Nome</Form.Label>
                        <Form.Control  placeholder="Entra Dipartimento" value={addRequestData.departmentName} onChange={(e) => setAddRequestData({
                            ...addRequestData,
                            departmentName: e.target.value
                        })} />
                    </Form.Group>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Chiudere
                    </Button>
                    <Button variant="primary" onClick={handleAddDepartmentData}>
                        {edit ? "Aggiorna modifiche" : "Salva"}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <div className="se-pre-con" style={{ display: 'none' }}>
                <img src={loader} />
            </div>
        </>
    )
}

