import React, { useState, useEffect } from 'react'
import { Route, Link, BrowserRouter as Router, Switch } from "react-router-dom";
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import mainLogo from "../assets/img/content/logo.svg"
import logoType from "../assets/img/content/logotype.svg";
import authBg from "../assets/img/content/auth-bg.jpg";
import authBgDark from "../assets/img/content/auth-bg-dark.jpg";
import logoutLogo from "../assets/img/content/logout.png";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import requestImage from "../assets/img/content/request.svg";
import taskTemplate from "../assets/img/content/task template.svg";
import departmentImage from "../assets/img/content/department.svg";
import assignoperator from "../assets/img/content/assign-operator.svg";
import taskCompleted from "../assets/img/content/task-completed.svg";
import Allnotification from "../assets/img/content/All notification.svg";
import operatorsLogo from "../assets/img/content/operator.svg";
import userLogo from "../assets/img/content/users-avatar.svg";
import documentImage from "../assets/img/content/document.svg";
import Visualiza from "../assets/img/content/Visualiza.svg";
import calendar from "../assets/img/content/calendar.svg";
import hospital from "../assets/img/content/hospital.svg";
import Room from "../assets/img/content/Room.svg";
import requesticon from "../assets/img/content/request icon.svg";


export const SideBarComponent = props => {
    const [userType, setUserType] = useState("ROLE_ADMIN")
    const [userTypeHead, setUserTypeHead] = useState();


    const handleLogout = () => {
        confirmAlert({
            title: 'Logout',
            message: 'Sei sicuro di uscire ?',
            buttons: [
                {
                    label: 'sì',
                    onClick: () => {
                        localStorage.removeItem("itemOxygenUserName")
                        localStorage.removeItem("itemOxygenRole")
                        localStorage.removeItem("itemOxygenAuth")
                        localStorage.removeItem("itemOxygenLoginData")
                        localStorage.removeItem("rId")
                        window.location.href = "/"
                    }
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    useEffect(() => {
        const role = localStorage.getItem("itemOxygenRole")
        console.log(role);
        setUserType(role)
        var url=window.location.href
        var data=url.split("/").pop();
        setUserTypeHead(data)
    }, []);

    const normalButton = () =>{
        window.normalButton()
    }

    // const goTo = (path) => {
    //     history.push(path || ROOT);
    // }
    return (
        <>
            <div>
                {
                    userTypeHead == 'documents' ?
                    <div className="subheader d-xl-none">
                    <div className="container pl-0">
                        <div className="row align-items-center no-gutters">
                            <div className="col-auto">
                                <button className="toggle-sidebar toggle-sidebar--secondary js-toggle-sidebar-panel" onClick={normalButton} type="button" data-toggle="#sidebarPanel">
                                    <svg className="icon-icon-menu">
                                        <use xlinkHref="#icon-menu" />
                                    </svg>
                                </button>
                            </div>
                            <div className="col-auto">
                                <h2 className="subheader__title">File</h2>
                            </div>
                        </div>
                    </div>
                </div>
                :
                ""   
                }
                
                <aside className="sidebar">
                    <div className="sidebar__backdrop" />
                    <div className="sidebar__container">
                        <div className="sidebar__top">
                            <div className="container container--sm">
                                <Link to="/" className="sidebar__logo">
                                    <img className="sidebar__logo-icon" src={mainLogo} alt="#" width={44} />
                                    {/* <div className="sidebar__logo-text">itemcarep</div> */}
                                </Link>
                            </div>
                        </div>

                        {userType === "ROLE_CLIENT" || userType === "ADMINISTRATION" ?
                            <div className="sidebar__content" data-simplebar="data-simplebar">
                                <nav className="sidebar__nav">
                                    <li className="sidebar__menu">
                                        <li className="sidebar__menu-item">
                                            <Link to="/request" className="sidebar__link" aria-expanded="false">
                                            <img src={requestImage} style={{ height :"20px" ,paddingRight: "11px"}} />   
                                                <span className="sidebar__link-text">Richiesta Calendario</span>
                                            </Link>
                                        </li>
                                        <li className="sidebar__menu-item">
                                            <Link to="/client-task-completed" className="sidebar__link" aria-expanded="false">
                                                <img src={taskCompleted} style={{ height :"20px" ,paddingRight: "11px"}} />
                                                <span className="sidebar__link-text">Modello completato</span>
                                            </Link>
                                        </li>
                                        {
                                        userType === "ADMINISTRATION" ?
                                        <>
                                            <li className="sidebar__menu-item">
                                                <Link to="/view-user-notification" className="sidebar__link" aria-expanded="false">
                                                    <img src={taskCompleted} style={{ height :"20px" ,paddingRight: "11px"}} />
                                                    <span className="sidebar__link-text">Tutte le notifiche</span>
                                                </Link>
                                            </li>
                                            <li className="sidebar__menu-item">
                                                            <Link to="/documents" className="sidebar__link">
                                                            <img src={documentImage} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Documento</span>
                                                            </Link>
                                            </li>
                                        </> 
                                        
                                        :
                                        ""
                                        }  
                                        <li className='sidebar__menu'>
                                        <li className="sidebar__menu-item">
                                            <a className="sidebar__link cursor" onClick={handleLogout} aria-expanded="false">
                                                <span className="sidebar__link-icon">
                                                    <img src={logoutLogo}></img>
                                                </span>
                                                <span className="sidebar__link-text">Esci</span>
                                            </a>
                                        </li>
                                    </li>
                                    </li>
                                </nav>
                            </div>
                            :

                            <div className="sidebar__content" data-simplebar="data-simplebar">
                                <nav className="sidebar__nav">
                                    <li className="sidebar__menu">
                                        <li className="sidebar__menu-item">
                                            <Link to="/calendar" className="sidebar__link" aria-expanded="false">
                                                <span className="sidebar__link-icon">
                                                    <svg className="icon-icon-dashboard">
                                                        <use xlinkHref="#icon-dashboard" />
                                                    </svg>
                                                </span>
                                                <span className="sidebar__link-text">Calendario</span>
                                            </Link>
                                        </li>
                                        <li className="sidebar__menu-item">
                                            <a className="sidebar__link" href="products.html" data-toggle="collapse" data-target="#Visualizza" aria-expanded="false">
                                                <img src={Visualiza} style={{ height :"20px" ,paddingRight: "11px"}} />
                                                <span className="sidebar__link-text">Visualizza</span>
                                                <span className="sidebar__link-arrow">
                                                    <svg className="icon-icon-keyboard-down">
                                                        <use xlinkHref="#icon-keyboard-down" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <div className="collapse" id="Visualizza">
                                                <ul className="sidebar__collapse-menu">
                                                    {/* <li className="sidebar__menu-item">
                                                    <a className="sidebar__link" href="products.html"><span className="sidebar__link-signal" /><span className="sidebar__link-text">Checklist</span></a>
                                                </li> */}
                                                    <li className="sidebar__menu-item">
                                                        <Link to="/documents" className="sidebar__link">
                                                        <img src={documentImage} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Documento</span>
                                                        </Link>
                                                    </li>
                                                    {/* <li className="sidebar__menu-item">
                                                        <Link to="/calendar" className="sidebar__link">
                                                        <img src={calendar} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Calendario</span>
                                                        </Link>
                                                    </li> */}
                                                    {/* <li className="sidebar__menu-item">
                                                    <a className="sidebar__link" href="order-details.html"><span className="sidebar__link-signal" /><span className="sidebar__link-text">Notifiche</span></a>
                                                </li>
                                                <li className="sidebar__menu-item">
                                                    <a className="sidebar__link" href="order-invoice.html"><span className="sidebar__link-signal" /><span className="sidebar__link-text">Messaggi</span></a>
                                                </li> */}
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="sidebar__menu-item">
                                            <a className="sidebar__link" href="products.html" data-toggle="collapse" data-target="#Gestione" aria-expanded="false">
                                                <span className="sidebar__link-icon">
                                                    <svg className="icon-icon-cart">
                                                        <use xlinkHref="#icon-cart" />
                                                    </svg>
                                                </span>
                                                <span className="sidebar__link-text">Gestione</span>
                                                <span className="sidebar__link-arrow">
                                                    <svg className="icon-icon-keyboard-down">
                                                        <use xlinkHref="#icon-keyboard-down" />
                                                    </svg>
                                                </span>
                                            </a>
                                            <div className="collapse" id="Gestione">
                                                <ul className="sidebar__collapse-menu">
                                                    <li className="sidebar__menu-item">
                                                        <Link to="/users" className="sidebar__link cursor">
                                                            <img className="sidebar__link-signal" src={userLogo} /><span className="sidebar__link-text">Utenti</span>
                                                        </Link>
                                                    </li>
                                                    <li className="sidebar__menu-item">
                                                        <Link to="/operators" className="sidebar__link">
                                                            <img className="sidebar__link-signal" src={operatorsLogo} /><span className="sidebar__link-text">Operatori</span>
                                                        </Link>
                                                    </li>
                                                   {/*  <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/request"><img src={requestImage} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Richiesta</span></Link>
                                                    </li> */}
                                                    <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/hospital"><img src={hospital} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Ospedale</span></Link>
                                                    </li>
                                                    <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/departments"><img src={departmentImage} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Dipartimento</span></Link>
                                                    </li>
                                                    <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/room"><img src={Room} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Stanza</span></Link>
                                                    </li>
                                                    <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/assign-operator"><img src={assignoperator} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Assegnare gli operatori</span></Link>
                                                    </li>
                                                   
                                                    {/* <li className="sidebar__menu-item">
                                                    <Link className="sidebar__link" to="#"><span className="sidebar__link-signal" /><span className="sidebar__link-text">Report</span></Link>
                                                </li> */}
                                                    {/* <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/templates"><img src={taskTemplate} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Modello di attività</span></Link>
                                                    </li> */}
                                                    <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/formPage"><img src={taskTemplate} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Modello di attività</span></Link>
                                                    </li>
                                                    <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/taskCom"><img src={taskCompleted} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Modello completato</span></Link>
                                                    </li>
                                                    <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/taskCom-check-list"><img src={taskCompleted} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Lista di controllo</span></Link>
                                                    </li>
                                                    <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/view-notification"><img src={Allnotification} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Tutte le notifiche </span></Link>
                                                    </li>
                                                    <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/requested-task"><img src={requesticon} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Anomalie </span></Link>
                                                    </li>
                                                   {/*  <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/taskCom"><img src={Allnotification} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Tutte le notifiche </span></Link>
                                                    </li> */}
                                                    {/* <li className="sidebar__menu-item">
                                                        <Link className="sidebar__link" to="/formPage"><img src={Allnotification} style={{ height :"20px" ,paddingRight: "11px"}} /><span className="sidebar__link-text">Tutte le notifiche  </span></Link>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </li>
                                    </li>
                                    <li className='sidebar__menu'>
                                        <li className="sidebar__menu-item">
                                            <a className="sidebar__link cursor" onClick={handleLogout} aria-expanded="false">
                                                <span className="sidebar__link-icon">
                                                    <img src={logoutLogo}></img>
                                                </span>
                                                <span className="sidebar__link-text">Esci</span>
                                            </a>
                                        </li>
                                    </li>
                                </nav>
                            </div>
                        }
                    </div>
                </aside>
            </div>

        </>

    )
}

