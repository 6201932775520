import React from "react";
import { Typography } from "@material-ui/core";
import { LinkRoute } from "../components/LinkRoute";
import { ROOT } from "./CONSTANTS";

export const NotFound = () => {
  return (
    <>
      {/* Page Not Found! */}
      {/* <LinkRoute to={ROOT}>Home</LinkRoute>
            <Typography variant="h2">404: page not found!</Typography> */}
      <div className="page-wrapper h-100">
        <main className="page-error">
          <div className="page-error__content">
            <div className="page-error__type">404</div>
            <h1 className="page-error__title">Looks like you got lost</h1>
            <p className="text-large">
              The page you're looking for doesn't exist or has been moved
            </p>
            <div className="page-error__bottom">
              <a className="button button--primary" href>
                <span className="button__text">
                  <LinkRoute to={ROOT}> Back to home</LinkRoute>
                  {/* Back to home */}
                </span>
              </a>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
