import React from 'react'
import { useEffect, useState } from "react";
import DataTable, { createTheme, FilterComponent } from 'react-data-table-component';
import { Modal, Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Swal from "sweetalert2";
import Select from 'react-select';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import {
    getClientKeyValueList, getTaskSlotKeyValue, getTemplatesKeyValueList, getOperatorKeyValueList,
    getDepartmentKeyvalueList, assignTask, getTaskWorkOrderList, getTaskTemplate, generateTaskSheet, updateTaskSheet
} from "../services/index"
import { CommanComponents } from '../components';
import jspdf from 'jspdf'
import $ from "jquery";
import loader from "../assets/img/content/loader.svg"

require("formBuilder");// For FormBuilder


var taskTemplateUuid;
var taskTemplateTitle;
var formData = [];
// var options = {
//     disableFields: [
//         'button'
//     ],
//     onSave: function (evt, formData) {
//         // toggleEdit();
//         $('.render-wrap').formRender({ formData });
//         window.sessionStorage.setItem('formData', JSON.stringify(formData));
//     },
// };

//Initialize formBuilder 
const FormBuilder = (props) => {
    // fb = createRef();
    // let formBuilderObject;
    const [formBuilderObject, setformBuilderObject] = useState(null)
    const [taskTitle, setTaskTitle] = useState("")

    // constructor() {
    //     this.handleGetData = this.handleGetData.bind(this);
    // }

    const handleGetData = () => {
        if (taskTemplateUuid == null) {
            const dict = {
                "taskTitle": taskTitle,
                "subTaskJson": JSON.stringify(formBuilderObject.actions.getData())
            }
            console.log("dict", dict)
            $('.se-pre-con').css('display','block');
            generateTaskSheet(dict).then((res) => {
                if (res.errorCode == 0) {
                    $('.se-pre-con').css('display','none');
                    toast('✅' + res.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    props.parentCallback()
                } else {
                    $('.se-pre-con').css('display','none');
                    toast('❌' + res.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
            }).catch((err) => {
                $('.se-pre-con').css('display','none');
                toast('❌' + err, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })

        } else {
            const dict = {
                "taskTitle": taskTitle,
                "subTaskJson": JSON.stringify(formBuilderObject.actions.getData())
            }
            $('.se-pre-con').css('display','block');
            updateTaskSheet(dict, taskTemplateUuid).then((res) => {
                if (res.errorCode == 0) {
                    $('.se-pre-con').css('display','none');
                    toast('✅' + res.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    props.parentCallback()
                } else {
                    $('.se-pre-con').css('display','none');
                    toast('❌' + res.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
            }).catch((err) => {
                $('.se-pre-con').css('display','none');
                toast('❌' + err, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
        }
    }

    useEffect(() => {
        // var formBuilderObject = $("#fb-editor").formBuilder({ formData, options });
        // .....
        var options = {
            disableFields: [
                'button',
                'autocomplete',
                'file',
                'date',
                'paragraph',
                'header',
                'number',
                'radio-group',
                'checkbox-group',
                'hidden',
                'text',
                'textarea',
                'select',
            ],
            onSave: function (evt, formData) {
                // toggleEdit();
                $('.render-wrap').formRender({ formData });
                window.sessionStorage.setItem('formData', JSON.stringify(formData));
            },
            formData: formData,

        };

        // $('.btn-group').css('display', 'none');
        // $('#frmb-1655210801323').css('display', 'none');
        // document.querySelector("btn-group").style.display="none"
        // document.getElementById("frmb-1655210801323").style.color="green"

        let formBuilderObject = $("#fb-editor").formBuilder(options);
        setTaskTitle(taskTemplateTitle);
        setformBuilderObject(formBuilderObject);
    }, []);

    return <>
        <div className="row">
            <div className="col-md-2">Task Title</div>
            <div className="col-md-8"><input  value={taskTitle} onChange={(e) => setTaskTitle(e.target.value)}></input></div>
        </div>
        <div id="fb-editor" /></>;
}

export const TaskCompleted = props => {

    const [rows, setRows] = useState([])
    const [open, setOpen] = useState(false);
    const [openDetails, setDetails] = useState(false);

    const [taskTemplateKeyValue, setTaskTemplateKeyValue] = useState([])
    const [taskTemplateValue, setTaskTemplateValue] = useState(null)
    const [operatorKeyValue, setOperatorKeyValue] = useState([])
    const [operatorValue, setOperatorValue] = useState(null)
    const [hospitalsKeyValue, setHospitalsKeyValue] = useState([])
    const [hospitalValue, setHospitalValue] = useState(null)
    const [departmentsKeyValue, setDepartmentsKeyValue] = useState([])
    const [departmentValue, setDepartmentValue] = useState(null)
    const [slotTypeKeyValue, setSlotTypeKeyValue] = useState([])
    const [slotType, setSlotType] = useState(null)
    const [weekDay, setWeekDay] = useState(null)
    const [show, setShow] = useState(false)


    const wekendOptions = [
        {
            label: "Monday",
            value: "Monday"
        },
        {
            label: "Tuesday",
            value: "Tuesday"
        },
        {
            label: "Wednesday",
            value: "Wednesday"
        },
        {
            label: "Thursday",
            value: "Thursday"
        },
        {
            label: "Friday",
            value: "Friday"
        },
        {
            label: "Saturday",
            value: "Saturday"
        },
        {
            label: "Sunday",
            value: "Sunday"
        },
    ]

    const handleClose = () => setOpen(false);
    const handleDetailsClose = () => setDetails(false);

    const handleSlotType = (e) => {
        setSlotType(e)
        console.log(e)
        if (e.label === "Weekly") {
            setShow(true)
        } else {
            setShow(false)
        }
    }

    const handleAssignOperator = () => {

        if (show === true) {
            var dict = {
                taskSlotType: slotType.label,
                associatedClientDepartmentId: departmentValue.value,
                assignedToOperatorId: operatorValue.value,
                startDate: addRequestData.startDate,
                endDate: addRequestData.endDate,
                weekDay: weekDay.value,
                associatedClientId: hospitalValue.value,
            }
        } else {
            var dict = {
                taskSlotType: slotType.label,
                associatedClientDepartmentId: departmentValue.value,
                assignedToOperatorId: operatorValue.value,
                startDate: addRequestData.startDate,
                endDate: addRequestData.endDate,
                associatedClientId: hospitalValue.value,

            }
        }

        assignTask(taskTemplateValue.option, dict).then((res) => {
            if (res.errorCode === 0) {
                toast(res.message,
                    {
                        icon: '👏',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
                handleClose()
                getAssignedtaskList()
            } else {
                toast(res.message,
                    {
                        icon: '🙁',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            }
        })
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const [addRequestData, setAddRequestData] = useState({
        startDate: new Date(),
        endDate: new Date(),

    })

    const columns = [
        {
            name: 'Nome attività',
            selector: row => row.taskTitle,
        },
        {
            name: 'Nome operatore',
            selector: row => row.operatorTitle,

        },
        {
            name: 'Data dell attività',
            selector: row => row.dateOfWork,

        },
        {
            name: 'Stato',
            selector: row => row.taskStatus,
        }
        
    ];

    const handlePDF = () => {
        let doc = new jspdf("p", "pt", "a3");
        doc.html(document.getElementById('pdf'), {
            callback: function (pdf) {
                pdf.save("task.pdf");
                toast("PDF Downloaded Successfully",
                    {
                        icon: '👏',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            }
        })
    }

    const callBasicAPI = () => {
        $('.se-pre-con').css('display','block');
        getTemplatesKeyValueList().then((res) => {
            if (res.errorCode == 0) {
                $('.se-pre-con').css('display','none');
                let opt = res.responsePacket.map((d) => ({
                    value: d.key,
                    label: d.value,
                    option: d.option
                }));
                setTaskTemplateKeyValue(opt);
            } else {
                alert(res.message)
            }
        })

        getOperatorKeyValueList().then((res) => {
            if (res.errorCode == 0) {
                $('.se-pre-con').css('display','none');
                let opt = res.responsePacket.map((d) => ({
                    value: d.option,
                    label: d.value,
                }));
                setOperatorKeyValue(opt);
            } else {
                alert(res.message)
            }
        })


        getClientKeyValueList().then((res) => {
            if (res.errorCode == 0) {
                $('.se-pre-con').css('display','none');
                let opt = res.responsePacket.map((d) => ({
                    value: d.option,
                    label: d.value,
                }));
                setHospitalsKeyValue(opt);
            } else {
                alert(res.message)
            }
        })

        getDepartmentKeyvalueList().then((res) => {
            if (res.errorCode == 0) {
                $('.se-pre-con').css('display','none');
                let opt = res.responsePacket.map((d) => ({
                    value: d.option,
                    label: d.value,
                }));
                setDepartmentsKeyValue(opt);
            } else {
                alert(res.message)
            }
        })

        getTaskSlotKeyValue().then((res) => {
            if (res.errorCode == 0) {
                $('.se-pre-con').css('display','none');
                let opt = res.responsePacket.map((d) => ({
                    value: d.option,
                    label: d.value,
                }));
                setSlotTypeKeyValue(opt)
            } else {
                alert(res.message)
            }
        })
    }

    const getAssignedtaskList = () => {
        var rId = localStorage.getItem("rId")
        if (rId != undefined) {
            var recordIdForList = rId
        }
        else {
            var recordIdForList = ""
        }

        let taskStatus = {
            taskStatusEnum: "Completed",
            clientId: recordIdForList
        }

        $('.se-pre-con').css('display','block');
        getTaskWorkOrderList(taskStatus).then((res) => {
            if (res.errorCode === 0) {
                $('.se-pre-con').css('display','none');
                setRows(res.responsePacket)
            }
        })
    }

    const conditionalRowStyles = [
        {
            when: row => row.taskStatus == 'Completed',
            style: {
                cursor: "pointer",
            }
        }
    ];

    const handleData = (e) => {

        if (e.taskStatus == "Completed") {
            setDetails(true);
            taskTemplateUuid = e.recordId;
            if (e.recordId != 0) {
                formData = JSON.parse(e.subTaskJson);
                taskTemplateTitle = e.taskTitle
                console.log("dict", formData)
                console.log("dict", taskTemplateTitle)



            } else {
                toast('❌' + e.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    useEffect(() => {
        callBasicAPI()
        getAssignedtaskList()
    }, []);


    return (
        <>
            <div className="sidebar-backdrop" />
            <div className="page-wrapper">
                <CommanComponents />
                <main className="page-content">
                    <div className="container">
                        <div className='row mb-2 justify-content-between align-items-center'>
                            <div style={{ fontSize: '20px', fontWeight: "500" }}>
                                <span>Modello completato</span>
                            </div>

                        </div>

                        <div className="row">
                        
                            <div style={{ height: 500, width: '100%' }}>

                                <DataTable
                                    columns={columns}
                                    data={rows}
                                    theme="solarized"
                                    pagination
                                    onRowClicked={(e) => handleData(e)}
                                    conditionalRowStyles={conditionalRowStyles}
                                // expandableRows
                                // expandableRowsComponent={ExpandedComponent}
                                />

                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <Modal show={open} className={open ? "is-active is-animate" : ""} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Assegnare gli operatori</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label htmlFor="exampleInputEmail1">Modello di attività</label>
                            <Select options={taskTemplateKeyValue} name='roundOffTo' value={taskTemplateValue} onChange={(e) => setTaskTemplateValue(e)} />
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor="exampleInputEmail1">Operatori</label>
                            <Select options={operatorKeyValue} name='roundOffTo' value={operatorValue} onChange={(e) => setOperatorValue(e)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label htmlFor="exampleInputEmail1">Ospedale</label>
                            <Select options={hospitalsKeyValue} name='roundOffTo' value={hospitalValue} onChange={(e) => setHospitalValue(e)} />
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor="exampleInputEmail1">Dipartimento</label>
                            <Select options={departmentsKeyValue} name='roundOffTo' value={departmentValue} onChange={(e) => setDepartmentValue(e)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label htmlFor="exampleInputEmail1">Data d'inizio</label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    label="."
                                    inputFormat="dd/MM/yyyy"
                                    value={addRequestData.startDate}
                                    onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        startDate: e
                                    })}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor="exampleInputEmail1">Data di fine</label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    label="."
                                    inputFormat="dd/MM/yyyy"
                                    value={addRequestData.endDate}
                                    onChange={(e) => setAddRequestData({
                                        ...addRequestData,
                                        endDate: e
                                    })}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label htmlFor="exampleInputEmail1">Tipo di slot</label>
                            <Select options={slotTypeKeyValue} name='roundOffTo' value={slotType} onChange={(e) => handleSlotType(e)} />
                        </div>
                        {show ?
                            <div className='col-md-6'>
                                <label htmlFor="exampleInputEmail1">Giorno della settimana</label>
                                <Select options={wekendOptions} name='roundOffTo' value={weekDay} onChange={(e) => setWeekDay(e)} />
                            </div>
                            : <></>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                    Chiudere
                    </Button>
                    <Button variant="primary" onClick={() => handleAssignOperator()}>
                    Salva
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={openDetails} className={openDetails ? "is-active is-animate" : ""} onHide={handleDetailsClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Assegna dettagli</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div id="pdf">
                        <FormBuilder />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handlePDF}>
                    Esporta in formato PDF
                    </Button>
                    <Button variant="secondary" onClick={() => handleDetailsClose()}>
                    Chiudere
                    </Button>
                    
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <div className="se-pre-con">
                <img src={loader}/>
            </div>
        </>
    )
}

export default TaskCompleted