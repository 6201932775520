import React, { useEffect, useState } from 'react'
import { CommanComponents } from "../components/index"
import DataTable, { createTheme, FilterComponent } from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { Modal, Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import listPlugin from '@fullcalendar/list' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import $ from "jquery"
import moment from 'moment';
import { getMessageList, sendMessage,upcomingTaskWorkOrderListForUser } from "../services/index"
import userOne from "../assets/img/content/humans/userone.png";
import userSecond from "../assets/img/content/humans/usersecond.png";
import loader from "../assets/img/content/loader.svg"
import allLocales from "@fullcalendar/core/locales-all";
import { Tooltip } from 'bootstrap';
import { useHistory } from 'react-router-dom'

import {
    addRequest, getRequestList, getUserRequestList
} from "../services/index"

export const Request = props => {
    const [events, setEvents] = useState()
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);
    const [requestDate, setRequestDate] = useState(new Date());
    const [adminId, setAdminId] = useState();
    const [clientId, setClientId] = useState();
    const [chatMessage, setChatMessage] = useState([]);
    const [message, setMessage] = useState("");
    const [clientRecordId, setClienRecordtId] = useState("")
    const [adminRecord, setAdminRecordId] = useState("")
    const [note, setNote] = useState("")
    const [userRole, setUserRole] = useState("");
    const history = useHistory();
    const [checkedStatus, setCheckedStatus] = useState({
        red:false,
        green:false,
        orange:false,
    })

    //Input Box onChange Set States
    const handleTextChange = (e) => {
        setNote(e.target.value);
    }

    const handleViewChat = (adminId) => {
        console.log(adminId);
        $(".sidebox").addClass('is-active');
        localStorage.setItem('clientDataId', localStorage.getItem("client_recordId"));
        localStorage.setItem('adminDataId', adminId.event._def.title);
        setClienRecordtId(localStorage.getItem("client_recordId"))
        setAdminRecordId(adminId)
        var messageRequest = {
            "receiverId": adminId.event._def.title,
            "senderId": localStorage.getItem("client_recordId"),
        }
        console.log(messageRequest);
        //$('.se-pre-con').css('display','block');
        getMessageList(messageRequest).then((res) => {
            if (res.errorCode === 0) {
                $('.se-pre-con').css('display', 'none');
                const messageData = res.responsePacket;
                setChatMessage(messageData)
            }
        })
    }

    /* setTimeout(function() {
        var adminRecordId = localStorage.getItem("clientDataId")
        var clientRecordId= localStorage.getItem("adminDataId")
        var messageRequest={
            "receiverId":clientRecordId,
            "senderId":adminRecordId, 
        }
        $('.se-pre-con').css('display','block');
         getMessageList(messageRequest).then((res) => {
            if (res.errorCode === 0) {
                $('.se-pre-con').css('display','none');
                const messageData=res.responsePacket;
                setChatMessage(messageData)
            }
        }) 
    }, 30000); */

    const handleSendMessage = () => {
        
        var dict = {
            "message": message,
            "receiverId": adminRecord.events._def.title,
            "senderId": clientRecordId,
            "senderReceiverEnum": "CLIENT"
        }
        console.log(dict);
        $('.se-pre-con').css('display', 'block');
        sendMessage(dict).then((res) => {
            console.log(res);
            if (res.errorCode === 0) {
                $('.se-pre-con').css('display', 'none');
                var data = {
                    "clientId": clientRecordId
                }
                handleViewChat(clientRecordId, adminRecord)
                setMessage('')
            }
        })
    }

    const handleClose = () => {
        $(".sidebox").removeClass('is-active');
    }

    const handleAddRequest = () => {
        setShow(true)
    }

    const handleSaveRequest = () => {
        let dict = {
            "note": note,
            "requestedDate": requestDate,
        }
        $('.se-pre-con').css('display', 'block');
        addRequest(dict).then((res) => {
            if (res.errorCode == 0) {
                $('.se-pre-con').css('display', 'none');
                console.log(res)
                let d = res.responsePacket
                setShow(false)
                getRequests(localStorage.getItem("startDateLink"), localStorage.getItem("endDateLink"))
                toast(res.message,
                    {
                        icon: '👏',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );

            } else {
                $('.se-pre-con').css('display', 'none');
                toast(res.message,
                    {
                        icon: '🙁',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );

            }
        }).catch((err) => {
            $('.se-pre-con').css('display', 'none');
            toast(err,
                {
                    icon: '🙁',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        })
    }
    const [taskStatus, setTaskStatus] = useState()
    const getRequests = (startDateData, endDateDate) => {
        console.log(startDateData, endDateDate);
        //debugger;
        var rId = localStorage.getItem("rId")
        if (rId != undefined && rId != '') {
            var recordIdForList = rId
        }
        else {
            var recordIdForList = ""
        }

        var itemOxygenRole = localStorage.getItem("itemOxygenRole")
        if (itemOxygenRole === "ROLE_CLIENT") {
            var dict = {
                "requestedPage": 0,
                "requestedPageSize": -1,
                "searchKey": ""
            }
            $('.se-pre-con').css('display', 'block');

            getRequestList(dict).then((res) => {
                console.log(res)
                if (res.errorCode == 0) {
                    $('.se-pre-con').css('display', 'none');
                    var clientId = res.responsePacket[0].clientId;
                    var adminId = res.responsePacket[0].adminId;
                    setClientId(clientId)
                    setAdminId(adminId)
                    let temp = []
                    res.responsePacket.map((event) => {
                        let color = null
                        if (event.requestStatusEnum === "REQUESTED") {
                            color = "blue"
                        } else if (event.requestStatusEnum === "REJECTED") {
                            color = "red"
                        } else if (event.requestStatusEnum === "ASSIGNED") {
                            color = "green"
                        }
                        let dict = {
                            title: event.adminId,
                            start: event.requestedDate,
                            end: event.requestedDate,
                            allDay: true,
                            backgroundColor: event.taskStatus == 'Completed' ? 'green' : (event.taskStatus == 'Todo') ? 'red' : '#f9a100',
                        }
                        temp.push(dict)
                        console.log(dict)
                    })
                    setEvents(temp)
                } else {
                    $('.se-pre-con').css('display', 'none');
                    toast(res.message,
                        {
                            icon: '🙁',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );

                }
            }).catch((err) => {
                toast(err,
                    {
                        icon: '🙁',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            })
        }
        else {
            var dict = {
                "endDate": endDateDate,
                "startDate": startDateData,
                "clientId": recordIdForList
            }
            $('.se-pre-con').css('display', 'block');
            getUserRequestList(dict).then((res) => {
                /* console.log(res);
                debugger; */
                var date = new Date().toDateString();
                var modifyDate = moment(date).format("YYYY-MM-DD");
                if (res.errorCode === 0) {
                    $('.se-pre-con').css('display', 'none');
                    let temp = []
                    res.responsePacket.map((event) => {
                        let dict = {
                            title: event.taskTitle + " - (" + event.operatorTitle + ") - " + event.clientTitle + " / " + event.departmentName + " / " + event.roomName,
                            start: event.dateOfWork,
                            end: event.dateOfWork,
                            allDay: true,
                            backgroundColor: event.taskStatus == 'Completed' ? 'green' : (event.taskStatus == 'Todo') && (event.dateOfWork > modifyDate) ? '#f9a100' : 'red',
                            taskStatus: event.taskStatus
                    }
                        temp.push(dict)
                    })
                    setTaskStatus(temp)
                    console.log(temp)
                    setEvents(temp)
                }
            }).catch((err) => {
                toast(err,
                    {
                        icon: '🙁',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            })

        }


    }

    const handleButton = (arg) => {
        var start = arg.start;
        var end = arg.end;
        var startDateData = moment(start).format("YYYY-MM-DD")
        var endDateDate = moment(end).format("YYYY-MM-DD")
        var startDateLink = localStorage.setItem('startDateLink', startDateData);
        var endDateLink = localStorage.setItem('endDateLink', endDateDate);
        getRequests(startDateData, endDateDate)

    }

    let tooltipInstance = null;
    const handleMouseEnter = (info) => {
        console.log(info)
        if (info.jsEvent.isTrusted) {
            tooltipInstance = new Tooltip(info.el, {
                title: info.event._def.title,
                html: true,
                placement: "top",
                trigger: "hover",
                container: "body"
            });

            tooltipInstance.show();
        }
    };

    const handleMouseLeave = (info) => {
        if (tooltipInstance) {
            tooltipInstance.dispose();
            tooltipInstance = null;
        }
    };

    const handleDateClick = (arg) => {
        console.log(arg);
        var startDateForFilter = arg.el.fcSeg.eventRange.range.start
        localStorage.setItem('listofDateUser', startDateForFilter);
        var taskStatus = arg.event._def.extendedProps.taskStatus
        if (taskStatus == "Completed") {

            history.push("/client-task-completed")
        }
        /* else
        {   
            history.push("/assign-operator")
        } */

    }

    const findByColor = (e, type, colorType) => {
        if(colorType === "Green")
        {
            setCheckedStatus({
                green:e.target.checked,
                red:false,
                orange:false
            })
        }
        else if(colorType === "Red")
        {
            setCheckedStatus({
                green:false,
                red:e.target.checked,
                orange:false
            })
        }
        else if(colorType === "Orange")
        {
            setCheckedStatus({
                green:false,
                red:false,
                orange:e.target.checked
            })
        }
        var startDateLink = localStorage.getItem('startDateLink');
        var endDateLink = localStorage.getItem('endDateLink');
        if (e.target.checked === true) {
            var rId = localStorage.getItem("rId")
            if (rId != undefined) {
                var recordIdForList = rId
            }
            else {
                var recordIdForList = ""
            }

            var taskStatus = {
                color: colorType,
                taskStatusEnum: type,
                clientId: recordIdForList,
                startDate: moment(startDateLink).format("YYYY-MM-DD"),
                endDate: moment(endDateLink).format("YYYY-MM-DD"),
                requestedPage: "0",
                requestedPageSize: "10000",
                clientName: ""
            }
            $('.se-pre-con').css('display', 'block');
            upcomingTaskWorkOrderListForUser(taskStatus).then((res) => {
                var date = new Date().toDateString();
                var modifyDate = moment(date).format("YYYY-MM-DD");
                console.log(modifyDate);
                if (res.errorCode === 0) {
                    $('.se-pre-con').css('display', 'none');
                    let dict = {}
                    let temp = []
                    res.responsePacket.map((event) => {
                        if (colorType == "Red") {
                            dict = {
                                title: event.taskTitle + " - (" + event.operatorTitle + ") - " + event.clientTitle + " / " + event.departmentName + " / " + event.roomName,
                                start: event.dateOfWork,
                                end: event.dateOfWork,
                                allDay: true,
                                backgroundColor: 'red',
                                taskStatus: event.taskStatus
                            }

                        }
                        else if (colorType === "Orange") {
                            dict = {
                                title: event.taskTitle + " - (" + event.operatorTitle + ") - " + event.clientTitle + " / " + event.departmentName + " / " + event.roomName,
                                start: event.dateOfWork,
                                end: event.dateOfWork,
                                allDay: true,
                                backgroundColor: '#f9a100',
                                taskStatus: event.taskStatus
                            }

                        }
                        else if (colorType == 'Green') {
                            dict = {
                                title: event.taskTitle + " - (" + event.operatorTitle + ") - " + event.clientTitle + " / " + event.departmentName + " / " + event.roomName,
                                start: event.dateOfWork,
                                end: event.dateOfWork,
                                allDay: true,
                                backgroundColor: 'green',
                                taskStatus: event.taskStatus
                            }

                        }
                        temp.push(dict)
                    })
                    setTaskStatus(temp)
                    console.log(temp)
                    setEvents(temp)
                }
            })
        }
        else
        {
            getRequests(startDateLink,endDateLink)
        }    
        
    }


    useEffect(() => {
        //getRequests(localStorage.getItem("startDateLink"),localStorage.getItem("endDateLink"))
        
        var itemOxygenRole = localStorage.getItem("itemOxygenRole")
        setUserRole(itemOxygenRole)
        /* if (itemOxygenRole === "ROLE_CLIENT") {
            const recentEffect = setInterval(() => {
                var adminRecordId = localStorage.getItem("clientDataId")
                var clientRecordId = localStorage.getItem("adminDataId")
                var messageRequest = {
                    "receiverId": clientRecordId,
                    "senderId": adminRecordId,
                }
                $('.se-pre-con').css('display', 'block');
                getMessageList(messageRequest).then((res) => {
                    if (res.errorCode === 0) {
                        $('.se-pre-con').css('display', 'none');
                        const messageData = res.responsePacket;
                        setChatMessage(messageData)
                    }
                })
            }, 5000)

            return () => {
                clearInterval(recentEffect);
            }; 

        }*/

    }, []);

    return (
        <>

            <div>
                <div className="sidebar-backdrop" />
                <div className="page-wrapper">
                    <CommanComponents />
                    <main className="page-content">
                        <div className="container">
                            <div className='row align-items-center justify-content-start'>
                                <div className=''>
                                    <input type="checkbox" checked={checkedStatus.green} onChange={(e) => findByColor(e, "Completed", "Green")} />&nbsp;Verde
                                </div>
                                <div className=''>
                                    <input type="checkbox" checked={checkedStatus.red} onChange={(e) => findByColor(e, "Todo", "Red")} />&nbsp;Rosso
                                </div>
                                <div className=''>
                                    <input type="checkbox" checked={checkedStatus.orange} onChange={(e) => findByColor(e, "Todo", "Orange")} />&nbsp;Arancione
                                </div>
                            </div>
                            <div className="page-header justify-content-between align-items-center d-flex">
                                <div>
                                    <h1 className="page-header__title">Calendario</h1>
                                </div>
                                {
                                    userRole === "ROLE_CLIENT" ?
                                    <div>
                                    <button type="button" className="btn btn-primary btn-sm" onClick={handleAddRequest}><i className='fa fa-plus'></i>Aggiungi richiesta</button> : <></>
                                    </div>
                                    :
                                    ""
                                }
                               
                            </div>

                            {
                                userRole === "ROLE_CLIENT" ?
                                    <FullCalendar
                                        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                                        initialView="dayGridMonth"
                                        events={events}
                                        eventClick={(events) => handleViewChat(events)}
                                        selectable={true}
                                        displayEventTime={true}
                                        locales={allLocales}
                                        locale="it"
                                        expandRows={true}
                                        navLinks={true}
                                        editable={true}
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                        }}

                                    // v-bind:options="options"
                                    />
                                    :
                                    <FullCalendar
                                        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                                        initialView="dayGridMonth"
                                        events={events}
                                        eventClick={(events) => handleDateClick(events)}
                                        selectable={true}
                                        displayEventTime={true}
                                        locales={allLocales}
                                        locale="it"
                                        expandRows={true}
                                        navLinks={true}
                                        editable={true}
                                        datesSet={(dateInfo) => handleButton(dateInfo)}
                                        eventMouseEnter={handleMouseEnter}
                                        eventMouseLeave={handleMouseLeave}
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                        }}
                                    />

                            }


                        </div>
                    </main>
                </div>
            </div>
            <Modal show={show} className={show ? "is-active is-animate" : ""} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Aggiungi richiesta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-12'>
                            <label for="exampleInputEmail1">richiesta data</label><br />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    label="."
                                    inputFormat="dd/MM/yyyy"
                                    value={requestDate}
                                    onChange={(e) => setRequestDate(e)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='col-md-12'>
                            <label for="exampleInputEmail1">Note</label>
                            <textarea className="form-control input-default" id="note" rows="4" onChange={handleTextChange}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Chiudere
                    </Button>
                    <Button variant="primary"
                        onClick={handleSaveRequest}
                    >
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="modal custommodal demo-panel modal--panel modal--right is-animate sidebox" id="demo">
                <div className="modal__overlay" data-dismiss="modal" />
                <div className="modal__wrap">
                    <div className="modal__window scrollbar-thin" data-simplebar="data-simplebar">
                        <div className="modal__content">
                            <div class="modal-header">
                                <h5 class="modal-title">Messaggi</h5>
                                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}><i class="fa fa-times" aria-hidden="true"></i></button>
                            </div>
                            <div className="modal__body p-2">
                                <div className='box box-primary direct-chat direct-chat-primary'>
                                    <div class="direct-chat-messages">
                                        {chatMessage.map((item) => (
                                            item.senderName != "Admin" ?
                                                <div class="direct-chat-msg right">
                                                    <div class="direct-chat-info clearfix">
                                                        <span class="direct-chat-name pull-left">{item.senderName}</span>
                                                        <span class="direct-chat-timestamp pull-right">{moment(item.createdAt).format("lll")}</span>
                                                    </div>
                                                    <img class="direct-chat-img" src={userOne} alt="Message User Image" /> <div class="direct-chat-text">
                                                        {item.message}
                                                    </div>
                                                </div>
                                                :
                                                <div class="direct-chat-msg">
                                                    <div class="direct-chat-info clearfix">
                                                        <span class="direct-chat-name pull-right">{item.senderName}</span>
                                                        <span class="direct-chat-timestamp pull-left">{moment(item.createdAt).format("lll")}</span>
                                                    </div>
                                                    <img class="direct-chat-img" src={userSecond} alt="Message User Image" /> <div class="direct-chat-text">
                                                        {item.message}
                                                    </div>
                                                </div>
                                        ))}
                                        {/* <div class="direct-chat-msg right">
                                    <div class="direct-chat-info clearfix">
                                        <span class="direct-chat-name pull-right">Sarah Bullock</span>
                                        <span class="direct-chat-timestamp pull-left">23 Jan 2:05 pm</span>
                                    </div>
                                   
                                    <img class="direct-chat-img" src="https://bootdey.com/img/Content/user_2.jpg" alt="Message User Image"/>
                                    <div class="direct-chat-text">
                                        You better believe it!
                                    </div>
                                    
                                    </div> */}
                                    </div>
                                </div>

                            </div>
                            <div className="modal__footer cursor py-2 px-2">
                                {/* <div className="modal__container">
                                    <button className="button button--primary button--block" data-dismiss="modal"><span className="button__text" >Close</span></button>
                                </div> */}

                                <div class="input-group">
                                    <textarea name="message" placeholder="Type Message ..." class="form-control" onChange={(e) => setMessage(e.target.value)} value={message}></textarea>
                                    <button type="submit" class="btn btn-primary btn-flat" onClick={handleSendMessage}>Inviare</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            {/* <div className="se-pre-con">
                <img src={loader}/>
            </div> */}
        </>
    )
}

