import React, { Component } from "react";
import { useEffect, useState } from "react";
import { CommanComponents } from "../components/index";
import DataTable, {
  createTheme,
  FilterComponent,
} from "react-data-table-component";
import { getClientTaskWorkList } from "../services/index";
import $ from "jquery";
import loader from "../assets/img/content/loader.svg";
import moment from "moment";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import StarRatingComponent from "react-star-rating-component";
import Slider from "react-rangeslider";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button, Form } from "react-bootstrap";
import jspdf from "jspdf";
import TextareaAutosize from "@mui/base/TextareaAutosize";

var sideFolderArrayTemp = [];
export const ClientTaskCompleted = () => {
  const [rows, setRows] = useState([]);
  const [isClient, setClient] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [openDetails, setDetails] = useState(false);
  const [sideFolderListHtml, setSideFolderListHtml] = useState([]);
  const [signatureValueFirst, setSignatureValueFirst] = useState(null);
  const [signatureValue, setSignatureValue] = useState(null);
  const [signatureValueOne, setSignatureValueOne] = useState(null);
  const [signatureValueSecond, setSignatureValueSecond] = useState(null);
  const [signatureValueThird, setSignatureValueThird] = useState(null);
  const [signatureValueFourth, setSignatureValueFourth] = useState(null);
  const [signatureValueFive, setSignatureValueFive] = useState(null);
  const [signatureValueSix, setSignatureValueSix] = useState(null);
  const [signatureValueSeven, setSignatureValueSeven] = useState(null);
  const [signatureValueEight, setSignatureValueEight] = useState(null);
  const [signatureValueNine, setSignatureValueNine] = useState(null);

  const [addRequestData, setAddRequestData] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const handleDetailsClose = () => {
    setDetails(false);
    sideFolderArrayTemp = [];
    setSideFolderListHtml([]);
  };

  const handlePDF = () => {
    let doc = new jspdf("p", "pt", "a2");
    doc.html(document.getElementById("pdf"), {
      callback: function (pdf) {
        pdf.save("task.pdf");
        toast("PDF Downloaded Successfully", {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // fetchData(page, newPerPage);
    getAssignedtaskList(0, newPerPage);
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    getAssignedtaskList(page - 1);
    setCurrentPage(page);
  };

  const getAssignedtaskList = async (page, size = perPage) => {
    var client_recordId;

    if (localStorage.getItem("rId") === "") {
      client_recordId = "";
    } else if (localStorage.getItem("itemOxygenRole") === "ADMINISTRATION") {
      client_recordId = localStorage.getItem("rId");
    } else {
      client_recordId = localStorage.getItem("client_recordId");
    }

    if (client_recordId === null) {
      client_recordId = "";
    }

    var listofDate = localStorage.getItem("listofDateUser");

    var taskStatus;
    if (listofDate != "" && listofDate != null) {
      taskStatus = {
        taskStatusEnum: "Completed",
        clientId: client_recordId,
        startDate: moment(listofDate).format("YYYY-MM-DD"),
        endDate: moment(listofDate).format("YYYY-MM-DD"),
        requestedPage: page,
        requestedPageSize: size,
      };
    } else {
      taskStatus = {
        taskStatusEnum: "Completed",
        clientId: client_recordId,
        startDate: moment(addRequestData.startDate).format("YYYY-MM-DD"),
        endDate: moment(addRequestData.endDate).format("YYYY-MM-DD"),
        requestedPage: page,
        requestedPageSize: size,
      };
    }

    /* if (client_recordId != undefined) {
            var recordIdForList = client_recordId
        } */

    $(".se-pre-con").css("display", "block");
    getClientTaskWorkList(taskStatus).then((res) => {
      if (res.errorCode === 0) {
        $(".se-pre-con").css("display", "none");
        setRows(res.responsePacket);
        if (res.responsePacket.length === 0) {
          setTotalRows(res.recordsTotal);
          localStorage.removeItem("listofDateUser");
        } else {
          setTotalRows(res.responsePacket[0].recordsTotal);
          localStorage.removeItem("listofDateUser");
        }
      }
    });
  };

  const columns = [
    {
      name: "Nome attività",
      selector: (row) => row.taskTitle,
    },
    {
      name: "Ospedale/Dipartimento/Stanza",
      selector: (row) => (
        <div
          title={
            row.clientTitle + "/" + row.departmentName + "/" + row.roomName
          }
        >
          {row.clientTitle + "/" + row.departmentName + "/" + row.roomName}
        </div>
      ),
    },
    {
      name: "Data dell attività",
      selector: (row) => moment(row.dateOfWork).format("DD/MM/YYYY"),
    },
    {
      name: "Stato",
      selector: (row) => row.taskStatus,
    },
  ];

  const checkVal = (appData, dataList, rowType) => {
    if (appData !== null) {
      for (let i = 0; i < appData.childItems.length; i++) {
        check(appData.childItems[i], dataList, rowType);
      }
    }
  };

  const check = (appDataV, newDataList, rowType) => {
    console.log(appDataV);
    console.log(newDataList);
    console.log(rowType);
    for (let i = 0; i < newDataList.length; i++) {
      console.log(appDataV, "===", newDataList[i].id);
      if (appDataV === newDataList[i].id) {
        var countType = 12 / rowType;
        if (newDataList[i].element === "Image") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                <img
                  src={newDataList[i].field_name}
                  style={{ height: "250px",width:"250px" }}
                />
              </p>
            </div>
          );
          getBase64FromUrl(newDataList[i].field_name).then((res) => {
            sideFolderArrayTemp.push(
              <div className={"spaceType col-md-" + countType}>
                <p>
                  <p
                    dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                  ></p>
                  <img src={res} style={{ height: "250px",width:"250px" }} />
                </p>
              </div>
            );
            //setSignatureValueFive(signatureValueFive+1)
          });
          break;
        } else if (newDataList[i].element === "Signature") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                <img
                  src={newDataList[i].field_name}
                  style={{ height: "250px",width:"250px" }}
                />
              </p>
            </div>
          );
          getBase64FromUrl(newDataList[i].field_name).then((res) => {
            sideFolderArrayTemp.push(
              <div className={"spaceType col-md-" + countType}>
                <p>
                  <p
                    dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                  ></p>
                  <img src={res} style={{ height: "250px",width:"250px" }} />
                </p>
              </div>
            );
            //setSignatureValueSix(signatureValueSix+1)
          });
          break;
        } else if (newDataList[i].element === "Camera") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                <img
                  src={newDataList[i].field_name}
                  style={{ height: "250px",width:"250px" }}
                />
              </p>
            </div>
          );
          getBase64FromUrl(newDataList[i].field_name).then((res) => {
            sideFolderArrayTemp.push(
              <div className={"spaceType col-md-" + countType}>
                <p>
                  <p
                    dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                  ></p>
                  <img src={res} style={{ height: "250px",width:"250px" }} />
                </p>
              </div>
            );
            //setSignatureValueSeven(signatureValueSeven+1)
          });
          break;
        } else if (newDataList[i].element === "Download") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                <img
                  src={newDataList[i].field_name}
                  style={{ height: "250px",width:"250px" }}
                />
              </p>
            </div>
          );
          getBase64FromUrl(newDataList[i].field_name).then((res) => {
            sideFolderArrayTemp.push(
              <div className={"spaceType col-md-" + countType}>
                <p>
                  <p
                    dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                  ></p>
                  <img src={res} style={{ height: "250px",width:"250px" }} />
                </p>
              </div>
            );
            //setSignatureValueEight(signatureValueEight+1)
          });
          break;
        } else if (newDataList[i].element === "FileUpload") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                <img
                  src={newDataList[i].field_name}
                  style={{ height: "250px",width:"250px" }}
                />
              </p>
            </div>
          );
          getBase64FromUrl(newDataList[i].field_name).then((res) => {
            sideFolderArrayTemp.push(
              <div className={"spaceType col-md-" + countType}>
                <p>
                  <p
                    dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                  ></p>
                  <img src={res} style={{ height: "250px",width:"250px" }} />
                </p>
              </div>
            );
            //setSignatureValueNine(signatureValueNine+1)
          });
          break;
        } else if (newDataList[i].element === "Header") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                {newDataList[i].field_name}
              </p>
            </div>
          );
          break;
        } else if (newDataList[i].element == "Paragraph") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                {newDataList[i].field_name}
              </p>
            </div>
          );
          break;
        } else if (newDataList[i].element === "TextInput") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                <input
                  className="form-control"
                  value={newDataList[i].field_name}
                  style={{ marginTop: "14px" }}
                  disabled
                />
              </p>
            </div>
          );
          break;
        } else if (newDataList[i].element === "Rating") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                <StarRatingComponent
                  name="rate2"
                  editing={false}
                  renderStarIcon={() => (
                    <span>
                      <i class="fa fa-star-o" aria-hidden="true"></i>
                    </span>
                  )}
                  starCount={5}
                  value={newDataList[i].field_name}
                />
              </p>
            </div>
          );
          break;
        } else if (newDataList[i].element === "DatePicker") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                {newDataList[i].field_name}
              </p>
            </div>
          );
          break;
        } else if (newDataList[i].element === "Range") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                <Slider
                  min={0}
                  max={newDataList[i].max_value}
                  value={newDataList[i].field_name}
                />
                <div className="value">{newDataList[i].field_name}</div>
              </p>
            </div>
          );
          break;
        } else if (newDataList[i].element === "NumberInput") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                <input
                  className="form-control"
                  value={newDataList[i].field_name}
                  style={{ marginTop: "14px" }}
                  disabled
                />
              </p>
            </div>
          );
          break;
        } else if (newDataList[i].element === "Label") {
          sideFolderArrayTemp.push(
            <div className={"col-md-" + countType}>
              <p
                dangerouslySetInnerHTML={{ __html: newDataList[i].content }}
              ></p>
            </div>
          );
          break;
        } else if (newDataList[i].element === "LineBreak") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <hr />
            </div>
          );
          break;
        } else if (newDataList[i].element === "Dropdown") {
          var optionValues = [];
          for (let k = 0; k < newDataList[i].options.length; k++) {
            optionValues.push(
              <option
                key={newDataList[i].options[k].key}
                value={newDataList[i].options[k].key}
                selected={
                  newDataList[i].field_name === newDataList[i].options[k].key
                    ? true
                    : false
                }
              >
                {newDataList[i].options[k].text}
              </option>
            );
          }
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                <select className="spaceType col-md-12 form-control" disabled>
                  {optionValues}
                </select>
              </p>
            </div>
          );
          break;
        } else if (newDataList[i].element === "Tags") {
          var optionValues = [];
          for (let k = 0; k < newDataList[i].options.length; k++) {
            optionValues.push(
              <option
                key={newDataList[i].options[k].key}
                value={newDataList[i].options[k].key}
                selected={
                  newDataList[i].field_name === newDataList[i].options[k].key
                    ? true
                    : false
                }
              >
                {newDataList[i].options[k].text}
              </option>
            );
          }
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <p
                  dangerouslySetInnerHTML={{ __html: newDataList[i].label }}
                ></p>
                <select className="spaceType col-md-12 form-control" disabled>
                  {optionValues}
                </select>
              </p>
            </div>
          );
          break;
        } else if (newDataList[i].element === "HyperLink") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p>
                <h4>{newDataList[i].field_name}</h4>
              </p>
            </div>
          );
          break;
        } else if (newDataList[i].element === "TextArea") {
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p dangerouslySetInnerHTML={{ __html: newDataList[i].label }}></p>
              <TextareaAutosize
                // rows="5"
                style={{ width: "100%" }}
                disabled
              >
                {newDataList[i].field_name}
              </TextareaAutosize>
            </div>
          );
          break;
        } else if (newDataList[i].element === "Checkboxes") {
          var optionArray = [];
          var splitData = newDataList[i].field_name.split(",");
          let k = 0;
          for (let p = 0; p < newDataList[i].options.length; p++) {
            if (splitData[k] === newDataList[i].options[p].value) {
              optionArray.push(
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={true}
                    disabled
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    {newDataList[i].options[p].text}
                  </label>
                </div>
              );
              k++;
            } else {
              optionArray.push(
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={false}
                    disabled
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    {newDataList[i].options[p].text}
                  </label>
                </div>
              );
            }
          }
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p dangerouslySetInnerHTML={{ __html: newDataList[i].label }}></p>
              {optionArray}
            </div>
          );
        } else if (newDataList[i].element === "RadioButtons") {
          var optionArray = [];
          console.log(newDataList[i].field_name);
          var splitData = newDataList[i].field_name;
          for (let j = 0; j < newDataList[i].options.length; j++) {
            console.log(
              newDataList[i].field_name,
              "===",
              newDataList[i].options[j].key
            );
            if (splitData === newDataList[i].options[j].key) {
              optionArray.push(
                <div class="form-check">
                  <div>
                    <label class="radio">
                      <input type="radio" checked={true} disabled />{" "}
                      <span>{newDataList[i].options[j].text}</span>{" "}
                    </label>
                  </div>
                </div>
              );
            } else {
              optionArray.push(
                <div class="form-check">
                  <div>
                    <label class="radio">
                      <input type="radio" checked={false} disabled />{" "}
                      <span>{newDataList[i].options[j].text}</span>{" "}
                    </label>
                  </div>
                </div>
              );
            }
          }
          sideFolderArrayTemp.push(
            <div className={"spaceType col-md-" + countType}>
              <p dangerouslySetInnerHTML={{ __html: newDataList[i].label }}></p>
              {optionArray}
            </div>
          );
        }
        //sideFolderArrayTemp.push(<p><h6>{newDataList[i].label}</h6><input  className="form-control" value={newDataList[i].field_name} style={{marginTop:'14px'}} disabled/></p>)
        //sideFolderArrayTemp.push(<div className="col-md-12 border"><p><h4>{sideFolderArrayTemp}</h4></p></div>)
      }
    }
    setSideFolderListHtml(sideFolderArrayTemp);
  };

  const handleData = (e) => {
    console.log(e);
    sideFolderArrayTemp = [];
    if (e.taskStatus == "Completed") {
      if (e.recordId != 0) {
        var operatorTitle = e.operatorTitle;
        var clientTitle = e.clientTitle;
        var roomName = e.roomName;
        var departmentName = e.departmentName;
        var taskUpdatedDate = e.taskUpdatedDate;
        var taskTitle = e.taskTitle;
        var formDataEditList = JSON.parse(e.subTaskJson);
        console.log("formDataEditList", formDataEditList.task_data);
        setDetails(true);
        //sideFolderArrayTemp.push(<p>Logo:<img src="https://s3.ap-south-1.amazonaws.com/item-oxygen-bucket/primary-image/1661424350996_logo.svg" style={{height: "51px"}}/></p>);
        getBase64FromUrl(
          "https://s3.ap-south-1.amazonaws.com/item-oxygen-bucket/primary-image/1661426306231_itemoxigon.png"
        ).then((res) => {
          sideFolderArrayTemp[0] = (
            <div className="col-md-12 text-left">
              <h4>
                <img src={res} style={{ height: "51px" }} />
              </h4>
            </div>
          );
          setSignatureValueFirst(signatureValueFirst + 1);
        });
        sideFolderArrayTemp[1] = (
          <div className="col-md-12 my-4">
            <div className="text-left" style={{ marginRight: "46px" }}>
              <b>Date : {moment(taskUpdatedDate).format("DD-MM-YYYY")}</b>
            </div>
            <div className="text-left titleForm">
              <div>
                <div></div>
                <div>
                  <ul style={{ textAlign: "left" }}>
                    <li>Scheda : {taskTitle}</li>
                    <li>Operatore : {operatorTitle}</li>
                    <li>Ospedale : {clientTitle}</li>
                    <li>Dipartimento : {departmentName}</li>
                    <li>Stanza : {roomName}</li>
                  </ul>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        );

        for (let i = 0; i < formDataEditList.task_data.length; i++) {
          if (formDataEditList.task_data[i].isAdded !== true) {
            let allData = formDataEditList.task_data[i];
            if (formDataEditList.task_data[i].element === "TwoColumnRow") {
              checkVal(allData, formDataEditList.task_data, 2);
            } else if (
              formDataEditList.task_data[i].element === "ThreeColumnRow"
            ) {
              checkVal(allData, formDataEditList.task_data, 3);
            } else if (
              formDataEditList.task_data[i].element === "FourColumnRow"
            ) {
              checkVal(allData, formDataEditList.task_data, 4);
            } else if (formDataEditList.task_data[i].element === "Image") {
              getBase64FromUrl(formDataEditList.task_data[i].field_name).then(
                (res) => {
                  sideFolderArrayTemp.push(
                    <div className="spaceType col-md-12">
                      <p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: formDataEditList.task_data[i].label,
                          }}
                        ></p>
                        <img src={res} style={{ height: "250px",width:"250px" }} />
                      </p>
                    </div>
                  );
                  setSignatureValue(signatureValue + 1);
                }
              );
            } else if (formDataEditList.task_data[i].element === "Signature") {
              //console.log("jk",formDataEditList.task_data[i].field_name);
              getBase64FromUrl(formDataEditList.task_data[i].field_name).then(
                (res) => {
                  sideFolderArrayTemp.push(
                    <div className="spaceType col-md-12">
                      <p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: formDataEditList.task_data[i].label,
                          }}
                        ></p>
                        <img src={res} style={{ height: "250px",width:"250px" }} />
                      </p>
                    </div>
                  );
                  setSignatureValueOne(signatureValueOne + 1);
                }
              );
            } else if (formDataEditList.task_data[i].element === "Camera") {
              getBase64FromUrl(formDataEditList.task_data[i].field_name).then(
                (res) => {
                  sideFolderArrayTemp.push(
                    <div className="spaceType col-md-12">
                      <p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: formDataEditList.task_data[i].label,
                          }}
                        ></p>
                        <img src={res} style={{ height: "250px",width:"250px" }} />
                      </p>
                    </div>
                  );
                  setSignatureValueSecond(signatureValueSecond + 1);
                }
              );
            } else if (formDataEditList.task_data[i].element === "Download") {
              getBase64FromUrl(formDataEditList.task_data[i].field_name).then(
                (res) => {
                  sideFolderArrayTemp.push(
                    <div className="spaceType col-md-12">
                      <p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: formDataEditList.task_data[i].label,
                          }}
                        ></p>
                        <img src={res} style={{ height: "250px",width:"250px" }} />
                      </p>
                    </div>
                  );
                  setSignatureValueThird(signatureValueThird + 1);
                }
              );
            } else if (formDataEditList.task_data[i].element === "FileUpload") {
              getBase64FromUrl(formDataEditList.task_data[i].field_name).then(
                (res) => {
                  sideFolderArrayTemp.push(
                    <div className="spaceType col-md-12">
                      <p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: formDataEditList.task_data[i].label,
                          }}
                        ></p>
                        <img src={res} style={{ height: "250px",width:"250px" }} />
                      </p>
                    </div>
                  );
                  setSignatureValueFourth(signatureValueFourth + 1);
                }
              );
            } else if (formDataEditList.task_data[i].element === "Header") {
              sideFolderArrayTemp.push(
                <div className="col-md-12">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formDataEditList.task_data[i].content,
                    }}
                  ></p>
                </div>
              );
            } else if (formDataEditList.task_data[i].element == "Paragraph") {
              sideFolderArrayTemp.push(
                <div className="col-md-12">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formDataEditList.task_data[i].content,
                    }}
                  ></p>
                </div>
              );
            } else if (formDataEditList.task_data[i].element === "TextInput") {
              sideFolderArrayTemp.push(
                <div className="spaceType col-md-12">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formDataEditList.task_data[i].label,
                    }}
                  ></p>
                  <input
                    className="form-control"
                    value={formDataEditList.task_data[i].field_name}
                    style={{ marginTop: "14px" }}
                    disabled
                  />
                </div>
              );
            } else if (formDataEditList.task_data[i].element === "TextArea") {
              sideFolderArrayTemp.push(
                <div className="spaceType col-md-12">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formDataEditList.task_data[i].label,
                    }}
                  ></p>
                  <TextareaAutosize
                    // rows="5"
                    style={{ width: "100%" }}
                    disabled
                  >
                    {formDataEditList.task_data[i].field_name}
                  </TextareaAutosize>
                </div>
              );
            } else if (
              formDataEditList.task_data[i].element === "NumberInput"
            ) {
              sideFolderArrayTemp.push(
                <div className="spaceType col-md-12">
                  <p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formDataEditList.task_data[i].label,
                      }}
                    ></p>
                    <input
                      className="form-control"
                      value={formDataEditList.task_data[i].field_name}
                      style={{ marginTop: "14px" }}
                      disabled
                    />
                  </p>
                </div>
              );
            } else if (formDataEditList.task_data[i].element === "Label") {
              sideFolderArrayTemp.push(
                <div className="spaceType col-md-12">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formDataEditList.task_data[i].content,
                    }}
                  ></p>
                </div>
              );
            } else if (formDataEditList.task_data[i].element === "Range") {
              sideFolderArrayTemp.push(
                <div className="spaceType col-md-12">
                  <p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formDataEditList.task_data[i].label,
                      }}
                    ></p>
                    <Slider
                      min={0}
                      max={formDataEditList.task_data[i].max_value}
                      value={formDataEditList.task_data[i].field_name}
                    />
                    <div className="value">
                      {formDataEditList.task_data[i].field_name}
                    </div>
                  </p>
                </div>
              );
            } else if (formDataEditList.task_data[i].element === "Rating") {
              sideFolderArrayTemp.push(
                <div className="spaceType col-md-12">
                  <p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formDataEditList.task_data[i].label,
                      }}
                    ></p>
                    <StarRatingComponent
                      name="rate2"
                      editing={false}
                      renderStarIcon={() => (
                        <span>
                          <i class="fa fa-star-o" aria-hidden="true"></i>
                        </span>
                      )}
                      starCount={5}
                      value={formDataEditList.task_data[i].field_name}
                    />
                  </p>
                </div>
              );
            } else if (formDataEditList.task_data[i].element === "DatePicker") {
              sideFolderArrayTemp.push(
                <div className="spaceType col-md-12">
                  <p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formDataEditList.task_data[i].label,
                      }}
                    ></p>
                    {formDataEditList.task_data[i].field_name}
                  </p>
                </div>
              );
            } else if (formDataEditList.task_data[i].element === "LineBreak") {
              sideFolderArrayTemp.push(
                <div className="spaceType col-md-12">
                  <hr />
                </div>
              );
            } else if (formDataEditList.task_data[i].element === "HyperLink") {
              sideFolderArrayTemp.push(
                <div className="spaceType col-md-12">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formDataEditList.task_data[i].content,
                    }}
                  ></p>
                </div>
              );
            } else if (formDataEditList.task_data[i].element === "Dropdown") {
              var optionValues = [];
              for (
                let k = 0;
                k < formDataEditList.task_data[i].options.length;
                k++
              ) {
                optionValues.push(
                  <option
                    key={formDataEditList.task_data[i].options[k].key}
                    value={formDataEditList.task_data[i].options[k].key}
                    selected={
                      formDataEditList.task_data[i].field_name ===
                      formDataEditList.task_data[i].options[k].key
                        ? true
                        : false
                    }
                  >
                    {formDataEditList.task_data[i].options[k].text}
                  </option>
                );
              }
              sideFolderArrayTemp.push(
                <div className="spaceType col-md-12">
                  <p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formDataEditList.task_data[i].label,
                      }}
                    ></p>
                    <select
                      className="spaceType col-md-12 form-control"
                      disabled
                    >
                      {optionValues}
                    </select>
                  </p>
                </div>
              );
            } else if (formDataEditList.task_data[i].element == "Tags") {
              var optionValues = [];
              for (
                let k = 0;
                k < formDataEditList.task_data[i].options.length;
                k++
              ) {
                optionValues.push(
                  <option
                    key={formDataEditList.task_data[i].options[k].key}
                    value={formDataEditList.task_data[i].options[k].key}
                    selected={
                      formDataEditList.task_data[i].field_name ===
                      formDataEditList.task_data[i].options[k].key
                        ? true
                        : false
                    }
                  >
                    {formDataEditList.task_data[i].options[k].text}
                  </option>
                );
              }
              sideFolderArrayTemp.push(
                <div className="spaceType col-md-12">
                  <p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formDataEditList.task_data[i].label,
                      }}
                    ></p>
                    <select
                      className="spaceType col-md-12 form-control"
                      disabled
                    >
                      {optionValues}
                    </select>
                  </p>
                </div>
              );
            } else if (
              formDataEditList.task_data[i].element == "RadioButtons"
            ) {
              var optionArray = [];
              var splitData = formDataEditList.task_data[i].field_name;
              for (
                let j = 0;
                j < formDataEditList.task_data[i].options.length;
                j++
              ) {
                console.log(
                  splitData,
                  "===",
                  formDataEditList.task_data[i].options[j].key
                );
                if (
                  splitData === formDataEditList.task_data[i].options[j].key
                ) {
                  optionArray.push(
                    <div>
                      <div>
                        <label class="radio">
                          <input
                            type="radio"
                            checked={true}
                            style={{ border: "8px solid green" }}
                            disabled
                          />{" "}
                          <span>
                            {formDataEditList.task_data[i].options[j].text}
                          </span>{" "}
                        </label>
                      </div>
                    </div>
                  );
                } else {
                  optionArray.push(
                    <div>
                      <div>
                        <label class="radio">
                          <input type="radio" checked={false} disabled />{" "}
                          <span>
                            {formDataEditList.task_data[i].options[j].text}
                          </span>{" "}
                        </label>
                      </div>
                    </div>
                  );
                }
              }
              sideFolderArrayTemp.push(
                <div className={"spaceType col-md-12"}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formDataEditList.task_data[i].label,
                    }}
                  ></p>
                  {optionArray}
                </div>
              );
            } else if (formDataEditList.task_data[i].element === "Checkboxes") {
              var optionArray = [];
              var splitData =
                formDataEditList.task_data[i].field_name.split(",");
              let k = 0;
              for (
                let p = 0;
                p < formDataEditList.task_data[i].options.length;
                p++
              ) {
                if (
                  splitData[k] ===
                  formDataEditList.task_data[i].options[p].value
                ) {
                  optionArray.push(
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={true}
                        disabled
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        {formDataEditList.task_data[i].options[p].text}
                      </label>
                    </div>
                  );
                  k++;
                } else {
                  optionArray.push(
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={false}
                        disabled
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        {formDataEditList.task_data[i].options[p].text}
                      </label>
                    </div>
                  );
                }
              }
              sideFolderArrayTemp.push(
                <div className={"spaceType col-md-12"}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formDataEditList.task_data[i].label,
                    }}
                  ></p>
                  {optionArray}
                </div>
              );
            } else {
              sideFolderArrayTemp.push(
                <div className="spaceType col-md-12">
                  <p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formDataEditList.task_data[i].label,
                      }}
                    ></p>
                    <span>{formDataEditList.task_data[i].field_name}</span>
                  </p>
                </div>
              );
            }
          }
        }
        setSideFolderListHtml(sideFolderArrayTemp);
      } else {
        toast("❌" + e.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const handleMouseLeave = () => {
    $(".tooltip-inner").css("display", "none");
  };

  useEffect(() => {
    getAssignedtaskList();
    handleMouseLeave();
    var itemOxygenRole = localStorage.getItem("itemOxygenRole");
    if (itemOxygenRole === "ROLE_CLIENT") {
      setClient(true);
    }
  }, []);

  return (
    <>
      <div className="sidebar-backdrop" />
      <div className="page-wrapper">
        <CommanComponents />
        <main className="page-content">
          <div className="container">
            <div className="row mb-2 justify-content-center gap-2 align-items-center">
              <div style={{ fontSize: "20px", fontWeight: "500" }}>
                <span>Modello completato</span>
              </div>
              <div className="row align-items-center justify-content-center gap-1 mb-2 flex-nowrap">
                <div className="col-auto align-items-center d-flex justify-content-center gap-1 d-xs-grid px-0">
                  <label htmlFor="exampleInputEmail1" className="mb-0">
                    Data di Inizio
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="."
                      inputFormat="dd/MM/yyyy"
                      value={addRequestData.startDate}
                      onChange={(e) =>
                        setAddRequestData({
                          ...addRequestData,
                          startDate: e,
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-auto align-items-center d-flex justify-content-center gap-1 d-xs-grid px-0">
                  <label htmlFor="exampleInputEmail1" className="mb-0">
                    Data di fine
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="."
                      inputFormat="dd/MM/yyyy"
                      value={addRequestData.endDate}
                      onChange={(e) =>
                        setAddRequestData({
                          ...addRequestData,
                          endDate: e,
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div className="pull-right px-0" id="margin">
                  <a
                    type="button"
                    className="btn btn-primary btn d-flex align-items-center justify-content-center"
                    onClick={() => getAssignedtaskList(0)}
                  >
                    <i className="fa fa-search mr-2"></i>Search
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div style={{ height: "100%", width: "100%" }}>
                {isClient ? (
                  <DataTable
                    columns={columns}
                    data={rows}
                    theme="solarized"
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    // expandableRows
                    // expandableRowsComponent={ExpandedComponent}
                  />
                ) : (
                  <DataTable
                    columns={columns}
                    data={rows}
                    theme="solarized"
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onRowClicked={(e) => handleData(e)}
                    // expandableRows
                    // expandableRowsComponent={ExpandedComponent}
                  />
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
      <Modal
        size="xl"
        show={openDetails}
        className={openDetails ? "is-active is-animate" : ""}
        onHide={handleDetailsClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assegna dettagli</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowX: "scroll", height: "553px" }}>
          <div id="pdf" class="resulted" style={{ padding: "1rem" }}>
            <div className="row">{sideFolderListHtml}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handlePDF}>
            Esporta in formato PDF
          </Button>
          <Button variant="secondary" onClick={() => handleDetailsClose()}>
            Chiudere
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="se-pre-con">
        <img src={loader} />
      </div>
    </>
  );
};
