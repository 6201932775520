import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../redux/index";
import {
  getDetails,
  deleteRecord,
  activeQR,
  getList,
  updateFileDetails,
  getListForUser,
} from "../../services/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Modal, Button, Form } from "react-bootstrap";

var itemOxygenRole = localStorage.getItem("itemOxygenRole");

export const FileCardComponent = (props) => {
  const history = useHistory();
  const [fileData, setFileData] = useState(null);
  const [fileSize, setFileSize] = useState("");
  const [open, setOpen] = useState(false);
  const [fileDetails, setFileDetails] = useState({
    fileName: "",
  });

  //Redux Start
  const state = useSelector((state) => state);

  const dispatch = useDispatch();

  const {
    addSideModelOpen,
    addFileFolderDetails,
    addFolderNavigationBar,
    addParentFolderId,
  } = bindActionCreators(actionCreator, dispatch);

  //Redux End

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0 || !bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleViewDetails = () => {
    getDetails(fileData.recordId)
      .then((res) => {
        localStorage.setItem("fileDetails", res.responsePacket.qrCodeUrl);
        localStorage.setItem("qrrecordId", res.responsePacket.recordId);
        addFileFolderDetails(res.responsePacket);
      })
      .catch((err) => {
        alert(err);
      });
    addSideModelOpen(true);

    /* activeQR(fileData.recordId).then((res) => {
            if (res.errorCode === 0) {
                addFileFolderDetails(res.responsePacket)
            }
        }).catch((err) => {
            toast(err,
                {
                    icon: '🙁',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        })
        addSideModelOpen(true) */
  };

  const handleFileDataName = (e) => {
    setFileDetails({
      ...fileDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleClose = () => setOpen(false);

  /* const handleRenameFile = () => {
        console.log(fileData.visibleFileName);
        if(localStorage.getItem("itemOxygenRole") === "ADMINISTRATION")
        {
            let data= {
                "clientId": fileData.recordId
              }
            setOpen(true)
            getListForUser(data).then((res) => {
                console.log(res);
                let fileRecordId = {
                    "fileName":res.responsePacket.parentFolderGetList[0].option
                }
                setFileDetails(fileRecordId)
                //setFileDetails(res.responsePacket.parentFolderGetList[0].value)
            })
        }
        else
        {
            let data= {
                "parentFolderId": fileData.recordId
              }
            setOpen(true)
            getList(data).then((res) => {
                let fileRecordId = {
                    "fileName":res.responsePacket.parentFolderGetList[0].option
                }
                setFileDetails(fileRecordId)
                //setFileDetails(res.responsePacket.parentFolderGetList[0].value)
            })
        }
        

    } */

  const handleRenameFile = () => {
    setOpen(true);
    let fileRecordId = {
      fileName: fileData.visibleFileName,
    };
    setFileDetails(fileRecordId);
  };

  const handleUpdateFileName = () => {
    let data = {
      recordId: fileData.recordId,
      visibleFileName: fileDetails.fileName,
      fileFolderType: fileData.fileFolderType,
    };
    updateFileDetails(data).then((res) => {
      if (res.errorCode == 0) {
        toast(res.message, {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        handleClose();
        window.location.reload("documents/" + fileData.recordId);
      } else {
        toast(res.message, {
          icon: "🙁",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    });
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Vuoi eliminare la cartella ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, conferma !!",
      cancelButtonText: "No !!",
    }).then((result) => {
      if (result.isConfirmed == true) {
        deleteRecord(fileData.recordId)
          .then((res) => {
            if (res.errorCode == 0) {
              toast(res.message, {
                icon: "👏",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            } else {
              toast(res.message, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            }
            // alert("Success")
            window.location.reload(true);
          })
          .catch((err) => {
            toast(err, {
              icon: "🙁",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          });
      }
    });
  };

  useEffect(() => {
    if (props.data != null) {
      setFileData(props.data);
      setFileSize(formatBytes(props.data.fileSizeInBits));
    }
  }, [props.data]);

  const createdAt = new Date(props.data.createdAt).toLocaleDateString();

  return (
    <div className="files-card">
      <div className="files-card__content">
        <div className="files-card__checkbox-wrapper">
          <label className="checkbox checkbox--circle">
            <input type="checkbox" defaultChecked="checked" />
            <span className="checkbox__marker">
              <span className="checkbox__marker-icon">
                <svg viewBox="0 0 21 18">
                  <path
                    className="border"
                    d="M16.3809 4.29875C16.1581 3.94957 15.6944 3.84715 15.3452 4.06998C14.9961 4.29281 14.8936 4.7565 15.1165 5.10568L16.3809 4.29875ZM12.9024 2.88868C13.2513 3.11197 13.7151 3.01016 13.9384 2.66128C14.1617 2.3124 14.0599 1.84857 13.711 1.62528L12.9024 2.88868ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM15.1165 5.10568C15.8341 6.23014 16.25 7.56535 16.25 9H17.75C17.75 7.27102 17.2478 5.65706 16.3809 4.29875L15.1165 5.10568ZM9 1.75C10.4381 1.75 11.7762 2.16793 12.9024 2.88868L13.711 1.62528C12.3507 0.754648 10.7331 0.25 9 0.25V1.75Z"
                  />
                  <path d="M18.0275 2.42896C18.3156 2.7323 18.3033 3.21181 18 3.49998L8.7036 12.0645C8.40574 12.3475 7.93663 12.3415 7.64613 12.051L5.22189 9.62673C4.92604 9.33088 4.92604 8.85121 5.22189 8.55536C5.51774 8.25951 5.99741 8.25951 6.29326 8.55536L8.19538 10.4575L16.9564 2.4015C17.2598 2.11333 17.7393 2.12563 18.0275 2.42896Z" />
                </svg>
              </span>
            </span>
          </label>
        </div>
        <div className="files-card__more">
          <button className="items-more__button">
            <svg className="icon-icon-dots">
              <use xlinkHref="#icon-dots" />
            </svg>
          </button>
          <div className="dropdown-items dropdown-items--right">
            <div className="dropdown-items__container">
              <ul className="dropdown-items__list">
                <li className="dropdown-items__item" onClick={handleRenameFile}>
                  <a className="dropdown-items__link">
                    <span className="dropdown-items__link-icon">
                      <svg className="icon-icon-duplicate">
                        <use xlinkHref="#icon-duplicate" />
                      </svg>
                    </span>
                    Rinominare
                  </a>
                </li>
                <li
                  className="dropdown-items__item"
                  onClick={handleViewDetails}
                >
                  <a className="dropdown-items__link">
                    <span className="dropdown-items__link-icon">
                      <svg className="icon-icon-detail">
                        <use xlinkHref="#icon-detail" />
                      </svg>
                    </span>
                    Visualizza dettagli
                  </a>
                </li>
                {/* {itemOxygenRole ==="ROLE_ADMIN" && 
                                } */}
                {/* <li className="dropdown-items__item">
                                    <a className="dropdown-items__link">
                                        <span className="dropdown-items__link-icon">
                                            <svg className="icon-icon-duplicate">
                                                <use xlinkHref="#icon-duplicate" />
                                            </svg>
                                        </span>
                                        Duplicate
                                    </a>
                                </li>
                                <li className="dropdown-items__item">
                                    <a className="dropdown-items__link">
                                        <span className="dropdown-items__link-icon">
                                            <svg className="icon-icon-archive">
                                                <use xlinkHref="#icon-archive" />
                                            </svg>
                                        </span>
                                        Archive
                                    </a>
                                </li> */}
                <li className="dropdown-items__item" onClick={handleDelete}>
                  <a className="dropdown-items__link">
                    <span className="dropdown-items__link-icon">
                      <svg className="icon-icon-trash">
                        <use xlinkHref="#icon-trash" />
                      </svg>
                    </span>
                    Elimina
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <a href={fileData != null ? fileData.fileUrl : "#"} target="_blank">
          <div className="files-card__icon files-card__icon--file">
            <svg className="icon-icon-file">
              <use xlinkHref="#icon-file" />
            </svg>
          </div>
          <h5 className="files-card__title">
            {fileData != null ? fileData.visibleFileName : ""}
          </h5>
          <div className="files-card__bottom">
            <div className="files-card__size">{fileSize}</div>
            <div className="files-card__date">
              <time dateTime="2019-07-12">{fileData && createdAt}</time>
            </div>
          </div>
        </a>
      </div>
      <Modal
        centered
        show={open}
        className={open ? "is-active is-animate" : ""}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Aggiorna nome file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <label htmlFor="exampleInputEmail1">Nome del file</label>
              <input
                className="form-control"
                name="fileName"
                value={fileDetails.fileName}
                onChange={(e) => handleFileDataName(e)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Chiudere
          </Button>
          <Button variant="primary" onClick={() => handleUpdateFileName()}>
            Salva
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

// LoaderComponent.propTypes = {
//     title: PropTypes.string.isRequired
// }

// export default LoaderComponent
