import React, { useState, useCallback, useEffect } from "react";
import { CommanComponents } from "../components/index";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../redux/index";

import {
  getList,
  getListForUser,
  addFolder,
  addFileFolderForUser,
  uploadFile,
  activeQR,
  getClientKeyValueList,
  getListClientWise,
} from "../services/index";
import { Modal, Button, Form } from "react-bootstrap";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FolderCardComponent,
  FileCardComponent,
  DropBox,
  ShowImage,
  FileRowComponent,
  FolderRowComponent,
} from "./Common/index";
import $ from "jquery";
import fileUnknown from "../assets/img/content/file-unknown.svg";
import filePdf from "../assets/img/content/file-pdf.svg";
import { DocumentsContent } from "./index";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import loader from "../assets/img/content/loader.svg";
import axios from "axios";
import { FILE_UPLOAD } from "../services/CONSTANTS";

var count = 10;

export const Documents = (props) => {
  const history = useHistory();

  //Redux Start
  const state = useSelector((state) => state);
  const [hospitalsKeyValue, setHospitalsKeyValue] = useState([]);
  const [hospitalValue, setHospitalValue] = useState(null);
  const [loaderData, setLoaderData] = useState(5);

  const dispatch = useDispatch();

  const {
    addSideModelOpen,
    addFileFolderDetails,
    addFolderNavigationBar,
    addFolderNavigationBarUuid,
    addParentFolderId,
  } = bindActionCreators(actionCreator, dispatch);

  //Redux End
  const [docComponent, setDocComponent] = useState([]);
  const [folderNavArray, setFolderNavArray] = useState([]);
  const [fileFolderRowHtml, setFileFolderRowHtml] = useState([]);
  const [parentFolderId, setParentFolderId] = useState("");
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [fileListHtml, setFileListHtml] = useState([]);
  const [folderListHtml, setFolderListHtml] = useState([]);
  const [sideFolderListHtml, setSideFolderListHtml] = useState([]);
  const [showUpload, setShowUpload] = useState(false);

  const [images, setImages] = useState([]);
  const [imagesAsFile, setImagesAsFile] = useState([]);
  const [folderData, setFolderData] = useState(null);

  const handleDeleteFile = (index) => {
    var tempArray = [...images];
    tempArray.splice(index, 1);
    setImages(tempArray);
    onDrop(tempArray);
  };

  const handleFileManagerClick = () => {
    addFolderNavigationBar("");
    addFolderNavigationBarUuid("");
    addParentFolderId("");
    setFolderNavArray([]);
  };

  const handleAddFiles12 = () => {
    const formData = new FormData();
    $(".custom-loader").css("display", "flex");
    const interval = setInterval(() => {
      setLoaderData((prev) => prev + 10);
      if (count == 60) {
        clearInterval(interval);
        // for (let i = 0; i < file.length; i++) {
        imagesAsFile.map((img) => {
          formData.append("file", img);
        });
        uploadFile(formData).then((res) => {
          if (res.errorCode == 0) {
            count = 100;
            handleCreateFileFolder("File", res.responsePacket);
          } else {
            $(".se-pre-con").css("display", "none");
            toast(res.message, {
              icon: "🙁",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          }
        });
      }
      count += 10;
    }, 1000);
  };

  const handleAddFiles = () => {
    const formData = new FormData();
    const auth = localStorage.getItem("itemOxygenAuth");

    const headersData = {
      "content-type": "application/json",
      Accept: "application/json",
      Authorization: auth != undefined ? "Basic " + auth : "",
    };
    imagesAsFile.map((img) => formData.append("file", img));
    $(".custom-loader").css("display", "flex");

    axios
      .post(FILE_UPLOAD(), formData, {
        headers: headersData,
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          setLoaderData(percentCompleted);
        },
      })
      .then((res) => {
        if (res.data.errorCode == 0) {
          handleCreateFileFolder("File", res.data.responsePacket);
        } else {
          $(".se-pre-con").css("display", "none");
          toast(res.data.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0 && acceptedFiles[0].id != null) {
      return acceptedFiles;
    } else {
      setImagesAsFile(acceptedFiles);
      acceptedFiles.map((file, index) => {
        const reader = new FileReader();

        reader.onload = function (e) {
          setImages((prevState) => [
            ...prevState,
            { id: index, src: e.target.result },
          ]);
        };

        reader.readAsDataURL(file);
        return file;
      });
    }
  }, []);

  // const goTo = (path) => {
  //     history.push(path || ROOT);
  // }

  const handleAddNewFile = () => {
    setShowUpload(true);
  };

  const getFileFolderList = () => {
    //Runs only on the first render
    var rId = localStorage.getItem("rId");
    if (rId != undefined && state.common.parentFolderId == "") {
      if (localStorage.getItem("itemOxygenRole") === "ADMINISTRATION") {
        var dict = {};
        if (localStorage.getItem("rId") === "") {
          var dict = {
            clientId: "",
          };
        } else {
          var dict = {
            clientId: localStorage.getItem("rId"),
          };
        }

        console.log(dict);
        //debugger;
        $(".se-pre-con").css("display", "block");
        getListForUser(dict)
          .then((res) => {
            if (res.errorCode == 0) {
              $(".se-pre-con").css("display", "none");
              setApiResponse(res.responsePacket);
              var folderRowTempArray = [];
              var fileRowTempArray = [];

              var folderArrayTemp = [];
              var sideFolderArrayTemp = [];
              var fileArrayTemp = [];
              sideFolderArrayTemp.push(
                <li
                  className="sidebar-panel__menu-item"
                  onClick={() => handleSideLiClick("allFolder", "", "")}
                >
                  <a
                    className="sidebar-panel__menu-link active"
                    id="allFolder"
                    href="#"
                  >
                    <svg className="icon-icon-folder text-grey-dark">
                      <use xlinkHref="#icon-folder" />
                    </svg>
                    <span className="sidebar-panel__menu-text">All</span>
                  </a>
                </li>
              );
              for (
                let i = 0;
                i < res.responsePacket.fileFolderRecordList.length;
                i++
              ) {
                if (
                  res.responsePacket.fileFolderRecordList[i].fileFolderType ==
                    "Folder" &&
                  res.responsePacket.fileFolderRecordList[i].parentFolderId ==
                    ""
                ) {
                  folderArrayTemp.push(
                    <FolderCardComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                  folderRowTempArray.push(
                    <FolderRowComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                  sideFolderArrayTemp.push(
                    <li
                      className="sidebar-panel__menu-item"
                      onClick={() =>
                        handleSideLiClick(
                          "f-" + i,
                          res.responsePacket.fileFolderRecordList[i]
                        )
                      }
                    >
                      <a
                        className="sidebar-panel__menu-link"
                        id={"f-" + i}
                        href="#"
                      >
                        <svg className="icon-icon-folder text-grey-dark">
                          <use xlinkHref="#icon-folder" />
                        </svg>
                        <span className="sidebar-panel__menu-text">
                          {
                            res.responsePacket.fileFolderRecordList[i]
                              .visibleFileName
                          }
                        </span>
                      </a>
                    </li>
                  );
                } else {
                  fileRowTempArray.push(
                    <FileRowComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                  fileArrayTemp.push(
                    <FileCardComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                }
              }
              const fileFolderRowTempArray =
                folderRowTempArray.concat(fileRowTempArray);
              setSideFolderListHtml(sideFolderArrayTemp);
              setFileListHtml(fileArrayTemp);
              setFolderListHtml(folderArrayTemp);
              setFileFolderRowHtml(fileFolderRowTempArray);
            } else {
              toast("⚠️" + res.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((err) => {
            toast("⚠️" + err, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      } else {
        var dict = {
          clientId: rId,
        };
        console.log(dict);
        //debugger;
        $(".se-pre-con").css("display", "block");
        getListClientWise(dict)
          .then((res) => {
            if (res.errorCode == 0) {
              $(".se-pre-con").css("display", "none");
              setApiResponse(res.responsePacket);
              var folderRowTempArray = [];
              var fileRowTempArray = [];

              var folderArrayTemp = [];
              var sideFolderArrayTemp = [];
              var fileArrayTemp = [];
              sideFolderArrayTemp.push(
                <li
                  className="sidebar-panel__menu-item"
                  onClick={() => handleSideLiClick("allFolder", "", "")}
                >
                  <a
                    className="sidebar-panel__menu-link active"
                    id="allFolder"
                    href="#"
                  >
                    <svg className="icon-icon-folder text-grey-dark">
                      <use xlinkHref="#icon-folder" />
                    </svg>
                    <span className="sidebar-panel__menu-text">All</span>
                  </a>
                </li>
              );
              for (
                let i = 0;
                i < res.responsePacket.fileFolderRecordList.length;
                i++
              ) {
                if (
                  res.responsePacket.fileFolderRecordList[i].fileFolderType ==
                    "Folder" &&
                  res.responsePacket.fileFolderRecordList[i].parentFolderId ==
                    ""
                ) {
                  folderArrayTemp.push(
                    <FolderCardComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                  folderRowTempArray.push(
                    <FolderRowComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                  sideFolderArrayTemp.push(
                    <li
                      className="sidebar-panel__menu-item"
                      onClick={() =>
                        handleSideLiClick(
                          "f-" + i,
                          res.responsePacket.fileFolderRecordList[i]
                        )
                      }
                    >
                      <a
                        className="sidebar-panel__menu-link"
                        id={"f-" + i}
                        href="#"
                      >
                        <svg className="icon-icon-folder text-grey-dark">
                          <use xlinkHref="#icon-folder" />
                        </svg>
                        <span className="sidebar-panel__menu-text">
                          {
                            res.responsePacket.fileFolderRecordList[i]
                              .visibleFileName
                          }
                        </span>
                      </a>
                    </li>
                  );
                } else {
                  fileRowTempArray.push(
                    <FileRowComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                  fileArrayTemp.push(
                    <FileCardComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                }
              }
              const fileFolderRowTempArray =
                folderRowTempArray.concat(fileRowTempArray);
              setSideFolderListHtml(sideFolderArrayTemp);
              setFileListHtml(fileArrayTemp);
              setFolderListHtml(folderArrayTemp);
              setFileFolderRowHtml(fileFolderRowTempArray);
            } else {
              toast("⚠️" + res.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((err) => {
            toast("⚠️" + err, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    } else {
      var rIdItemOxygenRole = localStorage.getItem("itemOxygenRole");
      if (rIdItemOxygenRole === "ADMINISTRATION") {
        if (
          localStorage.getItem("rId") === "" ||
          localStorage.getItem("testData") === "test"
        ) {
          var dict = {
            clientId: "",
          };
        } else {
          var dict = {
            clientId: localStorage.getItem("client_recordId"),
          };
        }
        $(".se-pre-con").css("display", "block");
        getListForUser(dict)
          .then((res) => {
            console.log(res);
            if (res.errorCode == 0) {
              $(".se-pre-con").css("display", "none");
              setApiResponse(res.responsePacket);
              var folderRowTempArray = [];
              var fileRowTempArray = [];
              var folderArrayTemp = [];
              var sideFolderArrayTemp = [];
              var fileArrayTemp = [];
              sideFolderArrayTemp.push(
                <li
                  className="sidebar-panel__menu-item"
                  onClick={() => handleSideLiClick("allFolder", "", "")}
                >
                  <a
                    className="sidebar-panel__menu-link active"
                    id="allFolder"
                    href="#"
                  >
                    <svg className="icon-icon-folder text-grey-dark">
                      <use xlinkHref="#icon-folder" />
                    </svg>
                    <span className="sidebar-panel__menu-text">All</span>
                  </a>
                </li>
              );
              for (
                let i = 0;
                i < res.responsePacket.fileFolderRecordList.length;
                i++
              ) {
                if (
                  res.responsePacket.fileFolderRecordList[i].fileFolderType ==
                    "Folder" &&
                  res.responsePacket.fileFolderRecordList[i].parentFolderId ==
                    ""
                ) {
                  console.log(
                    res.responsePacket.fileFolderRecordList[i].fileFolderType
                  );
                  folderArrayTemp.push(
                    <FolderCardComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                  folderRowTempArray.push(
                    <FolderRowComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                  sideFolderArrayTemp.push(
                    <li
                      className="sidebar-panel__menu-item"
                      onClick={() =>
                        handleSideLiClick(
                          "f-" + i,
                          res.responsePacket.fileFolderRecordList[i]
                        )
                      }
                    >
                      <a
                        className="sidebar-panel__menu-link"
                        id={"f-" + i}
                        href="#"
                      >
                        <svg className="icon-icon-folder text-grey-dark">
                          <use xlinkHref="#icon-folder" />
                        </svg>
                        <span className="sidebar-panel__menu-text">
                          {
                            res.responsePacket.fileFolderRecordList[i]
                              .visibleFileName
                          }
                        </span>
                      </a>
                    </li>
                  );
                } else {
                  fileRowTempArray.push(
                    <FileRowComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                  fileArrayTemp.push(
                    <FileCardComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                }
              }
              const fileFolderRowTempArray =
                folderRowTempArray.concat(fileRowTempArray);
              setSideFolderListHtml(sideFolderArrayTemp);
              setFileListHtml(fileArrayTemp);
              setFolderListHtml(folderArrayTemp);
              setFileFolderRowHtml(fileFolderRowTempArray);
            } else {
              toast("⚠️" + res.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((err) => {
            toast("⚠️" + err, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      } else {
        var dict = {
          parentFolderId: "",
          requestedPage: 0,
          requestedPageSize: 1000000,
          searchKey: "",
        };
        $(".se-pre-con").css("display", "block");
        getList(dict)
          .then((res) => {
            if (res.errorCode == 0) {
              $(".se-pre-con").css("display", "none");
              setApiResponse(res.responsePacket);
              var folderRowTempArray = [];
              var fileRowTempArray = [];

              var folderArrayTemp = [];
              var sideFolderArrayTemp = [];
              var fileArrayTemp = [];
              sideFolderArrayTemp.push(
                <li
                  className="sidebar-panel__menu-item"
                  onClick={() => handleSideLiClick("allFolder", "", "")}
                >
                  <a
                    className="sidebar-panel__menu-link active"
                    id="allFolder"
                    href="#"
                  >
                    <svg className="icon-icon-folder text-grey-dark">
                      <use xlinkHref="#icon-folder" />
                    </svg>
                    <span className="sidebar-panel__menu-text">All</span>
                  </a>
                </li>
              );
              for (
                let i = 0;
                i < res.responsePacket.fileFolderRecordList.length;
                i++
              ) {
                if (
                  res.responsePacket.fileFolderRecordList[i].fileFolderType ==
                    "Folder" &&
                  res.responsePacket.fileFolderRecordList[i].parentFolderId ==
                    null
                ) {
                  folderArrayTemp.push(
                    <FolderCardComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                  folderRowTempArray.push(
                    <FolderRowComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                  sideFolderArrayTemp.push(
                    <li
                      className="sidebar-panel__menu-item"
                      onClick={() =>
                        handleSideLiClick(
                          "f-" + i,
                          res.responsePacket.fileFolderRecordList[i]
                        )
                      }
                    >
                      <a
                        className="sidebar-panel__menu-link"
                        id={"f-" + i}
                        href="#"
                      >
                        <svg className="icon-icon-folder text-grey-dark">
                          <use xlinkHref="#icon-folder" />
                        </svg>
                        <span className="sidebar-panel__menu-text">
                          {
                            res.responsePacket.fileFolderRecordList[i]
                              .visibleFileName
                          }
                        </span>
                      </a>
                    </li>
                  );
                } else {
                  fileRowTempArray.push(
                    <FileRowComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                  fileArrayTemp.push(
                    <FileCardComponent
                      data={res.responsePacket.fileFolderRecordList[i]}
                    />
                  );
                }
              }
              const fileFolderRowTempArray =
                folderRowTempArray.concat(fileRowTempArray);
              setSideFolderListHtml(sideFolderArrayTemp);
              setFileListHtml(fileArrayTemp);
              setFolderListHtml(folderArrayTemp);
              setFileFolderRowHtml(fileFolderRowTempArray);
            }
          })
          .catch((err) => {
            toast("⚠️" + err, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    }
  };

  const sideButton = () => {
    window.sideButton();
  };

  const handleCreateFileFolder = (type, responsePacket) => {
    $(".custom-loader").css("display", "none");

    var rId = localStorage.getItem("rId");
    if (localStorage.getItem("itemOxygenRole") === "ADMINISTRATION") {
      var rId = localStorage.getItem("rId");
      var client_recordId = localStorage.getItem("client_recordId");
      if (rId != undefined) {
        var recordIdForList = rId;
      } else {
        var recordIdForList = "";
      }

      if (recordIdForList === "") {
        var dictArray = [];
        if (type == "Folder") {
          var dict = {
            parentFolderId: state.common.parentFolderId,
            fileFolderType: "Folder",
            visibleFileName: folderName,
            associateClientId: client_recordId,
            fileBaseId: "",
          };
          dictArray.push(dict);
        } else {
          responsePacket.map((res, i) => {
            var dict = {
              parentFolderId: state.common.parentFolderId,
              fileFolderType: "File",
              visibleFileName: "",
              fileBaseId: res.fileBaseId,
              associateClientId: client_recordId,
            };
            dictArray.push(dict);
          });
        }
      } else {
        var dictArray = [];
        if (type == "Folder") {
          var dict = {
            parentFolderId: state.common.parentFolderId,
            fileFolderType: "Folder",
            visibleFileName: folderName,
            associateClientId: rId,
            fileBaseId: "",
          };
          dictArray.push(dict);
        } else {
          responsePacket.map((res, i) => {
            var dict = {
              parentFolderId: state.common.parentFolderId,
              fileFolderType: "File",
              visibleFileName: "",
              fileBaseId: res.fileBaseId,
              associateClientId: rId,
            };
            dictArray.push(dict);
          });
        }
      }
    } else {
      var dictArray = [];
      if (type == "Folder") {
        var dict = {
          parentFolderId: state.common.parentFolderId,
          fileFolderType: "Folder",
          visibleFileName: folderName,
          associateClientId: rId,
          fileBaseId: "",
        };
        dictArray.push(dict);
      } else {
        responsePacket.map((res, i) => {
          var dict = {
            parentFolderId: state.common.parentFolderId,
            fileFolderType: "File",
            visibleFileName: "",
            fileBaseId: res.fileBaseId,
            associateClientId: rId,
          };
          dictArray.push(dict);
        });
      }
    }

    if (localStorage.getItem("itemOxygenRole") === "ADMINISTRATION") {
      $(".se-pre-con").css("display", "block");
      addFileFolderForUser(dictArray).then((res) => {
        if (res.errorCode == 0) {
          $(".se-pre-con").css("display", "none");
          setShowAddFolder(false);
          setShowUpload(false);
          setImagesAsFile([]);
          setFolderName("");
          setFolderListHtml([]);
          toast(res.message, {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          getFileFolderList();
          //history.push("/documents")
          var tempArray = [<DocumentsContent date={new Date()} />];
          setDocComponent(tempArray);
          window.location.reload(true);
        } else {
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      });
    } else {
      $(".se-pre-con").css("display", "block");
      addFolder(dictArray).then((res) => {
        if (res.errorCode == 0) {
          $(".se-pre-con").css("display", "none");
          setShowAddFolder(false);
          setShowUpload(false);
          setImagesAsFile([]);
          setFolderName("");
          setFolderListHtml([]);
          toast(res.message, {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          getFileFolderList();
          //history.push("/documents")
          var tempArray = [<DocumentsContent date={new Date()} />];
          setDocComponent(tempArray);
          window.location.reload(true);
        } else {
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      });
    }
  };

  const handleSideLiClick = (id, folderName) => {
    console.log(folderName);
    if (folderName == "") {
      addFolderNavigationBar("");
      addFolderNavigationBarUuid("");
      addParentFolderId("");
      setFolderNavArray([]);
    } else {
      var previous = state.common.folderNav;
      history.push("/documents/" + folderName.recordId);
      addFolderNavigationBar(previous + "-" + folderName.visibleFileName);
      var previousUuid = state.common.folderNavUuid;
      addFolderNavigationBarUuid(previousUuid + ":" + folderName.recordId);
      addParentFolderId(folderName.recordId);
    }
    // alert(uuid + " " + folderName)
    // $(".sidebar-panel__menu-link").removeClass("active")
    // if ($("#" + id).hasClass("active")) {
    //     $("#" + id).removeClass("active")
    // } else {
    //     $("#" + id).addClass("active")
    // }
  };

  const handleGoToFolder = (uuid) => {
    // alert(uuid)
    var folderNameArray = state.common.folderNav.split("-");
    var folderUuidArray = state.common.folderNavUuid.split(":");
    var indexOfClickFolderUuid = -1;
    for (let i = 1; i < folderUuidArray.length; i++) {
      if (folderUuidArray[i] == uuid) {
        indexOfClickFolderUuid = i;
        break;
      }
    }
    var tempFolderNameArray = folderNameArray.slice();
    var tempFolderUuidArray = folderUuidArray.slice();
    tempFolderNameArray.length = indexOfClickFolderUuid + 1;
    tempFolderUuidArray.length = indexOfClickFolderUuid + 1;
    if (tempFolderNameArray.length != 0 || tempFolderUuidArray.length != 0) {
      var tempFolderNav = "-";
      for (let j = 0; j < tempFolderNameArray.length; j++) {
        if (j != 0) {
          tempFolderNav += "-" + tempFolderNameArray[j];
        } else {
          tempFolderNav += tempFolderNameArray[j];
        }
      }
      var tempFolderNavUuid = ":";
      for (let k = 0; k < tempFolderUuidArray.length; k++) {
        if (k != 0) {
          tempFolderNavUuid += ":" + tempFolderUuidArray[k];
        } else {
          tempFolderNavUuid += tempFolderUuidArray[k];
        }
      }
      addFolderNavigationBar(tempFolderNav);
      addFolderNavigationBarUuid(tempFolderNavUuid);
      addParentFolderId(uuid);
    }
  };

  useEffect(() => {
    getFileFolderList();

    var folderNameArray = state.common.folderNav.split("-");
    var folderUuidArray = state.common.folderNavUuid.split(":");
    if (folderNameArray.length > 1 || folderUuidArray.length > 1) {
      var tempFolderNav = [];
      for (let i = 1; i < folderNameArray.length; i++) {
        tempFolderNav.push(
          <li className="breadcrumbs__item">
            <a
              className="breadcrumbs__link cursor"
              onClick={() => handleGoToFolder(folderUuidArray[i])}
            >
              <span>{folderNameArray[i]}</span>
              {i == folderNameArray.length - 1 ? (
                ""
              ) : (
                <>
                  <svg className="icon-icon-keyboard-right breadcrumbs__arrow">
                    <use xlinkHref="#icon-keyboard-right" />
                  </svg>
                </>
              )}
            </a>
          </li>
        );
      }
      setFolderNavArray(tempFolderNav);
    }
    var tempArray = [<DocumentsContent />];
    setDocComponent(tempArray);

    if (props.data != null) {
      setFolderData(props.data);
    }
  }, [state.common.folderNav]);

  return (
    <>
      <Modal
        show={showAddFolder}
        className={showAddFolder ? "is-active is-animate" : ""}
        onHide={() => setShowAddFolder(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Nuova cartella di lavoro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="form-group">
              <label for="exampleInputEmail1">Nome cartella di lavoro</label>
              <input
                className="form-control"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                placeholder="Nome cartella di lavoro"
              />
            </div>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn sm" onClick={() => setShowAddFolder(false)}>
            Cancella
          </button>
          <button
            className="btn sm"
            onClick={() => handleCreateFileFolder("Folder", [])}
          >
            Creare
          </button>
        </Modal.Footer>
      </Modal>
      <div>
        <div className="sidebar-backdrop" />
        <div className="page-wrapper">
          <CommanComponents />
          {/* <div className="subheader d-xl-none">
                        <div className="container pl-0">
                            <div className="row align-items-center no-gutters">
                                <div className="col-auto">
                                    <button className="toggle-sidebar toggle-sidebar--secondary js-toggle-sidebar-panel" type="button" data-toggle="#sidebarPanel">
                                        <svg className="icon-icon-menu">
                                            <use xlinkHref="#icon-menu" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <h2 className="subheader__title">Files <span className="text-grey">(50)</span></h2>
                                </div>
                            </div>
                        </div>
                    </div> */}
          <main className="page-content">
            <div className="file-manager">
              <aside
                className="sidebar-panel scrollbar-thin"
                id="sidebarPanel"
                data-simplebar="data-simplebar"
              >
                <div className="sidebar-panel__top">
                  <button
                    className="button button--primary"
                    onClick={handleAddNewFile}
                  >
                    <span className="button__icon button__icon--left">
                      <svg className="icon-icon-plus">
                        <use xlinkHref="#icon-plus" />
                      </svg>
                    </span>
                    <span className="button__text">Carica un file</span>
                  </button>
                </div>
                <div className="sidebar-panel__content">
                  <div className="sidebar-panel__nav">
                    <h3 className="sidebar-panel__nav-title">
                      Cartelle di lavoro
                    </h3>
                    <ul className="sidebar-panel__menu sidebar-panel__menu--colors">
                      {sideFolderListHtml}
                      {/* <li className="sidebar-panel__menu-item" onClick={() => handleSideLiClick("all")}>
                                                <a className="sidebar-panel__menu-link active" id="all" href="#">
                                                    <svg className="icon-icon-folder text-grey-dark">
                                                        <use xlinkHref="#icon-folder" />
                                                    </svg>
                                                    <span className="sidebar-panel__menu-text">All</span>
                                                </a>
                                            </li>
                                            <li className="sidebar-panel__menu-item">
                                                <a className="sidebar-panel__menu-link" href="#">
                                                    <svg className="icon-icon-folder text-red">
                                                        <use xlinkHref="#icon-folder" />
                                                    </svg>
                                                    <span className="sidebar-panel__menu-text">Images</span>
                                                </a>
                                            </li>
                                            <li className="sidebar-panel__menu-item">
                                                <a className="sidebar-panel__menu-link" href="#">
                                                    <svg className="icon-icon-folder text-teal">
                                                        <use xlinkHref="#icon-folder" />
                                                    </svg>
                                                    <span className="sidebar-panel__menu-text">Documents</span>
                                                </a>
                                            </li>
                                            <li className="sidebar-panel__menu-item">
                                                <a className="sidebar-panel__menu-link" href="#">
                                                    <svg className="icon-icon-folder text-green">
                                                        <use xlinkHref="#icon-folder" />
                                                    </svg>
                                                    <span className="sidebar-panel__menu-text">Projects</span>
                                                </a>
                                            </li>
                                            <li className="sidebar-panel__menu-item">
                                                <a className="sidebar-panel__menu-link" href="#">
                                                    <svg className="icon-icon-folder text-orange">
                                                        <use xlinkHref="#icon-folder" />
                                                    </svg>
                                                    <span className="sidebar-panel__menu-text">Design</span>
                                                </a>
                                            </li>
                                            <li className="sidebar-panel__menu-item">
                                                <a className="sidebar-panel__menu-link" href="#">
                                                    <svg className="icon-icon-folder text-grey-light">
                                                        <use xlinkHref="#icon-folder" />
                                                    </svg>
                                                    <span className="sidebar-panel__menu-text">Other</span>
                                                </a>
                                            </li> */}
                    </ul>
                  </div>
                </div>
                {/* <div className="sidebar-panel__bottom sidebar-panel__section">
                                    <div className="media-progress">
                                        <h5 className="media-progress__title"><span className="text-clamp">Storage</span></h5>
                                        <div className="media-progress__percentage">30 %</div>
                                        <div className="media-progress__progressbar">
                                            <div className="media-progress__bar" style={{ width: '30%' }} />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button className="button button--secondary" type="button"><span className="button__text">Upgrade</span></button>
                                    </div>
                                </div> */}
              </aside>
              <div className="file-manager__content">
                <div className="container">
                  {/* <div className="page-header">
                                        <h1 className="page-header__title">Files <span className="text-grey">(50)</span></h1>
                                    </div> */}
                  <div className="page-tools">
                    <div className="page-tools__breadcrumbs">
                      <div className="breadcrumbs">
                        <div className="breadcrumbs__container">
                          <ol className="breadcrumbs__list">
                            <li className="breadcrumbs__item">
                              <a className="breadcrumbs__link" href>
                                <svg className="icon-icon-home breadcrumbs__icon">
                                  <use xlinkHref="#icon-home" />
                                </svg>
                                <svg className="icon-icon-keyboard-right breadcrumbs__arrow">
                                  <use xlinkHref="#icon-keyboard-right" />
                                </svg>
                              </a>
                            </li>
                            <li className="breadcrumbs__item">
                              <a className="breadcrumbs__link" href="#">
                                <span
                                  className="cursor"
                                  onClick={handleFileManagerClick}
                                >
                                  Gestione file
                                </span>
                                {/* {state.common.folderNav != "" ? <svg className="icon-icon-keyboard-right breadcrumbs__arrow">
                                                                    <use xlinkHref="#icon-keyboard-right" />
                                                                </svg> : ""} */}
                              </a>
                            </li>
                            {folderNavArray}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="page-tools__right">
                      <ul className="page-tools__right-row nav" role="tablist">
                        <li className="page-tools__right-item">
                          <a
                            className="button-icon"
                            href="#tab-grid"
                            data-toggle="tab"
                          >
                            <span className="button-icon__icon">
                              <svg className="icon-icon-grid">
                                <use xlinkHref="#icon-grid" />
                              </svg>
                            </span>
                          </a>
                        </li>
                        <li className="page-tools__right-item">
                          <a
                            className="button-icon active"
                            href="#tab-list"
                            data-toggle="tab"
                          >
                            <span className="button-icon__icon">
                              <svg className="icon-icon-list">
                                <use xlinkHref="#icon-list" />
                              </svg>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="toolbox">
                    <div
                      className="toolbox__row row gutter-bottom-xs"
                      style={{ justifyContent: "right" }}
                    >
                      {/* <div className="toolbox__left col-12 col-lg">
                                                <div className="toolbox__left-row row row--xs gutter-bottom-xs">
                                                    <div className="form-group form-group--inline col col-sm-auto">
                                                        <div className="input-group input-group--white input-group--prepend input-group--append">
                                                            <div className="input-group__prepend">
                                                                <svg className="icon-icon-calendar">
                                                                    <use xlinkHref="#icon-calendar" />
                                                                </svg>
                                                            </div>
                                                            <input className="input input--select js-datepicker"  defaultValue="01.12.18 / 07.12.18" />
                                                            <span className="input-group__arrow">
                                                                <svg className="icon-icon-keyboard-down">
                                                                    <use xlinkHref="#icon-keyboard-down" />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-group--inline col-12 col-sm-auto">
                                                        <div className="toolbox__status input-group input-group--white input-group--append">
                                                            <input className="input input--select"  defaultValue="All type" data-toggle="dropdown" readOnly />
                                                            <span className="input-group__arrow">
                                                                <svg className="icon-icon-keyboard-down">
                                                                    <use xlinkHref="#icon-keyboard-down" />
                                                                </svg>
                                                            </span>
                                                            <div className="dropdown-menu dropdown-menu--right dropdown-menu--fluid js-dropdown-select">
                                                                <a className="dropdown-menu__item active" href="#" tabIndex={0} data-value="All type">
                                                                    <span className="dropdown-menu__icon"><img src={fileUnknown} alt="#" /></span>All type
                                                                </a>
                                                                <a className="dropdown-menu__item" href="#" tabIndex={0} data-value="DOC">
                                                                    <span className="dropdown-menu__icon"><img src="img/content/file-doc.svg" alt="#" /></span>DOC
                                                                </a>
                                                                <a className="dropdown-menu__item" href="#" tabIndex={0} data-value="XLS">
                                                                    <span className="dropdown-menu__icon"><img src="img/content/file-xls.svg" alt="#" /></span>XLS
                                                                </a>
                                                                <a className="dropdown-menu__item" href="#" tabIndex={0} data-value="PDF">
                                                                    <span className="dropdown-menu__icon"><img src={filePdf} alt="#" /></span>PDF
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                      <div className="toolbox__right col-12 col-lg-auto">
                        <div className="toolbox__right-row row row--xs flex-nowrap">
                          {/* <div className="col col-lg-auto">
                                                        <form className="toolbox__search" method="GET">
                                                            <div className="input-group input-group--white input-group--prepend">
                                                                <div className="input-group__prepend">
                                                                    <svg className="icon-icon-search">
                                                                        <use xlinkHref="#icon-search" />
                                                                    </svg>
                                                                </div>
                                                                <input className="input"  placeholder="Search Files" />
                                                            </div>
                                                        </form>
                                                    </div> */}
                          <div className="col-auto ml-auto">
                            <button
                              className="button-add button-add--blue"
                              onClick={() => setShowAddFolder(true)}
                            >
                              <span className="button-add__icon">
                                <svg className="icon-icon-plus">
                                  <use xlinkHref="#icon-plus" />
                                </svg>
                              </span>
                              <span className="button-add__text" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {docComponent}
                </div>
              </div>
              <div className="backdrop-sidebar-panel" onClick={sideButton} />
            </div>
          </main>
        </div>

        <div
          className={
            showUpload
              ? "inbox-add modal modal-compact scrollbar-thin is-active is-animate"
              : "inbox-add modal modal-compact scrollbar-thin"
          }
          id="addFiles"
          data-simplebar
        >
          <div className="modal__overlay" data-dismiss="modal" />
          <div className="modal__wrap">
            <div className="modal__window">
              <div className="modal__content">
                <button
                  className="modal__close"
                  onClick={() => setShowUpload(false)}
                >
                  <svg className="icon-icon-cross">
                    <use xlinkHref="#icon-cross" />
                  </svg>
                </button>
                <div className="modal__header">
                  <div className="modal__container">
                    <h2 className="modal__title">Carica un file</h2>
                  </div>
                </div>
                <div className="modal__body">
                  <div className="modal__container">
                    <form method="POST">
                      <div className="row">
                        <div className="col-12 form-group form-group--lg mb-0">
                          <div className="files-upload">
                            {/* <div className="files-upload__drop">
                                                            <input className="files-upload__input" type="file" name="file_upload" multiple="multiple" />
                                                            <div className="files-upload__drop-text">
                                                                <svg className="icon-icon-upload">
                                                                    <use xlinkHref="#icon-upload" />
                                                                </svg>
                                                                <span>Drag and Drop or </span>  <span className="image-upload__drop-action text-blue">Browse</span>  <span>to upload</span>
                                                            </div>
                                                        </div> */}
                            <DropBox
                              onDrop={onDrop}
                              parentCallBack={handleDeleteFile}
                            />
                            {/* <ShowImage images={images} /> */}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal__footer">
                  <div className="modal__container">
                    <div className="modal__footer-buttons">
                      <div className="modal__footer-button">
                        <button
                          className="button button--secondary button--block"
                          onClick={() => setShowUpload(false)}
                          data-dismiss="modal"
                        >
                          <span className="button__text">Cancella</span>
                        </button>
                      </div>
                      <div className="modal__footer-button">
                        <button className="button button--primary button--block">
                          <span className="button__icon button__icon--left">
                            <svg className="icon-icon-plus">
                              <use xlinkHref="#icon-plus" />
                            </svg>
                          </span>
                          <span
                            className="button__text"
                            onClick={handleAddFiles}
                          >
                            Aggiungere i file
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-loader" style={{ display: "none" }}>
        <div className="" style={{ width: "50%", margin: "0 auto" }}>
          <div id="myProgress" style={{ width: "100%" }}>
            <div
              id="myBar"
              style={{ width: loaderData + "%" }}
              dangerouslySetInnerHTML={{ __html: loaderData + "%" }}
            ></div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
