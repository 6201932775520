// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from 'axios'
import { USER_OPERATOR, ASSIGN_TASK, UPDATE_TASK } from "./CONSTANTS";

const auth = localStorage.getItem('itemOxygenAuth')

const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': auth != undefined ? "Basic " + auth : ''
    // 'Authorization': url.Auth_Token
}


/**
 * Function to Get User Operators List.
 */
export const getOperatorList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_OPERATOR() + "list", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

/**
 * Function to Add User Operator.
 */
export const addOperator = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_OPERATOR() + "add", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
/**
 * Function to active User Operator.
 */
export const activeOperator = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(USER_OPERATOR() + "active/" + uuid, {}, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
/**
 * Function to In-active User Operator.
 */
export const inActiveOperator = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(USER_OPERATOR() + "inactive/" + uuid, {}, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getOperatorKeyValueList = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_OPERATOR() + "getOperatorListInKeyValueByHospitalId/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getOpretorListByHospital axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const assignTask = (uuid, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(ASSIGN_TASK() + "assignTask/" + uuid, data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in assignTask axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateTask = (uuid, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(UPDATE_TASK() + "updateTaskWorkOrder/" + uuid, data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in assignTask axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deleteOperator = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(USER_OPERATOR() + "delete/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deleteOperator axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateOperator = (uuid, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(USER_OPERATOR() + "update/" + uuid, data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateOperator axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getOperatorDetails = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_OPERATOR() + "get/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getOperatorDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};