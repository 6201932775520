import React, { useState, useCallback, useEffect } from 'react'
import { CommanComponents } from "../components/index"
import { useSelector } from 'react-redux'

import { getList, addFolder, getListForUser, getFileFolderListForUser, uploadFile, getListClientWise,addFileFolderForUser } from "../services/index"
import { Modal, Button, Form } from 'react-bootstrap';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FolderCardComponent, FileCardComponent, DropBox, ShowImage, FileRowComponent, FolderRowComponent } from "./Common/index";
import $ from "jquery"
import fileUnknown from "../assets/img/content/file-unknown.svg"
import filePdf from "../assets/img/content/file-pdf.svg"
import { amET } from '@mui/material/locale';
import loader from "../assets/img/content/loader.svg"
import { useParams } from 'react-router-dom';

var count=10;

export const DocumentsContent = props => {

    //Redux below Start
    const state = useSelector((state) => state);
    const [folderNavArray, setFolderNavArray] = useState([])
    const [fileFolderRowHtml, setFileFolderRowHtml] = useState([])
    const [loaderData, setLoaderData] = useState(5)


    const [showAddFolder, setShowAddFolder] = useState(false);
    const [folderName, setFolderName] = useState('')
    const [apiResponse, setApiResponse] = useState(null)
    const [fileListHtml, setFileListHtml] = useState([])
    const [folderListHtml, setFolderListHtml] = useState([])
    const [sideFolderListHtml, setSideFolderListHtml] = useState([])
    const [showUpload, setShowUpload] = useState(false)
    const { id } = useParams()

    const [images, setImages] = useState([]);
    const [imagesAsFile, setImagesAsFile] = useState([]);

    const handleDeleteFile = (index) => {
        var tempArray = [...images]
        tempArray.splice(index, 1)
        setImages(tempArray)
        onDrop(tempArray)
    }


    const handleAddFiles = () => {
        const formData = new FormData();
        console.log(imagesAsFile)
        // for (let i = 0; i < file.length; i++) {
        $('.custom-loader').css('display', 'flex');
        const interval = setInterval(() => {
            setLoaderData(prev => prev + 10)
            if (count == 60) {
                clearInterval(interval);
                // for (let i = 0; i < file.length; i++) {
                imagesAsFile.map((img) => {
                    formData.append('file', img);
                })
                //$('.se-pre-con').css('display', 'block');
                uploadFile(formData).then((res) => {
                    if (res.errorCode == 0) {
                        $('.se-pre-con').css('display', 'none');
                        handleCreateFileFolder('File', res.responsePacket)
                    } else {
                        $('.se-pre-con').css('display', 'none');
                        toast(res.message,
                            {
                                icon: '🙁',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                            }
                        );
                    }
                })
            }
            count+=10; 
        },1000)
                // }
            }

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0 && acceptedFiles[0].id != null) {
            return acceptedFiles;
        } else {
            setImagesAsFile(acceptedFiles)
            acceptedFiles.map((file, index) => {

                const reader = new FileReader();

                reader.onload = function (e) {
                    setImages((prevState) => [
                        ...prevState,
                        { id: index, src: e.target.result },
                    ]);
                };

                reader.readAsDataURL(file);
                return file;
            });
        }
    }, []);

    // const goTo = (path) => {
    //     history.push(path || ROOT);
    // }

    const handleAddNewFile = () => {
        setShowUpload(true)
    }

    const getFileFolderList = () => {
        var rId = localStorage.getItem("rId")
        if (rId != undefined && state.common.parentFolderId == '') 
        {
            if(localStorage.getItem("itemOxygenRole") === "ADMINISTRATION")
            {
                var dict={}
                if(localStorage.getItem("rId") === '')
                {
                    var dict={
                        clientId : ""
                    }
                }
                else{
                    var dict={
                        clientId : localStorage.getItem("rId")
                    }
                }
                $('.se-pre-con').css('display', 'block');
                getListForUser(dict).then((res) => {
                    if (res.errorCode == 0) {
                        $('.se-pre-con').css('display', 'none');
                        setApiResponse(res.responsePacket)
                        var folderRowTempArray = []
                        var fileRowTempArray = []
    
                        var folderArrayTemp = [];
                        var sideFolderArrayTemp = [];
                        var fileArrayTemp = [];
                        sideFolderArrayTemp.push(<li className="sidebar-panel__menu-item" onClick={() => handleSideLiClick("allFolder", "", "")}>
                            <a className="sidebar-panel__menu-link active" id="allFolder" href="#">
                                <svg className="icon-icon-folder text-grey-dark">
                                    <use xlinkHref="#icon-folder" />
                                </svg>
                                <span className="sidebar-panel__menu-text">All</span>
                            </a>
                        </li>)
                        for (let i = 0; i < res.responsePacket.fileFolderRecordList.length; i++) {
                            if (res.responsePacket.fileFolderRecordList[i].fileFolderType == 'Folder' && res.responsePacket.fileFolderRecordList[i].parentFolderId == '') {
                                
                                folderArrayTemp.push(<FolderCardComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                folderRowTempArray.push(<FolderRowComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                sideFolderArrayTemp.push(<li className="sidebar-panel__menu-item PK" onClick={() => handleSideLiClick("f-" + i, res.responsePacket.fileFolderRecordList[i])}>
                                    <a className="sidebar-panel__menu-link" id={"f-" + i} href="#">
                                        <svg className="icon-icon-folder text-grey-dark">
                                            <use xlinkHref="#icon-folder" />
                                        </svg>
                                        <span className="sidebar-panel__menu-text">{res.responsePacket.fileFolderRecordList[i].visibleFileName}</span>
                                    </a>
                                </li>)
                            } else {
                                
                                if (res.responsePacket.fileFolderRecordList[i].parentFolderId == '') {
                                    fileRowTempArray.push(<FileRowComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                    fileArrayTemp.push(<FileCardComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                }
    
                            }
                        }
                        const fileFolderRowTempArray = folderRowTempArray.concat(fileRowTempArray);
                        setSideFolderListHtml(sideFolderArrayTemp)
                        setFileListHtml(fileArrayTemp)
                        setFolderListHtml(folderArrayTemp)
                        setFileFolderRowHtml(fileFolderRowTempArray)
                    } else {
                        toast('⚠️' + res.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch((err) => {
                    toast('⚠️' + err, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
    
                })
            }
            else
            {
                var dict = {
                    clientId: rId
                }
                $('.se-pre-con').css('display', 'block');
                getListClientWise(dict).then((res) => {
                    if (res.errorCode == 0) {
                        $('.se-pre-con').css('display', 'none');
                        setApiResponse(res.responsePacket)
                        var folderRowTempArray = []
                        var fileRowTempArray = []
    
                        var folderArrayTemp = [];
                        var sideFolderArrayTemp = [];
                        var fileArrayTemp = [];
                        sideFolderArrayTemp.push(<li className="sidebar-panel__menu-item" onClick={() => handleSideLiClick("allFolder", "", "")}>
                            <a className="sidebar-panel__menu-link active" id="allFolder" href="#">
                                <svg className="icon-icon-folder text-grey-dark">
                                    <use xlinkHref="#icon-folder" />
                                </svg>
                                <span className="sidebar-panel__menu-text">All</span>
                            </a>
                        </li>)
                        for (let i = 0; i < res.responsePacket.fileFolderRecordList.length; i++) {
                            if (res.responsePacket.fileFolderRecordList[i].fileFolderType == 'Folder' && res.responsePacket.fileFolderRecordList[i].parentFolderId == '') {
                                
                                folderArrayTemp.push(<FolderCardComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                folderRowTempArray.push(<FolderRowComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                sideFolderArrayTemp.push(<li className="sidebar-panel__menu-item PK" onClick={() => handleSideLiClick("f-" + i, res.responsePacket.fileFolderRecordList[i])}>
                                    <a className="sidebar-panel__menu-link" id={"f-" + i} href="#">
                                        <svg className="icon-icon-folder text-grey-dark">
                                            <use xlinkHref="#icon-folder" />
                                        </svg>
                                        <span className="sidebar-panel__menu-text">{res.responsePacket.fileFolderRecordList[i].visibleFileName}</span>
                                    </a>
                                </li>)
                            } else {
                                
                                if (res.responsePacket.fileFolderRecordList[i].parentFolderId == '') {
                                    fileRowTempArray.push(<FileRowComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                    fileArrayTemp.push(<FileCardComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                }
    
                            }
                        }
                        const fileFolderRowTempArray = folderRowTempArray.concat(fileRowTempArray);
                        setSideFolderListHtml(sideFolderArrayTemp)
                        setFileListHtml(fileArrayTemp)
                        setFolderListHtml(folderArrayTemp)
                        setFileFolderRowHtml(fileFolderRowTempArray)
                    } else {
                        toast('⚠️' + res.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch((err) => {
                    toast('⚠️' + err, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
    
                })
            }
            
        } else {

            var rIdItemOxygenRole = localStorage.getItem("itemOxygenRole")
            if (rIdItemOxygenRole === "ADMINISTRATION") {
                // console.log(id);
                if (id !== undefined) {
                    var dict = {
                        "parentFolderId": id,
                        "clientId": localStorage.getItem("rId")
                    }
                    $('.se-pre-con').css('display', 'block');
                    getFileFolderListForUser(dict).then((res) => {
                        if (res.errorCode == 0) {
                            $('.se-pre-con').css('display', 'none');
                            setApiResponse(res.responsePacket)
                            var folderRowTempArray = []
                            var fileRowTempArray = []
                            var folderArrayTemp = [];
                            var sideFolderArrayTemp = [];
                            var fileArrayTemp = [];
                            sideFolderArrayTemp.push(<li className="sidebar-panel__menu-item" onClick={() => handleSideLiClick("allFolder", "", "")}>
                                <a className="sidebar-panel__menu-link active" id="allFolder" href="#">
                                    <svg className="icon-icon-folder text-grey-dark">
                                        <use xlinkHref="#icon-folder" />
                                    </svg>
                                    <span className="sidebar-panel__menu-text">All</span>
                                </a>
                            </li>)
                            for (let i = 0; i < res.responsePacket.fileFolderRecordList.length; i++) {
                                if (res.responsePacket.fileFolderRecordList[i].fileFolderType == 'Folder' && res.responsePacket.fileFolderRecordList[i].parentFolderId == '') {
                                    folderArrayTemp.push(<FolderCardComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                    folderRowTempArray.push(<FolderRowComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                    sideFolderArrayTemp.push(<li className="sidebar-panel__menu-item PK" onClick={() => handleSideLiClick("f-" + i, res.responsePacket.fileFolderRecordList[i])}>
                                        <a className="sidebar-panel__menu-link" id={"f-" + i} href="#">
                                            <svg className="icon-icon-folder text-grey-dark">
                                                <use xlinkHref="#icon-folder" />
                                            </svg>
                                            <span className="sidebar-panel__menu-text">{res.responsePacket.fileFolderRecordList[i].visibleFileName}</span>
                                        </a>
                                    </li>)
                                } else {
                                    /* if(res.responsePacket.fileFolderRecordList[i].parentFolderId == '')
                                    { */
                                    fileRowTempArray.push(<FileRowComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                    fileArrayTemp.push(<FileCardComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                    /* } */

                                }
                            }
                            const fileFolderRowTempArray = folderRowTempArray.concat(fileRowTempArray);
                            setSideFolderListHtml(sideFolderArrayTemp)
                            setFileListHtml(fileArrayTemp)
                            setFolderListHtml(folderArrayTemp)
                            setFileFolderRowHtml(fileFolderRowTempArray)
                        } else {
                            toast('⚠️' + res.message, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }).catch((err) => {
                        toast('⚠️' + err, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    })
                }
                else {
                    // console.log("pp");
                    if(localStorage.getItem("rId") === "" || localStorage.getItem("testData") === "test")
                    {
                        var dict = {
                            "clientId":""
                        }
                    }
                    else
                    {
                        var dict = {
                            "clientId":localStorage.getItem("client_recordId")
                        }
                    }
                    $('.se-pre-con').css('display', 'block');
                    getListForUser(dict).then((res) => {
                        if (res.errorCode == 0) {
                            $('.se-pre-con').css('display', 'none');
                            setApiResponse(res.responsePacket)
                            var folderRowTempArray = []
                            var fileRowTempArray = []

                            var folderArrayTemp = [];
                            var sideFolderArrayTemp = [];
                            var fileArrayTemp = [];
                            sideFolderArrayTemp.push(<li className="sidebar-panel__menu-item" onClick={() => handleSideLiClick("allFolder", "", "")}>
                                <a className="sidebar-panel__menu-link active" id="allFolder" href="#">
                                    <svg className="icon-icon-folder text-grey-dark">
                                        <use xlinkHref="#icon-folder" />
                                    </svg>
                                    <span className="sidebar-panel__menu-text">All</span>
                                </a>
                            </li>)
                            for (let i = 0; i < res.responsePacket.fileFolderRecordList.length; i++) {
                                if (res.responsePacket.fileFolderRecordList[i].fileFolderType == 'Folder' && res.responsePacket.fileFolderRecordList[i].parentFolderId == '') {
                                    folderArrayTemp.push(<FolderCardComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                    folderRowTempArray.push(<FolderRowComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                    sideFolderArrayTemp.push(<li className="sidebar-panel__menu-item PK" onClick={() => handleSideLiClick("f-" + i, res.responsePacket.fileFolderRecordList[i])}>
                                        <a className="sidebar-panel__menu-link" id={"f-" + i} href="#">
                                            <svg className="icon-icon-folder text-grey-dark">
                                                <use xlinkHref="#icon-folder" />
                                            </svg>
                                            <span className="sidebar-panel__menu-text">{res.responsePacket.fileFolderRecordList[i].visibleFileName}</span>
                                        </a>
                                    </li>)
                                } else {
                                    if (res.responsePacket.fileFolderRecordList[i].parentFolderId == '') {
                                        fileRowTempArray.push(<FileRowComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                        fileArrayTemp.push(<FileCardComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                    }

                                }
                            }
                            const fileFolderRowTempArray = folderRowTempArray.concat(fileRowTempArray);
                            setSideFolderListHtml(sideFolderArrayTemp)
                            setFileListHtml(fileArrayTemp)
                            setFolderListHtml(folderArrayTemp)
                            setFileFolderRowHtml(fileFolderRowTempArray)
                        } else {
                            toast('⚠️' + res.message, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }).catch((err) => {
                        toast('⚠️' + err, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    })

                }

            }
            else {
                
                var FolderID = state.common.parentFolderId
                if (FolderID == undefined) {
                    FolderID = "";
                }
                var dict = {
                    "parentFolderId": FolderID,
                    "requestedPage": 0,
                    "requestedPageSize": 1000000,
                    "searchKey": ""
                }
                $('.se-pre-con').css('display', 'block');
                getList(dict).then((res) => {
                    if (res.errorCode == 0) {
                        $('.se-pre-con').css('display', 'none');
                        setApiResponse(res.responsePacket)
                        var folderRowTempArray = []
                        var fileRowTempArray = []

                        var folderArrayTemp = [];
                        var sideFolderArrayTemp = [];
                        var fileArrayTemp = [];
                        sideFolderArrayTemp.push(<li className="sidebar-panel__menu-item" onClick={() => handleSideLiClick("allFolder", "", "")}>
                            <a className="sidebar-panel__menu-link active" id="allFolder" href="#">
                                <svg className="icon-icon-folder text-grey-dark">
                                    <use xlinkHref="#icon-folder" />
                                </svg>
                                <span className="sidebar-panel__menu-text">All</span>
                            </a>
                        </li>)
                        for (let i = 0; i < res.responsePacket.fileFolderRecordList.length; i++) {
                            // console.log(res.responsePacket.fileFolderRecordList[i].fileFolderType);
                            if (res.responsePacket.fileFolderRecordList[i].fileFolderType == 'Folder' && res.responsePacket.fileFolderRecordList[i].parentFolderId == null) {
                                folderArrayTemp.push(<FolderCardComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                folderRowTempArray.push(<FolderRowComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                sideFolderArrayTemp.push(<li className="sidebar-panel__menu-item PK" onClick={() => handleSideLiClick("f-" + i, res.responsePacket.fileFolderRecordList[i])}>
                                    <a className="sidebar-panel__menu-link" id={"f-" + i} href="#">
                                        <svg className="icon-icon-folder text-grey-dark">
                                            <use xlinkHref="#icon-folder" />
                                        </svg>
                                        <span className="sidebar-panel__menu-text">{res.responsePacket.fileFolderRecordList[i].visibleFileName}</span>
                                    </a>
                                </li>)
                            } else {
                                fileRowTempArray.push(<FileRowComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                                fileArrayTemp.push(<FileCardComponent data={res.responsePacket.fileFolderRecordList[i]} />);
                            }
                        }
                        const fileFolderRowTempArray = folderRowTempArray.concat(fileRowTempArray);
                        // console.log("folderArrayTemp", folderArrayTemp)
                        // console.log("fileRowTempArray", folderRowTempArray)
                        setSideFolderListHtml(sideFolderArrayTemp)
                        setFileListHtml(fileArrayTemp)
                        setFolderListHtml(folderArrayTemp)
                        setFileFolderRowHtml(fileFolderRowTempArray)
                    } else {
                        toast('⚠️' + res.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch((err) => {
                    toast('⚠️' + err, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
            }

        }


    }



    const handleCreateFileFolder = (type, responsePacket) => {
        $('.custom-loader').css('display','none');
        var rId = localStorage.getItem("rId")
        var dictArray = []
        if (type == 'Folder') {
            var dict = {
                parentFolderId: "",
                fileFolderType: "Folder",
                visibleFileName: folderName,
                associateClientId: rId,
                fileBaseId: ""
            }
            dictArray.push(dict)

        } else {
            responsePacket.map((res) => {
                var dict = {
                    parentFolderId: "",
                    fileFolderType: "File",
                    visibleFileName: "",
                    fileBaseId: res.fileBaseId,
                    associateClientId: rId
                }
                dictArray.push(dict)
            })
        }
        console.log("Hii", dictArray);

        if(localStorage.getItem("itemOxygenRole") === "ADMINISTRATION")
        {
            $('.se-pre-con').css('display','block');
            addFileFolderForUser(dictArray).then((res) => {
                if (res.errorCode == 0) {
                    $('.se-pre-con').css('display', 'none');
                    setShowAddFolder(false)
                    setShowUpload(false)
                    setImagesAsFile([])
                    setFolderName('')
                    setFolderListHtml([])
                    toast(res.message,
                        {
                            icon: '👏',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );

                    getFileFolderList()
                    window.location.reload(true)
                } else {
                    toast(res.message,
                        {
                            icon: '🙁',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );

                }
            })

        }
        else
        {
            //debugger;
            $('.se-pre-con').css('display', 'block');
            addFolder(dictArray).then((res) => {
                if (res.errorCode == 0) {
                    $('.se-pre-con').css('display', 'none');
                    setShowAddFolder(false)
                    setShowUpload(false)
                    setImagesAsFile([])
                    setFolderName('')
                    setFolderListHtml([])
                    toast(res.message,
                        {
                            icon: '👏',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );

                    getFileFolderList()
                    window.location.reload(true)
                } else {
                    toast(res.message,
                        {
                            icon: '🙁',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );

                }
            })
        }   
    }

    const handleSideLiClick = (id) => {
        console.log(id);
        if ($("#" + id).hasClass("active")) {
            $("#" + id).removeClass("active")
        } else {
            $("#" + id).addClass("active")
        }
    }


    useEffect(() => {
        getFileFolderList()
        var folderNameArray = state.common.folderNav.split("-");
        if (folderNameArray.length > 1) {
            var tempFolderNav = [];
            for (let i = 1; i < folderNameArray.length; i++) {
                tempFolderNav.push(<li className="breadcrumbs__item">
                    <a className="breadcrumbs__link" href="#">
                        <span>{folderNameArray[i]}</span>
                        {
                            i == folderNameArray.length - 1 ? "" : <>
                                <svg className="icon-icon-keyboard-right breadcrumbs__arrow">
                                    <use xlinkHref="#icon-keyboard-right" />
                                </svg>
                            </>
                        }
                    </a>
                </li>)
            }
            setFolderNavArray(tempFolderNav)
        }
        var parentFolderId = state.common.parentFolderId;
        console.log(parentFolderId)
    }, [state.common.folderNav]);

    return (
        <>
            <div className="table-wrapper tab-content">
                <div className="tab-pane fade" id="tab-grid">
                    <div className="file-manager__table table-wrapper__content table-collapse scrollbar-thin scrollbar-visible" data-simplebar>
                        <table className="table table--spaces">
                            <colgroup>
                                <col className="colgroup-1" />
                                <col className="colgroup-2" />
                                <col />
                                <col />
                                <col />
                                <col />
                            </colgroup>
                            <thead className="table__header">
                                <tr className="table__header-row">
                                    <th>
                                        <div className="table__checkbox table__checkbox--all">
                                            <label className="checkbox">
                                                <input className="js-checkbox-all" type="checkbox" data-checkbox-all="files" />
                                                <span className="checkbox__marker">
                                                    <span className="checkbox__marker-icon">
                                                        <svg viewBox="0 0 14 12">
                                                            <path d="M11.7917 1.2358C12.0798 1.53914 12.0675 2.01865 11.7642 2.30682L5.7036 8.06439C5.40574 8.34735 4.93663 8.34134 4.64613 8.05084L2.22189 5.6266C1.92604 5.33074 1.92604 4.85107 2.22189 4.55522C2.51774 4.25937 2.99741 4.25937 3.29326 4.55522L5.19538 6.45734L10.7206 1.20834C11.024 0.920164 11.5035 0.93246 11.7917 1.2358Z" />
                                                        </svg>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                    </th>
                                    <th className="table__th-sort mw-200"><span className="align-middle">Nome</span><span className="sort sort--down" /></th>
                                    <th className="table__th-sort"><span className="align-middle">taglia</span><span className="sort sort--down" /></th>
                                    <th className="table__th-sort"><span className="align-middle">Tipa</span><span className="sort sort--down" /></th>
                                    <th className="table__th-sort"><span className="align-middle">Modificata</span><span className="sort sort--down" /></th>
                                    <th className="table__actions" />
                                </tr>
                            </thead>
                            <tbody>
                                {fileFolderRowHtml}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="tab-pane active" id="tab-list">
                    <section className="file-manager__section">
                        <h3 className="file-manager__section-title">Cartelle di lavoro</h3>
                        <div className="file-manager__section-cards">
                            {folderListHtml}
                        </div>
                    </section>
                    <section className="file-manager__section">
                        <h3 className="file-manager__section-title">File</h3>
                        <div className="file-manager__section-cards">
                            {fileListHtml}
                        </div>
                    </section>
                </div>
            </div>
            {/* <div className="se-pre-con">
                <img src={loader} />
            </div> */}
            <div className="custom-loader" style={{display:"none"}}>
            <div className='' style={{width:"50%", margin:"0 auto"}}>
                <div id="myProgress" style={{width:"100%"}}>
                    <div id="myBar" style={{width:count+"%"}} dangerouslySetInnerHTML={{__html: count + "%"}}></div>
                </div>
                </div>
            </div>

            <ToastContainer />
        </>

    )
}

