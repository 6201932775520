import React, { useEffect, useState } from "react";
// Handle console logs
import "./utils/dropConsole";
// ROUTER
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./navigation/RouterConfig";
// MUI Theme
// import { ThemeSwitch } from "components/ThemeSwitch";
// import { dark, light } from "styles/muiTheme";
import "./App.css";
import { ProvideAuth } from "./navigation/Auth/ProvideAuth";
// Redux
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { LoaderComponent, SvgComponent } from "./pages/Common/index";
import { Login } from './pages/index'
import $ from 'jquery'
// import { Startup } from './pages/LoginSignUp/index'
import { ClientConfig } from './navigation/ClientConfig';

function App() {
  const [userType, setUserType] = useState("ROLE_ADMIN")
  useEffect(() => {
    $("iframe").remove()
    const role = localStorage.getItem("itemOxygenRole")
    console.log(role);
    setUserType(role)
  }, []);



  // "react-scripts": "4.0.1",
  if (userType !== undefined && userType === "ROLE_ADMIN") {
    return (
      <>
        <Provider store={store}>
          <SvgComponent />
          <ProvideAuth>
            <BrowserRouter>
              <RouterConfig />
            </BrowserRouter>
          </ProvideAuth>
        </Provider>
      </>
    )
  }
  else {
    return (
      <>
        <Provider store={store}>
          <SvgComponent />
          <ProvideAuth>
            <BrowserRouter>
              <ClientConfig />
            </BrowserRouter>
          </ProvideAuth>
        </Provider>
      </>
    )
  }



}

export default App;
