import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  Dashboard,
  Documents,
  CalendarEvent,
  Users,
  Operators,
  Departments,
  TaskTemplates,
  TaskCompleted,
  AssignOperator,
  Scan,
  AfterScan,
  ViewNotification,
  Room,
  Hospital,
  FormPage,
  TaskCom,
  Print,
  RequestedTask,
  TaskComCheckList,
  NewTaskCom,
} from "../pages/index";
import { NotFound } from "./NotFound";
import {
  ROOT,
  DOCUMENTS,
  CALENDAR,
  USERS,
  OPERATORS,
  DEPARTMENTS,
  TASK_TEMPLATES,
  ASSIGN_OPERATOR,
  AUTH_PAGE1,
  REQUEST,
  SCAN_URL,
  DOCUMENT,
  TASK_COMPLETED,
  AFTER_SCAN,
  VIEWNOTIFICATION,
  ROOM,
  HOSPITAL,
  FORMPAGE,
  TASKCOM,
  PRINT,
  REQESTEDTASK,
  TASKCOMCHECKLIST,
  TASK_COM_ANDROID_PDF,
} from "./CONSTANTS";
import { AuthorizedPage1 } from "../pages/AuthorizedPage1";
import PrivateRoute from "./Auth/PrivateRoute";
import { RequestList } from "../pages/RequestList";
import SingleRecordPdf from "../pages/completedTasks/SingleRecordPdf";

export const RouterConfig = () => {
  return (
    <>
      <Switch>
        {/* List all public routes here */}
        {/* <Route exact path={ROOT} component={Home} /> */}
        <Route exact path={ROOT} component={CalendarEvent} />
        <Route exact path={DOCUMENT} component={Documents} />
        <Route exact path={DOCUMENTS} component={Scan} />
        <Route exact path={CALENDAR} component={CalendarEvent} />
        <Route exact path={USERS} component={Users} />
        <Route exact path={OPERATORS} component={Operators} />
        <Route exact path={DEPARTMENTS} component={Departments} />
        <Route exact path={HOSPITAL} component={Hospital} />
        <Route exact path={ROOM} component={Room} />
        <Route exact path={TASK_TEMPLATES} component={TaskTemplates} />
        <Route exact path={TASK_COMPLETED} component={TaskCompleted} />
        <Route exact path={ASSIGN_OPERATOR} component={AssignOperator} />
        <Route exact path={REQUEST} component={RequestList} />
        <Route exact path={VIEWNOTIFICATION} component={ViewNotification} />
        <Route exact path={FORMPAGE} component={FormPage} />
        {/* <Route exact path={TASKCOM} component={TaskCom} /> */}
        <Route exact path={TASKCOM} render={(props) => <NewTaskCom {...props} taskType={`Completed`} key="Completed"/>} />
        <Route exact path={TASKCOMCHECKLIST} render={(props) => <NewTaskCom {...props} taskType={`Todo`}  key="Completed"/>} />
        {/* <Route exact path={TASKCOMCHECKLIST} component={TaskComCheckList} /> */}
        <Route exact path={PRINT} component={Print} />
        <Route exact path={REQESTEDTASK} component={RequestedTask} />
        <Route exact path={TASK_COM_ANDROID_PDF} component={SingleRecordPdf} />
        {/* <Route exact path={TEST_TASK_COM_OPERATOR} component={SingleRecordPdf} /> */}
        {/* <Route exact path={FORGOTPASSWORD} component={ForgotPassword} /> */}
        {/* <Route exact path={AFTER_SCAN} component={AfterScan} /> */}
        {/* <Route exact path={SCAN_URL} component={Scan} /> */}

        {/* <Route exact path={SCAN_URL} component={Scan} /> */}

        {/* <Route path="/gettingStart">
                    <Startup />
                </Route> */}

        {/* List all private/auth routes here */}
        <PrivateRoute path={AUTH_PAGE1}>
          <AuthorizedPage1 />
        </PrivateRoute>
        {/* <PrivateRoute path={DASHBOARD}>
          <Dashboard />
        </PrivateRoute> */}

        {/* List a generic 404-Not Found route here */}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};
