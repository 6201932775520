import { Fragment } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";

import {
    getTaskWorkOrderList,
    deleteCompletedTask,
    deleteRequestedTask,
} from "../../services/index";
import { CommanComponents } from "../../components";
import $ from "jquery";

import moment from "moment";
import "react-rangeslider/lib/index.css";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { Checkboxes, DefaultElement, Dropdown, FileUpload, Header, HyperLink, Label, LineBreak, Paragraph, PdfHeader, RadioButtons, Range, Rating, Signature, TextArea, TextInput } from "./pdfElements";

var sideFolderArrayTemp = [];
var sideFolderArrayTempComplete = [];

export const NewTaskCom = ({ taskType = "" }) => {
    var curr = new Date();
    curr.setDate(curr.getDate());
    var date1 = curr.toISOString()?.substring(0, 10);

    const [rows, setRows] = useState([]);
    const [rows2, setRows2] = useState([]);

    const [addRequestData, setAddRequestData] = useState({
        startDate: date1,
        endDate: date1,
    });
    const [sideFolderListHtml, setSideFolderListHtml] = useState([]);

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordData, setRecordData] = useState("");
    const [viewPdf, setViewPdf] = useState(false);

    const columns = [
        {
            name: "ID",
            selector: (row) => row.taskCode,
        },
        {
            name: "Nome attività",
            selector: (row) => row.taskTitle,
        },
        {
            name: "Ospedale/ Dipartimento/ Stanza",
            selector: (row) => (
                <>
                    <div style={{ margin: "10px" }}>{row?.clientTitle}</div>
                    <div style={{ margin: "10px" }}>{row?.departmentName}</div>
                    <div style={{ margin: "10px" }}>{row?.roomName}</div>
                </>
            ),
            width: "300px"
        },
        {
            name: "Data dell attività",
            selector: (row) => moment(row.dateOfWork).format("DD/MM/YYYY"),
        },
        {
            name: "Modello completato Data",
            selector: (row) => moment(row.taskUpdatedDate).format("DD/MM/YYYY"),
        },
        {
            name: "Stato",
            selector: (row) => row.taskStatus,
        },
        {
            name: "Delete",
            selector: (row) => (
                <DeleteIcon
                    style={{ color: "red" }}
                    onClick={() => handleDelete(row.recordId)}
                />
            ),
        },
    ];

    const handleDelete = (recId) => {
        Swal.fire({
            title: "Sei sicuro?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sì, conferma !!",
            cancelButtonText: "No !!",
        }).then((result) => {
            $(".se-pre-con").css("display", "block");
            if (result.isConfirmed) {
                //Active Request
                deleteCompletedTask(recId).then((res) => {
                    if (res.errorCode == 0) {
                        toast(res.message);
                        getAssignedtaskList();
                    } else {
                        toast(res.message);
                    }
                }).catch((err) => {
                    $(".se-pre-con").css("display", "none");
                    toast(err, {
                        icon: "🙁",
                        style: {
                            borderRadius: "10px",
                            background: "#333",
                            color: "#fff",
                        },
                    });
                });

            } else {
                $(".se-pre-con").css("display", "none");
            }
        });

    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAssignedtaskList(0, newPerPage);
        setPerPage(newPerPage);
    };

    const handlePageChange = (page) => {
        getAssignedtaskList(page - 1);
        setCurrentPage(page);
    };

    const getAssignedtaskList = async (page, size = perPage, searchKey = "") => {
        var rId = localStorage.getItem("rId");
        if (rId != undefined) {
            var recordIdForList = rId;
        } else {
            var recordIdForList = "";
        }

        var listofDate = localStorage.getItem("listofDate");

        var taskStatus;
        if (listofDate != "" && listofDate != null) {
            taskStatus = {
                searchKey: searchKey,
                clientName: recordData,
                taskStatusEnum: taskType,
                clientId: recordIdForList,
                startDate: moment(listofDate).format("YYYY-MM-DD"),
                endDate: moment(listofDate).format("YYYY-MM-DD"),
                requestedPage: page,
                requestedPageSize: size,
            };

            setAddRequestData({
                ...addRequestData,
                startDate: listofDate,
                endDate: listofDate,
            });
        } else {
            taskStatus = {
                searchKey: searchKey,
                clientName: recordData,
                taskStatusEnum: taskType,
                clientId: recordIdForList,
                startDate: moment(addRequestData.startDate).format("YYYY-MM-DD"),
                endDate: moment(addRequestData.endDate).format("YYYY-MM-DD"),
                // requestedPage: 0,
                // requestedPageSize: -1,
                requestedPage: page,
                requestedPageSize: size,
            };
        }

        $(".se-pre-con").css("display", "block");
        getTaskWorkOrderList(taskStatus).then((res) => {
            localStorage.removeItem("listofDate");
            $(".se-pre-con").css("display", "none");
            if (res.errorCode === 0) {
                setRows(res.responsePacket);
                setRows2(res.responsePacket);
                if (res.responsePacket.length > 0) {
                    setTotalRows(res.responsePacket[0].recordsTotal);
                } else {
                    setTotalRows(0);
                }
            }
        });
    };

    const conditionalRowStyles = [
        {
            when: (row) => row.taskStatus === taskType,
            style: {
                cursor: "pointer",
            },
        },
    ];

    const handleMouseLeave = () => {
        $(".tooltip-inner").css("display", "none");
    };

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
        });
    };

    const check = (childItemId, newDataList, col_count, backgroundColor) => {
        for (let i = 0; i < newDataList.length; i++) {
            if (childItemId === newDataList[i].id) {
                const _elementData = { ...newDataList[i], backgroundColor }
                const { element } = _elementData
                if (element === "Image" || element === "Signature" || element === "Camera" || element === "Download") {
                    return (<Signature elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "FileUpload") {
                    return (<FileUpload elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "Header") {
                    return (<Header elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "Paragraph") {
                    return (<Paragraph elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "TextInput" || element === "NumberInput" || element === "EmailInput" || element === "PhoneNumber" || element === "DatePicker") {
                    return (<TextInput elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "Rating") {
                    return (<Rating elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "Range") {
                    return (<Range elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "Label") {
                    return (<Label elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "LineBreak") {
                    return (<LineBreak elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "Dropdown" || element === "Tags") {
                    return (<Dropdown elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "HyperLink") {
                    return (<HyperLink elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "TextArea") {
                    return (<TextArea elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "Checkboxes") {
                    return (<Checkboxes elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else if (element === "RadioButtons") {
                    return (<RadioButtons elementData={_elementData} col_count={col_count} taskType={taskType} />);
                } else {
                    return (<DefaultElement elementData={_elementData} col_count={col_count} taskType={taskType} />);
                }
            }
        }
        setSideFolderListHtml(sideFolderArrayTemp);
    };

    const checkVal = (elementData, dataList, col_count) => {
        if (elementData !== null) {
            const backgroundColor = elementData?.backgroundColor || "#fff"
            const pageBreakBefore = elementData.pageBreakBefore || false
            const pageBreakAfter = elementData.pageBreakAfter || false

            let pageBreakClass = ""
            if (pageBreakBefore) {
                pageBreakClass += "page-break-before-item"
            }
            if (pageBreakAfter) {
                pageBreakClass += "page-break-after-item"
            }

            let singleRowItems = []
            for (let i = 0; i < elementData.childItems.length; i++) {
                const matchedItem = check(elementData.childItems[i], dataList, col_count, backgroundColor);
                singleRowItems.push(matchedItem)
            }
            const itemsInRow = <div className={`w-100 ${pageBreakClass}`} style={{ backgroundColor }}>{singleRowItems}</div>
            sideFolderArrayTemp.push(itemsInRow)
        }
    };

    //Dhanesh Code

    const handleExportAllToPDF = (selectedRow) => {
        sideFolderArrayTemp = [];
        var _rows = []
        if (selectedRow) {
            _rows = [selectedRow]
        } else {
            _rows = [...rows]
        }
        for (let p = 0; p < _rows.length; p++) {
            let e = _rows[p];
            if (e.taskStatus == taskType) {
                if (e.recordId != 0) {
                    var operatorTitle = e.operatorTitle;
                    var clientTitle = e.clientTitle;
                    var roomName = e.roomName;
                    var departmentName = e.departmentName;
                    var taskUpdatedDate = e.taskUpdatedDate;
                    var taskTitle = e.taskTitle;
                    var taskCode = e.taskCode;
                    var formDataEditList = JSON.parse(e.subTaskJson);

                    const headerData = {
                        taskTitle,
                        operatorTitle,
                        clientTitle,
                        departmentName,
                        roomName,
                        taskUpdatedDate,
                        taskCode,
                    }
                    sideFolderArrayTemp.push(<Fragment key={e.recordId}><PdfHeader headerData={headerData} /></Fragment>);
                    for (let i = 0; i < formDataEditList.task_data.length; i++) {
                        if (formDataEditList.task_data[i].isAdded !== true) {
                            console.log("i",i)
                            let elementData = formDataEditList.task_data[i];
                            let { col_count, element } = elementData
                            col_count = col_count || 1
                            if (element === "TwoColumnRow") {
                                checkVal(
                                    elementData,
                                    formDataEditList.task_data,
                                    2
                                );
                            } else if (element === "ThreeColumnRow") {
                                checkVal(
                                    elementData,
                                    formDataEditList.task_data,
                                    3
                                );
                            }
                            else if (element === "FourColumnRow") {
                                checkVal(
                                    elementData,
                                    formDataEditList.task_data,
                                    4
                                );
                            } else if (
                                element === "SixColumnRow" || element === "MultiColumnRow"
                            ) {
                                checkVal(
                                    elementData,
                                    formDataEditList.task_data,
                                    6
                                );
                            } else if (element === "CustomColumnRow") {
                                checkVal(
                                    elementData,
                                    formDataEditList.task_data,
                                    col_count
                                );
                            }
                            else if (element === "Image" || element === "Signature" || element === "Camera" || element === "Download") {
                                sideFolderArrayTemp.push(<Signature elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "FileUpload") {
                                sideFolderArrayTemp.push(<FileUpload elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "Header") {
                                sideFolderArrayTemp.push(<Header elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "Paragraph") {
                                sideFolderArrayTemp.push(<Paragraph elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "TextInput" || element === "NumberInput" || element === "EmailInput" || element === "DatePicker") {
                                sideFolderArrayTemp.push(<TextInput elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "TextArea") {
                                sideFolderArrayTemp.push(<TextArea elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "Label") {
                                sideFolderArrayTemp.push(<Label elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "Range") {
                                sideFolderArrayTemp.push(<Range elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "Rating") {
                                sideFolderArrayTemp.push(<Rating elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "LineBreak") {
                                sideFolderArrayTemp.push(<LineBreak elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "HyperLink") {
                                sideFolderArrayTemp.push(<HyperLink elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "Dropdown" || element === "Tags") {
                                sideFolderArrayTemp.push(<Dropdown elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "RadioButtons") {
                                sideFolderArrayTemp.push(<RadioButtons elementData={elementData} col_count={1} taskType={taskType} />);
                            } else if (element === "Checkboxes") {
                                sideFolderArrayTemp.push(<Checkboxes elementData={elementData} col_count={1} taskType={taskType} />);
                            } else {
                                sideFolderArrayTemp.push(<DefaultElement elementData={elementData} col_count={1} taskType={taskType} />);
                            }
                        }
                    }

                    sideFolderArrayTempComplete.push(
                        <>
                            <div className="page-break-after dr" style={{ position: "relative" }} key={p}>
                                <div className="m-0" style={{ width: "100%" }}>{sideFolderArrayTemp}</div>
                            </div>
                        </>
                    );
                    sideFolderArrayTemp = [];

                    if (p === _rows.length - 1) {
                        if (_rows.length === 1) {
                            let tempTaskUpdatedDate = moment(taskUpdatedDate).format("DD-MM-YYYY")
                            let title = `${tempTaskUpdatedDate}_${taskCode}_${clientTitle}_${taskTitle}`
                            document.title = title
                            // Just for firefox
                            window.history.replaceState({}, title, title);
                        }
                        setSideFolderListHtml(sideFolderArrayTempComplete);
                        setViewPdf(true);
                        setTimeout(() => {
                            window.print();
                        }, 3000);
                    }
                } else {
                    toast("❌" + e.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        }
    };

    useEffect(() => {
        window.onafterprint = function () {
            var mobile =
                /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(
                    navigator.userAgent.toLowerCase()
                );
            if (mobile) {
                setViewPdf(true);
            } else {
                setViewPdf(false);
                // Just for firefox
                window.history.replaceState({}, "taskCom", "taskCom");
                document.title = "itemCare"
                sideFolderArrayTempComplete = [];
                setSideFolderListHtml([]);
            }
        };
    });

    useEffect(() => {
        getAssignedtaskList(0);
        handleMouseLeave();
    }, [taskType]);


    // const handleSearch = (search) => {
    //     const result = rows2.filter((a) => {
    //         return (
    //             a.taskTitle?.toLowerCase().match(search.toLowerCase()) ||
    //             a.taskStatus?.toLowerCase().match(search.toLowerCase()) ||
    //             a.clientTitle?.toLowerCase().match(search.toLowerCase()) ||
    //             a.operatorTitle?.toLowerCase().match(search.toLowerCase()) ||
    //             a.roomName?.toLowerCase().match(search.toLowerCase()) ||
    //             a.departmentName?.toLowerCase().match(search.toLowerCase()) ||
    //             a.dateOfWork?.toLowerCase().match(search.toLowerCase()) ||
    //             a.taskCode?.toLowerCase().match(search.toLowerCase())
    //         );
    //     });
    //     setRows(result);
    // };

    const handleSearchTasks = (e) => {
        const searchKey = e.target.value
        getAssignedtaskList(0, perPage, searchKey)
        setRecordData(e.target.value)
    }


    return (
        <div className={taskType} key={taskType}>
            {viewPdf ? (
                <div id="pdf" className="">
                    <div className="print-items">
                        <div className="page">
                            {sideFolderListHtml && sideFolderListHtml}
                        </div>
                        <div className="footer">
                            <div className="text-center">
                                <div>
                                    <span>Item Oxygen s.r.l. Via Le Fogge, 11 - 70022 Altamura (BA) Italy</span>
                                    <span>   p.iva/c.f. 06068560728 Tel +39 080 3143647 fax +39 080 3160611</span>
                                </div>
                                <div style={{ color: "#4687ff" }}>
                                    <span>info@itemoxygen.com – item.oxygen@legalmail.it</span>
                                    <span>  www.itemoxygen.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="sidebar-backdrop" />
                    <div className="page-wrapper">
                        <CommanComponents />
                        <main className="page-content">
                            <div className="container">
                                <div className="row mb-2 justify-content-between align-items-center">
                                    <div style={{ fontSize: "20px", fontWeight: "500" }}>
                                        <span>Modello completato</span>
                                    </div>
                                </div>
                                <div className="row align-items-center justify-content-between mb-2 mx-0">
                                    <div className="col-auto align-items-center d-flex px-0">
                                        <div className="pull-right" onClick={() => handleExportAllToPDF(null)}>
                                            <a type="button" className="btn btn-primary btn">
                                                Esporta tutto
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-auto align-items-center d-flex">
                                        <div className="pull-right">
                                            <input
                                                className="form-control"
                                                placeholder="Search Nome Attivita"
                                                onChange={handleSearchTasks}
                                            />

                                            {/* <input
                                                className="form-control"
                                                placeholder="Search Nome Attivita"
                                                onChange={(e) => handleSearch(e.target.value)}
                                            /> */}
                                        </div>
                                    </div>
                                    <div className="col-auto align-items-center d-flex gap-2">
                                        <label htmlFor="exampleInputEmail1">Data di Inizio</label>
                                        <input
                                            // style={{ width: "250px" }}
                                            className="form-control"
                                            type="date"
                                            value={addRequestData.startDate}
                                            onChange={(e) =>
                                                setAddRequestData({
                                                    ...addRequestData,
                                                    startDate: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-auto align-items-center d-flex gap-2">
                                        <label htmlFor="exampleInputEmail1">Data di fine</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            value={addRequestData.endDate}
                                            onChange={(e) =>
                                                setAddRequestData({
                                                    ...addRequestData,
                                                    endDate: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="pull-right">
                                        <a
                                            type="button"
                                            className="btn btn-primary btn"
                                            onClick={() => getAssignedtaskList(0)}
                                        >
                                            <i className="fa fa-search mr-2"></i>Search
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="mt-3"
                                        style={{ height: "100%", width: "100%" }}
                                    >
                                        <DataTable
                                            columns={columns}
                                            data={rows}
                                            theme="solarized"
                                            pagination
                                            //onRowClicked={(e) => handleData(e)}
                                            onRowClicked={(e) => handleExportAllToPDF(e)}
                                            conditionalRowStyles={conditionalRowStyles}
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                        // expandableRows
                                        // expandableRowsComponent={ExpandedComponent}
                                        />
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </>
            )}
            <ToastContainer />
        </div>
    );
};