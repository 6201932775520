import React, { useEffect, useState } from 'react'
import { CommanComponents } from "../components/index"
import $ from "jquery"; //Load jquery
import { Modal ,Button } from 'react-bootstrap';
import { getTemplatesKeyValueList, getTaskTemplate, generateTaskSheet, updateTaskSheet,getClientKeyValueList, getClientDepartmentListInKeyValue,getDepartmentDataByHospital,getRoomListInKeyValue,getRoomDataByDepartment } from "../services/index"

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { ToastContainer, toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import 'react-toastify/dist/ReactToastify.css';
import loader from "../assets/img/content/loader.svg"
import Select from 'react-select';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
window.jQuery = $; //JQuery alias
window.$ = $; //JQuery alias

require("jquery-ui-sortable"); //For FormBuilder Element Drag and Drop
require("formBuilder");// For FormBuilder

var formData = [];
var taskTemplateUuid;
var taskTemplateTitle;
var hospitalRecord; 
var departmentRecord; 
var roomRecord; 
var dataObj;
var options = {
    onSave: function (evt, formData) {
        // toggleEdit();
        $('.render-wrap').formRender({ formData });
        window.sessionStorage.setItem('formData', JSON.stringify(formData));
    },
};

//Initialize formBuilder 
const FormBuilder = (props) => {
    // fb = createRef();
    // let formBuilderObject;
    const [formBuilderObject, setformBuilderObject] = useState(null)
    const [taskTitle, setTaskTitle] = useState("")
    const [hospitalKeyValuePair, setHospitalKeyValuePair] = useState([])
    const [departmentKeyValuePair, setDepartmentKeyValuePair] = useState([])
    const [roomKeyValuePair, setRoomKeyValuePair] = useState([])
    const [hospital, setHospital] = useState()
    const [department, setDepartment] = useState()
    const [room, setRoom] = useState()
    const [addRequestData, setAddRequestData] = useState({
        clientId: '',
        departmentId: '',
        roomId: ''
    })

    // constructor() {
    //     this.handleGetData = this.handleGetData.bind(this);
    // }



    const handleHospital = (e) => {
        setAddRequestData({
          ...addRequestData,
          clientId: e.value
        })
        setHospital(e)
        
        getDepartmentDataByHospital(e.value).then((res) => {
          const data = res.responsePacket;
          const options = data.map((d) => ({
                  value: d.option,
                  label: d.value,
          }));
          setDepartmentKeyValuePair(options)
        }).catch((err) => {
          alert(err)
        })
    
    
    
      }
    
      const handleDepartment = (e) => {
        setAddRequestData({
          ...addRequestData,
          departmentId: e.value
        })
        setDepartment(e)

        let dep_id={
            "departmentId":e.value  
        };
        
        getRoomDataByDepartment(dep_id).then((res) => {
            const data = res.responsePacket;
            const options = data.map((d) => ({
                    value: d.option,
                    label: d.value,
            }));
            setRoomKeyValuePair(options)
          }).catch((err) => {
            alert(err)
        })
      }

      const handleRoom = (e) => {
        setAddRequestData({
          ...addRequestData,
          roomId: e.value
        })
        setRoom(e)
      }

    const handleGetData = () => {
        
        if (addRequestData.clientId === undefined || addRequestData.clientId === "") {
            toast("Please select hospital first !",
              {
                icon: '🙁',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }
            );
            return
          }else if (addRequestData.roomId === undefined || addRequestData.roomId === "") {
            toast("Please select room first",
              {
                icon: '🙁',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }
            );
            return
          }

        if (taskTemplateUuid == null) {
            const dict = {
                "clientId":addRequestData?.clientId,
                "departmentId":addRequestData?.departmentId,
                "roomId":addRequestData?.roomId,
                "taskTitle": taskTitle,
                "subTaskJson": JSON.stringify(formBuilderObject.actions.getData())
            }
            $('.se-pre-con').css('display','block');
            generateTaskSheet(dict).then((res) => {
                if (res.errorCode == 0) {
                    $('.se-pre-con').css('display','none');
                    toast('✅' + res.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    props.parentCallback()
                } else {
                    $('.se-pre-con').css('display','none');
                    toast('❌' + res.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
            }).catch((err) => {
                $('.se-pre-con').css('display','none');
                toast('❌' + err, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })

        } else {
            const dict = {
                "taskTitle": taskTitle,
                "departmentId":addRequestData?.departmentId,
                "roomId":addRequestData?.roomId,
                "taskTitle": taskTitle,
                "subTaskJson": JSON.stringify(formBuilderObject.actions.getData())
            }
            $('.se-pre-con').css('display','block');
            updateTaskSheet(dict, taskTemplateUuid).then((res) => {
                if (res.errorCode == 0) {
                    $('.se-pre-con').css('display','none');
                    toast('✅' + res.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    props.parentCallback()
                } else {
                    $('.se-pre-con').css('display','none');
                    toast('❌' + res.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
            }).catch((err) => {
                $('.se-pre-con').css('display','none');
                toast('❌' + err, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
        }
        // $(this.fb.current).actions.getData('json', true)
        console.log("JSON:")
        console.log(formBuilderObject.actions.getData('json', true))
        console.log("XML:")
        console.log(formBuilderObject.actions.getData('xml'))
        console.log("JS:")
        console.log(formBuilderObject.actions.getData())
        console.log("SAVE:")
        console.log(formBuilderObject.actions.save())
    }

    useEffect(() => {
        var formBuilderObject = $("#fb-editor").formBuilder({ formData, options });
        setTaskTitle(taskTemplateTitle)
        setformBuilderObject(formBuilderObject)
        var dict = {
            "clientRoleEnum": "CLIENTS"
          }
          $('.se-pre-con').css('display','block');
          getClientKeyValueList(dict).then((res) => {
            if (res.errorCode == 0) {
              $('.se-pre-con').css('display','none');
              const data = res.responsePacket;
              const options = data.map((d) => ({
                value: d.option,
                label: d.value,
              }));
      
              setHospitalKeyValuePair(options)
            } else {
              $('.se-pre-con').css('display','none');
              toast(res.message,
                {
                  icon: '🙁',
                  style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                  },
                }
              );
            }
          }).catch((err) => {
            $('.se-pre-con').css('display','none');
            toast(err,
              {
                icon: '🙁',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }
            );
          })
      
          getClientDepartmentListInKeyValue().then((res) => {
            if (res.errorCode == 0) {
              $('.se-pre-con').css('display','none');
              const data = res.responsePacket;
              const options = data.map((d) => ({
                value: d.option,
                label: d.value,
              }));
      
              setDepartmentKeyValuePair(options)
            } else {
              $('.se-pre-con').css('display','none');
              toast(res.message,
                {
                  icon: '🙁',
                  style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                  },
                }
              );
            }
          }).catch((err) => {
            $('.se-pre-con').css('display','none');
            toast(err,
              {
                icon: '🙁',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }
            );
          })

          let dep_id={
            "departmentId":""  
          };
          getRoomDataByDepartment(dep_id).then((res) => {
            if (res.errorCode == 0) {
                $('.se-pre-con').css('display','none');
                const data = res.responsePacket;
                const options = data.map((d) => ({
                  value: d.option,
                  label: d.value,
                }));
        
                setRoomKeyValuePair(options)
              } else {
                $('.se-pre-con').css('display','none');
                toast(res.message,
                  {
                    icon: '🙁',
                    style: {
                      borderRadius: '10px',
                      background: '#333',
                      color: '#fff',
                    },
                  }
                );
              }
            }).catch((err) => {
              $('.se-pre-con').css('display','none');
              toast(err,
                {
                  icon: '🙁',
                  style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                  },
                }
              );
          })

          setHospital(hospitalRecord)
          setDepartment(departmentRecord)
          setRoom(roomRecord)
          setAddRequestData(dataObj)


    }, [dataObj]);

    return <>
        <div className='mb-2'>
            <button className="btn btn-primary" onClick={handleGetData}>Save</button>
        </div>
        <div className="row mb-2">
            <div className="col-md-2">Hospital</div>
            <div className="col-md-8"><Select options={hospitalKeyValuePair} name='roundOffTo' value={hospital} onChange={handleHospital} /></div>
        </div>
        <div className="row mb-2">
            <div className="col-md-2">Department</div>
            <div className="col-md-8"><Select options={departmentKeyValuePair} name='roundOffTo' value={department} onChange={handleDepartment} /></div>
        </div>
        <div className="row mb-2">
            <div className="col-md-2">Room</div>
            <div className="col-md-8"><Select options={roomKeyValuePair} name='roundOffTo' value={room} onChange={handleRoom}/></div>
        </div>
        <div className="row mb-2">
            <div className="col-md-2">Task Title</div>
            <div className="col-md-8"><input  className="form-control"value={taskTitle} onChange={(e) => setTaskTitle(e.target.value)}></input></div>
        </div>
        <div id="fb-editor" /></>;
        
}



export const TaskTemplates = props => {

    const [showModal, setShowModal] = useState(false)
    const [taskTemplatesHtml, setTaskTemplatesHtml] = useState([])
    const [showUpload, setShowUpload] = useState(false)
    

    const handleParentCallback = () => {
        setShowModal(false)
        getTemplatesList()
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const handleViewOrEditTemplate = (uuid) => {
        taskTemplateUuid = uuid;
        $('.se-pre-con').css('display','block');
        getTaskTemplate(uuid).then((res) => {
            if (res.errorCode == 0) {
                $('.se-pre-con').css('display','none');
                dataObj=res.responsePacket
                let d = res.responsePacket;
                hospitalRecord = {
                    value: d.clientId,
                    label: d.clientName,
                }
                departmentRecord = {
                    value: d.departmentId,
                    label: d.departmentName,
                }
                roomRecord = {
                    value: d.roomId,
                    label: d.roomName,
                }
                formData = JSON.parse(res.responsePacket.subTaskJson);
                taskTemplateTitle = res.responsePacket.taskTitle
                setShowModal(true)
            } else {
                $('.se-pre-con').css('display','none');
                toast('❌' + res.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch((err) => {
            $('.se-pre-con').css('display','none');
            toast('❌' + err, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })

    }

    const handleMouseOver = (e) => {
        $(e.target.parentElement).find(".MuiPaper-elevation").removeClass("mouseIn")
        // $(e.target.parentElement).find(".MuiGrid-item").addClass("mouseOver")
    }
    const handleMouseEnter = (e) => {
        // $(e.target.parentElement).find(".MuiGrid-item").removeClass("mouseOver")
        $(e.target.parentElement).find(".MuiPaper-elevation").addClass("mouseIn")
    }

    const getTemplatesList = () => {
        var temp = [];
        $('.se-pre-con').css('display','block');

        var rId = localStorage.getItem("rId")
        if (rId != undefined) {
              var recordIdForList = rId
        }
        else {
              var recordIdForList = ""
        }
        var dict={
            "clientId": recordIdForList,
            "requestedPage": 0,
            "requestedPageSize": 1000
        }
        getTemplatesKeyValueList(dict).then((res) => {
            $('.se-pre-con').css('display','none');
            if (res.errorCode == 0) {
                res.responsePacket.map((data, index) => {
                    const random = Math.floor(Math.random() * (6 - 3 + 1) + 3);
                    var html = <Grid item xs={6} md={random} className="cursor" onMouseOver={handleMouseOver} onMouseEnter={handleMouseEnter} onClick={() => handleViewOrEditTemplate(data.option)}>
                        <Item>{data.value}</Item>
                    </Grid >
                    temp.push(html)
                })
                setTaskTemplatesHtml(temp)
            } else {
                $('.se-pre-con').css('display','none');
                toast('❌' + res.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch((err) => {
            $('.se-pre-con').css('display','none');
            toast('❌' + err, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }


    const handleShowAdd = () => {
        taskTemplateUuid = null;
        formData = []
        setShowModal(true)
        
    }
    

    useEffect(() => {
        getTemplatesList()
    }, []);

    return (
        <>
            <div>
                <div className="sidebar-backdrop" />
                <div className="page-wrapper">
                    <CommanComponents />
                    <main className="page-content">
                        <div className="container">
                            <div className="page-header">
                                <h1 className="page-header__title">Elenco modelli</h1>
                            </div>
                            <div className='row mb-2 justify-content-between align-items-center'>
                                <div style={{ fontSize: '20px', fontWeight: "500" }}>
                                    {/* <span>Create Task Template</span> */}
                                </div>
                                <div>
                                    <button type="button" className="btn btn-primary btn-sm" onClick={handleShowAdd}><i className='fa fa-plus'></i>Aggiungi modello</button>
                                </div>
                            </div>
                            <div className="mt-5">
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        {taskTemplatesHtml}
                                    </Grid>
                                </Box>
                            </div>
                            <div className="render-wrap">
                            </div>
                            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={showModal} className={showModal ? "is-active is-animate" : ""}>
                                <Modal.Header>
                                    <Modal.Title>Aggiungi modello</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <FormBuilder parentCallback={handleParentCallback}/>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => handleClose()}>
                                    Chiudere
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </main>
                </div>
            </div>
            <ToastContainer />
            <div className="se-pre-con">
                <img src={loader}/>
            </div>
        </>
    )
}

