import { useDropzone } from 'react-dropzone';
import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components';
import fileUnknown from "../../assets/img/content/file-unknown.svg"
import filePdf from "../../assets/img/content/file-pdf.svg"

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
};

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px;
	border-width: 2px;
	border-radius: 10px;
	border-color: ${(props) => getColor(props)};
	border-style: dashed;
	background-color: #fafafa;
	color: black;
	font-weight: bold;
	font-size: 1.4rem;
	outline: none;
	transition: border 0.24s ease-in-out;
`;

export function DropBox({ onDrop, parentCallBack }) {
    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        open,
        isDragAccept,
        isFocused,
        isDragReject,
    } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
    });
    const [tempAcceptedFiles, setTempAcceptedFiles] = useState(acceptedFiles);

    useEffect(() => {
        setTempAcceptedFiles(acceptedFiles)

    }, [acceptedFiles]);



    const deleteFile = (index) => {
        parentCallBack(index)
    }

    const removeFile = (index) => {
        var tempArray = [...tempAcceptedFiles]
        const unsupportedFileIndex = index;
        if (unsupportedFileIndex !== -1) {
            tempArray.splice(unsupportedFileIndex, 1);
            // update unsupportedFiles array
            setTempAcceptedFiles(tempArray);
        }
    }

    const lists = tempAcceptedFiles.map((list, index) => (
        <li className="files-upload__item" key={list.path}>
            <div className="media-file">
                <div className="media-file__icon">
                    <img className="media-file__thumb" src={filePdf} alt="#" />
                </div>
                <div className="media-file__right">
                    <div className="media-file__prepend">
                        <h5 className="media-file__title">{list.path}</h5>
                        <span className="text-sm text-grey">{list.size} bytes</span>
                    </div>
                    <div className="media-file__append" onClick={() => removeFile(index)}>
                        <a className="button-icon button-icon--grey" href="#">
                            <span className="button-icon__icon">
                                <svg className="icon-icon-trash">
                                    <use xlinkHref="#icon-trash" />
                                </svg>
                            </span>
                        </a>
                    </div>
                    <div className="media-file__upload">
                        <div className="media-file__upload-text">Uploading File... 100%</div>
                        <button className="media-file__upload-dismiss" type="button">
                            <svg className="icon-icon-cross">
                                <use xlinkHref="#icon-cross" />
                            </svg>
                        </button>
                        <div className="media-file__progressbar">
                            <div className="media-file__progressbar-item" style={{ width: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>
        </li>
        // <li key={list.path}>
        //     {list.path} - {list.size} bytes
        // </li>
    ));

    return (
        <>
            {' '}
            <section className="dropbox">
                <Container
                    className="dropbox"
                    {...getRootProps({ isDragAccept, isFocused, isDragReject })}
                >
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here</p>
                    <button type="button" className="btn" onClick={open}>
                        Click to select file
                    </button>
                </Container>
            </section>
            <aside>
                <div className="files-upload__items scrollbar-thin" data-simplebar="data-simplebar">
                    <ul className="files-upload__list">
                        {/* <li className="files-upload__item files-upload__item--progress">
                            <div className="media-file">
                                <div className="media-file__icon">
                                    <img className="media-file__thumb" src={fileUnknown} alt="#" />
                                </div>
                                <div className="media-file__right">
                                    <div className="media-file__prepend">
                                        <h5 className="media-file__title">Resume.pdf</h5>
                                        <span className="text-sm text-grey">570 KB</span>
                                    </div>
                                    <div className="media-file__append">
                                        <a className="button-icon button-icon--grey" href="#">
                                            <span className="button-icon__icon">
                                                <svg className="icon-icon-trash">
                                                    <use xlinkHref="#icon-trash" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="media-file__upload">
                                        <div className="media-file__upload-text">Uploading File... 70%</div>
                                        <button className="media-file__upload-dismiss" type="button">
                                            <svg className="icon-icon-cross">
                                                <use xlinkHref="#icon-cross" />
                                            </svg>
                                        </button>
                                        <div className="media-file__progressbar">
                                            <div className="media-file__progressbar-item" style={{ width: '70%' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li> */}
                        {lists}
                    </ul>
                </div>
            </aside>
        </>
    );
}
