import React, { useState, useEffect,useCallback } from 'react'
import { Route, Link, BrowserRouter as Router, Switch } from "react-router-dom";
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import mainLogo from "../assets/img/content/logo.svg"
import logoType from "../assets/img/content/logotype.svg";
import authBg from "../assets/img/content/auth-bg.jpg";
import authBgDark from "../assets/img/content/auth-bg-dark.jpg";
import logoutLogo from "../assets/img/content/logout.png";
import userLogo from "../assets/img/content/userLogo.png";
import operatorsLogo from "../assets/img/content/operatorsLogo.png";
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from "redux"
import { actionCreator } from "../redux/index"
import QRCode from "react-qr-code";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import $ from "jquery"


export const SideModel = props => {
    const { id } = useParams()

    
    const [rkId, setRkId] = useState(null)
    const [fileFolderTypeScan, setFileFolderTypeScan] = useState(null)

    const [details, setDetails] = useState(null)
    const [displayDetails, setDisplayDetails] = useState({
        user: "Admin",
        size: "0.0 KB",
        author: "ItemCare",
        type: "Miei File",
        modifiyDate: "",
        createdDate: ""
    })

    const state = useSelector((state) => state);
    // const []

    const dispatch = useDispatch()

    const { addSideModelOpen } = bindActionCreators(actionCreator, dispatch)

    const handleClose = () => {
        addSideModelOpen(false)
    }


    const [copied, setCopied] = React.useState(false);

    const onCopy = React.useCallback(() => {
        $('.copyMsg').css('display','block');
        $(".copyMsg").delay(350).fadeOut("slow");
        setCopied(true);
    }, [])


    useEffect(() => {
        setDetails(state.common.fileFolderDetails)
        if (state.common.fileFolderDetails != null) {

            setDisplayDetails({
                ...displayDetails,
                createdDate: state.common.fileFolderDetails.createdAt,
                modifiyDate: state.common.fileFolderDetails.updatedAt,
                fileSizeInBits: state.common.fileFolderDetails.fileSizeInBits,
                fileFolderType: state.common.fileFolderDetails.fileFolderType,
                qrCodeUrl: state.common.fileFolderDetails.qrCodeUrl,
            })
        }

        var rId = localStorage.getItem("qrrecordId")
        var fileDetails = localStorage.getItem("fileDetails");
     
        //debugger;
        setRkId(fileDetails);
        setFileFolderTypeScan(fileDetails)

    }, [state.common.fileFolderDetails]);
 



    return (
        <>
            <div className={state.common.sideModel ? "modal demo-panel modal--panel modal--right is-active is-animate" : "modal demo-panel modal--panel modal--right"} id="demo">
                <div className="modal__overlay" data-dismiss="modal" />
                <div className="modal__wrap">
                    <div className="modal__window scrollbar-thin" data-simplebar="data-simplebar">
                        <div className="modal__content">
                            <div className="modal__body p-2">
                                <div>
                                    <div className="files-card__icon folder-details">
                                        <svg className={details != null ? details.fileFolderType == 'Folder' ? "icon-icon-folder" : "icon-icon-file" : ""}>
                                            <use xlinkHref={details != null ? details.fileFolderType == 'Folder' ? "#icon-folder" : "#icon-file" : ""} />
                                        </svg>
                                    </div>
                                </div>
                                <h5 className="files-card__title">{details != null ? details.visibleFileName : ""}</h5>
                                <div className="p-3">

                                    <div className='row mt-2' style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        <div className='col-6'>
                                            <span>Informazioni</span>
                                        </div>
                                    </div>
                                    <div className='row mt-2' style={{ fontSize: '12px' }}>
                                        <div className='col-6'>
                                            <span>Tipo</span>
                                        </div>
                                        <div className='col-6'>
                                            <span>{displayDetails.fileFolderType}</span>
                                        </div>
                                    </div>
                                    <div className='row mt-2' style={{ fontSize: '12px' }}>
                                        <div className='col-6'>
                                            <span>Dimensione</span>
                                        </div>
                                        <div className='col-6'>
                                            <span>{displayDetails.fileSizeInBits !=null ? displayDetails.fileSizeInBits:"N/A"}</span>
                                        </div>
                                    </div>
                                    <div className='row mt-2' style={{ fontSize: '12px' }}>
                                        <div className='col-6'>
                                            <span>Autore</span>
                                        </div>
                                        <div className='col-6'>
                                            <span>ItemCare</span>
                                        </div>
                                    </div>
                                    <div className='row mt-2' style={{ fontSize: '12px' }}>
                                        <div className='col-6'>
                                            <span>Posizione</span>
                                        </div>
                                        <div className='col-6'>
                                            <span>Admin</span>
                                        </div>
                                    </div>
                                    <div className='row mt-2' style={{ fontSize: '12px' }}>
                                        <div className='col-6'>
                                            <span>Ultima modifica</span>
                                        </div>
                                        <div className='col-6'>
                                        <span>{moment(displayDetails.modifiyDate).format("ll")}</span>
                                        </div>
                                    </div>
                                    <div className='row mt-2' style={{ fontSize: '12px' }}>
                                        <div className='col-6'>
                                            <span>Creata</span>
                                        </div>
                                        <div className='col-6'>
                                            <span>{moment(displayDetails.createdDate).format("ll")}</span>
                                        </div>
                                    </div>
                                    <div className='row mt-2' style={{ fontSize: '12px' }}>
                                        <div className='col-6'>
                                            <span>Copia URL</span>
                                        </div>
                                        <div className='col-6'>
                                            {/* <span><a href={displayDetails.qrCodeUrl}>{displayDetails.qrCodeUrl}</a></span> */}
                                            <CopyToClipboard text={displayDetails.qrCodeUrl} onCopy={onCopy}>
                                                <span><i className="fa fa-copy" style={{fontSize:"36px"}}></i></span>
                                            </CopyToClipboard>
                                            <div className="copyMsg" style={{background: "black",color: "white",textAlign: "center",padding: "1px",borderRadius: "9px", display:'none'}}>
                                            <h5>Copia</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    {/* <img src={"http://www.itemcare.it:8080/documents/"+} alt="QR Code" width="500" height="600" /> */}
                                   {/*  <QRCode value={ window.location.origin + "/after-scan/" + rkId} /> */}
                                    {/* <QRCode value={"http://itemcare.it/after-scan/" + fileFolderTypeScan + "/" + rkId} /> */}
                                    <QRCode value={rkId !== null ? rkId : ""}/>        

                                </div>

                            </div>
                            <div className="modal__footer cursor" onClick={handleClose}>
                                <div className="modal__container">
                                    <button className="button button--primary button--block" data-dismiss="modal"><span className="button__text" >Chiudere</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

