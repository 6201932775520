import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
// import {logoType} from "../assets/i"
import logoType from "../assets/img/content/logo.svg";
import authBg from "../assets/img/content/auth-bg.jpg";
import authBgDark from "../assets/img/content/auth-bg-dark.jpg";
import { adminLogin, clientLogin } from "../services/index"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Route, Link, BrowserRouter as Router, Switch } from "react-router-dom";

export const Login = props => {


    const history = useHistory();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loginType, setLoginType] = useState("admin")


    const handleLoginType = (e) => {
        setLoginType(e.target.value)
        console.log(e.target.value)

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLoginClick()
        }
    }


    const handleLoginClick = () => {
        var dict = {
            "password": password,
            "username": email
        }
        adminLogin(dict).then((res) => {
            if (res.errorCode == 0) {
                if(res.responsePacket.role == 'ROLE_ADMIN')
                {
                    var loginData = res.responsePacket;
                    localStorage.setItem('adminRecordId', loginData.recordId);
                    localStorage.setItem('itemOxygenUserName', loginData.username);
                    localStorage.setItem('itemOxygenRole', loginData.role);
                    var authToken = btoa(res.responsePacket.username + ':' + res.responsePacket.secretKey)
                    localStorage.setItem('itemOxygenAuth', authToken);
                    toast('✅' + res.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true, 
                        draggable: true,
                        progress: undefined,
                    });
                    history.push("/calendar");
                    window.location.reload(true)
                }
                else if(res.responsePacket.clientRoleEnum == 'CLIENTS')
                {
                    var loginData = res.responsePacket;
                    localStorage.setItem('itemOxygenUserName', loginData.username);
                    localStorage.setItem('itemOxygenRole', loginData.role);
                    localStorage.setItem('client_recordId', loginData.recordId);
                    var authToken = btoa(res.responsePacket.username + ':' + res.responsePacket.secretKey)
                    localStorage.setItem('itemOxygenAuth', authToken);
                    toast('✅' + res.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    history.push("/request");
                    window.location.reload(true)
                }
                else
                {
                    var loginData = res.responsePacket;
                    localStorage.setItem('itemOxygenUserName', loginData.username);
                    localStorage.setItem('itemOxygenRole', loginData.clientRoleEnum);
                    localStorage.setItem('client_recordId', loginData.recordId);
                    localStorage.setItem('testData', "test");
                    var authToken = btoa(res.responsePacket.username + ':' + res.responsePacket.secretKey)
                    localStorage.setItem('itemOxygenAuth', authToken);
                    toast('✅' + res.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    history.push("/request");
                    window.location.reload(true)

                }

            } else {
                toast('❌' + res.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch((err) => {

        })

    }

    useEffect(() => {
        console.log(loginType)
    }, []);


    // const goTo = (path) => {
    //     history.push(path || ROOT);
    // }
    return (
        <div className="page-wrapper h-100">
            <main className="page-auth">
                <div className="page-auth__center">
                    <div className="page-auth__gradient">
                        <div className="page-auth__gradient-shape" />
                        <div className="page-auth__gradient-shape" />
                        <div className="auth-logo">
                            <img className="auth-logo__icon" src={logoType} width={44} alt="#" />
                            {/* <div className="auth-logo__text">item care</div> */}
                        </div>
                        <div className="page-auth__gradient-column" />
                    </div>
                    <div className="auth-card card">
                        <div className="auth-card__shape" />
                        <div className="auth-card__shape" />
                        <div className="card__wrapper">
                            <div className="auth-card__left bg-white">
                                <div className="auth-card__logo">
                                    <div className="auth-logo">
                                        <img className="auth-logo__icon" src={logoType} width={44} alt="#" style={{width: '200%'}} />
                                        {/* <div className="auth-logo__text">item care</div> */}
                                    </div>
                                </div>
                                <img className="auth-card__bg auth-bg-image-dark" src={authBgDark} alt="#" />
                            </div>
                            <form className="auth-card__right bg-white" method="POST">
                                <div className="auth-card__top">
                                    <h1 className="auth-card__title">Benvenuto in <span className="text-theme">item care</span></h1>
                                    <p className="auth-card__text">Bentornato per, Favore accedi 
                                        <br />al tuo account.</p>
                                </div>
                               {/*  <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Accedi Come</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={loginType}
                                        onChange={handleLoginType}
                                    >
                                        <FormControlLabel control={<Radio value="admin" />} label="Amministratore" />
                                        <FormControlLabel control={<Radio value="client" />} label="Cliente" />
                                    </RadioGroup>
                                </FormControl> */}
                                <div className="auth-card__body">
                                    <div className="form-group">
                                        <div className="input-group input-group--prepend"><span className="input-group__prepend">
                                            <svg className="icon-icon-user">
                                                <use xlinkHref="#icon-user" />
                                            </svg></span>
                                            <input className="input" onChange={(e) => setEmail(e.target.value)} value={email} type="email" required />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group input-group--prepend"><span className="input-group__prepend">
                                            <svg className="icon-icon-password">
                                                <use xlinkHref="#icon-password" />
                                            </svg></span>
                                            <input className="input" type="password"  onKeyPress={handleKeyPress} onChange={(e) => setPassword(e.target.value)} value={password} required />
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        {/* <div className="col">
                                            <div className="form-group">
                                                <div className="input-group input-group--prepend">
                                                    <label className="checkbox">
                                                        <input type="checkbox" defaultChecked /><span className="checkbox__marker"><span className="checkbox__marker-icon"><svg viewBox="0 0 14 12">
                                                            <path d="M11.7917 1.2358C12.0798 1.53914 12.0675 2.01865 11.7642 2.30682L5.7036 8.06439C5.40574 8.34735 4.93663 8.34134 4.64613 8.05084L2.22189 5.6266C1.92604 5.33074 1.92604 4.85107 2.22189 4.55522C2.51774 4.25937 2.99741 4.25937 3.29326 4.55522L5.19538 6.45734L10.7206 1.20834C11.024 0.920164 11.5035 0.93246 11.7917 1.2358Z" />
                                                        </svg></span></span><span className="d-inline-block ml-2">Remember Me</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-auto">
                                            <div className="form-group"><a className="text-blue" href="auth-forgot-v2.html">Forgot Password?</a>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="auth-card__bottom">
                                    <div className="auth-card__buttons">
                                        {/* <div className="auth-card__button"><a className="button button--secondary button--block" href="auth-create-v2.html"><span className="button__text">Sign Up</span></a>
                                        </div> */}
                                        <div className="auth-card__button">
                                            <button className="button button--primary button--block" onClick={handleLoginClick} type="button"><span className="button__text">Accedi</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <p>Ver-1.79</p>
                                <Link to="/ForgotPassword">Passowrd dimenticata ?</Link>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
            <ToastContainer />
        </div>

    )
}

