// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from 'axios'
import { ADMIN_LOGIN, CLIENT_LOGIN, ADMIN_URL,CLIENT_REQUEST,USER_CLIENT,MESSAGE_URL } from "./CONSTANTS";
const auth = localStorage.getItem('itemOxygenAuth')

const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': auth != undefined ? "Basic " + auth : ''
    // 'Authorization': url.Auth_Token
}

/**
 * Function to Login the Admin.
 */
export const adminLogin = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(ADMIN_LOGIN(), data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in signup axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const clientLogin = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(CLIENT_LOGIN(), data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in signup axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const profileDetail = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(ADMIN_URL() + "profileDetail", {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in profileDetail axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateAdmin = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(ADMIN_URL() + "updateProfileDetail", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in adminDetail axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const openNotification = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(CLIENT_REQUEST() + "requestListForAdminForNotification", data,{
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in notification axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getClientKeyValueList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_CLIENT() + "getClientListInKeyValue", {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getClientListData = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_CLIENT() + "get/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getMessageList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(MESSAGE_URL() + "messageList", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in adminDetail axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getUserMessageList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(MESSAGE_URL() + "messageListByRequestIdForUser", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in adminDetail axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const sendMessage = (data) => {
    console.log(MESSAGE_URL);
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(MESSAGE_URL() + "saveMessage", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addMessage axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updatePassword = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(ADMIN_URL() + "resetPassword" , data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in changepassword axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateClientPassword = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_CLIENT() + "resetPassword" , data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in changepassword axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const forgotPassword = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(ADMIN_URL() + "forgotPasswordRequest" , data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in forgot password axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const verifyOTPData = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(ADMIN_URL() + "forgotPassword" , data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in verifypassword axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const openClientNotification = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_CLIENT() + "requestListForClient", data,{
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in notification axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const openUserNotification = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_CLIENT() + "requestListForClientByClientId", data,{
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in notification axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const ticketStatus = (ticketListId) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_CLIENT() + "closeRequest/" + ticketListId, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getSearchByDate = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(CLIENT_REQUEST() + "requestListForAdminForNotificationByDate", data,{
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in notification axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getTicketList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(CLIENT_REQUEST() + "requestListForAdminForNotificationByStatus", data,{
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in notification axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getTicketListByUser = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(CLIENT_REQUEST() + "requestListForClientForNotificationByStatus", data,{
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in notification axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getAdminMessageList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(MESSAGE_URL() + "messageListByRequestIdForAdmin", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in adminDetail axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addRequestByAdmin = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                    .post(CLIENT_REQUEST() + "addRequestForUserByAdmin", data, {
                        headers: headersdata,
                    })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject("Error in addRequestForUserByAdmin axios!");
                    });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addRequestByUser = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                    .post(CLIENT_REQUEST() + "addRequestForUser", data, {
                        headers: headersdata,
                    })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject("Error in addRequestForUserByAdmin axios!");
                    });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getSearchByDateUser = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(CLIENT_REQUEST() + "requestListForClientForNotificationByDate", data,{
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in notification axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};