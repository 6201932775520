import {
    ADD_LOGIN_DATA, ADD_LOGIN_AUTH
} from "../actions/loginActions";

const initialState = {
    loginData: {},
    auth: "",
};

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_LOGIN_DATA:
            return {
                ...state,
                loginData: action.payload,
            };
        case ADD_LOGIN_AUTH:
            return {
                ...state,
                auth: action.payload,
            };
        default:
            return state;
    }
};
