import React, { Component } from 'react'
import { useEffect, useState } from "react";
import DataTable, { createTheme, FilterComponent } from 'react-data-table-component';
import { Modal, Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Swal from "sweetalert2";
import Select from 'react-select';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import {
    getClientKeyValueList, getTaskSlotKeyValue, getTemplatesKeyValueList, getOperatorKeyValueList,
    getDepartmentKeyvalueList, assignTask, getTaskWorkOrderList, getTaskTemplate, generateTaskSheet, updateTaskSheet
} from "../services/index"
import { CommanComponents } from '../components';
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import jspdf from 'jspdf'
import $ from "jquery";
import { display } from '@mui/system';

import loader from "../assets/img/content/loader.svg";
import { ReactFormBuilder } from "react-form-builder2";
import { useHistory } from 'react-router-dom'

export const Print = () => {

    const [rows, setRows] = useState([])
    const [open, setOpen] = useState(false);
    const [openDetails, setDetails] = useState(false);

    const [taskTemplateKeyValue, setTaskTemplateKeyValue] = useState([])
    const [taskTemplateValue, setTaskTemplateValue] = useState(null)
    const [operatorKeyValue, setOperatorKeyValue] = useState([])
    const [operatorValue, setOperatorValue] = useState(null)
    const [hospitalsKeyValue, setHospitalsKeyValue] = useState([])
    const [hospitalValue, setHospitalValue] = useState(null)
    const [departmentsKeyValue, setDepartmentsKeyValue] = useState([])
    const [departmentValue, setDepartmentValue] = useState(null)
    const [slotTypeKeyValue, setSlotTypeKeyValue] = useState([])
    const [slotType, setSlotType] = useState(null)
    const [weekDay, setWeekDay] = useState(null)
    const [show, setShow] = useState(false)
    const [formData, setFormData] = useState([])
    const history = useHistory();   

    

    const getAssignedtaskList = () => {
        var taskDataList = JSON.parse(localStorage.getItem('taskDataList'));
        console.log(taskDataList.subTaskJson);
        //debugger;
        if (taskDataList.recordId != 0) {
            var formDataEditList = taskDataList.subTaskJson;
            //console.log(taskDataList.subTaskJson);
            setFormData(formDataEditList.task_data);
            var taskTemplateTitle = taskDataList.taskTitle
            console.log("dict", formData)
            console.log("dict", taskTemplateTitle)

        } else {
            toast('❌' + taskDataList.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } 
        
    }

    const onLoad = () => {
        console.log(formData);
        return new Promise((resolve, reject) => {
            resolve(formData);
          });
    };



    useEffect(() => {
        getAssignedtaskList()
    }, []); 
  return (
    <>
     <div className="sidebar-backdrop" />
          <div className="page-wrapper">
              <CommanComponents />
              <main className="page-content">
                  <div className="container">
                      <div className='row mb-2 justify-content-between align-items-center'>
                          <div style={{ fontSize: '20px', fontWeight: "500" }}>
                              <span>Modello completato</span>
                          </div>
                      </div>
                      <div className="row">
                            <div id="pdf" class="resulted">
                                <ReactFormBuilder onLoad={onLoad}/> 
                            </div>
                        </div>
                        <Button variant="primary">
                        Esporta in formato PDF
                        </Button>
                        <Button variant="secondary">
                        Chiudere
                        </Button>
                  </div>
              </main>
          </div>
        <ToastContainer />
    </>
  )
}