// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from "axios";
import {
  USER_CLIENT,
  USER_DEPARTMENT,
  ROOM_URL,
  USER_TASK_TEMPLATES,
  USER_OPERATOR,
  CLIENT_REQUEST,
  BASE,
} from "./CONSTANTS";

const auth = localStorage.getItem("itemOxygenAuth");

const headersdata = {
  "content-type": "application/json",
  Accept: "application/json",
  Authorization: auth != undefined ? "Basic " + auth : "",
  // 'Authorization': url.Auth_Token
};

// DELETE_URL
export const deleteCompletedTask = (recId) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .delete(BASE()+"taskTemplate/delete/"+recId, {
            headers: headersdata,
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in deleteCompletedTask axios!");
          });
      } catch (error) {
        reject(SYSTEM_ERROR);
      }
    });
  };

  export const deleteRequestedTask = (recId) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .delete(BASE()+"clientRequest/delete/"+recId, {
            headers: headersdata,
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in deleteRequestedTask axios!");
          });
      } catch (error) {
        reject(SYSTEM_ERROR);
      }
    });
  };


export const getClientList = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(USER_CLIENT() + "list", data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
/**
 * Function to Get User Client List.
 */
export const getClientKeyValueList = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(USER_CLIENT() + "getClientListInKeyValue", data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getuserAssociatedClientsList = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(USER_CLIENT() + "userAssociatedClientsInKeyValue", {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
/**
 * Function to Add User Client List.
 */
export const addClient = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(USER_CLIENT() + "add", data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
/**
 * Function to active User Client List.
 */
export const activeClient = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(
          USER_CLIENT() + "active/" + uuid,
          {},
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
/**
 * Function to In-active User Client List.
 */
export const inActiveClient = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(
          USER_CLIENT() + "inactive/" + uuid,
          {},
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getUserClientDetails = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(USER_CLIENT() + "get/" + uuid, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getUserClientDetails axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const deleteUserClient = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(USER_CLIENT() + "delete/" + uuid, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in deleteUserClient axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const updateClient = (uuid, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(USER_CLIENT() + "update/" + uuid, data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in deleteUserClient axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const getClientDepartmentListInKeyValue = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(USER_DEPARTMENT() + "getClientDepartmentListInKeyValue", {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const addRoom = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ROOM_URL() + "addRoom", data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in addRoom axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getRoomListData = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ROOM_URL() + "roomList", data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in addRoom axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const updateRoom = (uuid, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(ROOM_URL() + "updateRoom/" + uuid, data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in updateRoom axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const activeRoom = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(
          ROOM_URL() + "activeRoom/" + uuid,
          {},
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
/**
 * Function to In-active User Client List.
 */
export const inActiveRoom = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(
          ROOM_URL() + "inactiveRoom/" + uuid,
          {},
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const deleteRoom = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(ROOM_URL() + "deleteRoom/" + uuid, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in deleteRoom axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const getRoomByID = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ROOM_URL() + "getRoomById/" + uuid, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getUserClientDetails axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getDepartmentDataByHospital = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          USER_DEPARTMENT() +
            "getClientDepartmentListInKeyValueByClientId/" +
            uuid,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getClientDepartmentListInKeyValueByClientId axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const getRoomListInKeyValue = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ROOM_URL() + "getRoomListInKeyValue", {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const getRoomDataByDepartment = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ROOM_URL() + "getRoomListInKeyValue", data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getClientDepartmentListInKeyValueByClientId axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const deleteTaskTemplate = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(USER_TASK_TEMPLATES() + "deleteTaskTemplate/" + uuid, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in deleteTaskTemplate axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getClientListListInKeyValueByOperatorId = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          USER_OPERATOR() + "getClientListListInKeyValueByOperatorId/" + uuid,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getClientDepartmentListInKeyValueByClientId axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
