import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
// import {logoType} from "../assets/i"
import logoType from "../assets/img/content/logo.svg";
import authBg from "../assets/img/content/auth-bg.jpg";
import authBgDark from "../assets/img/content/auth-bg-dark.jpg";
import { adminLogin, clientLogin ,forgotPassword,verifyOTPData} from "../services/index"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Route, Link, BrowserRouter as Router, Switch } from "react-router-dom";
import loader from "../assets/img/content/loader.svg"
import $ from "jquery"; //Load jquery
import { Modal, Button, Form } from 'react-bootstrap';

export const VerifyOTP = () => {

    const [userPassword, setUserPassword] = useState({
        "otp": "",
        "password": "",
        "confirmpassword": ""
    });
    const history = useHistory();

    const handleUserPassword = (e) => {
        setUserPassword({
            ...userPassword,
            [e.target.name]: e.target.value
        })
    }

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(null)
    const [openResendModal, setOpenResendModal] = useState(false)

    const handleClose = () => {
        setOpen(false);
        history.push("/");
    }

    const handleResendClose = () => {
        setOpenResendModal(false);
        setUserPassword({
          ...userPassword,
            otp: "",
        })
        //$("#otp").val();
    }


    const handleVerifyClick = () =>
    {
        var forgotPasswordUserEmail = localStorage.getItem("forgotPasswordUserEmail");

         var dict = {
            oneTimePassword: userPassword.otp,
            password : userPassword.password,
            confirmPassword: userPassword.confirmpassword,
            email:forgotPasswordUserEmail
        }
        if (dict.oneTimePassword === null || dict.oneTimePassword === "") {
            toast("Please enter verfication code !",
              {
                icon: '🙁',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }
            );
            return
        }
        else if (dict.password === null || dict.password === "") {
            toast("Please enter new password !",
              {
                icon: '🙁',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }
            );
            return
        }
        else if (dict.confirmPassword === null || dict.confirmPassword === "") {
            toast("Please enter confirmpassword !",
              {
                icon: '🙁',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }
            );
            return
        }
        else{
            $('.se-pre-con').css('display','block');
            verifyOTPData(dict).then((res) => {
                if (res.errorCode == 0) {
                    $('.se-pre-con').css('display','none');
                    var message=res.message;
                    setMessage(message);
                    setOpen(true)
    
                } else {
                    $('.se-pre-con').css('display','none');
                    toast('❌' + res.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
            });
        }
    }

    const handleResendOTP = ()=>{

        var resendOTPEmail=localStorage.getItem("forgotPasswordUserEmail")
        var dict = {
            "email": resendOTPEmail
        }
        forgotPassword(dict).then((res) => {
            if (res.errorCode == 0) {
                $('.se-pre-con').css('display','none');
                var message=res.message;
                setMessage(message);
                setOpenResendModal(true)
                localStorage.setItem('forgotPasswordUserEmail', resendOTPEmail);
                /* setTimeout(() => {
                    history.push("/VerifyOTP");
                }, 3000) */
            
            } else {
                $('.se-pre-con').css('display','none');
                toast('❌' + res.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });

    }
  return (
    <>
        <div className="page-wrapper h-100">
            <main className="page-auth">
                <div className="page-auth__center">
                    <div className="page-auth__gradient">
                        <div className="page-auth__gradient-shape" />
                        <div className="page-auth__gradient-shape" />
                        <div className="auth-logo">
                            <img className="auth-logo__icon" src={logoType} width={44} alt="#" />
                            {/* <div className="auth-logo__text">item care</div> */}
                        </div>
                        <div className="page-auth__gradient-column" />
                    </div>
                    <div className="auth-card card">
                        <div className="auth-card__shape" />
                        <div className="auth-card__shape" />
                        <div className="card__wrapper">
                            <div className="auth-card__left bg-white">
                                <div className="auth-card__logo">
                                    <div className="auth-logo">
                                        <img className="auth-logo__icon" src={logoType} width={44} alt="#" style={{width: "200%"}}/>
                                        {/* <div className="auth-logo__text">item care</div> */}
                                    </div>
                                </div>
                                {/* <img className="auth-card__bg auth-bg-image-light" src={authBg} alt="#" /> */}
                                <img className="auth-card__bg auth-bg-image-dark" src={authBgDark} alt="#" />
                            </div>
                            <div className="auth-card__right bg-white">
                                <div className="auth-card__top">
                                    <h1 className="auth-card__title">Benvenuto in <span className="text-theme">item care</span></h1>
                                    <p className="auth-card__text">Passowrd dimenticata</p>
                                </div>
                                <div className="auth-card__body">
                                    <div className="form-group">
                                        <div className="input-group input-group--prepend"><span className="input-group__prepend">
                                            <svg className="icon-icon-password">
                                                <use xlinkHref="#icon-password" />
                                            </svg></span>
                                            <input className="input"  id="otp" name="otp" value={userPassword.otp} onChange={(e) => handleUserPassword(e)} placeholder='Inserisci la OTP' required />
                                        </div>
                                    </div>
                                    <div className='text-right'>
                                    <button className="button button--primary" type="button" onClick={handleResendOTP}><span className="button__text">Resend OTP</span>
                                        </button>
                                    </div>
                                    
                                </div>
                                <div className="auth-card__body">
                                    <div className="form-group">
                                        <div className="input-group input-group--prepend"><span className="input-group__prepend">
                                            <svg className="icon-icon-password">
                                                <use xlinkHref="#icon-password" />
                                            </svg></span>
                                            <input className="input" type="password"  value={userPassword.password} name="password" onChange={(e) => handleUserPassword(e)} placeholder='Nuova Password' required />
                                        </div>
                                    </div>
                                </div>
                                <div className="auth-card__body">
                                    <div className="form-group">
                                        <div className="input-group input-group--prepend"><span className="input-group__prepend">
                                            <svg className="icon-icon-password">
                                                <use xlinkHref="#icon-password" />
                                            </svg></span>
                                            <input className="input" type="password" name="confirmpassword" value={userPassword.confirmpassword} onChange={(e) => handleUserPassword(e)} placeholder='Conferma Password' required />
                                        </div>
                                    </div>
                                </div>
                                <div className="auth-card__bottom">
                                    <div className="auth-card__buttons">
                                        {/* <div className="auth-card__button"><a className="button button--secondary button--block" href="auth-create-v2.html"><span className="button__text">Sign Up</span></a>
                                        </div> */}
                                        <div className="auth-card__button">
                                            <button className="button button--primary button--block" type="button" onClick={handleVerifyClick}><span className="button__text">Verifica</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ToastContainer />
        </div>
        <div className="se-pre-con" style={{display:'none'}}> 
                <img src={loader}/>
        </div>
        <Modal show={open} className={open ? "is-active is-animate" : ""} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                            <p className="text-center">{message}</p>            
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Chiudere
                    </Button>
                </Modal.Footer>
        </Modal>
        <Modal show={openResendModal} className={openResendModal ? "is-active is-animate" : ""} onHide={handleResendClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                            <p className="text-center">{message}</p>            
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleResendClose()}>
                        Chiudere
                    </Button>
                </Modal.Footer>
        </Modal>

    </>
  )
}

