/**
 * We purposely added 2 reducers for the example of combineReducers method.
 * There can be only 1 or even more than 2 reducers.
 * combineReducers defines the structure of the store object.
 */
import { combineReducers } from "redux";
import { appReducer } from "./appReducer";
import { loginReducer } from "./loginReducer"
import { commonReducer } from "./commonReducer"

export const rootReducer = combineReducers({
    app: appReducer,
    admin: loginReducer,
    common: commonReducer
})
