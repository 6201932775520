// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from 'axios'
import { USER_TASK_TEMPLATES,USER_DEPARTMENT,ROOM_URL } from "./CONSTANTS";

const auth = localStorage.getItem('itemOxygenAuth')

const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': auth != undefined ? "Basic " + auth : ''
    // 'Authorization': url.Auth_Token
}


/**
 * Function to Get User Operators List.
 */
export const getTemplatesKeyValueList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_TASK_TEMPLATES() + "taskTemplateList", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
/**
 * Function to Get Temapltes By UUID.
 */
export const getTaskTemplate = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_TASK_TEMPLATES() + "getTaskTemplate/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
/**
 * Function to Create Temaplte.
 */
export const generateTaskSheet = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_TASK_TEMPLATES() + "generateTaskSheet", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
/**
 * Function to Update Temaplte.
 */
export const updateTaskSheet = (data, recordId) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_TASK_TEMPLATES() + "updateTaskSheet/" + recordId, data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getTaskSlotKeyValue = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_TASK_TEMPLATES() + "getTaskSlotTypeInKeyValue/", {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

/* export const getTaskWorkOrderList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_TASK_TEMPLATES() + "taskWorkOrderList/", {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getTaskWorkOrderList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
 */
export const getTaskWorkOrderList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_TASK_TEMPLATES() + "taskWorkOrderList", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getTaskWorkOrderListDate = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_TASK_TEMPLATES() + "taskWorkOrderListForDate", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getClientTaskWorkList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_TASK_TEMPLATES() + "taskWorkOrderListForClient", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const deleteAssignOperator = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(USER_TASK_TEMPLATES() + "delete/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deleteAssignOperator axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const deleteMultipleTask = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_TASK_TEMPLATES() + "deleteMultipleTask" , data,  {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deleteMultipleTask axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getAssignOperatorDetails = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_TASK_TEMPLATES() + "getTaskWorkOder/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getTaskWorkOder axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getTaskWorkOderWithoutAuth = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_TASK_TEMPLATES() + "getTaskWorkOderWithoutAuth/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getTaskWorkOder axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateAssignOperator = (uuid, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(USER_TASK_TEMPLATES() + "updateTaskSheet/" + uuid, data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateTaskSheet axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getRequestedTaskWorkOrderList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_TASK_TEMPLATES() + "reportedProblemTaskWorkOrderList", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in reportedProblemTaskWorkOrderList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getDepartmentDataByHospital = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_DEPARTMENT() + "getClientDepartmentListInKeyValueByClientId/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getClientDepartmentListInKeyValueByClientId axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getRoomDataByDepartment = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(ROOM_URL() + "getRoomListInKeyValue", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getClientDepartmentListInKeyValueByClientId axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getRoomListInKeyValue = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(ROOM_URL() + "getRoomListInKeyValue", {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const upcomingTaskWorkOrderList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_TASK_TEMPLATES() + "upcomingTaskWorkOrderList", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const upcomingTaskWorkOrderListForUser = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(USER_TASK_TEMPLATES() + "upcomingTaskWorkOrderListForUser", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};