import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { getDetails, deleteRecord, activeQR } from "../../services/index"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from "redux"
import { actionCreator } from "../../redux/index"

var itemOxygenRole = localStorage.getItem("itemOxygenRole")

export const FileRowComponent = props => {
    const history = useHistory();
    const [fileData, setFileData] = useState(null)
    const [fileSize, setFileSize] = useState("");
    //Redux Start
    const state = useSelector((state) => state);

    const dispatch = useDispatch()

    const { addSideModelOpen, addFileFolderDetails, addFolderNavigationBar, addParentFolderId } = bindActionCreators(actionCreator, dispatch)

    //Redux End

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const handleViewDetails = () => {
        getDetails(fileData.recordId).then((res) => {
            console.log(res.responsePacket)
            addFileFolderDetails(res.responsePacket)
        }).catch((err) => {
            alert(err)
        })
        addSideModelOpen(true)
        activeQR(fileData.recordId).then((res) => {
            if (res.errorCode === 0) {
                addFileFolderDetails(res.responsePacket)
            }
        }).catch((err) => {
            toast(err,
                {
                    icon: '🙁',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        })
    }

    const handleDelete = () => {
        deleteRecord(fileData.recordId).then((res) => {
            if (res.errorCode == 0) {
                toast(res.message,
                    {
                        icon: '👏',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            } else {
                toast(res.message,
                    {
                        icon: '🙁',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            }
            // alert("Success")
            window.location.reload(true)
        }).catch((err) => {
            toast(err,
                {
                    icon: '🙁',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        })
    }



    useEffect(() => {
        if (props.data != null) {
            setFileData(props.data)
            setFileSize(formatBytes(props.data.fileSizeInBits))
        }
    }, [props.data]);

    return (
        <>
            <tr className="table__row" >
                <td className="table__td">
                    <div className="table__checkbox table__checkbox--all">
                        <label className="checkbox">
                            <input type="checkbox" data-checkbox="files" />
                            <span className="checkbox__marker">
                                <span className="checkbox__marker-icon">
                                    <svg viewBox="0 0 14 12">
                                        <path d="M11.7917 1.2358C12.0798 1.53914 12.0675 2.01865 11.7642 2.30682L5.7036 8.06439C5.40574 8.34735 4.93663 8.34134 4.64613 8.05084L2.22189 5.6266C1.92604 5.33074 1.92604 4.85107 2.22189 4.55522C2.51774 4.25937 2.99741 4.25937 3.29326 4.55522L5.19538 6.45734L10.7206 1.20834C11.024 0.920164 11.5035 0.93246 11.7917 1.2358Z" />
                                    </svg>
                                </span>
                            </span>
                        </label>
                    </div>
                </td>
                <td className="table__td">
                    <div className="media-item media-item--file">
                        <div className="media-item__icon-file">
                            <svg className="icon-icon-doc">
                                <use xlinkHref="#icon-doc" />
                            </svg>
                        </div>
                        <div className="media-item__right">
                            <h5 className="media-item__title"><span className="text-clamp">{fileData != null ? fileData.visibleFileName : ""}</span></h5>
                        </div>
                    </div>
                </td>
                <td className="table__td text-grey text-uppercase">{fileSize}</td>
                <td className="table__td text-uppercase">DOC</td>
                <td className="table__td text-grey">
                    <time className="text-nowrap" dateTime="2019-07-12">12.07.2019</time>
                </td>
                <td className="table__td table__actions">
                    <div className="items-more">
                        
                        <button className="items-more__button">
                                <svg className="icon-icon-dots">
                                    <use xlinkHref="#icon-dots" />
                                </svg>
                            </button>
                        
                        <div className="dropdown-items dropdown-items--right">
                            <div className="dropdown-items__container">
                                <ul className="dropdown-items__list">
                                    <li className="dropdown-items__item" onClick={handleViewDetails}>
                                        <a className="dropdown-items__link">
                                            <span className="dropdown-items__link-icon">
                                                <svg className="icon-icon-detail">
                                                    <use xlinkHref="#icon-detail" />
                                                </svg>
                                            </span>
                                            Visualizza dettagli
                                        </a>
                                    </li>
                                {/* {itemOxygenRole ==="ROLE_ADMIN" && 
                                }    */}
                                    <li className="dropdown-items__item" onClick={handleDelete}>
                                        <a className="dropdown-items__link">
                                            <span className="dropdown-items__link-icon">
                                                <svg className="icon-icon-trash">
                                                    <use xlinkHref="#icon-trash" />
                                                </svg>
                                            </span>
                                            Elimina
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr className="table__space">
                <td colSpan={6} />
            </tr>
            <ToastContainer />
        </>
    )
}

// LoaderComponent.propTypes = {
//     title: PropTypes.string.isRequired
// }

// export default LoaderComponent
