import React, { useEffect, useState } from 'react'
import { HeaderComponent, SideBarComponent, SideModel } from "./index"

export function CommanComponents(props) {
    useEffect(() => {
        window.closeSlider()
    }, [])
    return (
        <>
            <HeaderComponent />
            <SideBarComponent />
            <SideModel />
        </>
    );
}
