import React, { useState, useEffect } from "react";
import { Route, Link, BrowserRouter as Router, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
// import {logoType} from "../assets/i"
import logoType from "../assets/img/content/logotype.svg";
import item4 from "../assets/img/content/humans/item-4.jpg";
import authBgDark from "../assets/img/content/auth-bg-dark.jpg";
import { useSelector, useDispatch } from "react-redux";
import { StaticTimePicker } from "@mui/lab";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Modal, Button, Form } from "react-bootstrap";
import {
  profileDetail,
  updateAdmin,
  openNotification,
  openClientNotification,
  getClientKeyValueList,
  getuserAssociatedClientsList,
  getClientListData,
  updatePassword,
  updateClientPassword,
} from "../services/index";
import { ToastContainer, toast } from "react-toastify";
import * as moment from "moment";
import Select from "react-select";
import $ from "jquery";

export const HeaderComponent = (props) => {
  //Redux below Start
  const history = useHistory();
  const state = useSelector((state) => state);

  //Redux End

  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [recordId, setRecordId] = useState("");
  const [notification, setNotification] = useState([]);
  const [open, setOpen] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [password, setPassword] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const [hospitalsKeyValue, setHospitalsKeyValue] = useState([]);
  const [hospitalValue, setHospitalValue] = useState();

  const handleOpenNotification = () => {
    var rId = localStorage.getItem("rId");
    if (rId != undefined) {
      var recordIdForList = rId;
    } else {
      var recordIdForList = "";
    }
    var dict = {
      requestedPage: 0,
      requestedPageSize: -1,
      searchKey: "",
      clientId: recordIdForList,
    };
    openNotification(dict).then((res) => {
      if (res.errorCode === 0) {
        const data = res.responsePacket;
        setNotification(data);
      }
    });
  };

  const handleOpenClientNotification = () => {
    var rId = localStorage.getItem("rId");
    if (rId != undefined) {
      var recordIdForList = rId;
    } else {
      var recordIdForList = "";
    }
    var dict = {
      requestedPage: 0,
      requestedPageSize: -1,
      searchKey: "",
      clientId: recordIdForList,
    };
    openClientNotification(dict).then((res) => {
      if (res.errorCode === 0) {
        const data = res.responsePacket;
        setNotification(data);
      }
    });
  };

  const setOKOpen = (e) => {
    localStorage.setItem("testData", "");

    setHospitalValue({
      ...hospitalValue,
      [e.name]: e.value,
    });

    let dict = {
      value: e.value,
      label: e.label,
    };
    console.log(dict);
    setHospitalValue(dict);
    localStorage.setItem("rId", e.value);
    window.location.reload(false);
  };

  const handleLogout = () => {
    confirmAlert({
      title: "Logout",
      message: "Sei sicuro di uscire ?",
      buttons: [
        {
          label: "sì",
          onClick: () => {
            localStorage.removeItem("itemOxygenUserName");
            localStorage.removeItem("itemOxygenRole");
            localStorage.removeItem("itemOxygenAuth");
            localStorage.removeItem("itemOxygenLoginData");
            localStorage.removeItem("clientRecordId");
            localStorage.removeItem("client_recordId");
            localStorage.removeItem("adminRecordId");
            localStorage.removeItem("qrrecordId");
            localStorage.removeItem("rId");
            localStorage.removeItem("testData");
            window.location.href = "/";
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleClose = () => setOpen(false);
  const handleCloseChangePassowrd = () => setOpenChangePassword(false);

  const handleOpen = (id) => {
    setOpen(true);
    profileDetail().then((res) => {
      if (res.errorCode === 0) {
        let data = res.responsePacket;
        let dict = {
          fullName: data.fullName,
          email: data.email,
          mobile: data.mobile,
        };
        setDetails(dict);
      }
    });
  };
  const handleOpenChangePassword = (id) => {
    setOpenChangePassword(true);
    profileDetail().then((res) => {
      if (res.errorCode === 0) {
        let data = res.responsePacket.recodId;
        setRecordId(data);
      }
    });
  };

  const handleOpenClientChangePassword = (id) => {
    setOpenChangePassword(true);
    profileDetail().then((res) => {
      if (res.errorCode === 0) {
        let data = res.responsePacket.recodId;
        setRecordId(data);
      }
    });
  };

  const handleUpdatePassword = () => {
    let dict = {
      oldPassword: password.oldpassword,
      password: password.newpassword,
      confirmPassword: password.confirmpassword,
    };
    if (localStorage.getItem("itemOxygenRole") == "ROLE_ADMIN") {
      updatePassword(dict)
        .then((res) => {
          if (res.errorCode == 0) {
            toast(res.message, {
              icon: "👏",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            handleCloseChangePassowrd();
          } else {
            toast(res.message, {
              icon: "🙁",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          }
        })
        .catch((err) => {
          toast(err, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        });
    } else {
      updateClientPassword(dict)
        .then((res) => {
          if (res.errorCode == 0) {
            toast(res.message, {
              icon: "👏",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            handleCloseChangePassowrd();
          } else {
            toast(res.message, {
              icon: "🙁",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          }
        })
        .catch((err) => {
          toast(err, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        });
    }
  };

  const handleProfile = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const handleProfilePassword = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateProfile = () => {
    updateAdmin(details)
      .then((res) => {
        if (res.errorCode == 0) {
          localStorage.removeItem("itemOxygenUserName");
          localStorage.removeItem("itemOxygenRole");
          localStorage.removeItem("itemOxygenAuth");
          var loginData = res.responsePacket;
          localStorage.setItem("adminRecordId", loginData.recordId);
          localStorage.setItem("itemOxygenUserName", loginData.username);
          localStorage.setItem("itemOxygenRole", loginData.role);
          var authToken = btoa(
            res.responsePacket.username + ":" + res.responsePacket.secretKey
          );
          localStorage.setItem("itemOxygenAuth", authToken);

          toast(res.message, {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setDetails({
            email: "",
            name: "",
            mobile: "",
          });
          handleClose();
          history.push("/calendar");
          window.location.reload(true);
        } else {
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((err) => {
        toast(err, {
          icon: "🙁",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });
  };

  const handleClientSelected = () => {
    var rId = localStorage.getItem("rId");
    var itemOxygenRole = localStorage.getItem("itemOxygenRole");
    if (rId == "") {
      getClientKeyValueList().then((res) => {
        if (res.errorCode == 0) {
          const op = {
            value: "",
            label: "All",
          };
      
          setHospitalValue(op);
        } else {
          alert(res.message);
        }
      });
    } else {
      if (itemOxygenRole == "ROLE_ADMIN" && rId != undefined) {
        getClientListData(rId).then((res) => {
          let d = res.responsePacket;
          let dict = {
            value: d.recordId,
            label: d.fullName,
          };
          console.log(dict);
          setHospitalValue(dict);
        });
      } else if (itemOxygenRole == "ADMINISTRATION" && rId != undefined) {
        getClientListData(rId).then((res) => {
          console.log(res);
          let d = res.responsePacket;
          let dict = {
            value: d.recordId,
            label: d.fullName,
          };
          console.log(dict);
          setHospitalValue(dict);
        });
      }
    }
  };

  const handleOpenViewNotification = (clientId, loginType) => {
    if (loginType === "admin") {
      localStorage.setItem("openNotificationId", clientId);
      window.location.href = "/view-notification";
    } else {
      localStorage.setItem("openUserNotificationId", clientId);
      window.location.href = "/view-user-notification";
    }
  };

  useEffect(() => {
    //Runs only on the first render
    var userName = localStorage.getItem("itemOxygenUserName");
    var itemOxygenRole = localStorage.getItem("itemOxygenRole");
    setUserName(userName);
    setUserRole(itemOxygenRole);
    console.log(state);
    if (itemOxygenRole === "ROLE_ADMIN") {
      getClientKeyValueList().then((res) => {
        if (res.errorCode == 0) {
          const options = res.responsePacket.map((d) => ({
            value: d.option,
            label: d.value,
          }));
          const op = {
            value: "",
            label: "All",
          };
          options.unshift(op);
          //options.splice(0, 1, op);
          setHospitalsKeyValue(options);
        } else {
          alert(res.message);
        }
      });
    } else if (itemOxygenRole === "ADMINISTRATION") {
      getuserAssociatedClientsList().then((res) => {
        if (res.errorCode == 0) {
          const options = res.responsePacket.map((d) => ({
            value: d.option,
            label: d.value,
          }));
          const op = {
            value: "",
            label: "All",
          };
          options.unshift(op);
          setHospitalsKeyValue(options);
        }
      });
    }

    handleClientSelected();

  }, []);

  const headerTogleMenuClick = () => {
    window.headerTogleMenuClick();
  };

  return (
    <>
      <header className="header">
        <div className="header__inner">
          <div className="container-fluid">
            <div className="header__row row justify-content-between">
              <div className="header__col-left col d-flex align-items-center">
                <div className="header__left-toggle">
                  <button
                    className="header__toggle-menu toggle-sidebar"
                    type="button"
                    onClick={headerTogleMenuClick}
                  >
                    <svg className="icon-icon-menu">
                      <use xlinkHref="#icon-menu" />
                    </svg>
                  </button>
                  {userRole === "ROLE_ADMIN"||userRole === "ADMINISTRATION" ? (
                    <div style={{width:"300px"}}>
                      <Select
                        options={hospitalsKeyValue}
                        name="roundOffTo"
                        value={hospitalValue}
                        onChange={(e) => setOKOpen(e)}
                        id="roundOffTo"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
              
                </div>
              
              </div>
              <div className="header__col-right col d-flex align-items-center">
              
                <div className="header__tools">
                  <div className="header__notes header__tools-item">
                   
                    <div className="dropdown-menu">
                      <div className="dropdown-menu__top dropdown-menu__item">
                        <span className="dropdown-menu__title">
                          Notifications
                        </span>
                        <span className="badge badge--red">5</span>
                        <a
                          className="dropdown-menu__clear-all"
                          href="#"
                          role="button"
                        >
                          Clear All
                        </a>
                      </div>
                      <div
                        className="dropdown-menu__items scrollbar-thin scrollbar-visible"
                        data-simplebar="data-simplebar"
                      >
                        <div className="dropdown-menu__item">
                          <a className="dropdown-menu__item-remove" href="#">
                            <svg className="icon-icon-cross">
                              <use xlinkHref="#icon-cross" />
                            </svg>
                          </a>
                          <a
                            className="dropdown-menu__item-block dropdown-menu__note"
                            href="#"
                          >
                            <div className="dropdown-menu__item-left">
                              <div className="dropdown-menu__item-icon color-green">
                                <svg className="icon-icon-cart">
                                  <use xlinkHref="#icon-cart" />
                                </svg>
                              </div>
                            </div>
                            <div className="dropdown-menu__item-right">
                              <h4 className="dropdown-menu__item-title">
                                New Order Received
                              </h4>
                              <span className="dropdown-menu__item-time">
                                25 min ago
                              </span>
                            </div>
                          </a>
                        </div>
                        <div className="dropdown-menu__item">
                          <a className="dropdown-menu__item-remove" href="#">
                            <svg className="icon-icon-cross">
                              <use xlinkHref="#icon-cross" />
                            </svg>
                          </a>
                          <a
                            className="dropdown-menu__item-block dropdown-menu__note"
                            href="#"
                          >
                            <div className="dropdown-menu__item-left">
                              <div className="dropdown-menu__item-icon color-orange">
                                <svg className="icon-icon-bill">
                                  <use xlinkHref="#icon-bill" />
                                </svg>
                              </div>
                            </div>
                            <div className="dropdown-menu__item-right">
                              <h4 className="dropdown-menu__item-title">
                                New invoice received
                              </h4>
                              <span className="dropdown-menu__item-time">
                                5 hours ago
                              </span>
                            </div>
                          </a>
                        </div>
                        <div className="dropdown-menu__item">
                          <a className="dropdown-menu__item-remove" href="#">
                            <svg className="icon-icon-cross">
                              <use xlinkHref="#icon-cross" />
                            </svg>
                          </a>
                          <a
                            className="dropdown-menu__item-block dropdown-menu__note"
                            href="#"
                          >
                            <div className="dropdown-menu__item-left">
                              <div className="dropdown-menu__item-icon color-teal">
                                <svg className="icon-icon-truck">
                                  <use xlinkHref="#icon-truck" />
                                </svg>
                              </div>
                            </div>
                            <div className="dropdown-menu__item-right">
                              <h4 className="dropdown-menu__item-title">
                                new batch is shipped
                              </h4>
                              <span className="dropdown-menu__item-time">
                                10 hours ago
                              </span>
                            </div>
                          </a>
                        </div>
                        <div className="dropdown-menu__item">
                          <a className="dropdown-menu__item-remove" href="#">
                            <svg className="icon-icon-cross">
                              <use xlinkHref="#icon-cross" />
                            </svg>
                          </a>
                          <a
                            className="dropdown-menu__item-block dropdown-menu__note"
                            href="#"
                          >
                            <div className="dropdown-menu__item-left">
                              <div className="dropdown-menu__item-icon color-green">
                                <svg className="icon-icon-cart">
                                  <use xlinkHref="#icon-cart" />
                                </svg>
                              </div>
                            </div>
                            <div className="dropdown-menu__item-right">
                              <h4 className="dropdown-menu__item-title">
                                New Order Received
                              </h4>
                              <span className="dropdown-menu__item-time">
                                25 min ago
                              </span>
                            </div>
                          </a>
                        </div>
                        <div className="dropdown-menu__item">
                          <a className="dropdown-menu__item-remove" href="#">
                            <svg className="icon-icon-cross">
                              <use xlinkHref="#icon-cross" />
                            </svg>
                          </a>
                          <a
                            className="dropdown-menu__item-block dropdown-menu__note"
                            href="#"
                          >
                            <div className="dropdown-menu__item-left">
                              <div className="dropdown-menu__item-icon color-orange">
                                <svg className="icon-icon-bill">
                                  <use xlinkHref="#icon-bill" />
                                </svg>
                              </div>
                            </div>
                            <div className="dropdown-menu__item-right">
                              <h4 className="dropdown-menu__item-title">
                                New invoice received
                              </h4>
                              <span className="dropdown-menu__item-time">
                                5 hours ago
                              </span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="dropdown-menu__divider" />
                      <a
                        className="dropdown-menu__item dropdown-menu__link-all"
                        href="#"
                      >
                        Visualizza tutte le notifiche
                        <svg className="icon-icon-keyboard-right">
                          <use xlinkHref="#icon-keyboard-right" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  {userRole === "ADMINISTRATION" ? (
                    <div className="header__messages header__tools-item">
                      <a
                        onClick={handleOpenClientNotification}
                        className="header__tools-toggle header__tools-toggle--bell"
                        href="#"
                        data-tippy-content="Messages"
                        data-tippy-placement="bottom"
                        data-toggle="dropdown"
                      >
                        <svg className="icon-icon-bell">
                          <use xlinkHref="#icon-bell" />
                        </svg>
                        <span className="badge-signal" />
                      </a>
                      <div className="dropdown-menu">
                        <div className="dropdown-menu__top dropdown-menu__item">
                          <span className="dropdown-menu__title">
                            Notifichp
                          </span>
                          <span className="badge badge--red">
                            {notification.length}
                          </span>
                        </div>
                        <div
                          className="dropdown-menu__items scrollbar-thin scrollbar-visible"
                          data-simplebar="data-simplebar"
                        >
                          {notification.map((d, index) => {
                            if (index < 3)
                              return (
                                <div className="dropdown-menu__item" key={index}>
                                  <a
                                    className="dropdown-menu__item-remove"
                                    href="#"
                                  >
                                    <svg className="icon-icon-cross">
                                      <use xlinkHref="#icon-cross" />
                                    </svg>
                                  </a>
                                  <a
                                    className="dropdown-menu__item-block dropdown-menu__message"
                                    href="#"
                                  >
                                    <div
                                      className="dropdown-menu__item-right"
                                      onClick={() =>
                                        handleOpenViewNotification(
                                          d.clientId,
                                          "user"
                                        )
                                      }
                                    >
                                      <div className="dropdown-menu__item-column">
                                        <h4 className="dropdown-menu__item-title">
                                          {d.clientName}
                                        </h4>
                                        <p className="dropdown-menu__text">
                                          Richiesto in questa data{" "}
                                          {moment(d.requestedDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </p>
                                      </div>
                                      {/* <span className="dropdown-menu__item-time">25 min ago</span> */}
                                    </div>
                                  </a>
                                </div>
                              );
                          })}
                        </div>
                        <div className="dropdown-menu__divider" />
                        <Link
                          to="/view-user-notification"
                          className="dropdown-menu__item dropdown-menu__link-all"
                        >
                          Visualizza tutte le notifiche
                          <svg className="icon-icon-keyboard-right">
                            <use xlinkHref="#icon-keyboard-right" />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {userRole === "ROLE_ADMIN" ? (
                    <div className="header__messages header__tools-item">
                      <a
                        onClick={handleOpenNotification}
                        className="header__tools-toggle header__tools-toggle--bell"
                        href="#"
                        data-tippy-content="Messages"
                        data-tippy-placement="bottom"
                        data-toggle="dropdown"
                      >
                        <svg className="icon-icon-bell">
                          <use xlinkHref="#icon-bell" />
                        </svg>
                        <span className="badge-signal" />
                      </a>
                      <div className="dropdown-menu">
                        <div className="dropdown-menu__top dropdown-menu__item">
                          <span className="dropdown-menu__title">
                            Notifiche
                          </span>
                          <span className="badge badge--red">
                            {notification.length}
                          </span>
                        </div>
                        <div
                          className="dropdown-menu__items scrollbar-thin scrollbar-visible"
                          data-simplebar="data-simplebar"
                        >
                          {notification.map((d, index) => {
                            if (index < 3)
                              return (
                                <div className="dropdown-menu__item" key={index}>
                                  <a
                                    className="dropdown-menu__item-remove"
                                    href="#"
                                  >
                                    <svg className="icon-icon-cross">
                                      <use xlinkHref="#icon-cross" />
                                    </svg>
                                  </a>
                                  <a
                                    className="dropdown-menu__item-block dropdown-menu__message"
                                    href="#"
                                  >
                                    <div
                                      className="dropdown-menu__item-right"
                                      onClick={() =>
                                        handleOpenViewNotification(
                                          d.clientId,
                                          "admin"
                                        )
                                      }
                                    >
                                      <div className="dropdown-menu__item-column">
                                        <h4 className="dropdown-menu__item-title">
                                          {d.clientName}
                                        </h4>
                                        <p className="dropdown-menu__text">
                                          Richiesto in questa data{" "}
                                          {moment(d.requestedDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              );
                          })}
                        </div>
                        <div className="dropdown-menu__divider" />
                        <Link
                          to="/view-notification"
                          className="dropdown-menu__item dropdown-menu__link-all"
                        >
                          Visualizza tutte le notifiche
                          <svg className="icon-icon-keyboard-right">
                            <use xlinkHref="#icon-keyboard-right" />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {userRole === "ROLE_CLIENT" ? (
                  <div className="header__profile dropdown">
                    <a
                      className="header__profile-toggle dropdown__toggle"
                      href="#"
                      data-toggle="dropdown"
                    >
                      <div className="header__profile-image">
                        <span className="header__profile-image-text">MA</span>
                        <img src={item4} alt="#" />
                      </div>
                      <div className="header__profile-text">
                        <span>{userName}</span>
                      </div>
                      <span className="icon-arrow-down">
                        <svg className="icon-icon-arrow-down">
                          <use xlinkHref="#icon-arrow-down" />
                        </svg>
                      </span>
                    </a>
                    <div className="profile-dropdown dropdown-menu dropdown-menu--right">
                      {/* <a className="profile-dropdown__item dropdown-menu__item" href="#" tabIndex={0}>
                                                <span className="profile-dropdown__icon">
                                                    <svg className="icon-icon-user">
                                                        <use xlinkHref="#icon-user" />
                                                    </svg>
                                                </span>
                                                <span>Il mio profilo</span>
                                            </a> */}
                      {/* <a className="profile-dropdown__item dropdown-menu__item" href="#" tabIndex={0}>
                                                <span className="profile-dropdown__icon">
                                                    <svg className="icon-icon-chat">
                                                        <use xlinkHref="#icon-chat" />
                                                    </svg>
                                                </span>
                                                <span>My chat</span>
                                            </a> */}
                      <a
                        className="profile-dropdown__item dropdown-menu__item"
                        href="#"
                        tabIndex={0}
                      >
                        <span className="profile-dropdown__icon">
                          <svg className="icon-icon-task">
                            <use xlinkHref="#icon-task" />
                          </svg>
                        </span>
                        <span onClick={handleOpenClientChangePassword}>
                          Cambia Password
                        </span>
                      </a>
                      {/* <a className="profile-dropdown__item dropdown-menu__item" href="#" tabIndex={0}>
                                                <span className="profile-dropdown__icon">
                                                    <svg className="icon-icon-settings">
                                                        <use xlinkHref="#icon-settings" />
                                                    </svg>
                                                </span>
                                                <span>Settings</span>
                                            </a> */}
                      <div className="dropdown-menu__divider" />
                      <a
                        className="profile-dropdown__item dropdown-menu__item"
                        href="#"
                        onClick={handleLogout}
                        tabIndex={0}
                      >
                        <span className="profile-dropdown__icon">
                          <svg className="icon-icon-logout">
                            <use xlinkHref="#icon-logout" />
                          </svg>
                        </span>
                        <span>Logout</span>
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="header__profile dropdown">
                    <a
                      className="header__profile-toggle dropdown__toggle"
                      href="#"
                      data-toggle="dropdown"
                    >
                      <div className="header__profile-image">
                        <span className="header__profile-image-text">MA</span>
                        <img src={item4} alt="#" />
                      </div>
                      <div className="header__profile-text">
                        <span>{userName}</span>
                      </div>
                      <span className="icon-arrow-down">
                        <svg className="icon-icon-arrow-down">
                          <use xlinkHref="#icon-arrow-down" />
                        </svg>
                      </span>
                    </a>
                    <div className="profile-dropdown dropdown-menu dropdown-menu--right">
                      <a
                        className="profile-dropdown__item dropdown-menu__item"
                        href="#"
                        tabIndex={0}
                      >
                        <span className="profile-dropdown__icon">
                          <svg className="icon-icon-user">
                            <use xlinkHref="#icon-user" />
                          </svg>
                        </span>
                        <span onClick={handleOpen}>Il mio profilo</span>
                      </a>
                      {/* <a className="profile-dropdown__item dropdown-menu__item" href="#" tabIndex={0}>
                                            <span className="profile-dropdown__icon">
                                                <svg className="icon-icon-chat">
                                                    <use xlinkHref="#icon-chat" />
                                                </svg>
                                            </span>
                                            <span>My chat</span>
                                        </a> */}
                      <a
                        className="profile-dropdown__item dropdown-menu__item"
                        href="#"
                        tabIndex={0}
                      >
                        <span className="profile-dropdown__icon">
                          <svg className="icon-icon-task">
                            <use xlinkHref="#icon-task" />
                          </svg>
                        </span>
                        <span onClick={handleOpenChangePassword}>
                          Cambia Password
                        </span>
                      </a>
                      {/* <a className="profile-dropdown__item dropdown-menu__item" href="#" tabIndex={0}>
                                            <span className="profile-dropdown__icon">
                                                <svg className="icon-icon-settings">
                                                    <use xlinkHref="#icon-settings" />
                                                </svg>
                                            </span>
                                            <span>Settings</span>
                                        </a> */}
                      <div className="dropdown-menu__divider" />
                      <a
                        className="profile-dropdown__item dropdown-menu__item"
                        href="#"
                        onClick={handleLogout}
                        tabIndex={0}
                      >
                        <span className="profile-dropdown__icon">
                          <svg className="icon-icon-logout">
                            <use xlinkHref="#icon-logout" />
                          </svg>
                        </span>
                        <span>Logout</span>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      <>
        <Modal
          show={open}
          className={open ? "is-active is-animate" : ""}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Aggiorna il profilo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1">Nome</label>
                <input
                  value={details.fullName}
                  onChange={(e) => handleProfile(e)}
                  className="form-control"
                  name="fullName"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1">E-mail</label>
                <input
                  type="email"
                  value={details.email}
                  onChange={(e) => handleProfile(e)}
                  className="form-control"
                  name="email"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1">numero di cellulare</label>
                <input
                  type="email"
                  value={details.mobile}
                  onChange={(e) => handleProfile(e)}
                  className="form-control"
                  name="mobile"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose()}>
              Chiudere
            </Button>
            <Button variant="primary" onClick={() => handleUpdateProfile()}>
              Salva
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={openChangePassword}
          className={openChangePassword ? "is-active is-animate" : ""}
          onHide={handleCloseChangePassowrd}
        >
          <Modal.Header closeButton>
            <Modal.Title>Cambia Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1">
                  Inserisci la vecchia Password
                </label>
                <input
                  type="password"
                  onChange={(e) => handleProfilePassword(e)}
                  className="form-control"
                  name="oldpassword"
                  placeholder="Inserisci la vecchia Password"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1">Nuova Password</label>
                <input
                  type="password"
                  onChange={(e) => handleProfilePassword(e)}
                  className="form-control"
                  name="newpassword"
                  placeholder="Nuova Password"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1">Conferma Password</label>
                <input
                  type="password"
                  onChange={(e) => handleProfilePassword(e)}
                  className="form-control"
                  name="confirmpassword"
                  placeholder="Conferma Password"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => handleCloseChangePassowrd()}
            >
              Chiudere
            </Button>
            <Button variant="primary" onClick={() => handleUpdatePassword()}>
              Salva
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <ToastContainer />
    </>
  );
};
