export const ROOT = "/";
export const DOCUMENT = "/documents";
export const DOCUMENTS = "/documents/:id";
export const DASHBOARD = "/dashboard";
export const CALENDAR = "/calendar";
export const USERS = "/users";
export const OPERATORS = "/operators";
export const DEPARTMENTS = "/departments";
export const TASK_TEMPLATES = "/templates";
export const TASK_COMPLETED = "/task-completed";
export const ASSIGN_OPERATOR = "/assign-operator";
export const PAGE1 = "/page1";
export const AUTH_PAGE1 = "/authorized1";
export const PROFILE = "/profile";
export const SCAN_URL = "/scan/:id";
export const AFTER_SCAN = "/after-scan/:type/:id";
export const REQUEST = "/request";
export const CLIENTTASKCOMPLETED = "/client-task-completed";
export const VIEWNOTIFICATION = "/view-notification";
export const ROOM = "/room";
export const HOSPITAL = "/hospital";
export const FORMPAGE = "/formpage";
export const TASKCOM = "/taskCom";
export const TASKCOMCHECKLIST = "/taskCom-check-list";
export const PRINT = "/Print";
export const FORGOTPASSWORD = "/ForgotPassword";
export const VERIFIEDOTP = "/VerifyOTP";
export const REQESTEDTASK = "/requested-task";
export const VIEWCLIENTNOTIFICATION = "/view-client-notification";
export const VIEWUSERNOTIFICATION = "/view-user-notification";
// export const TASK_COM_ANDROID_PDF = "/TestTaskCom/:operatorId";
export const TASK_COM_ANDROID_PDF = "/taskCom-android-pdf/:operatorId";


