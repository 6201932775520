import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../redux/index";
import { getQRFileFolderDetails } from "../services/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Modal, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { display } from "@mui/system";
import $ from "jquery";
import { Window } from "@mui/icons-material";

export const AfterScan = (props) => {
  const { type, id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [folderData, setFolderData] = useState(null);
  const [open, setOpen] = useState(false);
  const [folderDetails, setFolderDetails] = useState({
    folderName: "",
  });
  const [sideFolderListHtml, setSideFolderListHtml] = useState([]);
  const [fileListHtml, setFileListHtml] = useState([]);
  const [folderListHtml, setFolderListHtml] = useState([]);
  const [fileFolderRowHtml, setFileFolderRowHtml] = useState([]);
  const [dummy, setDummy] = useState(false);

  //Redux Start
  const state = useSelector((state) => state);

  const dispatch = useDispatch();

  const {
    addSideModelOpen,
    addFileFolderDetails,
    addFolderNavigationBar,
    addFolderNavigationBarUuid,
    addParentFolderId,
  } = bindActionCreators(actionCreator, dispatch);

  //Redux End

  const handleViewDetails = () => {
    var dict = {
      clientId: "",
      parentFolderId: id,
    };
    console.log(id);
    console.log(type);
    //debugger;
    getQRFileFolderDetails(dict).then((res) => {
      if (res.errorCode == 0 && type == "Folder") {
        $("#fileOption").css("display", "block");
        var folderRowTempArray = [];
        var fileRowTempArray = [];
        var folderArrayTemp = [];
        var sideFolderArrayTemp = [];
        var fileArrayTemp = [];
        for (
          let i = 0;
          i < res.responsePacket.fileFolderRecordList.length;
          i++
        ) {
          if (
            res.responsePacket.fileFolderRecordList[i].fileFolderType ==
            "Folder"
          ) {
            folderArrayTemp.push(res.responsePacket.fileFolderRecordList[i]);
            folderRowTempArray.push(res.responsePacket.fileFolderRecordList[i]);
            sideFolderArrayTemp.push(
              <li>
                <div
                  className="files-card cursor"
                  onClick={() =>
                    handleFilePage(
                      res.responsePacket.fileFolderRecordList[i].recordId,
                      res.responsePacket.fileFolderRecordList[i].fileFolderType
                    )
                  }
                >
                  <div className="files-card__content">
                    <div className="files-card__checkbox-wrapper">
                      <label className="checkbox checkbox--circle">
                        <input type="checkbox" defaultChecked="checked" />
                        <span className="checkbox__marker">
                          <span className="checkbox__marker-icon">
                            <svg viewBox="0 0 21 18">
                              <path
                                className="border"
                                d="M16.3809 4.29875C16.1581 3.94957 15.6944 3.84715 15.3452 4.06998C14.9961 4.29281 14.8936 4.7565 15.1165 5.10568L16.3809 4.29875ZM12.9024 2.88868C13.2513 3.11197 13.7151 3.01016 13.9384 2.66128C14.1617 2.3124 14.0599 1.84857 13.711 1.62528L12.9024 2.88868ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM15.1165 5.10568C15.8341 6.23014 16.25 7.56535 16.25 9H17.75C17.75 7.27102 17.2478 5.65706 16.3809 4.29875L15.1165 5.10568ZM9 1.75C10.4381 1.75 11.7762 2.16793 12.9024 2.88868L13.711 1.62528C12.3507 0.754648 10.7331 0.25 9 0.25V1.75Z"
                              />
                              <path d="M18.0275 2.42896C18.3156 2.7323 18.3033 3.21181 18 3.49998L8.7036 12.0645C8.40574 12.3475 7.93663 12.3415 7.64613 12.051L5.22189 9.62673C4.92604 9.33088 4.92604 8.85121 5.22189 8.55536C5.51774 8.25951 5.99741 8.25951 6.29326 8.55536L8.19538 10.4575L16.9564 2.4015C17.2598 2.11333 17.7393 2.12563 18.0275 2.42896Z" />
                            </svg>
                          </span>
                        </span>
                      </label>
                    </div>
                    <div className="files-card__icon">
                      <svg className="icon-icon-folder">
                        <use xlinkHref="#icon-folder" />
                      </svg>
                    </div>
                    <h5 className="files-card__title">
                      {
                        res.responsePacket.fileFolderRecordList[i]
                          .visibleFileName
                      }
                    </h5>
                    <div className="files-card__bottom">
                      <div className="files-card__size">8 GB</div>
                      {/* <div className="files-card__date">
                                    <time dateTime="2019-07-12">12.07.2019</time>
                                </div> */}
                    </div>
                  </div>
                </div>
              </li>
            );
          } else {
            folderArrayTemp.push(res.responsePacket.fileFolderRecordList[i]);
            folderRowTempArray.push(res.responsePacket.fileFolderRecordList[i]);
            sideFolderArrayTemp.push(
              <li>
                <div
                  className="files-card cursor"
                  onClick={() =>
                    handleFilePage(
                      res.responsePacket.fileFolderRecordList[i].recordId,
                      res.responsePacket.fileFolderRecordList[i].fileFolderType
                    )
                  }
                >
                  <div className="files-card__content">
                    <div className="files-card__checkbox-wrapper">
                      <label className="checkbox checkbox--circle">
                        <input type="checkbox" defaultChecked="checked" />
                        <span className="checkbox__marker">
                          <span className="checkbox__marker-icon">
                            <svg viewBox="0 0 21 18">
                              <path
                                className="border"
                                d="M16.3809 4.29875C16.1581 3.94957 15.6944 3.84715 15.3452 4.06998C14.9961 4.29281 14.8936 4.7565 15.1165 5.10568L16.3809 4.29875ZM12.9024 2.88868C13.2513 3.11197 13.7151 3.01016 13.9384 2.66128C14.1617 2.3124 14.0599 1.84857 13.711 1.62528L12.9024 2.88868ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM15.1165 5.10568C15.8341 6.23014 16.25 7.56535 16.25 9H17.75C17.75 7.27102 17.2478 5.65706 16.3809 4.29875L15.1165 5.10568ZM9 1.75C10.4381 1.75 11.7762 2.16793 12.9024 2.88868L13.711 1.62528C12.3507 0.754648 10.7331 0.25 9 0.25V1.75Z"
                              />
                              <path d="M18.0275 2.42896C18.3156 2.7323 18.3033 3.21181 18 3.49998L8.7036 12.0645C8.40574 12.3475 7.93663 12.3415 7.64613 12.051L5.22189 9.62673C4.92604 9.33088 4.92604 8.85121 5.22189 8.55536C5.51774 8.25951 5.99741 8.25951 6.29326 8.55536L8.19538 10.4575L16.9564 2.4015C17.2598 2.11333 17.7393 2.12563 18.0275 2.42896Z" />
                            </svg>
                          </span>
                        </span>
                      </label>
                    </div>
                    <a
                      href={
                        res.responsePacket.fileFolderRecordList[i].fileUrl !=
                        null
                          ? res.responsePacket.fileFolderRecordList[i].fileUrl
                          : ""
                      }
                      target="_blank"
                    >
                      <div className="files-card__icon">
                        <svg className="icon-icon-file">
                          <use xlinkHref="#icon-file" />
                        </svg>
                      </div>
                      <h5
                        className="files-card__title"
                        style={{ padding: "20px" }}
                      >
                        {
                          res.responsePacket.fileFolderRecordList[i]
                            .visibleFileName
                        }
                      </h5>
                      <div className="files-card__bottom">
                        <div className="files-card__size">8 GB</div>
                        {/* <div className="files-card__date">
                                        <time dateTime="2019-07-12">12.07.2019</time>
                                    </div> */}
                      </div>
                    </a>
                  </div>
                </div>
              </li>
            );
          }
        }
      } else {
        $("#fileOption").css("display", "none");
        var folderRowTempArray = [];
        var fileRowTempArray = [];
        var folderArrayTemp = [];
        var sideFolderArrayTemp = [];
        var fileArrayTemp = [];
        for (
          let i = 0;
          i < res.responsePacket.parentFolderGetList.length;
          i++
        ) {
          folderArrayTemp.push(res.responsePacket.parentFolderGetList[0]);
          folderRowTempArray.push(res.responsePacket.parentFolderGetList[0]);
          sideFolderArrayTemp.push(
            <li>
              <div className="files-card cursor">
                <div className="files-card__content">
                  <div className="files-card__checkbox-wrapper">
                    <label className="checkbox checkbox--circle">
                      <input type="checkbox" defaultChecked="checked" />
                      <span className="checkbox__marker">
                        <span className="checkbox__marker-icon">
                          <svg viewBox="0 0 21 18">
                            <path
                              className="border"
                              d="M16.3809 4.29875C16.1581 3.94957 15.6944 3.84715 15.3452 4.06998C14.9961 4.29281 14.8936 4.7565 15.1165 5.10568L16.3809 4.29875ZM12.9024 2.88868C13.2513 3.11197 13.7151 3.01016 13.9384 2.66128C14.1617 2.3124 14.0599 1.84857 13.711 1.62528L12.9024 2.88868ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM15.1165 5.10568C15.8341 6.23014 16.25 7.56535 16.25 9H17.75C17.75 7.27102 17.2478 5.65706 16.3809 4.29875L15.1165 5.10568ZM9 1.75C10.4381 1.75 11.7762 2.16793 12.9024 2.88868L13.711 1.62528C12.3507 0.754648 10.7331 0.25 9 0.25V1.75Z"
                            />
                            <path d="M18.0275 2.42896C18.3156 2.7323 18.3033 3.21181 18 3.49998L8.7036 12.0645C8.40574 12.3475 7.93663 12.3415 7.64613 12.051L5.22189 9.62673C4.92604 9.33088 4.92604 8.85121 5.22189 8.55536C5.51774 8.25951 5.99741 8.25951 6.29326 8.55536L8.19538 10.4575L16.9564 2.4015C17.2598 2.11333 17.7393 2.12563 18.0275 2.42896Z" />
                          </svg>
                        </span>
                      </span>
                    </label>
                  </div>
                  <a
                    href={
                      res.responsePacket.parentFolderGetList[0].value != null
                        ? res.responsePacket.parentFolderGetList[0].value
                        : ""
                    }
                    target="_blank"
                  >
                    <div className="files-card__icon">
                      <svg className="icon-icon-file">
                        <use xlinkHref="#icon-file" />
                      </svg>
                    </div>
                    <h5
                      className="files-card__title"
                      style={{ padding: "20px" }}
                    >
                      {res.responsePacket.parentFolderGetList[0].option}
                    </h5>
                    <div className="files-card__bottom">
                      <div className="files-card__size">8 GB</div>
                      {/* <div className="files-card__date">
                                        <time dateTime="2019-07-12">12.07.2019</time>
                                    </div> */}
                    </div>
                  </a>
                </div>
              </div>
            </li>
          );
        }
      }
      const fileFolderRowTempArray =
        folderRowTempArray.concat(fileRowTempArray);
      setSideFolderListHtml(sideFolderArrayTemp);
    });
  };

  const [folderNavArray, setFolderNavArray] = useState(false);

  const handleViewDetails_a = (id) => {
    var dict = {
      clientId: "",
      parentFolderId: id,
    };
    getQRFileFolderDetails(dict).then((res) => {
      console.log(res);
      if (res.errorCode == 0) {
        if (res.responsePacket.fileFolderRecordList.length > 0) {
          var folderRowTempArray = [];
          var fileRowTempArray = [];
          var folderArrayTemp = [];
          var sideFolderArrayTemp = [];
          var fileArrayTemp = [];
          $("#fileOption").css("display", "block");
          for (
            let i = 0;
            i < res.responsePacket.fileFolderRecordList.length;
            i++
          ) {
            if (
              res.responsePacket.fileFolderRecordList[i].fileFolderType ==
              "Folder"
            ) {
              folderArrayTemp.push(res.responsePacket.fileFolderRecordList[i]);
              folderRowTempArray.push(
                res.responsePacket.fileFolderRecordList[i]
              );
              sideFolderArrayTemp.push(
                <li>
                  <div
                    className="files-card cursor"
                    onClick={() =>
                      handleFilePage(
                        res.responsePacket.fileFolderRecordList[i].recordId,
                        res.responsePacket.fileFolderRecordList[i]
                          .fileFolderType
                      )
                    }
                  >
                    <div className="files-card__content">
                      <div className="files-card__checkbox-wrapper">
                        <label className="checkbox checkbox--circle">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="checkbox__marker">
                            <span className="checkbox__marker-icon">
                              <svg viewBox="0 0 21 18">
                                <path
                                  className="border"
                                  d="M16.3809 4.29875C16.1581 3.94957 15.6944 3.84715 15.3452 4.06998C14.9961 4.29281 14.8936 4.7565 15.1165 5.10568L16.3809 4.29875ZM12.9024 2.88868C13.2513 3.11197 13.7151 3.01016 13.9384 2.66128C14.1617 2.3124 14.0599 1.84857 13.711 1.62528L12.9024 2.88868ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM15.1165 5.10568C15.8341 6.23014 16.25 7.56535 16.25 9H17.75C17.75 7.27102 17.2478 5.65706 16.3809 4.29875L15.1165 5.10568ZM9 1.75C10.4381 1.75 11.7762 2.16793 12.9024 2.88868L13.711 1.62528C12.3507 0.754648 10.7331 0.25 9 0.25V1.75Z"
                                />
                                <path d="M18.0275 2.42896C18.3156 2.7323 18.3033 3.21181 18 3.49998L8.7036 12.0645C8.40574 12.3475 7.93663 12.3415 7.64613 12.051L5.22189 9.62673C4.92604 9.33088 4.92604 8.85121 5.22189 8.55536C5.51774 8.25951 5.99741 8.25951 6.29326 8.55536L8.19538 10.4575L16.9564 2.4015C17.2598 2.11333 17.7393 2.12563 18.0275 2.42896Z" />
                              </svg>
                            </span>
                          </span>
                        </label>
                      </div>
                      <div className="files-card__icon">
                        <svg className="icon-icon-folder">
                          <use xlinkHref="#icon-folder" />
                        </svg>
                      </div>
                      <h5 className="files-card__title">
                        {
                          res.responsePacket.fileFolderRecordList[i]
                            .visibleFileName
                        }
                      </h5>
                      <div className="files-card__bottom">
                        <div className="files-card__size">8 GB</div>
                        {/*  <div className="files-card__date">
                                            <time dateTime="2019-07-12">12.07.2019</time>
                                        </div> */}
                      </div>
                    </div>
                  </div>
                </li>
              );
            } else {
              folderArrayTemp.push(res.responsePacket.fileFolderRecordList[i]);
              folderRowTempArray.push(
                res.responsePacket.fileFolderRecordList[i]
              );
              sideFolderArrayTemp.push(
                <li>
                  <div
                    className="files-card cursor"
                    onClick={() =>
                      handleFilePage(
                        res.responsePacket.fileFolderRecordList[i].recordId,
                        res.responsePacket.fileFolderRecordList[i]
                          .fileFolderType
                      )
                    }
                  >
                    <div className="files-card__content">
                      <div className="files-card__checkbox-wrapper">
                        <label className="checkbox checkbox--circle">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="checkbox__marker">
                            <span className="checkbox__marker-icon">
                              <svg viewBox="0 0 21 18">
                                <path
                                  className="border"
                                  d="M16.3809 4.29875C16.1581 3.94957 15.6944 3.84715 15.3452 4.06998C14.9961 4.29281 14.8936 4.7565 15.1165 5.10568L16.3809 4.29875ZM12.9024 2.88868C13.2513 3.11197 13.7151 3.01016 13.9384 2.66128C14.1617 2.3124 14.0599 1.84857 13.711 1.62528L12.9024 2.88868ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM15.1165 5.10568C15.8341 6.23014 16.25 7.56535 16.25 9H17.75C17.75 7.27102 17.2478 5.65706 16.3809 4.29875L15.1165 5.10568ZM9 1.75C10.4381 1.75 11.7762 2.16793 12.9024 2.88868L13.711 1.62528C12.3507 0.754648 10.7331 0.25 9 0.25V1.75Z"
                                />
                                <path d="M18.0275 2.42896C18.3156 2.7323 18.3033 3.21181 18 3.49998L8.7036 12.0645C8.40574 12.3475 7.93663 12.3415 7.64613 12.051L5.22189 9.62673C4.92604 9.33088 4.92604 8.85121 5.22189 8.55536C5.51774 8.25951 5.99741 8.25951 6.29326 8.55536L8.19538 10.4575L16.9564 2.4015C17.2598 2.11333 17.7393 2.12563 18.0275 2.42896Z" />
                              </svg>
                            </span>
                          </span>
                        </label>
                      </div>
                      <a
                        href={
                          res.responsePacket.fileFolderRecordList[i].fileUrl !=
                          null
                            ? res.responsePacket.fileFolderRecordList[i].fileUrl
                            : ""
                        }
                        target="_blank"
                      >
                        <div className="files-card__icon">
                          <svg className="icon-icon-file">
                            <use xlinkHref="#icon-file" />
                          </svg>
                        </div>
                        <h5
                          className="files-card__title"
                          style={{ padding: "20px" }}
                        >
                          {
                            res.responsePacket.fileFolderRecordList[i]
                              .visibleFileName
                          }
                        </h5>
                        <div className="files-card__bottom">
                          <div className="files-card__size">8 GB</div>
                          {/* <div className="files-card__date">
                                        <time dateTime="2019-07-12">12.07.2019</time>
                                    </div> */}
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
              );
            }
          }
        }
        if (res.responsePacket.parentFolderGetList.length > 0) {
          setFolderNavArray(true);
        }
        const fileFolderRowTempArray =
          folderRowTempArray.concat(fileRowTempArray);
        setSideFolderListHtml(sideFolderArrayTemp);
      }
    });
  };

  const handleFilePage = (id) => {
    history.push("/after-scan/" + type + "/" + id);
    handleViewDetails_a(id);
  };

  const goBack = () => {
    history.goBack();
    //setDummy(!dummy);
    setFolderNavArray(true);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    handleViewDetails();
  }, []);

  return (
    <>
      <div>
        <div className="sidebar-backdrop" />
        <div className="page-wrapper">
          <main
            className="page-content"
            style={{ maxWidth: "100%", padding: "0" }}
          >
            <div className="file-manager" style={{ display: "block" }}>
              <div className="file-manager__content">
                <div className="container">
                  <div className="page-tools">
                    <div className="page-tools__breadcrumbs">
                      <div className="breadcrumbs">
                        <div className="breadcrumbs__container" id="fileOption">
                          <ol className="breadcrumbs__list">
                            <li className="breadcrumbs__item">
                              <a className="breadcrumbs__link" href>
                                <svg className="icon-icon-home breadcrumbs__icon">
                                  <use xlinkHref="#icon-home" />
                                </svg>
                                <svg className="icon-icon-keyboard-right breadcrumbs__arrow">
                                  <use xlinkHref="#icon-keyboard-right" />
                                </svg>
                              </a>
                            </li>
                            <li className="breadcrumbs__item">
                              <a className="breadcrumbs__link" href="#">
                                <span className="cursor">File Manager</span>
                              </a>
                              <svg className="icon-icon-keyboard-right breadcrumbs__arrow">
                                <use xlinkHref="#icon-keyboard-right" />
                              </svg>

                              <a
                                className="breadcrumbs__link"
                                onClick={goBack}
                                style={{ marginLeft: "16px" }}
                              >
                                <span className="cursor">
                                  Go to Previous Page
                                </span>
                              </a>
                            </li>
                            {/* {folderNavArray} */}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="page-tools__right">
                      <ul className="page-tools__right-row nav" role="tablist">
                        <li className="page-tools__right-item">
                          <a
                            className="button-icon"
                            href="#tab-grid"
                            data-toggle="tab"
                          >
                            <span className="button-icon__icon">
                              <svg className="icon-icon-grid">
                                <use xlinkHref="#icon-grid" />
                              </svg>
                            </span>
                          </a>
                        </li>
                        <li className="page-tools__right-item">
                          <a
                            className="button-icon active"
                            href="#tab-list"
                            data-toggle="tab"
                          >
                            <span className="button-icon__icon">
                              <svg className="icon-icon-list">
                                <use xlinkHref="#icon-list" />
                              </svg>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ul className="custom-card">{sideFolderListHtml}</ul>
                </div>
              </div>
              <div className="backdrop-sidebar-panel" />
            </div>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
