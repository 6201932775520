import React, { useEffect, useState } from "react";
import { ReactFormBuilder } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import { Modal, Button } from "react-bootstrap";
import { CommanComponents } from "../components/index";
import $ from "jquery";

import {
  getTemplatesKeyValueList,
  getTaskTemplate,
  generateTaskSheet,
  updateTaskSheet,
  getClientKeyValueList,
  getClientDepartmentListInKeyValue,
  getDepartmentDataByHospital,
  getRoomDataByDepartment,
  deleteTaskTemplate,
} from "../services/index";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Swal from "sweetalert2";
import CancelIcon from "@mui/icons-material/Cancel";
 
var formDataEdit = [];
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const FormPage = () => {
  //addrequestdata-> formInfo

  const [taskTempList1, setTaskTempList1] = useState([]);
  const [taskId, setTaskId] = useState([]);

  const [folderData, setFolderData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [getFormData, setGetFormData] = useState([]);
  const [formInfo, setFormInfo] = useState({
    clientId: "",
    departmentId: "",
    roomId: "",
  });

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    setFormInfo({});
    setFolderData([]);
    setGetFormData([]);
    setFormData([]);
    setTaskId("");
    formDataEdit = [];
  };

  const [taskTitle, setTaskTitle] = useState("");
  const [hospitalKeyValuePair, setHospitalKeyValuePair] = useState([]);
  const [departmentKeyValuePair, setDepartmentKeyValuePair] = useState([]);
  const [roomKeyValuePair, setRoomKeyValuePair] = useState([]);
  const [hospital, setHospital] = useState();
  const [department, setDepartment] = useState();
  const [room, setRoom] = useState();

  const handleHospital = (e) => {
    setFormInfo({
      ...formInfo,
      clientId: e.value,
    });
    setHospital(e);

    getDepartmentDataByHospital(e.value)
      .then((res) => {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setDepartmentKeyValuePair(options);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleDepartment = (e) => {
    setFormInfo({
      ...formInfo,
      departmentId: e.value,
    });
    setDepartment(e);

    let dep_id = {
      departmentId: e.value,
    };

    getRoomDataByDepartment(dep_id)
      .then((res) => {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setRoomKeyValuePair(options);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleRoom = (e) => {
    setFormInfo({
      ...formInfo,
      roomId: e.value,
    });
    setRoom(e);
  };

  const getTemplatesList = () => {
    var rId = localStorage.getItem("rId");
    if (rId != undefined) {
      var recordIdForList = rId;
    } else {
      var recordIdForList = "";
    }
    var dict = {
      clientId: recordIdForList,
      requestedPage: 0,
      requestedPageSize: 1000000,
    };
    getTemplatesKeyValueList(dict).then((res) => {
      if (res.errorCode == 0) {
        setTaskTempList1(res.responsePacket);
      } else {
        toast(res.message);
      }
    });
  };

  const handleViewOrEditTemplate = (id) => {
    getTaskTemplate(id).then((res) => {
      if (res.errorCode == 0) {
        setTaskId(id);

        var d = res.responsePacket;
        console.log("ddddd", d);
        setFormInfo(d);
        setHospital({
          value: d.clientId,
          label: d.clientName,
        });
        setDepartment({
          value: d.departmentId,
          label: d.departmentName,
        });

        setRoom({
          value: d.roomId,
          label: d.roomName,
        });

        if (
          d.subTaskJson == null ||
          d.subTaskJson == "null" ||
          d.subTaskJson == undefined ||
          d.subTaskJson == []
        ) {
          setFormData([]);
          setGetFormData([]);
          setTaskTitle(d.taskTitle);
          setShowModal(true);
          setFolderData([]);
        } else {
          formDataEdit = JSON.parse(d?.subTaskJson);
          if (formDataEdit) {
            setFolderData(formDataEdit);
            setFormData(formDataEdit?.task_data);
          } else {
            setFolderData([]);
            setFormData([]);
          }
          console.log(formDataEdit, "formDataEdit");

          setGetFormData(d.subTaskJson);
          setTaskTitle(d.taskTitle);
          setShowModal(true);
        }
      } else {
        toast("❌" + res.message);
      }
    });
  };

  const handleDeleteTaskTemplate = (id) => {
    Swal.fire({
      title: "Sei sicuro?",
      showCancelButton: true,
      confirmButtonText: "Sì, conferma !!",
      cancelButtonText: "No !!",
    }).then((result) => {
      if (result.isConfirmed) {
        $(".se-pre-con").css("display", "block");
        deleteTaskTemplate(id).then((res) => {
          if (res.errorCode == 0) {
            $(".se-pre-con").css("display", "none");
            toast(res.message);
            getTemplatesList();
          } else {
            $(".se-pre-con").css("display", "none");
            toast(res.message);
          }
        });
      }
    });
  };

  const handleShowAdd = () => {
    setTaskId(null);
    formDataEdit = [];
    setShowModal(true);
    setFormInfo([]);
    setFormData([]);
    setHospital();
    setTaskTitle();
    setDepartment();
    setRoom();
    var dict = {
      clientRoleEnum: "CLIENTS",
    };
    $(".se-pre-con").css("display", "block");
    getClientKeyValueList(dict).then((res) => {
      if (res.errorCode == 0) {
        $(".se-pre-con").css("display", "none");
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.option,
          label: d.value,
        }));

        setHospitalKeyValuePair(options);
      } else {
        $(".se-pre-con").css("display", "none");
        toast(res.message);
      }
    });

    setDepartmentKeyValuePair();
    setRoomKeyValuePair();
  };

  const handleSubmit = () => {
    if (formInfo.clientId === undefined || formInfo.clientId === "") {
      toast("Si prega di selezionare prima l'ospedale !");
      return;
    } else if (
      formInfo.departmentId === undefined ||
      formInfo.departmentId === ""
    ) {
      toast("Si prega di selezionare il dipartimento");
      return;
    }
    const dict = {
      taskTitle: taskTitle,
      clientId: formInfo?.clientId,
      departmentId: formInfo?.departmentId,
      roomId: formInfo?.roomId,
      subTaskJson: JSON.stringify(folderData),
    };
    console.log(dict, "dict");

    if (taskId) {
      updateTaskSheet(dict, taskId).then((res) => {
        if (res.errorCode == 0) {
          toast("✅" + res.message);
          handleClose();
          getTemplatesList();
        } else {
          toast("❌" + res.message);
        }
      });
    } else {
      generateTaskSheet(dict).then((res) => {
        if (res.errorCode == 0) {
          toast("✅" + res.message);
          handleClose();
          getTemplatesList();
          setDepartment();
          setRoom();
        } else {
          toast("❌" + res.message);
        }
      });
    }
  };

  const handleSubmitClone = () => {
    if (formInfo.clientId === undefined || formInfo.clientId === "") {
      toast("Si prega di selezionare prima l'ospedale !");
      return;
    } else if (
      formInfo.departmentId === undefined ||
      formInfo.departmentId === ""
    ) {
      toast("Si prega di selezionare il dipartimento");
      return;
    }
    const dict = {
      clientId: formInfo?.clientId,
      departmentId: formInfo?.departmentId,
      roomId: formInfo?.roomId,
      taskTitle: taskTitle + "_clone",
      subTaskJson: getFormData,
    };
    generateTaskSheet(dict).then((res) => {
      if (res.errorCode == 0) {
        toast("✅" + res.message);
        setShowModal(false);
        getTemplatesList();
      } else {
        toast("❌" + res.message);
      }
    });
  };

  useEffect(() => {
    getTemplatesList();

    var dict = {
      clientRoleEnum: "CLIENTS",
    };
    $(".se-pre-con").css("display", "block");
    getClientKeyValueList(dict).then((res) => {
      if (res.errorCode == 0) {
        $(".se-pre-con").css("display", "none");
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.option,
          label: d.value,
        }));

        setHospitalKeyValuePair(options);
      } else {
        $(".se-pre-con").css("display", "none");
        toast(res.message);
      }
    });

    getClientDepartmentListInKeyValue().then((res) => {
      if (res.errorCode == 0) {
        $(".se-pre-con").css("display", "none");
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.option,
          label: d.value,
        }));

        setDepartmentKeyValuePair(options);
      } else {
        $(".se-pre-con").css("display", "none");
        toast(res.message);
      }
    });

    let dep_id = {
      departmentId: "",
    };
    getRoomDataByDepartment(dep_id).then((res) => {
      if (res.errorCode == 0) {
        $(".se-pre-con").css("display", "none");
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.option,
          label: d.value,
        }));

        setRoomKeyValuePair(options);
      } else {
        $(".se-pre-con").css("display", "none");
        toast(res.message);
      }
    });
  }, []);

  const onPost = (data) => {
    console.log(data, "onPostData folderdata");
    setFolderData(data);
  };

  const onLoad = () => {
    console.log("formDataOnLoad", formData);
    return new Promise((resolve, reject) => {
      resolve(formData);
    });
  };

  useEffect(() => {
    console.log("formInfo", formInfo);
    console.log("folderData", folderData);
    console.log("getFormData", getFormData);
    console.log("formData", formData);
  }, [formInfo, folderData, getFormData, formData]);

  return (
    <>
      <Modal
        size="xl"
        show={showModal}
        // onHide={handleClose}
        className={showModal ? "is-active is-animate" : ""}
      >
        <Modal.Header>
          <Modal.Title>Aggiungi modello</Modal.Title>
          <div>
            <Button onClick={handleSubmit}>Submit</Button>&nbsp;&nbsp;
            <Button onClick={handleSubmitClone}>Clona</Button>
            &nbsp;&nbsp;
            <CancelIcon className="pointer" onClick={() => handleClose()} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <table className="mTable">
            <tr>
              <td>Ospedale</td>
              <td>Department</td>
              <td>Room</td>
              <td>Titolo scheda</td>
            </tr>
            <tr>
              <td>
                <Select
                  options={hospitalKeyValuePair}
                  name="roundOffTo"
                  value={hospital}
                  onChange={handleHospital}
                />
              </td>
              <td>
                <Select
                  options={departmentKeyValuePair}
                  name="roundOffTo"
                  value={department}
                  onChange={handleDepartment}
                />
              </td>
              <td>
                <Select
                  options={roomKeyValuePair}
                  name="roundOffTo"
                  value={room}
                  onChange={handleRoom}
                />
              </td>
              <td>
                <input
                  
                  className="form-control"
                  value={taskTitle}
                  onChange={(e) => setTaskTitle(e.target.value)}
                ></input>
              </td>
            </tr>
          </table>
          <br />
          <br />

          <div id="form-page">
            <ReactFormBuilder locale="it" onLoad={onLoad} onPost={onPost} />
          </div>
        </Modal.Body>
      </Modal>

      <div>
        <div className="sidebar-backdrop" />
        <div className="page-wrapper">
          <CommanComponents />
          <main className="page-content">
            <div className="container">
              <div className="row0">
                <div>Elenco modelli</div>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleShowAdd}
                >
                  <i className="fa fa-plus" /> Aggiungi modello
                </button>
              </div>

              <div className="mt-5">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {taskTempList1 &&
                      taskTempList1.map((a) => {
                        return (
                          <Grid item className="cursor col-md-6 col-sm-12">
                            <div className="d-flex align-items-center pointer">
                              <Item
                                className="w-100"
                                onClick={() =>
                                  handleViewOrEditTemplate(a.option)
                                }
                              >
                                {a.value}
                              </Item>
                              <span
                                className="btn bg-primary"
                                style={{ borderRadius: "0px 5px 5px 0" }}
                                onClick={() =>
                                  handleDeleteTaskTemplate(a.option)
                                }
                              >
                                <DeleteOutlineOutlinedIcon
                                  style={{ color: "white" }}
                                />
                              </span>
                            </div>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Box>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
