// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from 'axios'
import { FILE_FOLDER, FILE_UPLOAD,USER_CLIENT } from "./CONSTANTS";

const auth = localStorage.getItem('itemOxygenAuth')

const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': auth != undefined ? "Basic " + auth : ''
    // 'Authorization': url.Auth_Token
}


/**
 * Function to Get File Folder List.
 */
export const getList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_FOLDER() + "list", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getListForUser = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_FOLDER() + "listForUser", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in editFileFolderName axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
/**
 * Function to Create New Folder.
 */
export const addFolder = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_FOLDER() + "add", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addFolder axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
/**
 * Function to Get Folder Or Folder Details.
 */
export const getDetails = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(FILE_FOLDER() + "getFileFolderDetails/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addFolder axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
/**
 * Function to Delete Folder Or Folder Details.
 */
export const deleteRecord = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(FILE_FOLDER() + "delete/" + uuid, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deleteRecord axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
/**
 * Function to Upload Files.
 */
export const uploadFile = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_UPLOAD(), data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addFolder axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const activeQR = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(FILE_FOLDER() + "activeShare/" + id, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addFolder axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getClientKeyValueList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(USER_CLIENT() + "getClientListInKeyValue", {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateFileDetails = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_FOLDER() + "editFileFolderName", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in adminDetail axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getListClientWise = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_FOLDER() + "listForClient", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in adminDetail axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getQRFileFolderDetails = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_FOLDER() + "listWithoutAuth", data,
                )
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in adminDetail axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getFileFolderListForUser = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_FOLDER() + "fileFolderListForUser", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addFileFolderForUser = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_FOLDER() + "addFileFolderForUser", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addFileFolderForUser axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};