import {
    ADD_SIDE_MODEL_OPEN, ADD_FILE_FOLDER_DETAILS, ADD_FOLDER_NAV, ADD_PARENT_FOLDER_ID, ADD_FOLDER_NAV_UUID
} from "../actions/commonActions";

const initialState = {
    loginData: {},
    auth: "",
    sideModel: false,
    fileFolderDetails: null,
    folderNav: "",
    folderNavUuid: "",
    parentFolderId: ""
};

export const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SIDE_MODEL_OPEN:
            return {
                ...state,
                sideModel: action.payload,
            };
        case ADD_FILE_FOLDER_DETAILS:
            return {
                ...state,
                fileFolderDetails: action.payload,
            };
        case ADD_FOLDER_NAV:
            return {
                ...state,
                folderNav: action.payload,
            };
        case ADD_FOLDER_NAV_UUID:
            return {
                ...state,
                folderNavUuid: action.payload,
            };
        case ADD_PARENT_FOLDER_ID:
            return {
                ...state,
                parentFolderId: action.payload,
            };
        default:
            return state;
    }
};
