import React, { useEffect, useState } from "react";
import { CommanComponents } from "../components/index";
import DataTable, {
  createTheme,
  FilterComponent,
} from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import $ from "jquery";
import loader from "../assets/img/content/loader.svg";
import {
  requestListForAdmin,
  rejectRequest,
  acceptRequest,
  deleteCompletedTask,
  deleteRequestedTask,
} from "../services/index";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

export const RequestList = (props) => {
  const [rows, setRows] = useState([]);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [hospitalsKeyValue, setHospitalsKeyValue] = useState([]);
  const [hospitalValue, setHospitalValue] = useState(null);

  const columns = [
    {
      name: "Nome del cliente",
      selector: (row) => row.clientName,
    },
    {
      name: "Data",
      selector: (row) => moment(row.requestedDate).format("DD/MM/YYYY"),
    },
    {
      name: "Stato",
      selector: (row) => row.requestStatusEnum,
    },

    {
      name: "Azione",
      cell: (row) => (
        <>
          <>
            <CheckCircleIcon
              style={{ color: "blue" }}
              onClick={() => handleApproveRequest(row.recordId)}
            />
            <CancelIcon
              style={{ color: "red" }}
              onClick={() => handleRejectRequest(row.recordId)}
            />
            {/* <DeleteIcon
              style={{ color: "red" }}
              onClick={() => handleDelete(row.recordId)}
            /> */}
          </>
        </>
      ),
    },
  ];

  const handleDelete = (recId) => {
    deleteRequestedTask(recId).then((res) => {
      if (res.errorCode == 0) {
        toast(res.message);
        getRequestList();
      } else {
        toast(res.message);
      }
    });
  };

  const handleApproveRequest = (id) => {
    $(".se-pre-con").css("display", "block");
    acceptRequest(id)
      .then((res) => {
        if (res.errorCode == 0) {
          $(".se-pre-con").css("display", "none");
          getRequestList();
          toast(res.message, {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        } else {
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((err) => {
        $(".se-pre-con").css("display", "none");
        toast(err, {
          icon: "🙁",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });
  };

  const handleRejectRequest = (id) => {
    $(".se-pre-con").css("display", "block");
    rejectRequest(id)
      .then((res) => {
        if (res.errorCode == 0) {
          $(".se-pre-con").css("display", "none");
          getRequestList();
          toast(res.message, {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        } else {
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((err) => {
        $(".se-pre-con").css("display", "none");
        toast(err, {
          icon: "🙁",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });
  };

  const getRequestList = () => {
    var rId = localStorage.getItem("rId");
    if (rId != undefined) {
      var recordIdForList = rId;
    } else {
      var recordIdForList = "";
    }
    var dict = {
      requestedPage: 0,
      requestedPageSize: -1,
      searchKey: "",
      clientId: recordIdForList,
    };
    $(".se-pre-con").css("display", "block");
    requestListForAdmin(dict)
      .then((res) => {
        if (res.errorCode == 0) {
          $(".se-pre-con").css("display", "none");
          console.log(res);
          setRows(res.responsePacket);
        } else {
          $(".se-pre-con").css("display", "none");
          toast(res.message, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      })
      .catch((err) => {
        $(".se-pre-con").css("display", "none");
        toast(err, {
          icon: "🙁",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });
  };

  useEffect(() => {
    getRequestList();
  }, []);

  return (
    <>
      <div>
        <div className="sidebar-backdrop" />
        <div className="page-wrapper">
          <CommanComponents />
          <main className="page-content">
            <div className="container">
              <div className="page-header justify-content-between align-items-center d-flex">
                <div>
                  <h1 className="page-header__title">Richieste</h1>
                </div>
              
              </div>
              <div className="row">
                <div style={{ height: "100%", width: "100%" }}>
                  <DataTable
                    columns={columns}
                    data={rows}
                    theme="solarized"
                    pagination
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ToastContainer />
      <div className="se-pre-con">
        <img src={loader} />
      </div>
    </>
  );
};
