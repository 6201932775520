import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Login } from "./index";
import {
  getTaskWorkOrderListDate,
  getTaskWorkOrderList,
  upcomingTaskWorkOrderList,
} from "../services/index";
import { CommanComponents } from "../components/index";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import listPlugin from "@fullcalendar/list"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import $ from "jquery";
import loader from "../assets/img/content/loader.svg";
import moment from "moment";
import allLocales from "@fullcalendar/core/locales-all";
import { Tooltip } from "bootstrap";

export const CalendarEvent = (props) => {
  const [events, setEvents] = useState();
  const [taskStatus, setTaskStatus] = useState();
  const history = useHistory();
  const [rows, setRows] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [checkedStatus, setCheckedStatus] = useState({
    red: false,
    green: false,
    orange: false,
  });

  const handleDateClick = (arg) => {
    var startDateForFilter = arg.el.fcSeg.eventRange.range.start;
    localStorage.setItem("listofDate", startDateForFilter);
    var taskStatus = arg.event._def.extendedProps.taskStatus;
    if (taskStatus == "Completed") {
      history.push("/taskCom");
    } else {
      history.push("/assign-operator");
    }
  };

  const handleButton = (arg) => {
    var start = arg.start;
    var end = arg.end;
    var startDateData = moment(start).format("YYYY-MM-DD");
    var endDateDate = moment(end).format("YYYY-MM-DD");
    var startDateLink = localStorage.setItem("startDateLink", startDateData);
    var endDateLink = localStorage.setItem("endDateLink", endDateDate);
    getEventList(startDateData, endDateDate);
  };

  const getEventList = (startDateData, endDateDate) => {
    var rId = localStorage.getItem("rId");
    if (rId != undefined) {
      var recordIdForList = rId;
    } else {
      var recordIdForList = "";
    }

    let taskStatus = {
      clientId: recordIdForList,
      startDate: startDateData,
      endDate: endDateDate,
    };
    $(".se-pre-con").css("display", "block");
    getTaskWorkOrderListDate(taskStatus).then((res) => {
      var date = new Date().toDateString();
      var modifyDate = moment(date).format("YYYY-MM-DD");
      if (res.errorCode === 0) {
        $(".se-pre-con").css("display", "none");
        let temp = [];
        res.responsePacket.map((event) => {
          let dict = {
            title:event.taskCode +
            " - " +
              event.taskTitle +
              " - (" +
              event.operatorTitle +
              ") - " +
              event.clientTitle +
              " / " +
              event.departmentName +
              " / " +
              event.roomName,
            start: event.dateOfWork,
            end: event.dateOfWork,
            allDay: true,
            backgroundColor:
              event.taskStatus == "Completed"
                ? "green"
                : event.taskStatus == "Todo" && event.dateOfWork > modifyDate
                ? "#f9a100"
                : "red",
            taskStatus: event.taskStatus,
          };
          temp.push(dict);
        });
        setTaskStatus(temp);

        setEvents(temp);
      }
    });
  };

  let tooltipInstance = null;
  const handleMouseEnter = (info) => {
    if (info.jsEvent.isTrusted) {
      tooltipInstance = new Tooltip(info.el, {
        title: info.event._def.title,
        html: true,
        placement: "top",
        trigger: "hover",
        container: "body",
      });

      tooltipInstance.show();
    }
  };

  const handleMouseLeave = (info) => {
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
    }
  };

  const findByColor = (e, type, colorType) => {
    if (colorType === "Green") {
      setCheckedStatus({
        green: e.target.checked,
        red: false,
        orange: false,
      });
    } else if (colorType === "Red") {
      setCheckedStatus({
        green: false,
        red: e.target.checked,
        orange: false,
      });
    } else if (colorType === "Orange") {
      setCheckedStatus({
        green: false,
        red: false,
        orange: e.target.checked,
      });
    }

    var startDateLink = localStorage.getItem("startDateLink");
    var endDateLink = localStorage.getItem("endDateLink");
    if (e.target.checked === true) {
      var rId = localStorage.getItem("rId");
      if (rId != undefined) {
        var recordIdForList = rId;
      } else {
        var recordIdForList = "";
      }

      var taskStatus = {
        color: colorType,
        taskStatusEnum: type,
        clientId: recordIdForList,
        startDate: moment(startDateLink).format("YYYY-MM-DD"),
        endDate: moment(endDateLink).format("YYYY-MM-DD"),
        requestedPage: "0",
        requestedPageSize: "10000",
        clientName: "",
      };
      $(".se-pre-con").css("display", "block");
      upcomingTaskWorkOrderList(taskStatus).then((res) => {
        var date = new Date().toDateString();
        var modifyDate = moment(date).format("YYYY-MM-DD");
        console.log(modifyDate, "modifyDate");
        if (res.errorCode === 0) {
          $(".se-pre-con").css("display", "none");
          let dict = {};
          let temp = [];
          res.responsePacket.map((event) => {
            if (colorType == "Red") {
              dict = {
                title:
                  event.taskTitle +
                  " - (" +
                  event.operatorTitle +
                  ") - " +
                  event.clientTitle +
                  " / " +
                  event.departmentName +
                  " / " +
                  event.roomName,
                start: event.dateOfWork,
                end: event.dateOfWork,
                allDay: true,
                backgroundColor: "red",
                taskStatus: event.taskStatus,
              };
            } else if (colorType === "Orange") {
              dict = {
                title:
                  event.taskTitle +
                  " - (" +
                  event.operatorTitle +
                  ") - " +
                  event.clientTitle +
                  " / " +
                  event.departmentName +
                  " / " +
                  event.roomName,
                start: event.dateOfWork,
                end: event.dateOfWork,
                allDay: true,
                backgroundColor: "#f9a100",
                taskStatus: event.taskStatus,
              };
            } else if (colorType == "Green") {
              dict = {
                title:
                  event.taskTitle +
                  " - (" +
                  event.operatorTitle +
                  ") - " +
                  event.clientTitle +
                  " / " +
                  event.departmentName +
                  " / " +
                  event.roomName,
                start: event.dateOfWork,
                end: event.dateOfWork,
                allDay: true,
                backgroundColor: "green",
                taskStatus: event.taskStatus,
              };
            }
            temp.push(dict);
          });
          setTaskStatus(temp);
          setEvents(temp);
        }
      });
    } else {
      getEventList(startDateLink, endDateLink);
    }
  };

  return (
    <>
      <div>
        <div className="sidebar-backdrop" />
        <div className="page-wrapper">
          <CommanComponents />
          <main className="page-content">
            <div className="container">
              <div className="row align-items-center justify-content-start">
                <div className="">
                  <input
                    type="checkbox"
                    checked={checkedStatus.green}
                    onChange={(e) => findByColor(e, "Completed", "Green")}
                  />
                  &nbsp;Verde
                </div>
                <div className="">
                  <input
                    type="checkbox"
                    checked={checkedStatus.red}
                    onChange={(e) => findByColor(e, "Todo", "Red")}
                  />
                  &nbsp;Rosso
                </div>
                <div className="">
                  <input
                    type="checkbox"
                    checked={checkedStatus.orange}
                    onChange={(e) => findByColor(e, "Todo", "Orange")}
                  />
                  &nbsp;Arancione
                </div>
              </div>

              <div className="page-header">
                <h1 className="page-header__title">Calendario</h1>
              </div>
              <div className="customcalendar" id="custom_cal">
                <FullCalendar
                  plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    listPlugin,
                    interactionPlugin,
                  ]}
                  eventClick={(events) => handleDateClick(events)}
                  initialView="dayGridMonth"
                  events={events}
                  selectable={true}
                  displayEventTime={true}
                  locales={allLocales}
                  locale="it"
                  eventMouseEnter={handleMouseEnter}
                  eventMouseLeave={handleMouseLeave}
                  datesSet={(dateInfo) => handleButton(dateInfo)}
                  expandRows={true}
                  height="100%"
                  navLinks={true}
                  editable={true}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                  }}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
      {/* <div className="se-pre-con">
                <img src={loader}/>
            </div> */}
    </>
  );
};
