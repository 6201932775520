import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CommanComponents } from "../components/index";
import DataTable, {
  createTheme,
  FilterComponent,
} from "react-data-table-component";
import { Modal, Button, Form } from "react-bootstrap";
import {
  getClientList,
  addClient,
  activeClient,
  inActiveClient,
  getUserClientDetails,
  deleteUserClient,
  updateClient,
  getClientKeyValueList,
} from "../services/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Select from "react-select";
import $ from "jquery";
import loader from "../assets/img/content/loader.svg";
import { Multiselect } from "multiselect-react-dropdown";
import CancelIcon from "@mui/icons-material/Cancel";

export const Users = (props) => {
  const [rows, setRows] = useState([]);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [hospitalsKeyValue, setHospitalsKeyValue] = useState([]);
  const [hospitalValue, setHospitalValue] = useState([]);
  const [selectedHospitalValue, setSelectedHospitalValue] = useState([]);
  const [addRequestData, setAddRequestData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
    photoImageUrl: "",
    notes: "",
    recordId: "",
    clientId: "",
    email_two: "",
    mobile_two: "",
    description: "",
  });

  const handleHospital = (selectedItem) => {
    console.log(selectedItem);
    setSelectedHospitalValue(selectedItem);
    //setHospital(e)
  };

  const handleRemove = (selectedItem) => {
    console.log(selectedItem);
    setSelectedHospitalValue(selectedItem);
    //setHospital(e)
  };

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  //const [clientRoleEnum, setClientRoleEnum] = useState(null);

  /* const [roleKeyValue, setRoleKeyValue] = useState([{
        value: "ADMINISTRATION",
        label: "Administration",
    }, {
        value: "CLIENTS",
        label: "Clients",
    }]) */

  const handleClearModal = () => {
    setAddRequestData(true);
    handleClearForm();
  };

  const handleClearForm = () => {
    setShow(false);
  };

  const columns = [
    {
      name: "Nome e cognome",
      selector: (row) => row.fullName,
    },
    {
      name: "Cellulare",
      selector: (row) => row.mobile,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Stato",
      cell: (row) => (
        <>
          {row.active ? (
            <span className="badge badge-success">Attivo</span>
          ) : (
            <span className="badge badge-danger">Inattivo</span>
          )}
        </>
      ),
    },
    {
      name: "Azione",
      cell: (row) => (
        <>
          <ModeEditOutlineOutlinedIcon
            style={{ color: "blue" }}
            onClick={() => handleEditRecord(row.recordId)}
          />
          {row.active ? (
            <CancelOutlinedIcon
              style={{ color: "green" }}
              onClick={() => handleActiveInActive(false, row.recordId)}
            />
          ) : (
            <CheckOutlinedIcon
              style={{ color: "red" }}
              onClick={() => handleActiveInActive(true, row.recordId)}
            />
          )}
          <DeleteOutlineOutlinedIcon
            style={{ color: "red" }}
            onClick={() => handleDeleteUserClient(row.recordId)}
          />
        </>
      ),
    },
  ];

  const handleActiveInActive = (status, uuid) => {
    Swal.fire({
      title: "Sei sicuro?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, conferma !!",
      cancelButtonText: "No !!",
    }).then((result) => {
      if (result.isConfirmed) {
        //Active Request
        if (status) {
          $(".se-pre-con").css("display", "block");
          activeClient(uuid)
            .then((res) => {
              if (res.errorCode == 0) {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "👏",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });

                getHospitalList(0);
              } else {
                toast(res.message, {
                  icon: "🙁",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });
              }
            })
            .catch((err) => {
              toast(err, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            });
        }
        //InActive Request
        else {
          $(".se-pre-con").css("display", "block");
          inActiveClient(uuid)
            .then((res) => {
              if (res.errorCode == 0) {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "👏",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });

                getHospitalList(0);
              } else {
                $(".se-pre-con").css("display", "none");
                toast(res.message, {
                  icon: "🙁",
                  style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                  },
                });
              }
            })
            .catch((err) => {
              $(".se-pre-con").css("display", "none");
              toast(err, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            });
        }
      }
    });
  };

  /* const handleClientRoleEnum = (e) => {
        setClientRoleEnum(e);
      }; */

  const handleEditRecord = (id) => {
    setEdit(true);
    setShow(true);
    setId(id);
    $(".se-pre-con").css("display", "block");
    getUserClientDetails(id).then((res) => {
      if (res.errorCode === 0) {
        $(".se-pre-con").css("display", "none");
        console.log(res);
        setAddRequestData(res.responsePacket);
        let d = res.responsePacket;
        let arr = [];
        let vk = d.clientId.split(",");
        vk?.map((data_get) => {
          console.log(data_get);

          hospitalsKeyValue?.map((data) => {
            if (data_get == data.value) {
              let temp = {
                label: data.label,
                value: data.value,
              };
              console.log(temp);
              arr.push(temp);
            }
          });
        });
        console.log(arr);
        setSelectedHospitalValue(arr);
        /* let d = res.responsePacket;
                let dict = {
                    value: d.clientRoleEnum,
                    label: d.clientRoleEnum,
                }
                setClientRoleEnum(dict) */
      }
    });
  };

  const handleDeleteUserClient = (id) => {
    Swal.fire({
      title: "Sei sicuro?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, conferma !!",
      cancelButtonText: "No !!",
    }).then((result) => {
      if (result.isConfirmed) {
        //Active Request
        $(".se-pre-con").css("display", "block");
        deleteUserClient(id)
          .then((res) => {
            if (res.errorCode == 0) {
              $(".se-pre-con").css("display", "none");
              toast(res.message, {
                icon: "👏",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
              getHospitalList(0);
            } else {
              $(".se-pre-con").css("display", "none");
              toast(res.message, {
                icon: "🙁",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            }
          })
          .catch((err) => {
            $(".se-pre-con").css("display", "none");
            toast(err, {
              icon: "🙁",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          });
      }
    });

    /*  $('.se-pre-con').css('display','block');
        deleteUserClient(id).then((res) => {
            if (res.errorCode === 0) {
                $('.se-pre-con').css('display','none');
                toast(res.message,
                    {
                        icon: '👏',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
                getHospitalList(0)
            } else {
                $('.se-pre-con').css('display','none');
                toast(res.message,
                    {
                        icon: '🙁',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            }
        }) */
  };

  const handlePageChange = (page) => {
    getHospitalList(page - 1);
    setCurrentPage(page);
  };

  const getHospitalList = async (page, size = perPage) => {
    var rId = localStorage.getItem("rId");
    if (rId != undefined) {
      var recordIdForList = rId;
    } else {
      var recordIdForList = "";
    }
    var dict = {
      requestedPage: page,
      requestedPageSize: size,
      searchKey: "",
      clientRoleEnum: "ADMINISTRATION",
      clientId: recordIdForList,
    };
    $(".se-pre-con").css("display", "block");
    getClientList(dict)
      .then((res) => {
        $(".se-pre-con").css("display", "none");
        setRows(res.data);
        setTotalRows(res.recordsTotal);
      })
      .catch((err) => {
        $(".se-pre-con").css("display", "none");
        alert(err);
      });
  };

  const handleShowAdd = () => {
    setEdit(false);
    setShow(true);
    setSelectedHospitalValue([]);
  };

  const handleWarnToast = (data) => {
    toast(data, {
      icon: "🙁",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  };

  const handleSaveHospital = () => {
    let selectedAssociatedClientId = "";
    console.log(selectedHospitalValue);
    for (var i = 0; i < selectedHospitalValue.length; i++) {
      console.log(selectedHospitalValue[i].value);
      selectedAssociatedClientId =
        selectedAssociatedClientId + selectedHospitalValue[i].value + ",";
    }

    let dict = {
      firstName: addRequestData.firstName,
      lastName: addRequestData.lastName,
      clientRoleEnum: "ADMINISTRATION",
      photoImageUrl: "",
      notes: "Test Note",
      mobile: addRequestData.mobile,
      email: addRequestData.email,
      password: addRequestData.password,
      email_two: addRequestData.email_two,
      mobile_two: addRequestData.mobile_two,
      description: addRequestData.description,
      clientId: selectedAssociatedClientId,
    };
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (dict.firstName === undefined || dict.firstName === "") {
      handleWarnToast("Il nome non dovrebbe essere vuoto !");
      return;
    } else if (dict.lastName === undefined || dict.lastName === "") {
      handleWarnToast("il cognome non dovrebbe essere vuoto !");
      return;
    } else if (dict.email === undefined || dict.email === "") {
      handleWarnToast("L e-mail non dovrebbe essere vuota !");
      return;
    } else if (!dict.email.match(mailformat)) {
      handleWarnToast("Si prega di fornire un e-mail valida !");
      return;
    } else if (dict.mobile === "") {
      handleWarnToast("Il numero di cellulare non dovrebbe essere vuoto !");
      return;
    } else if (10 > dict.mobile.length || dict.mobile.length > 10) {
      handleWarnToast("Si prega di fornire un numero di cellulare valido !");
      return;
    } else if (dict.password === undefined || dict.password === "") {
      handleWarnToast("La password non dovrebbe essere vuota !");
      return;
    } else if (dict.clientId === undefined || dict.clientId === "") {
      handleWarnToast("Prego entra Ospedale !");
      return;
    }
    $(".se-pre-con").css("display", "block");
    if (edit) {
      updateClient(id, dict)
        .then((res) => {
          if (res.errorCode == 0) {
            $(".se-pre-con").css("display", "none");
            toast(res.message, {
              icon: "👏",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            setAddRequestData({
              email: "",
              fullName: "",
              mobile: "",
              password: "",
            });
            setShow(false);
            getHospitalList(0);
          } else {
            $(".se-pre-con").css("display", "none");
            toast(res.message, {
              icon: "🙁",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          }
        })
        .catch((err) => {
          $(".se-pre-con").css("display", "none");
          toast(err, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        });
    } else {
      addClient(dict)
        .then((res) => {
          if (res.errorCode == 0) {
            $(".se-pre-con").css("display", "none");
            toast(res.message, {
              icon: "👏",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            setAddRequestData({
              email: "",
              fullName: "",
              mobile: "",
              password: "",
            });
            setShow(false);

            getHospitalList(0);
          } else {
            $(".se-pre-con").css("display", "none");
            toast(res.message, {
              icon: "🙁",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          }
        })
        .catch((err) => {
          $(".se-pre-con").css("display", "none");
          toast(err, {
            icon: "🙁",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        });
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // fetchData(page, newPerPage);
    getHospitalList(0, newPerPage);
    setPerPage(newPerPage);
  };

  useEffect(() => {
    getHospitalList(0);
    getClientKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        $(".se-pre-con").css("display", "none");
        const options = res.responsePacket.map((d) => ({
          value: d.option,
          label: d.value,
        }));
        setHospitalsKeyValue(options);
      } else {
        alert(res.message);
        $(".se-pre-con").css("display", "none");
      }
    });
  }, []);

  return (
    <>
      <Modal
        show={show}
        className={show ? "is-active is-animate" : ""}
        onHide={() => handleClearModal()}
      >
        <Modal.Header>
          <Modal.Title>Utenti</Modal.Title>
          <CancelIcon className="pointer" onClick={() => handleClearModal()} />
        </Modal.Header>
        <Modal.Body>
          <p className="required">tutti i campi sono obbligatori</p>
          <table>
            <tr>
              <td style={{ width: "200px" }}>Nome di battesimo</td>
              <td>
                <input
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={addRequestData.firstName}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      firstName: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Cognome</td>
              <td>
                <input
                  
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={addRequestData.lastName}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      lastName: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>E-mail</td>
              <td>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={addRequestData.email}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      email: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>Alternate E-mail</td>
              <td>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={addRequestData.email_two}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      email_two: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>Cellulare</td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={addRequestData.mobile}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      mobile: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>Alternate Cellulare</td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={addRequestData.mobile_two}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      mobile_two: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>Parola d'ordine</td>
              <td>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  value={addRequestData.password}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      password: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>Ospedale</td>
              <td>
                {" "}
                <Multiselect
                  options={hospitalsKeyValue}
                  selectedValues={selectedHospitalValue}
                  displayValue="label"
                  onSelect={handleHospital}
                  onRemove={handleRemove}
                />
              </td>
            </tr>

            <tr>
              <td>Mansione</td>
              <td>
                <textarea
                  className="form-control"
                  id="exampleInputPassword1"
                  value={addRequestData.description}
                  onChange={(e) =>
                    setAddRequestData({
                      ...addRequestData,
                      description: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="primary"
            className="btn btn-primary"
            onClick={() => handleSaveHospital()}
          >
            Salva
          </button>
        </Modal.Footer>
      </Modal>
      <div>
        <div className="sidebar-backdrop" />
        <div className="page-wrapper">
          <CommanComponents />
          <main className="page-content">
            <div className="container">
              <div className="page-header justify-content-between align-items-center d-flex gap-1">
                <div>
                  <h1 className="page-header__title">Utenti</h1>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm d-flex justify-content-center gap-1"
                    onClick={() => handleShowAdd()}
                  >
                    <i className="fa fa-plus"></i>Aggiungi utente
                  </button>
                </div>
              </div>
              <div className="row">
                <div style={{ height: "100%", width: "100%" }}>
                  <DataTable
                    columns={columns}
                    data={rows}
                    theme="solarized"
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ToastContainer />
      <div className="se-pre-con">
        <img src={loader} />
      </div>
    </>
  );
};
