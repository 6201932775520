// You can use CONSTANTS.js file for below definitions of constants and import here.
export const ADD_SIDE_MODEL_OPEN = "ADD_SIDE_MODEL_OPEN";
export const ADD_FILE_FOLDER_DETAILS = "ADD_FILE_FOLDER_DETAILS";
export const ADD_FOLDER_NAV = "ADD_FOLDER_NAV";
export const ADD_FOLDER_NAV_UUID = "ADD_FOLDER_NAV_UUID";
export const ADD_PARENT_FOLDER_ID = "ADD_PARENT_FOLDER_ID";

export const addSideModelOpen = (payload) => ({
    type: ADD_SIDE_MODEL_OPEN,
    payload
});
export const addFileFolderDetails = (payload) => ({
    type: ADD_FILE_FOLDER_DETAILS,
    payload
});
export const addFolderNavigationBar = (payload) => ({
    type: ADD_FOLDER_NAV,
    payload
});
export const addFolderNavigationBarUuid = (payload) => ({
    type: ADD_FOLDER_NAV_UUID,
    payload
});
export const addParentFolderId = (payload) => ({
    type: ADD_PARENT_FOLDER_ID,
    payload
});



