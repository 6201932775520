import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import {
  Dashboard,
  Documents,
  Login,
  CalendarEvent,
  Users,
  Operators,
  Departments,
  TaskTemplates,
  AssignOperator,
  Scan,
  ClientTaskCompleted,
  AfterScan,
  ForgotPassword,
  VerifyOTP,
  ViewNotification,
} from "../pages/index";
import { NotFound } from "./NotFound";
import {
  ROOT,
  REQUEST,
  CALENDAR,
  DOCUMENTS,
  USERS,
  OPERATORS,
  DEPARTMENTS,
  TASK_TEMPLATES,
  ASSIGN_OPERATOR,
  AUTH_PAGE1,
  SCAN_URL,
  DOCUMENT,
  CLIENTTASKCOMPLETED,
  AFTER_SCAN,
  FORGOTPASSWORD,
  VERIFIEDOTP,
  VIEWCLIENTNOTIFICATION,
  VIEWUSERNOTIFICATION,
  TASK_COM_ANDROID_PDF,
} from "./CONSTANTS";
import { AuthorizedPage1 } from "../pages/AuthorizedPage1";
import PrivateRoute from "./Auth/PrivateRoute";
import { Request } from "./../pages/Request";
import ViewClientNotification from "../pages/ViewClientNotification";
import ViewUserNotification from "../pages/ViewUserNotification";
import SingleRecordPdf from "../pages/completedTasks/SingleRecordPdf";

export const ClientConfig = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    var loginAuth = localStorage.getItem("itemOxygenAuth");
    if (loginAuth == undefined) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <>
      <Switch>
        {/* List all public routes here */}
        {/* <Route exact path={ROOT} component={Home} /> */}
        <Route exact path={TASK_COM_ANDROID_PDF} component={SingleRecordPdf} />
        {isLoggedIn ? (
          <Route exact path={ROOT} component={Request} />
        ) : (
          <Route exact path={ROOT} component={Login} />
        )}
        <Route exact path={DEPARTMENTS} component={Departments} />
        <Route exact path={DOCUMENT} component={Documents} />
        <Route exact path={DOCUMENTS} component={Scan} />
        <Route exact path={REQUEST} component={Request} />
        <Route
          exact
          path={CLIENTTASKCOMPLETED}
          component={ClientTaskCompleted}
        />
        <Route exact path={AFTER_SCAN} component={AfterScan} />
        <Route exact path={FORGOTPASSWORD} component={ForgotPassword} />
        <Route exact path={VERIFIEDOTP} component={VerifyOTP} />
        <Route
          exact
          path={VIEWCLIENTNOTIFICATION}
          component={ViewClientNotification}
        />
        <Route
          exact
          path={VIEWUSERNOTIFICATION}
          component={ViewUserNotification}
        />

        {/* <Route path="/gettingStart">
                    <Startup />
                </Route> */}

        {/* List all private/auth routes here */}
        <PrivateRoute path={AUTH_PAGE1}>
          <AuthorizedPage1 />
        </PrivateRoute>
        {/* <PrivateRoute path={DASHBOARD}>
          <Dashboard />
        </PrivateRoute> */}

        {/* List a generic 404-Not Found route here */}
        {/*  <Route path="*">
                    <NotFound />
                </Route> */}
      </Switch>
    </>
  );
};
